import React from "react";
import PropTypes from "prop-types";
import {
  Icon,
  Text,
  Button,
  ButtonLayout, Tile,
} from "@mbank-design/design-system/components";
import {FontSize, LineHeight} from "@mbank-design/design-system/design-tokens/general/typography";
import "./index.scss";
import {TileBackgroundColor, TileBorderRadius} from "@mbank-design/design-system/components/Tile/Tile.constants";

const InformationTile = (props) => {
  InformationTile.propTypes = {
    titleText: PropTypes.string,
    titleAs: PropTypes.string.isRequired,
    description: PropTypes.string,
    buttonText: PropTypes.string,
    icon: PropTypes.any,
    useClass: PropTypes.string,
    linkUrl: PropTypes.string,
    linkText: PropTypes.string,
    titleStyledAs: PropTypes.string,
  };

  return (
    <div className="information-tile-container">
      <Tile component="div"
            backgroundColor={TileBackgroundColor.WILD_SAND_GRAY}
            borderRadius={[TileBorderRadius.LARGE,TileBorderRadius.EXTRA_LARGE]}
            >
        <div className={`tile-wrapper ${props.useClass || ""}`}>
          {props.icon && <Icon iconComponent={props.icon} />}
          <Text
            as={props.titleAs}
            styledAs={props.titleStyledAs}
            fontSize={props.titleAs === "h4" || props.titleStyledAs === "h4" ? FontSize.SIZE_20 : FontSize.SIZE_16}
            lineHeight={LineHeight.HEIGHT_24}
            className="title"
          >
            {props.titleText}
          </Text>
          {props.description && (
            <Text as="p" className="description">
              {props.description}
            </Text>
          )}

          {props.linkUrl && props.linkText && (
            <Text as="p" className="link">
              <a href={props.linkUrl}>{props.linkText}</a>
            </Text>
          )}

          {props.buttonText && (
            <div className="button-container">
              <ButtonLayout direction="row">
                <Button size="normal" isFullWidth={false} buttonType="primary">
                  {props.buttonText}
                </Button>
              </ButtonLayout>
            </div>
          )}
        </div>
      </Tile>
    </div>
  );
};

export default InformationTile;
