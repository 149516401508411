export function determineChipColor(name) {
  let color = "";
  switch (name.toLowerCase()) {
    case "yellow":
      color = "confettiYellow";
      break;
    case "red":
      color = "brightRedUI";
      break;
    case "green":
      color = "funGreenUI";
      break;
    default:
    case "blue":
      color = "lochmaraBlueUI30";
      break;
      case "endeavourBlue":
        color = "endeavourBlue"
    case "orange":
      color = "selectiveYellow";
      break;
    case "darkred":
      color = "darkRed30";
      break;
    case "grey":
      color = "doveGrayUI";
      break;
  }
  return color;
}
