import React from "react";
import PropTypes from "prop-types";
import "./index.scss";
import { Text } from "@mbank-design/design-system/components";
import { FontSize, FontWeight } from "@mbank-design/design-system/design-tokens/general/typography";

const SectionTitleComponent = (props) => {
  SectionTitleComponent.propTypes = {
    title: PropTypes.string,
    titleAs: PropTypes.string,
    description: PropTypes.string,
    icon: PropTypes.node,
  };

  return (
    <div className="section-title-container">
      <div className="title-and-icon">
        {props.icon}
        <Text
          as={props.titleAs ? props.titleAs : "h2"}
          styledAs={"h1"}
          fontSize={[FontSize.SIZE_32, FontSize.SIZE_48]}
          className="title"
        >
          {props.title} {props.count && `(${props.count})`}
        </Text>
      </div>
      <Text fontWeight={500} as="p">
        {props.description}
      </Text>
    </div>
  );
};

export default SectionTitleComponent;
