import React, { Component } from "react";
import { Autocomplete, Button, Icon, Stripe, Text } from "@mbank-design/design-system/components";
import { Link } from "react-router-dom";
import "./index.scss";
import { ChevronLeft, Clock, Star } from "@mbank-design/design-system/icons";
import { MobileSearchContext } from "../../../scripts/commons/contexts/index";
import { handleAddSearchToLocalStorage } from "../SearchMenu";
import { FontColor, FontSize } from "@mbank-design/design-system/design-tokens/general/typography";
import { IconColor, IconSize } from "@mbank-design/design-system/enums";
import SearchInput from "../SearchInput";

const recomendedSearch = [
  { name: "Nowe samochody", url: "/nowe-samochody" },
  { name: "Poleasingowe samochody", url: "/samochody-poleasingowe" },
  { name: "Elektryczne samochody", url: "/samochody-elektryczne" }
];

const autocompleteInputRef = React.createRef();

export default class MobileMainPageSearch extends Component {
  state = {
    searchCriteria: "",
    selectedModels: [],
    selectedMakes: [],
    isCompany: true,
    bodyType: "",
    url: "",
    numberOfSelectedCars:0,
    selectedModels:[]

  };

  componentDidMount() {
    let sortedMakesAndModels = [];
    this.props.avalibleMakesAndModels.forEach((el) => {
      sortedMakesAndModels.push([
        `${el.Make.Value} (${el.Make.Count})`,
        el.Models.map((el) => `${el.Value} (${el.Count})`)
      ]);
    });

    this.setState({ cars: new Map(sortedMakesAndModels) });
    autocompleteInputRef.current.focus();
    this.blockScroll(true);
    const savedHistory = JSON.parse(localStorage.getItem("searchMenuAsk"));
    savedHistory && this.setState({ viewHistory: savedHistory.toReversed() });
  }

  componentWillUnmount() {
    this.blockScroll(false);
  }

  blockScroll(block) {
    let root = document.getElementById("root");
    let htmlEl = document.getElementsByTagName("html")[0];
    if (root) {
      root.style.overflowY = block ? "hidden" : "unset";
    }
    if (htmlEl) {
      htmlEl.style.overflowY = block ? "hidden" : "unset";
    }
  }

  handleSearchCriteriaChange = (value, reason) => {
    this.setState({ searchCriteria: value });
    this.addClassToAtcList();
  };

  addClassToAtcList = () => {
    setTimeout(() => {
      const tooltips = document.querySelectorAll('[data-component="Grow"]')[0];
      if (tooltips) {
        tooltips.classList.add("atc-list");
      }
    }, 0);
  };

  handleValueChange = (mySelectedOptions) => {
    let url = "";
    let numberOfSelectedCars=0;
    let makes = [];

    mySelectedOptions.forEach((make) => {
      let activeModels = 0;
      make.Models.forEach((model) => {
        if (model.Selected) {
          activeModels++;
        }
      });
      if (activeModels !== 0) {
        makes.push(make.Make.Value);
      }
    });
    let models = [];
    mySelectedOptions.forEach((make) => make.Models.forEach((model) => {if(model.Selected)
    {
    models.push(model.Value);
    numberOfSelectedCars+=model.Count;
    
    }}));

    if (makes.length === 1 && this.findMatchingLengths(makes[0], models)) {
      url = `/marki/${makes[0].toLowerCase()}`;
    } else {
      url = `/lista-pojazdow?${makes.length ? `makes=${makes}&` : ""}${
        models.length ? `models=${models}&` : ""
      }isCompany=${this.state.isCompany}&financialMethod=${
        this.state.isCompany ? "all" : "cash"
      }&selectedSortingValue=0`;
    }

    this.setState({
      ...this.state,
      url,
      numberOfSelectedCars,
      selectedModels:models
     })
  };

  countAmountOfSelectedCars = (cars) => {
    let amountOfCars = 0;
    cars.forEach((model) => {
      const start = model.lastIndexOf("(");
      const end = model.lastIndexOf(")");
      const amountOfModels = Number(model.substring(start + 1, end));
      amountOfCars += amountOfModels;
    });
    return amountOfCars;
  };

  addSearchHistoryToLocalStorage = () => {
    const selectedModels = this.state.selectedModels;

    handleAddSearchToLocalStorage(selectedModels, this.state.url);
  };

  handleSubmit = (mySelectedOptions) => {
    let url = "";

    let makes = [];
    mySelectedOptions.forEach((make) => {
      if (make.Models.length) {
        makes.push(make.Make.Value);
      }
    });
    let models = [];
    mySelectedOptions.forEach((make) => make.Models.forEach((model) => models.push(model.Value)));

    if (!makes.length && !models.length) {
      return;
    }


    if (makes.length === 1 && this.findMatchingLengths(makes[0], models)) {
      url = `/marki/${makes[0].toLowerCase()}`;
    } else {
      url = `/lista-pojazdow?${makes.length ? `makes=${makes}&` : ""}${
        models.length ? `models=${models}&` : ""
      }isCompany=${this.state.isCompany}&financialMethod=${
        this.state.isCompany ? "all" : "cash"
      }&selectedSortingValue=0`;
    }

    setTimeout(() => {
      this.props.history.push(url);
      this.props.toggleSearchMenuState();
    }, 0);
  };

  findMatchingLengths = (makeVal, reference) => {
    const correspondingMakeCars = this.props.avalibleMakesAndModels.find((item) => item.Make.Value === makeVal);
    return correspondingMakeCars.Models.length === reference.length;
  };

  getModelsMakesUrl = () => {
    const selectedModels = this.state.selectedModels.map((str) => {
      const parts = str.split(" ");
      parts.pop();
      return parts.join(" ");
    });

    const selectedMakes = [];
    this.props.avalibleMakesAndModels.forEach((entry) => {
      const models = entry.Models.map((model) => model.Name);
      const hasCommonModels = models.some((model) => selectedModels.includes(model));
      if (hasCommonModels) {
        selectedMakes.push(entry.Make.Value);
      }
    });

    let url = "";
    url = `/lista-pojazdow?${!this.state.isCompany ? "status=Poleasingowy&" : ""}${
      selectedMakes.length ? `makes=${selectedMakes}&` : ""
    }${
      selectedModels.length ? `models=${selectedModels}&` : ""
    }isCompany=true&financialMethod=all&selectedSortingValue=0`;

    return { selectedModels, selectedMakes, url };
  };

  generateSearchButtonLink = () => {
    return this.getModelsMakesUrl().url;
  };

  focusInput = () => {
    autocompleteInputRef.current.focus();
  };

  render() {
    return (
      <>
        <MobileSearchContext.Consumer>
          {({ toggleMobileSearch }) => {
            return (
              <>
                <div className="search-container">
                  <Stripe />
                  <div className="mobile-list-baner-navbar"></div>
                  <div className="search-panel-container">
                    <div className="atc-container">
                      <div className="back-arrow-container" onClick={this.props.closeMobileSearch}>
                        <Icon
                          iconComponent={ChevronLeft}
                          size={IconSize.NORMAL}
                          primaryColor={IconColor.SECONDARY_CONTRAST}
                        ></Icon>
                      </div>
                      <div className="atc-input">
                        <SearchInput
                          label={
                            this.state.selectedMakes.length === 0 &&
                            this.props.literals &&
                            this.props.literals.hp_srch_lbl
                          }
                          options={this.props.avalibleMakesAndModels || []}
                          handleValueChange={this.handleValueChange}
                          handleSubmit={this.handleSubmit} //wysylam
                          autocompleteInputRef={autocompleteInputRef}
                          isMobile
                        />
                      </div>
                    </div>

                    <div className="content-area">
                      {this.state.viewHistory && this.state.viewHistory.length ? (
                        <div className="content-area__history">
                          <div className="content-area__header">
                            <Text styledAs={"h3"}>historia</Text>
                            <p
                              onClick={() => {
                                localStorage.removeItem("searchMenuAsk");
                                this.setState({ viewHistory: [] });
                              }}
                              className="content-area__clear-button"
                            >
                              wyczyść
                            </p>
                          </div>
                          {this.state.viewHistory.slice(0, 3).map((historyVehicle) => (
                            <>
                              <div className="content-area__element">
                                <Icon iconComponent={Clock} size={IconSize.NORMAL}></Icon>
                                <Link to={historyVehicle.url}>
                                  <Text
                                    fontSize={FontSize.SIZE_16}
                                    marginLeft={16}
                                    color={FontColor.DARK}
                                    onClick={this.props.closeMobileSearch}
                                  >
                                    {historyVehicle.name.toString().replaceAll(",", ", ")}
                                  </Text>
                                </Link>
                              </div>
                            </>
                          ))}
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="content-area__recomended">
                        <div className="content-area__header">
                          <Text styledAs={"h3"}>polecane</Text>
                        </div>
                        {recomendedSearch.map((search) => (
                          <div className="content-area__element">
                            <Icon
                              iconComponent={Star}
                              size={IconSize.NORMAL}
                              primaryColor={IconColor.SECONDARY_CONTRAST}
                            ></Icon>
                            <Link to={search.url}>
                              <Text
                                fontSize={FontSize.SIZE_16}
                                marginLeft={16}
                                color={FontColor.DARK}
                                onClick={this.props.closeMobileSearch}
                              >
                                {search.name}
                              </Text>
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                {!!this.state.url && (
                  <div className="search-btn-container" id="search-btn-container" onScroll={() => alert("sc")}>
                    <Link to={this.state.url} className="search-btn-link">
                      <Button
                        className="search-btn"
                        onClick={() => {
                          this.props.closeMobileSearch();
                          this.addSearchHistoryToLocalStorage();
                        }}
                      >
                        {`pokaż wyniki (${this.state.numberOfSelectedCars})`}
                      </Button>
                    </Link>
                  </div>
                )}
              </>
            );
          }}
        </MobileSearchContext.Consumer>
      </>
    );
  }
}
