import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScriptExecutor = () => {
  const location = useLocation();

  useEffect(() => {
    const scriptExecution = (pathname) => {
      const IsNewCarsSubpage = /^\/nowe-samochody\/.+/.test(pathname);

      if (IsNewCarsSubpage) {
        addScript("https://trafficscanner.pl/ws2/script/4006/koniec.js");
        removeScript("https://trafficscanner.pl/ws2/script/3729/koniec.js");
      } else {
        addScript("https://trafficscanner.pl/ws2/script/3729/koniec.js");
        removeScript("https://trafficscanner.pl/ws2/script/4006/koniec.js");
      }
    };

    scriptExecution(location.pathname);

    return () => {
      removeScript("https://trafficscanner.pl/ws2/script/3729/koniec.js");
      removeScript("https://trafficscanner.pl/ws2/script/4006/koniec.js");
    };
  }, [location.pathname]);

  const addScript = (src) => {
    if (!document.querySelector(`script[src="${src}"]`)) {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      script.setAttribute("nonce","2726c7f26c")
      document.body.appendChild(script);
    }
  };

  const removeScript = (src) => {
    const script = document.querySelector(`script[src="${src}"]`);
    if (script) {
      document.body.removeChild(script);
    }
  };

  return null;
};

export default ScriptExecutor;
