import React, { Component } from "react";
import Validator from "../../../scripts/commons/validator";
import {
  Button,
  Checkbox,
  Text,
  TextField,
  Stack,
} from "@mbank-design/design-system/components";
import {
  FontColor,
  FontSize,
  FontWeight,
  LineHeight,
} from "@mbank-design/design-system/design-tokens/general/typography";
import {getSiteRules, sendContactForm, sendContactMarketingForm} from "../../../services";
import { LoadingSpinner } from "../../parts/LoadingSpinner";
import { regexName, regexSurname } from "../../../scripts/commons/regex/regex";
import "./index.scss";
import ReCAPTCHA from "react-google-recaptcha";
import { literalCreator } from "../../../literals/literals";
import {
  setCampaignFormSentAuctionId,
  setCampaignFormSentData,
} from "../../common/campaign-data-setter";
import { dataLayerObj } from "../../../scripts/commons/common";
import { withRouter } from "react-router-dom";
import { CheckboxLabelSize, Direction } from "@mbank-design/design-system/enums";
import { Spacing } from "@mbank-design/design-system/design-tokens/general/spacing";

const recaptchaRef = React.createRef();

class ContactFormDetails extends Component {
  state = {
    email: "",
    emailError: "",
    phoneNumber: "",
    phoneNumberError: "",
    question: "",
    questionError: "",
    agreement: null,
    acceptRules: null,
    isSending: false,
    name: "",
    nameError: "",
    surName: "",
    surNameError: ""
  };

  componentDidMount() {
    literalCreator.getLiterals().then((res) => {
      this.setState({ literals: literalCreator.data });
    });
    getSiteRules().then((res) => {
      let rulesAnchor = document.getElementById("siteRules");
      if (rulesAnchor) {
        rulesAnchor.download = res.data.Name;
        rulesAnchor.href = res.data.Base64;
        rulesAnchor.target = "_blank";
      }
    });
  }

  validateField(fieldName, validators) {
    let isError = false;
    let errorMsg = "";
    let fieldValue =
      typeof this.state[fieldName] === "string" ? this.state[fieldName].toString().trim() : this.state[fieldName];

    if (fieldValue && validators.regex) {
      let regexTestPassed = true;
      if (validators.regex === "email") {
        let regex =
          /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        regexTestPassed = regex.test(fieldValue);
        if (!regexTestPassed) {
          errorMsg = "Email jest nieprawidłowy";
          isError = true;
        }
      }
    }

    if (fieldValue && validators.exactLength) {
      if (fieldValue.toString().length !== validators.exactLength) {
        isError = true;
        errorMsg = `Pole przyjmuje ${validators.exactLength} znaków`;
      }
    }

    if (fieldValue && validators.maxLength) {
      if (fieldValue.toString().length > validators.maxLength) {
        isError = true;
        errorMsg = `Pole przyjmuje maksymalnie ${validators.maxLength} znaków`;
      }
    }

    if (fieldValue && validators.minLength) {
      if (fieldValue.toString().length < validators.minLength) {
        isError = true;
        errorMsg = `Pole wymaga minimum ${validators.minLength} znaków`;
      }
    }

    if (validators.required) {
      if (!fieldValue) {
        isError = true;
        errorMsg = "Pole wymagane";
      }
    }
    this.setState({ [fieldName]: fieldValue, [fieldName + "Error"]: errorMsg });
    return isError;
  }

  sendAfterLeaseContactForm(stateRecaptchaValue) {
    let isCash = this.props.selectedCalcOptions.FinancingMethod == 3;

    sendContactForm({
      AuctionId: this.props.carData.AuctionId,
      CptResp: stateRecaptchaValue,
      Email: this.state.email,
      PhoneNumber: this.state.phoneNumber,
      MessageBody: this.state.question,
      Name: this.state.name,
      Surname: this.state.surName,
      Utm_source: localStorage.getItem("utm_source") ? localStorage.getItem("utm_source") : "",
      Utm_medium: localStorage.getItem("utm_medium") ? localStorage.getItem("utm_medium") : "",
      Utm_campaign: localStorage.getItem("utm_campaign") ? localStorage.getItem("utm_campaign") : "",
      Utm_content: localStorage.getItem("utm_content") ? localStorage.getItem("utm_content") : "",
      Dclid: localStorage.getItem("dclid") ? localStorage.getItem("dclid") : "",
      CapaginId: localStorage.getItem("mAutoCampaign") ? localStorage.getItem("mAutoCampaign") : "",
      CapaginCmId: localStorage.getItem("mAutoclick_id") ? localStorage.getItem("mAutoclick_id") : "",
      CapaginSaId: localStorage.getItem("mAutoGCLID") ? localStorage.getItem("mAutoGCLID") : "",
      CarDetails: {
        Make: this.props.carData.Make,
        Model: this.props.carData.Model,
        PlateNumber: this.props.carData.PlateNumber,
        ProductionYear: this.props.carData.Year,
        CarMileage: this.props.carData.Mileage,
        GearBoxType: this.props.carData.GearBoxType,
        FuelType: this.props.carData.FuelType
      },
      CalculatorContactFormData: this.props.calculatorData
        ? {
            IsLeasing: isCash ? false : this.props.selectedCalcOptions.FinancingMethod !== 1,
            IsNew: false,
            Period: isCash ? -1 : this.props.selectedCalcOptions.Filters.Period || 0,
            Ow: isCash ? -1 : this.props.calcResult.Ow || 0,
            OwPercent: isCash ? -1 : this.props.selectedCalcOptions.Filters.Ow || 0,
            Rv: isCash ? -1 : this.props.calcResult.Rv || 0,
            RvPercent: isCash ? -1 : this.props.selectedCalcOptions.Filters.Rv || 0,
            Mileage: isCash ? -1 : this.props.selectedCalcOptions.Filters.Mileage || 0,
            InstallmentPriceNetto: isCash
              ? -1
              : this.props.calcResult.InstallmentPricePromo > 0
              ? this.props.calcResult.InstallmentPricePromo
              : this.props.calcResult.InstallmentPrice,
            TotalFeesPercent: isCash ? -1 : this.props.calcResult.TotalFees || 0,
            TotalPriceBrutto: this.props.carData.TotalPriceBrutto,
            AdditionalServices: isCash ? [] : this.props.selectedCalcOptions.Filters.AdditionalServices
          }
        : {}
    }).then((res) => {
        dataLayerObj(res.data.LeadId, "afterLease_contact_form");
        setCampaignFormSentData();
        setCampaignFormSentAuctionId(this.props.carData.AuctionId);
        window.location.href = "/wyslano-pomyslnie";
      });
  }

  sendAuctionContactForm(stateRecaptchaValue) {
    sendContactForm({
      AuctionId: this.props.carData.AuctionId,
      CptResp: stateRecaptchaValue,
      Email: this.state.email,
      PhoneNumber: this.state.phoneNumber,
      MessageBody: this.state.question,
      Name: this.state.name,
      Surname: this.state.surName,
      Utm_source: localStorage.getItem("utm_source") ? localStorage.getItem("utm_source") : "",
      Utm_medium: localStorage.getItem("utm_medium") ? localStorage.getItem("utm_medium") : "",
      Utm_campaign: localStorage.getItem("utm_campaign") ? localStorage.getItem("utm_campaign") : "",
      Utm_content: localStorage.getItem("utm_content") ? localStorage.getItem("utm_content") : "",
      Dclid: localStorage.getItem("dclid") ? localStorage.getItem("dclid") : "",
      CapaginId: localStorage.getItem("mAutoCampaign") ? localStorage.getItem("mAutoCampaign") : "",
      CapaginCmId: localStorage.getItem("mAutoclick_id") ? localStorage.getItem("mAutoclick_id") : "",
      CapaginSaId: localStorage.getItem("mAutoGCLID") ? localStorage.getItem("mAutoGCLID") : "",
      CarDetails: {
        Make: this.props.carData.Make,
        Model: this.props.carData.Model,
        PlateNumber: null,
        ProductionYear: this.props.carData.Year,
        CarMileage: this.props.carData.Mileage,
        GearBoxType: this.props.carData.GearBoxType,
        FuelType: this.props.carData.FuelType
      },
      CalculatorContactFormData: {
        IsLeasing: false,
        IsNew: false,
        IsAuction: true,
        Period: -1,
        Ow: -1,
        OwPercent: -1,
        Rv: -1,
        RvPercent: -1,
        Mileage: -1,
        InstallmentPriceNetto: -1,
        TotalFeesPercent: -1,
        TotalPriceBrutto: this.props.carData.TotalPriceBrutto,
        AdditionalServices: []
      }
    }).then((res) => {
        dataLayerObj(res.data.LeadId, "auction_contact_form");
        setCampaignFormSentData();
        setCampaignFormSentAuctionId(this.props.carData.AuctionId);
        window.location.href = "/wyslano-pomyslnie";
      });
  }

  sendNewContactForm(stateRecaptchaValue) {
    sendContactForm({
      AuctionId: this.props.carData.AuctionId,
      CptResp: stateRecaptchaValue,
      Email: this.state.email,
      PhoneNumber: this.state.phoneNumber,
      MessageBody: this.state.question,
      Name: this.state.name,
      Surname: this.state.surName,
      Utm_source: localStorage.getItem("utm_source") ? localStorage.getItem("utm_source") : "",
      Utm_medium: localStorage.getItem("utm_medium") ? localStorage.getItem("utm_medium") : "",
      Utm_campaign: localStorage.getItem("utm_campaign") ? localStorage.getItem("utm_campaign") : "",
      Utm_content: localStorage.getItem("utm_content") ? localStorage.getItem("utm_content") : "",
      Dclid: localStorage.getItem("dclid") ? localStorage.getItem("dclid") : "",
      CapaginId: localStorage.getItem("mAutoCampaign") ? localStorage.getItem("mAutoCampaign") : "",
      CapaginCmId: localStorage.getItem("mAutoclick_id") ? localStorage.getItem("mAutoclick_id") : "",
      CapaginSaId: localStorage.getItem("mAutoGCLID") ? localStorage.getItem("mAutoGCLID") : "",
      CarDetails: {
        Make: this.props.carData.Make,
        Model: this.props.carData.Model,
        PlateNumber: null,
        ProductionYear: this.props.carData.Year,
        CarMileage: this.props.carData.Mileage,
        GearBoxType: this.props.carData.GearBoxType,
        FuelType: this.props.carData.FuelType
      },
      CalculatorContactFormData: {
        IsLeasing: this.props.selectedCalcOptions.FinancingMethod === 2 ? true : false,
        IsNew: true,
        Period: this.props.selectedCalcOptions.Filters.Period || 0,
        Ow: this.props.calcResult.Ow || 0,
        OwPercent: this.props.selectedCalcOptions.Filters.Ow || 0,
        Rv: this.props.calcResult.Rv || 0,
        RvPercent: this.props.selectedCalcOptions.Filters.Rv || 0,
        Mileage: this.props.selectedCalcOptions.Filters.Mileage || 0,
        InstallmentPriceNetto:
          this.props.calcResult.InstallmentPricePromo > 0
            ? this.props.calcResult.InstallmentPricePromo
            : this.props.calcResult.InstallmentPrice,
        TotalFeesPercent: this.props.calcResult.TotalFees || 0,
        TotalPriceBrutto: this.props.carData.TotalPriceBrutto,
        AdditionalServices: this.props.selectedCalcOptions.Filters.AdditionalServices
      }
    })
      .then((res) => {
        dataLayerObj(res.data.LeadId, "new_contact_form");
        setCampaignFormSentData();
        setCampaignFormSentAuctionId(this.props.carData.AuctionId);
        window.location.href = "/wyslano-pomyslnie";
      });
  }

  sendSimpleMessageForm(stateRecaptchaValue) {
    // this.props.origin
    // 0 - product line
    // 1 - financing

    sendContactMarketingForm({
      Email: this.state.email,
      CptResp: stateRecaptchaValue,
      PhoneNumber: this.state.phoneNumber,
      MessageBody: this.state.question,
      Name: this.state.name,
      Surname: this.state.surName,
      FormType: this.props.origin,
      Utm_source: localStorage.getItem("utm_source") ? localStorage.getItem("utm_source") : "",
      Utm_medium: localStorage.getItem("utm_medium") ? localStorage.getItem("utm_medium") : "",
      Utm_campaign: localStorage.getItem("utm_campaign") ? localStorage.getItem("utm_campaign") : "",
      Utm_content: localStorage.getItem("utm_content") ? localStorage.getItem("utm_content") : "",
      Dclid: localStorage.getItem("dclid") ? localStorage.getItem("dclid") : "",
      CapaginId: localStorage.getItem("mAutoCampaign") ? localStorage.getItem("mAutoCampaign") : "",
      CapaginCmId: localStorage.getItem("mAutoclick_id") ? localStorage.getItem("mAutoclick_id") : "",
      CapaginSaId: localStorage.getItem("mAutoGCLID") ? localStorage.getItem("mAutoGCLID") : ""
    }).then((res) => {
      dataLayerObj(res.data.LeadId, "contact_marketing_form");
      window.location.href = "/wyslano-pomyslnie";
    });
  }

  async sendForm(event) {
    let nameErr = this.validateField("name", {
      required: true,
      minLength: 2,
      maxLength: 20
    });
    let surErr = this.validateField("surName", {
      required: true,
      minLength: 2,
      maxLength: 50
    });
    let emailErr = this.validateField("email", {
      required: true,
      minLength: 6,
      maxLength: 50,
      regex: "email"
    });
    let phoneErr = this.validateField("phoneNumber", {
      required: true,
      minLength: 9,
      maxLength: 14
    });
    if (new Validator(event.target).isFormValid && !nameErr && !surErr && !emailErr && !phoneErr) {
      let stateRecaptchaValue=this.state.recaptchaValue;
      if(!this.state.recaptchaValue){
        stateRecaptchaValue = await recaptchaRef.current.executeAsync();
        this.setState({ recaptchaValue: stateRecaptchaValue });
      }
      // CptResp: stateRecaptchaValue,
      if (stateRecaptchaValue) {
        this.setState({ isSending: true });
        if (this.props.contactOnly) {
          this.sendSimpleMessageForm(stateRecaptchaValue);
        } else {
          if (this.props.contactType === "new") {
            this.sendNewContactForm(stateRecaptchaValue);
          } else if (this.props.contactType === "auction") {
            this.sendAuctionContactForm(stateRecaptchaValue);
          } else {
            this.sendAfterLeaseContactForm(stateRecaptchaValue);
          }
        }
      }
    } else {
      this.setState({
        acceptRules: this.state.acceptRules ? true : false,
        agreement: this.state.agreement ? true : false,
      });
    }
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  handleMessageChange(e) {
    this.setState({ question: e.target.value });
  }

  handlePhoneChange(e) {
    this.setState({ phoneNumber: e.target.value });
  }

  handleAgreement(e) {
    this.setState({ [e.target.name]: e.target.checked });
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }

  handleSurNameChange(e) {
    this.setState({ surName: e.target.value });
  }

  render() {
    const state = this.state;

    return (
      <div className={`contact-form-details-section-container ${this.props.useClass || ""}`}>
        <div className="contact-form-details-header">
          <Text as="h1" marginTop={16} className="title" fontWeight={FontWeight.WEIGHT_700}>
            {this.state.literals && this.state.literals.cntct_new_hdr}
          </Text>
          <Text as="body">{this.state.literals && this.state.literals.cntct_new_subhdr}</Text>
        </div>
        {this.state.isSending ? (
          <LoadingSpinner />
        ) : (
          <form>
            <div className="text-field-container">
              <Stack direction={[Direction.COLUMN, Direction.ROW]} spacing={Spacing.SPACE_24}>
                <TextField
                  placeholder="imię"
                  value={state.name}
                  inputProps={{
                    required: "required",
                    pattern: regexName,
                    autoComplete: "given-name",
                    minLength: 2
                  }}
                  errorMessage={this.state.nameError}
                  onChange={(v) => this.handleNameChange(v)}
                  id="name"
                />
                <TextField
                  placeholder="nazwisko"
                  value={state.surName}
                  inputProps={{
                    required: "required",
                    pattern: regexSurname,
                    autoComplete: "family-name",
                    minLength: 2
                  }}
                  errorMessage={this.state.surNameError}
                  onChange={(v) => this.handleSurNameChange(v)}
                  id="surname"
                />
              </Stack>{" "}
            </div>

            <div className="text-field-container">
              <Stack direction={[Direction.COLUMN, Direction.ROW]} spacing={Spacing.SPACE_24}>
                <TextField
                  placeholder="adres e-mail"
                  onChange={(v) => this.handleEmailChange(v)}
                  defaultValue={state.email}
                  errorMessage={this.state.emailError}
                  inputProps={{
                    required: "required",
                    minLength: "3",
                    pattern: "^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$"
                  }}
                  className="form-input"
                  label="Adres e-mail"
                />
                <TextField
                  placeholder="numer telefonu"
                  inputProps={{
                    required: "required",
                    minLength: "7",
                    autoComplete: "tel"
                  }}
                  onChange={(v) => this.handlePhoneChange(v)}
                  errorMessage={this.state.phoneNumberError}
                  className="form-input"
                  label="Numer telefonu"
                />
              </Stack>
            </div>
            <TextField
              className="message-box"
              onChange={(e) => this.handleMessageChange(e)}
              multiline={true}
              inputProps={{}}
              placeholder="wiadomość"
              defaultValue={state.question}
              errorMessage={this.state.questionError}
            />

            <Checkbox
              id="ar"
              name="acceptRules"
              required={true}
              labelSize={CheckboxLabelSize.SMALL}
              fontWeight={FontWeight.WEIGHT_600}
              checked={state.acceptRules}
              onChange={(e) => this.handleAgreement(e)}
              label={
                <Text as="span" fontSize={FontSize.SIZE_12} fontWeight={FontWeight.WEIGHT_600}>
                  Zapoznałem się z <a id="siteRules">regulaminem</a> i akceptuje jego postanowienia.*
                </Text>
              }
              errorMessage={state.acceptRules === false ? "Zgoda jest wymagana" : ""}
            />
            <Checkbox
              id="agreement"
              name="agreement"
              required={true}
              labelSize={CheckboxLabelSize.SMALL}
              fontWeight={FontWeight.WEIGHT_600}
              label="Wyrażam zgodę na wykorzystanie przez mLeasing Sp. z o.o. wskazanych w formularzu numeru telefonu lub adresu e-mail, w celu przedstawienia informacji handlowej (oferty leasingu) zgodnie z ustawą Prawo telekomunikacyjne. Wyrażenie zgody jest dobrowolne lecz niezbędne dla realizacji powyższego celu. Wyrażona zgoda może być cofnięta w każdym czasie, co nie będzie jednak miało wpływu na podejmowane uprzednio na jej podstawie działania.* "
              checked={state.agreement}
              onChange={(e) => this.handleAgreement(e)}
              errorMessage={state.agreement === false ? "Zgoda jest wymagana" : ""}
            />

            {/* <div className="submit-status-container">
            <Text
              fontSize={FontSize.SIZE_12}
              color={FontColor.funGreenUI}
              fontWeight={FontWeight.BOLD}
            >
              wiadomość została wysłana
            </Text>
          </div> */}
            <br />
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6Le-vHMpAAAAAMMtKNbTxwBIKhZaYIm-ci5dNCEg"
                size="invisible"
              />
            {(this.props.selectedCalcOptions ||
              this.props.contactOnly ||
              (this.props.contactType && this.props.contactType === "auction")) && (
              <div className="buttons-container">
                <Button onClick={(e) => this.sendForm(e)} buttonType="primary">
                  {this.state.literals && this.state.literals.cntct_new_cta}
                </Button>
              </div>
            )}
          </form>
        )}

        {!this.state.showMore && this.state.literals && (
          <Text
            fontSize={FontSize.SIZE_12}
            as="p"
            fontWeight={FontWeight.WEIGHT_600}
            lineHeight={LineHeight.HEIGHT_16}
            color={FontColor.MONO_600}
            marginTop={16}
          >
            {this.props.shortDisclaimer
              ? `${this.state.literals.form_disclaimer_short.slice(0, 306)}...`
              : `${this.state.literals.form_disclaimer_long.slice(0, 751)}...`}{" "}
            <span className="show-more-element" onClick={() => this.setState({ showMore: true })}>
              rozwiń
            </span>
          </Text>
        )}
        {this.state.showMore && (
          <>
            <Text
              fontSize={FontSize.SIZE_12}
              as="p"
              fontWeight={FontWeight.WEIGHT_600}
              lineHeight={LineHeight.HEIGHT_16}
              color={"doveGrayUI"}
              marginTop={16}
            >
              {this.state.literals && this.props.shortDisclaimer
                ? this.state.literals.form_disclaimer_short
                : this.state.literals.form_disclaimer_long}
            </Text>
          </>
        )}
      </div>
    );
  }
}

export default withRouter(ContactFormDetails)
