import React, { useEffect, useState } from "react";
import "./index.scss";
import { Icon, Text } from "@mbank-design/design-system/components";
import { FontSize, FontWeight } from "@mbank-design/design-system/design-tokens/general/typography";
import { Cross, QuestionMarkRound } from "@mbank-design/design-system/icons";
import carIllustration from "./img/car.png";
import ContactFormFormModal from "../ContactFormSectionModal";
import { IconColor, IconSize } from "@mbank-design/design-system/enums";
const ContactFormModal = ({ setActiveModal }) => {

  useEffect(() => {
    blockScroll(true);
  
    return () => {
      blockScroll(false);
    };
  }, []);
  

  const blockScroll=(block)=> {
    let htmlEl = document.getElementsByTagName("html")[0];
    if (htmlEl) {
      htmlEl.style.overflowY = block ? "hidden" : "unset";
    }
  }

  return (
    <div className="contact-form-modal-container" onClick={(e) => {setActiveModal(false); e.stopPropagation()}}>
      <div className="contact-form-modal" onClick={(e) => {e.stopPropagation()}}>
        <div className="contact-form-modal__exit" onClick={() => setActiveModal(false)}>
          <Icon iconComponent={Cross} primaryColor={IconColor.SECONDARY_CONTRAST} size={IconSize.NORMAL} />
        </div>
        <Text
          as="h2"
          fontWeight={FontWeight.WEIGHT_600}
          fontSize={FontSize.SIZE_24}
          className="contact-form-modal__title"
        >
          chcesz więcej? sfinansujemy dowolne&nbsp;auto
        </Text>
        <div className="contact-form-modal__form-image-container">
          <div className="contact-form-modal__form-container">
          <ContactFormFormModal
            isMainPageContactModal
          />
          </div>
          <div className="contact-form-modal__image-tile-container">
            <div className="image-tile">
              <div className="image-tile__top">
                <div className="image-tile__shadow"></div>
                <img src={carIllustration} alt="Samochód" className="image-tile__image" />
                <div className="image-tile__icon">
                  <Icon iconComponent={QuestionMarkRound} primaryColor={IconColor.LIGHT} size={64} />
                </div>
              </div>
              <div className="image-tile__bottom">
                <Text
                  as="h3"
                  fontWeight={FontWeight.WEIGHT_600}
                  fontSize={FontSize.SIZE_20}
                  marginBottom={12}
                  className="contact-form-modal__title"
                >
                  Twój wymarzony samochód
                </Text>
                <Text
                  as="p"
                  fontWeight={FontWeight.WEIGHT_500}
                  fontSize={FontSize.SIZE_16}
                  className="contact-form-modal__subtitle"
                >
                  skontaktuj się z nami, a my dopasujemy do Ciebie ofertę
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactFormModal;
