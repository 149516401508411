import React, { useEffect, useState } from "react";
import "./index.scss";
import { Icon, Text } from "@mbank-design/design-system/components";
import { FontColor, FontSize, FontWeight } from "@mbank-design/design-system/design-tokens/general/typography";
import { ChevronDown } from "@mbank-design/design-system/icons";
import { IconColor } from "@mbank-design/design-system/enums";
const EquipmentList = ({ title, list, id }) => {
  const [equipmentCategories, setEquipmentCategories] = useState([]);
  const [equipmentItems, setEquipmentItems] = useState({ items: [], isExtended: false });

  useEffect(() => {
    let categories = [];
    let equipmentsItems = { items: [], isExtended: false };

    let currentCategory;
    if (list[0].label.startsWith("<b>")) {
      list.forEach((item) => {
        if (item.label.startsWith("<b>")) {
          currentCategory = item.label.replace("<b>", "").replace("</b>", "");
          categories.push({ category: currentCategory, items: [], isOpen: false, isExtended: false });
        } else {
          categories[categories.length - 1].items.push(item.label);
        }
      });
    } else {
      list.forEach((item) => {
        equipmentsItems.items.push(item.label);
      });
    }
    setEquipmentItems(equipmentsItems);
    setEquipmentCategories(categories);
  }, []);

  const switchSection = (equipments, action) => {
    if (equipmentCategories.length) {
      let equipmentCategoriesCopy = JSON.parse(JSON.stringify(equipmentCategories));
      const index = equipmentCategories.findIndex((element) => element.category === equipments.category);
      equipmentCategoriesCopy[index][action] = !equipmentCategoriesCopy[index][action];
      setEquipmentCategories(equipmentCategoriesCopy);
    } else {
      let equipmentItemsCopy = JSON.parse(JSON.stringify(equipmentItems));
      equipmentItemsCopy.isExtended = !equipmentItemsCopy.isExtended;
      setEquipmentItems(equipmentItemsCopy);
    }
  };

  return (
    <div className="equipment-list" id={id}>
      <Text as={'h2'} marginBottom={32}>{title}</Text>
      {equipmentCategories.length ? (
        <ListWithCategories equipmentCategories={equipmentCategories} switchSection={switchSection} />
      ) : (
        <CategoryList equipmentItems={equipmentItems} switchSection={switchSection} />
      )}
    </div>
  );
};

export default EquipmentList;

const ListWithCategories = ({ equipmentCategories, switchSection }) => {
  return (
    <>
      {equipmentCategories.map((equipments) => (
        <>
          <div className="equipment-list__category-name-container" onClick={() => switchSection(equipments, "isOpen")}>
            <Text
              className="equipment-list__category-name"
              fontSize={FontSize.SIZE_16}
              fontWeight={FontWeight.WEIGHT_600}
            >
              {equipments.category}
            </Text>
            <div className={`equipment-list__arrow ${equipments.isOpen ? "upside-down" : ""}`}>
              <Icon iconComponent={ChevronDown} primaryColor={IconColor.SECONDARY_CONTRAST} />
            </div>
          </div>
          {equipments.isOpen && <CategoryList equipmentItems={equipments} switchSection={switchSection} />}
        </>
      ))}
    </>
  );
};

const CategoryList = ({ equipmentItems, switchSection }) => {
  return (
    <>
      <div className="equipment-list__category-content">
        <div className="equipment-list__category-lists">
          <ul className="equipment-list__category-list">
            {(equipmentItems.isExtended
              ? equipmentItems.items.slice(0, Math.ceil(equipmentItems.items.length / 2))
              : equipmentItems.items.slice(0, 3)
            ).map((equipment, index) => (
              <li className="equipment-list__category-item" key={index}>
                <Text fontSize={FontSize.SIZE_16} fontWeight={FontWeight.WEIGHT_500} color={FontColor.DARK}>
                  {equipment}
                </Text>
              </li>
            ))}
          </ul>
          <ul className="equipment-list__category-list">
            {(equipmentItems.isExtended
              ? equipmentItems.items.slice(Math.ceil(equipmentItems.items.length / 2), equipmentItems.items.length)
              : equipmentItems.items.slice(3, 6)
            ).map((equipment) => (
              <li className="equipment-list__category-item">
                <Text fontSize={FontSize.SIZE_16} fontWeight={FontWeight.WEIGHT_500} color={FontColor.DARK}>
                  {equipment}
                </Text>
              </li>
            ))}
          </ul>
        </div>
        {equipmentItems.items.length > 6 && (
          <Text
            fontSize={FontSize.SIZE_16}
            fontWeight={FontWeight.WEIGHT_600}
            color={FontColor.SECONDARY_CONTRAST}
            onClick={() => switchSection(equipmentItems, "isExtended")}
            className="equipment-list__extend-button"
          >
            {(equipmentItems.isExtended) ? "pokaż mniej" : "pokaż więcej"}
          </Text>
        )}
      </div>
    </>
  );
};
