import React from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import {
  Button,
  Icon,
  Illustration,
  Text
} from "@mbank-design/design-system/components";
import {
  FontWeight,
} from "@mbank-design/design-system/design-tokens/general/typography";
import { QuestionMarkManMass } from "@mbank-design/design-system/illustrations";
import { Bell } from "@mbank-design/design-system/icons";
import { IconColor, IconSize } from "@mbank-design/design-system/enums";

const AdviseSection = (props) => {
  return (
    <div className={`advise-section-container ${props.useClass || ''}`}>
      <div className="mleasing-main-container">
        <div className="wrapper">
          <div className="content">
            <div className="illustration-container">
              <Illustration illustrationComponent={QuestionMarkManMass} />
            </div>
            <div className="text-container">
              <Text as="p" styledAs={"h4"} fontWeight={FontWeight.BOLD} marginBottom={16}>
                szukasz czegoś konkretnego?
                <br />
                zapisz się na alert
              </Text>
              <Text as="p" size={IconSize.SMALL} marginBottom={32}>
                użyj ikony{" "}
                <span className="icon">
                  <Icon
                    iconComponent={Bell}
                    size={IconSize.SMALL}
                    primaryColor={IconColor.LIGHT}
                  />
                </span>{" "}
                aby ustawić powiadomienie o interesującym Cię
                aucie.
              </Text>
              <div className="buttons-container">
                <Link to={"/alert"}>
                  <Button>utwórz powiadomienie</Button>
                </Link>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdviseSection;
