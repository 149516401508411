import React, { Component } from "react";
import "./index.scss";
import { Amount, Icon, Tag, Text, Tooltip } from "@mbank-design/design-system/components";
import {
  FontColor,
  FontSize,
  FontWeight,
  LineHeight,
} from "@mbank-design/design-system/design-tokens/general/typography";
import { Stack } from "@mbank-design/design-system/components/Stack/Stack";
import { LoadingSpinner } from "../../parts/LoadingSpinner";
import AdditionalPackageTile from "../../parts/AdditionalPackageTile";
import uuid from "react-uuid";
import { replaceSpecialCharsets } from "../../../scripts/commons/common";
import { InfoRound } from "@mbank-design/design-system/icons";

export default class CarTileSummary extends Component {
  calcDataWithUpdateInsurancePrice() {
    let calculatorData = JSON.parse(JSON.stringify(this.props.calculatorData));
    if (calculatorData && calculatorData.CalculationResult && calculatorData.AdditionalServices) {
      calculatorData.AdditionalServices.forEach((service) => {
        if (service.Name === "ubezpieczenie" && !service.Price) {
          service.Price = calculatorData.CalculationResult.InsurancePriceInfo;
        }
      });
    }
    return calculatorData;
  }

  render() {
    let calcData = this.calcDataWithUpdateInsurancePrice();
    let offerData = this.props.offerData;
    let calcPrice= this.props.calcPrice
    return (
      <div
        className="car-tile-summary-container"
      >
        <div
          className="photo-container"
          style={{ backgroundImage: `url(${this.props.previewPhoto || ""})` }}
        >
          {!this.props.previewPhoto && <LoadingSpinner />}
        </div>
        <div className="logo-container" />
        {offerData && calcData && (
          <div className="information-container">
            <div className="information-container-upper">
              <div className="information-container__labels">
                {this.props.pageType && (this.props.pageType === "new" || this.props.pageType === "afterlease") ? (
                  <Tag
                    textColor={this.props.pageType === "new" ? FontColor.DARK : FontColor.LIGHT}
                    key={uuid()}
                    backgroundColor={this.props.pageType === "new" ? "yellow30" : "doveGrayUI"}
                  >
                    {this.props.pageType === "new" ? "nowy" : "poleasingowy"}
                  </Tag>
                ) : (
                  <></>
                )}
                {this.props.chips && this.props.chips.map((label) => {
                  return (
                    <Tag key={uuid()} isBorder>
                      {label.Text}
                    </Tag>
                  );
                })}
              </div>
              <Text
                as="h4"
                fontSize={FontSize.SIZE_20}
                lineHeight={LineHeight.HEIGHT_24}
              >
                {offerData.Make} {offerData.Model}
              </Text>
              <Text
                as="p"
                fontSize={FontSize.SIZE_12}
                fontWeight={FontWeight.WEIGHT_600}
                lineHeight={LineHeight.HEIGHT_16}
              >
                {replaceSpecialCharsets(offerData.Type)}
              </Text>
            </div>
            {this.props.financingMethod !== "4" && (
            <div className="two-parts-container">
              <Stack direction="row">
                <Text
                  as="h5"
                  styledAs={"small"}
                  color={"doveGrayUI"}
                >
                  rodzaj finansowania
                </Text>
                {this.props.FinancingMethod !== 3 && (
                  <Text
                    as="h5"
                    styledAs={"small"}
                    color={"doveGrayUI"}
                  >
                    okres
                  </Text>
                )}
              </Stack>
              <Stack direction="row">
                {calcData && calcData.FinancingMethod && calcData.FinancingMethod.Name && (
                  <Text
                    as="h4"
                    fontWeight={FontWeight.WEIGHT_600}
                    paddingBottom={this.props.FinancingMethod === 3 ? 48 : 16}
                  >
                    {calcData.FinancingMethod.Name}
                  </Text>)}
                {this.props.FinancingMethod !== 3 &&
                  calcData.Periods &&
                  calcData.Periods.Name && (
                    <Text
                      as="h4"
                      paddingBottom={16}
                    >
                      {calcData.Periods.Name} mies.
                    </Text>
                  )}
              </Stack>
              <Stack direction="row">
                {this.props.FinancingMethod !== 3 && (
                  <Text
                    as="h5"
                    styledAs={"small"}
                    color={"doveGrayUI"}
                  >
                    opłata wstępna{!this.props.isAfterLease ? "" : " netto"}
                  </Text>
                )}
                {this.props.FinancingMethod !== 3 &&
                  calcData.Rvs &&
                  calcData.Rvs.Name && (
                    <Text
                      as="h5"
                      fontSize={FontSize.SIZE_12}
                      color={"doveGrayUI"}
                      fontWeight={FontWeight.WEIGHT_600}
                      lineHeight={LineHeight.HEIGHT_16}
                    >
                      wykup{!this.props.isAfterLease ? "" : " netto"}
                    </Text>
                  )}
                {this.props.FinancingMethod !== 3 &&
                  calcData.Mileages &&
                  calcData.Mileages.Name && (
                    <Text
                      as="h5"
                      styledAs={"small"}
                      color={"doveGrayUI"}
                    >
                      przebieg roczny
                    </Text>
                  )}
              </Stack>
              <Stack direction="row">
                {this.props.isAfterLease &&
                  this.props.FinancingMethod !== 3 &&
                  calcData.Ows &&
                  calcData.Ows.Name && (
                    <Text
                      as="h5"
                      fontWeight={FontWeight.WEIGHT_600}
                      paddingBottom={16}
                    >
                      {calcData.Ows.Value}%
                      {calcData.CalculationResult.Ow !== null &&
                        calcData.CalculationResult.Ow !== undefined &&
                        calcData.CalculationResult.Ow !== -1 && (
                          <>
                            {" "}
                            (
                            <Amount
                              styledAsTextTagName="h5"
                              value={calcData.CalculationResult.Ow}
                              type="decimal"
                              isFractionPartShown={false}
                            />
                            zł)
                          </>
                        )}
                    </Text>
                  )}
                {!this.props.isAfterLease &&
                  this.props.FinancingMethod !== 3 &&
                  calcData.Ows &&
                  calcData.Ows.Name && (
                    <Text
                      as="h5"
                      fontWeight={FontWeight.WEIGHT_600}
                      paddingBottom={16}
                    >
                      {calcData.CalculationResult.Ow !== null &&
                        calcData.CalculationResult.Ow !== undefined &&
                        calcData.CalculationResult.Ow !== -1 && (
                          <>
                            {" "}
                            <Amount
                              styledAsTextTagName="h5"
                              value={calcData.CalculationResult.Ow}
                              type="decimal"
                              isFractionPartShown={false}
                            />
                            zł
                          </>
                        )
                      }
                    </Text>
                  )}
                {this.props.FinancingMethod !== 3 &&
                  calcData.Rvs &&
                  calcData.Rvs.Name && (
                    <Text
                      as="h5"
                      fontWeight={FontWeight.WEIGHT_600}
                      paddingBottom={16}
                    >
                      {calcData.Rvs.Name} (
                      {calcData.CalculationResult.Rv !== -1 && (
                        <Amount
                          styledAsTextTagName="h5"
                          value={calcData.CalculationResult.Rv}
                          type="decimal"
                          isFractionPartShown={false}
                        />
                      )}
                      zł)
                    </Text>
                  )}
                {this.props.FinancingMethod !== 3 &&
                  calcData.Mileages &&
                  calcData.Mileages.Name && (
                    <Text
                      as="h5"
                      fontWeight={FontWeight.WEIGHT_600}
                      paddingBottom={16}
                    >
                      {calcData.Mileages.Name} tys. km
                    </Text>
                  )}
              </Stack>
            </div>
            )}
            {calcData && this.props.FinancingMethod != 3 && this.props.financingMethod != 4 && (
              <Stack direction={"column"} spacing={16}>
                {calcPrice && this.props.calcCurrentPrices && (
                  <div className="raw-price-container">
                    <Text as="p" styledAs={"small"} color={calcPrice.InstallmentPricePromo > 0 ? "funGreenUI" :"zeusBlackUI" } fontWeight={FontWeight.WEIGHT_600}>
                      rata finansowa netto
                    </Text>
                    <Text as={"h4"} fontWeight={FontWeight.WEIGHT_600}>
                      <Amount
                        isFractionPartShown={false}
                        currencyDisplay="symbol"
                        currency="PLN"
                        isFractionPartBolded={false}
                        isFractionPartResized={false}
                        styledAsTextTagName={"h2"}
                        value={this.props.calcCurrentPrices.purePrice || 0}
                      />
                    </Text>
                  </div>
                )}
                {calcData &&
                  calcData.AdditionalServices.filter((service) => service.isChecked).map((pkg) => {
                    return (
                      <AdditionalPackageTile
                        package={pkg}
                        packagesState={calcData.AdditionalServicesState}
                        useStateAmount={!!calcData.AdditionalServicesState}
                        key={uuid()}
                        isPreviewOnly={true}
                        color={"doveGrayUI"}
                        tooltipHidden
                      />
                    );
                  })}
              </Stack>
            )}
            {this.props.financingMethod == 4 && (
              <div className="cash-container">
                <Text 
                  as="p"
                  styledAs="h3"
                  fontSize={FontSize.SIZE_24}
                  fontWeight={FontWeight.WEIGHT_600}
                  marginBottom={16}
                  lineHeight={LineHeight.HEIGHT_32}
                > {`W leasingu 105% rata w wysokości `}
                <Amount 
                  isFractionPartShown={false}
                  type="decimal"
                  currencyDisplay="symbol"
                  currency="PLN"
                  value={calcData && calcData.CalculationResult && (calcData.CalculationResult.InstallmentPricePromo > 0 ? calcData.CalculationResult.InstallmentPricePromo : calcData.CalculationResult.InstallmentPrice)}
                />
                {" zł   "}
                <Tooltip               
                  placement="top-end"
                  hint={"Promocyjne warunki dotyczą wybranych samochodów oznaczonych na mAuto.pl hasłem „Leasing 105%”. Dla parametrów: 20% opłaty wstępnej, 1% wartości wykupu oraz czasu trwania umowy leasingu 36 miesięcy suma opłat leasingowych wyniesie 105% dla auta spalinowego. Dla parametrów: 0% opłaty wstępnej, 27 tys. zł dotacji Mój elektryk, 1% wartości wykupu oraz czas trwania umowy leasingu 36 miesięcy suma opłat leasingowych wyniesie 105% dla auta elektrycznego. Ubezpieczenia, serwis opon ani usługi serwisowe nie wliczają się do sumy opłat leasingowych. Minimalny okres umowy leasingu to 36 miesięcy. Umowa tylko w PLN. Suma opłat 105% dotyczy WIBOR 1M z dnia przedstawienia oferty. W zależności od wahań stopy bazowej przyjętej w ofercie suma opłat może się zmienić. Oznacza to, że końcowa suma opłat może być niższa niż 105%, jeżeli stopa bazowa się zmniejszy lub wyższa niż 105%, jeżeli stopa bazowa się zwiększy. Informacja ma charakter zaproszenia do rokowań i nie stanowi oferty w rozumieniu Kodeksu Cywilnego."}               
                  icon={<Icon iconComponent={InfoRound} primaryColor="primary_contrast" />}
                  maxWidth={320}
                />
                </Text>
              </div>
            )}
          </div>
        )}
        {calcData &&
          calcData.CalculationResult &&
          calcData.CalculationResult.InstallmentPrice &&
          this.props.calcCurrentPrices &&
          (this.props.financingMethod != 3 && this.props.financingMethod != 4) && (
            <div className="price-container">
              <Text as="p" styledAs={"small"} fontWeight={FontWeight.WEIGHT_600}>
                rata łącznie netto
              </Text>
              <Text as={"h4"} fontWeight={FontWeight.WEIGHT_600}>
                <Amount
                  isFractionPartShown={false}
                  currencyDisplay="symbol"
                  currency="PLN"
                  isFractionPartBolded={false}
                  isFractionPartResized={false}
                  styledAsTextTagName={"h2"}
                  value={this.props.calcCurrentPrices && this.props.calcCurrentPrices.totalPrice}
                />
              </Text>
            </div>
          )}
        {offerData &&
        offerData.TotalPriceNetto &&
        this.props.FinancingMethod === 3 ? (
          <div className="price-container">
            <Text as="p" fontWeight={FontWeight.WEIGHT_600}>
              cena netto
            </Text>
            <Text as={"h2"} fontWeight={FontWeight.WEIGHT_600}>
              <Amount
                isFractionPartShown={false}
                currencyDisplay="symbol"
                currency="PLN"
                isFractionPartBolded={false}
                isFractionPartResized={false}
                styledAsTextTagName={"h2"}
                value={offerData.TotalPriceNetto}
              />
            </Text>
          </div>
        ) : null}
        {(offerData && offerData.TotalPriceBrutto && this.props.financingMethod != 4 &&
        (this.props.isAfterLease) || (calcData && this.props.FinancingMethod == 3)) ? (
          <div className="price-container">
            <Text
              as="p"
              fontWeight={FontWeight.WEIGHT_600}
              fontSize={FontSize.SIZE_12}
            >
              cena brutto
            </Text>
            <Text
              fontSize={FontSize.SIZE_12}
              as={"p"}
              fontWeight={FontWeight.WEIGHT_600}
              lineHeight={LineHeight.HEIGHT_32}
            >
              <Amount
                isFractionPartShown={false}
                currencyDisplay="symbol"
                currency="PLN"
                isFractionPartResized={false}
                styledAsTextTagName={"p"}
                value={offerData.TotalPriceBrutto}
              />
            </Text>
          </div>
        ) : null}
      </div>
    );
  }
}
