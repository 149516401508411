import { Text } from "@mbank-design/design-system/components";
import React from "react";
import "../../../assets/styles/components/question-text-section-container.scss";
import { FontColor, FontSize, FontWeight, LineHeight } from "@mbank-design/design-system/design-tokens/general/typography";

const QuestionTextSectionElectricCar = () => {
  return (
    <div className='question-text-section-container mleasing-main-container'>

      <Text as='h5' fontSize={FontSize.SIZE_24} lineHeight={LineHeight.HEIGHT_32}>
        Samochody elektryczne – kierunek przyszłości
      </Text>
      <Text
        as='p'
        fontSize={FontSize.SIZE_12}
        lineHeight={LineHeight.HEIGHT_16}
        fontWeight={FontWeight.WEIGHT_600}
        color={"doveGrayUI"}
      >
        W ostatnich latach rynek motoryzacji przechodzi prawdziwą elektryczną rewolucję. Elektromobilność staje się
        bardzo ważnym trendem, który może nadać kierunek rozwoju branży na najbliższe dekady. Rosnąca obawa o stan
        środowiska oraz zmieniające się normy dotyczące poziomu zanieczyszczeń i spalin dodatkowo przyspieszają ten
        proces.
      </Text>
      <Text
        as='p'
        fontSize={FontSize.SIZE_12}
        lineHeight={LineHeight.HEIGHT_16}
        fontWeight={FontWeight.WEIGHT_600}
        color={"doveGrayUI"}
      >
        Na te wyzwania odpowiadają także producenci samochodów. Zdecydowana większość marek ma dziś w swojej ofercie
        przynajmniej jeden pojazd zasilany energią elektryczną. Auta te wyróżniają się nie tylko wyjątkową estetyką,
        lecz także zastosowaniem wielu nowoczesnych rozwiązań. Przewaga pojazdów z silnikiem benzynowym powoli topnieje,
        co zaczynają dostrzegać również kierowcy.
      </Text>

      <Text as='h5' fontSize={FontSize.SIZE_24} lineHeight={LineHeight.HEIGHT_32}>
        W stronę nowoczesnych rozwiązań
      </Text>
      <Text
        as='p'
        fontSize={FontSize.SIZE_12}
        lineHeight={LineHeight.HEIGHT_16}
        fontWeight={FontWeight.WEIGHT_600}
        color={"doveGrayUI"}
      >
        W parze z modą na samochody elektryczne idą także zmiany, które mają ułatwić życie przyszłym użytkownikom takich
        modeli. Przez wiele lat jednym z głównych mankamentów elektryków był ich zasięg. W opinii kierowców takie
        pojazdy nieźle sprawdzały się w przestrzeni miejskiej, jednak nie były jeszcze wystarczająco przystosowane do
        dłuższych tras.
      </Text>
      <Text
        as='p'
        fontSize={FontSize.SIZE_12}
        lineHeight={LineHeight.HEIGHT_16}
        fontWeight={FontWeight.WEIGHT_600}
        color={"doveGrayUI"}
      >
        Powoli zaczyna się to jednak zmieniać – wraz z ciągle rosnącymi możliwościami samochodów elektrycznych. Topowe
        modele (np. Tesli) są dziś w stanie pokonać odległość nawet ponad 600 km na jednym ładowaniu. Ponadto oferta
        elektryków nie ogranicza się już wyłącznie do niewielkich hatchbacków przystosowanych do miejskich warunków.
        Mercedes, Volkswagen, Hyundai i wielu innych producentów montuje silniki elektryczne także pod maskami SUV-ów,
        eleganckich limuzyn i wszechstronnych crossoverów.
      </Text>
      <Text
        as='p'
        fontSize={FontSize.SIZE_12}
        lineHeight={LineHeight.HEIGHT_16}
        fontWeight={FontWeight.WEIGHT_600}
        color={"doveGrayUI"}
      >
        Na przystępność samochodów elektrycznych wpływa także zmieniająca się infrastruktura. Stacje ładowania nie
        stanowią już dziś rzadkości – bez trudu można je znaleźć w największych miastach Polski. Dzięki temu planowanie
        nawet długich podróży przestaje stanowić problem.
      </Text>

      <Text as='h5' fontSize={FontSize.SIZE_24} lineHeight={LineHeight.HEIGHT_32}>
        Firmowy samochód elektryczny – dlaczego warto?
      </Text>
      <Text
        as='p'
        fontSize={FontSize.SIZE_12}
        lineHeight={LineHeight.HEIGHT_16}
        fontWeight={FontWeight.WEIGHT_600}
        color={"doveGrayUI"}
      >
        Wyposażenie firmowej floty w samochody elektryczne może przynieść wiele korzyści – zarówno finansowych, jak i
        wizerunkowych. Na właścicieli takich aut czeka bowiem szereg udogodnień, które mogą znacząco ułatwić prowadzenie
        działalności.
        <br/><br/>
        <b>Dlaczego warto inwestować w firmowe samochody elektryczne?</b>
        <ol>
          <li><b>Obniżenie kosztów eksploatacji</b> – samochody elektryczne pozwalają wygenerować znaczące oszczędności w
            porównaniu z tradycyjnymi pojazdami napędzanymi paliwem. Ładowanie elektryków może być tańsze nawet o
            połowę! Warto także zwrócić uwagę na trwałość nowoczesnych modeli, która może dodatkowo obniżyć wydatki na
            konserwację i naprawy.
          </li>
          <li><b>Dodatkowe przywileje dla kierowców</b> – podróżujący samochodami z napędem elektrycznym mogą omijać korki za
            pomocą buspasów. Oprócz tego zyskują możliwość bezpłatnego korzystania z miejskich stref płatnego
            parkowania.
          </li>
          <li><b>Redukcja emisji zanieczyszczeń</b> – auta z silnikiem elektrycznym stanowią mniejsze obciążenie dla
            środowiska, m.in. wskutek ograniczonej emisji CO2. Dzięki temu wspierasz planetę oraz budujesz wizerunek
            innowacyjnego i odpowiedzialnego przedsiębiorstwa.
          </li>
          <li><b>Finansowe wsparcie w ramach programów ochrony środowiska</b> – rozwój elektromobilności jest wspierany także
            przez rządowe dotacje. Możesz skorzystać np. z programu „Mój elektryk” prowadzonego przez Narodowy Fundusz
            Ochrony Środowiska i Gospodarki Wodnej (patrz dalej).
          </li>
        </ol>
      </Text>

      <Text as='h5' fontSize={FontSize.SIZE_24} lineHeight={LineHeight.HEIGHT_32}>
        Samochód elektryczny a wizerunek marki
      </Text>
      <Text
        as='p'
        fontSize={FontSize.SIZE_12}
        lineHeight={LineHeight.HEIGHT_16}
        fontWeight={FontWeight.WEIGHT_600}
        color={"doveGrayUI"}
      >
        Firmowy samochód to dziś znacznie więcej niż po prostu narzędzie pracy. Flota pojazdów stanowi ważny element
        budowania wizerunku firmy w oczach kontrahentów. Auto świadczy nie tylko o zaradności i finansowych
        możliwościach przedsiębiorcy. Daje także wgląd w filozofię marki, co jest bardzo istotne w kontekście
        potencjalnej współpracy.
      </Text>
      <Text
        as='p'
        fontSize={FontSize.SIZE_12}
        lineHeight={LineHeight.HEIGHT_16}
        fontWeight={FontWeight.WEIGHT_600}
        color={"doveGrayUI"}
      >
        Nie inaczej dzieje się w przypadku samochodów elektrycznych. To nowoczesne rozwiązania, po które chętnie sięgają
        przede wszystkim firmy chcące wzmocnić innowacyjny i pionierski wizerunek swoich marek. Taki wybór może
        świadczyć o nieszablonowym podejściu i proaktywności – cechach tak istotnych we współczesnym biznesie. Do tego
        dochodzi oczywiście także działanie w duchu troski o środowisko.
      </Text>
      <Text
        as='p'
        fontSize={FontSize.SIZE_12}
        lineHeight={LineHeight.HEIGHT_16}
        fontWeight={FontWeight.WEIGHT_600}
        color={"doveGrayUI"}
      >
        Na wizerunek firmy korzystnie wpływają również elegancja i design nowoczesnych elektryków. Efektowne linie
        nadwozia i stylowe wykończenie stawiają je w szeregu z topowymi modelami z segmentu premium.
      </Text>

      <Text as='h5' fontSize={FontSize.SIZE_24} lineHeight={LineHeight.HEIGHT_32}>
        Samochód elektryczny – wybierz dogodną formę finansowania
      </Text>
      <Text
        as='p'
        fontSize={FontSize.SIZE_12}
        lineHeight={LineHeight.HEIGHT_16}
        fontWeight={FontWeight.WEIGHT_600}
        color={"doveGrayUI"}
      >
        Samochody elektryczne z oferty mAuto są dostępne w dwóch wariantach finansowania:
        <ol>
          <li>
            <b>Leasing</b> – w jego ramach spłacasz całą wartość samochodu, a po zakończeniu umowy możesz go wykupić (nawet
            za 1% pierwotnej ceny). W leasingu nie obowiązuje Cię limit kilometrów.
          </li>
          <li><b>Najem</b> – w tym przypadku spłacasz jedynie kwotę pokrywającą utratę wartości pojazdu. Takie rozwiązanie
            skutkuje niższą ratą, a po zakończeniu umowy możesz swobodnie wymienić samochód na nowszy model.
          </li>
        </ol>
        W obu formach finansowania zyskujesz możliwość swobodnego dopasowania oferty do swoich potrzeb.
        <br/><br/>
        W ramach miesięcznej raty w mAuto skorzystasz także z dodatkowych udogodnień. Zaliczają się do nich:
        <ol>
          <li>kompleksowa ochrona ubezpieczeniowa (OC, AC, NNW oraz polisa Autoszyba),</li>
          <li>assistance,</li>
          <li>pakiet serwisowy (w przypadku najmu dostępny w dwóch wariantach – Basic lub Comfort),</li>
          <li>pakiet Opony (dostępny w ofercie najmu).</li>
        </ol>
      </Text>
      <Text as='h5' fontSize={FontSize.SIZE_24} lineHeight={LineHeight.HEIGHT_32}>
        Zyskaj dofinansowanie w ramach programu „Mój elektryk”
      </Text>
      <Text
        as='p'
        fontSize={FontSize.SIZE_12}
        lineHeight={LineHeight.HEIGHT_16}
        fontWeight={FontWeight.WEIGHT_600}
        color={"doveGrayUI"}
      >
        Decydując się na firmowy samochód elektryczny, możesz stać się beneficjentem programu „<a
        href="https://mleasing.pl/elektromobilnosc/" target="_blank">Mój elektryk</a>”
        przygotowanego przez Narodowy Fundusz Ochrony Środowiska i Gospodarki Wodnej. W ramach projektu przedsiębiorcy
        mogą uzyskać dotację do leasingu nowego pojazdu – nawet do 70 tys. zł. Wsparcie jest bezzwrotne i dotyczy
        zeroemisyjnych samochodów osobowych lub dostawczych.
      </Text>
      <Text
        as='p'
        fontSize={FontSize.SIZE_12}
        lineHeight={LineHeight.HEIGHT_16}
        fontWeight={FontWeight.WEIGHT_600}
        color={"doveGrayUI"}
      >
        Aby ułatwić Ci przejście przez procedury, mLeasing oferuje kompleksową pomoc w uzyskaniu dotacji. W ramach
        współpracy możesz wybrać interesujący Cię model samochodu, a doradca upewni się, czy wybrane auto spełnia
        wszystkie wymagania programu. Dodatkowo uzyskasz pomoc w wypełnieniu wniosku o dotację i formularza
        leasingowego. Po zaakceptowaniu wniosku pozostaje już tylko podpisać umowę i odebrać nowy samochód!
      </Text>
      <Text
        as='p'
        fontSize={FontSize.SIZE_12}
        lineHeight={LineHeight.HEIGHT_16}
        fontWeight={FontWeight.WEIGHT_600}
        color={"doveGrayUI"}
      >
        Wnioski o dotację w ramach programu „Mój elektryk” można składać od 22 listopada 2021 roku do 30 września 2025
        roku.
      </Text>
    </div>
  );
};

export default QuestionTextSectionElectricCar;
