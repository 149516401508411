import React from "react";
import { Helmet } from "react-helmet";
import { capitalize, isOdd, replaceSpecialCharsets } from "../../../scripts/commons/common";
import {newTileLinkCreator} from "../../../services/newTileLinkCreator";

 const DetailsPageHelmet = (props) => {
  return (
    <Helmet>
      {props.pageType === "new" && isOdd(props.paramId) && (
        <meta
          name="description"
          content={`Przymierzasz się do zakupu samochodu? Szukasz ${props.details.Make} ${props.details.Model}? Sprawdź ofertę nowych samochodów na mAuto i wybierz coś dla siebie.`}
        />
      )}
      {props.pageType === "new" && !isOdd(props.paramId) && (
        <meta
          name="description"
          content={`mAuto to szeroka oferta samochodów – również tych nowych. Marzysz o nowym ${props.details.Make} ${props.details.Model}? Dobrze trafiłeś. Sprawdź nasze propozycje.`}
        />
      )}

      {props.pageType === "new" && (
        <title>
          {capitalize(props.details.Make)} {props.details.Model} {replaceSpecialCharsets(props.details.Type)} - nowy samochód
          | mAuto.pl
        </title>
      )}

      {props.pageType === "new" && (
        <link rel="canonical" href={`/nowe-samochody/${newTileLinkCreator(props.details.Make, props.details.Model, props.details.AuctionId)}`} />
      )}

      {props.pageType === "afterLease" && isOdd(props.paramId) && (
        <meta
          name="description"
          content={`Samochody poleasingowe mAuto to najwyższa jakość w najlepszej cenie. ${props.details.Make} ${props.details.Model}? Znajdź swój wymarzony samochód!`}
        />
      )}
      {props.pageType === "afterLease" && !isOdd(props.paramId) && (
        <meta
          name="description"
          content={`Marzysz o ${props.details.Make} ${props.details.Model}? Sprawdź ofertę samochodów poleasingowych mAuto i znajdź idealny samochód w najlepszej cenie? Znajdź swój wymarzony samochód!`}
        />
      )}
      {props.pageType === "afterLease" && (
        <title>
          {capitalize(props.details.Make)} {props.details.Model} {replaceSpecialCharsets(props.details.Type)}  - samochód
          poleasingowy | mAuto.pl
        </title>
      )}
      {props.pageType === "auction" && isOdd(props.paramId) && (
        <meta
          name="description"
          content={`Znajdź swój wymarzony samochód z mAuto. Sprawdź nasze aukcje pojazdów. Może znajdziesz tu ${props.details.Make} ${props.details.Model}, na który czekasz? `}
        />
      )}
      {props.pageType === "auction" && !isOdd(props.paramId) && (
        <meta
          name="description"
          content={`Szukasz ${props.details.Make} ${props.details.Model} w atrakcyjnej cenie? Weź udział w aukcjach mAuto i daj sobie szansę na zakup idealnego auta!`}
        />
      )}
      {props.pageType === "auction" && (
        <title>
          {capitalize(props.details.Make)} {props.details.Model} {replaceSpecialCharsets(props.details.Type)} - aukcja
          samochodu | mAuto.pl
        </title>
      )}
    </Helmet>
  );
};
export default DetailsPageHelmet;
