export const extraInsuranceTooltipText =
  " Wymagane dwa niezależne zabezpieczenia, przy czym jednym z zabezpieczeń musi być system lokalizacji i powiadamiania o zasięgu nie mniejszym niż przyjęty w umowie AC zakres terytorialny. Oferta nie uwzględnia kosztu systemu lokalizacji i powiadamiania. ";

export const CARS_PER_PAGE = 24;
export const CARS_PER_PAGE_MOBILE = 16;

export const CONTAINER_WIDTH = 1308;

export const IS_COMPANY_MOCKED_OPTIONS = [
  {
    Name: "na firmę",
    Value: "isCompany",
    Status: true
  },
  {
    Name: "prywatnie",
    Value: "isPrivate",
    Status: false
  }
];

export const OBSERVED_RESULTS_KEY = "observedVehicleResults";

export const sortingOptions = [
  {
    value: "0",
    label: "domyślny"
  },
  {
    value: "1",
    label: "najtańszy"
  },
  {
    value: "2",
    label: "najdroższy"
  },
  {
    value: "3",
    label: "najmniejszy przebieg"
  },
  {
    value: "4",
    label: "największy przebieg"
  },
  {
    value: "5",
    label: "najstarszy"
  },
  {
    value: "6",
    label: "najnowszy"
  }
];

export const VOIVODESHIPS_DICT = [
  {
    Id:0,
    Name:""
  },
  {
    Id:1,
    Name:"dolnośląskie"
  },
  {
    Id:2,
    Name:"kujawsko-pomorskie"
  },
  {
    Id:3,
    Name:"lubelskie"
  },
  {
    Id:4,
    Name:"lubuskie"
  },
  {
    Id:5,
    Name:"łódzkie"
  },
  {
    Id:6,
    Name:"małopolskie"
  },
  {
    Id:7,
    Name:"mazowieckie"
  },
  {
    Id:8,
    Name:"opolskie"
  },
  {
    Id:9,
    Name:"podkarpackie"
  },
  {
    Id:10,
    Name:"podlaskie"
  },
  {
    Id:11,
    Name:"pomorskie"
  },
  {
    Id:12,
    Name:"śląskie"
  },
  {
    Id:13,
    Name:"świętokrzyskie"
  },
  {
    Id:14,
    Name:"warmińsko-mazurskie"
  },
  {
    Id: 15,
    Name: "wielkopolskie"
  },
  {
    Id: 16,
    Name: "zachodniopomorskie"
  }
]
export const PACKAGE = {
  INSURANCE: 6, //ubezpieczenie
  ASSISTANCE: 5, //assistance
  BASIC_SERVICE: 1, //pakiet serwisowy Basic
  COMFORT_SERVICE: 7, //pakiet serwisowy Comfort
  SERVICE_COORDINATION: 1, //koordynacja serwisu
  TYRES: 3, //pakiet Opony
  PETROL_CARD: 2 //karta paliwowa
};

export const REDIRECTS = [
  { from: "/mAuto-z-prezentem", to: "/leasing-BEZ-OBAW" },
  { from: "/contactForm", to: "/formularz-kontaktowy" + window.location.search },
  { from: "/list", to: "/lista-pojazdow" + window.location.search },
  { from: "/wzorowe-samochody", to: "/samochody-poleasingowe" },
  { from: "/favorite", to: "/ulubione" },
  { from: "/register", to: "/rejestracja" },
  { from: "/password-recovery", to: "/przypomnienie-hasla" },
  { from: "/activate-account", to: "/aktywuj-konto" + window.location.search },
  { from: "/product-line", to: "/oferta" },
  { from: "/regulamin-promocji-pierwsze-urodziny-mAuto", to: "/nowe-samochody" },
  { from: "/pierwsze-urodziny-mAuto", to: "/nowe-samochody" },
  { from: "/financing", to: "/finansowanie" },
  { from: "/accept-rules", to: "/zaakceptuj-regulamin" },
  { from: "/registered-successfully", to: "/pomyslnie-zarejestrowano" },
  { from: "/message-sent-successfully", to: "/wyslano-pomyslnie" },
  { from: "/activated-successfully", to: "/aktywacja-zakonczona-sukcesem" },
  { from: "/insalesoon", to: "/wkrotce-w-sprzedazy" },
  { from: "/bidding", to: "/licytowanie" },
  { from: "/wonauctions", to: "/wygrane-aukcje" },
  { from: "/self-financing-form", to: "/formularz-samofinansowanie" },
  { from: "/settings", to: "/ustawienia" },
  { from: "/marki/AUDI", to: "/marki/audi" },
  { from: "/marki/Audi", to: "/marki/audi" },
  { from: "/marki/FIAT", to: "/marki/fiat" },
  { from: "/marki/JEEP", to: "/marki/jeep" },
  { from: "/marki/KIA", to: "/marki/kia" },
  { from: "/marki/LAND-ROVER", to: "/marki/land-rover" },
  { from: "/marki/MAZDA", to: "/marki/mazda" },
  { from: "/marki/MERCEDES-BENZ", to: "/marki/mercedes-benz" },
  { from: "/marki/NISSAN", to: "/marki/nissan" },
  { from: "/marki/OPEL", to: "/marki/opel" },
  { from: "/marki/RENAULT", to: "/marki/renault" },
  { from: "/marki/SEAT", to: "/marki/seat" },
  { from: "/marki/TOYOTA", to: "/marki/toyota" },
  { from: "/marki/VOLKSWAGEN", to: "/marki/volkswagen" },
  { from: "/marki/MINI-[BMW]", to: "/marki/mini-[bmw]" },
  { from: "/marki/Alfa-Romeo", to: "/marki/alfa-romeo" },
  { from: "/marki/BMW", to: "/marki/bmw" },
  { from: "/marki/CITROEN", to: "/marki/citroen" },
  { from: "/marki/DACIA", to: "/marki/dacia" },
  { from: "/marki/DACIA", to: "/marki/dacia" },
  { from: "/marki/FORD", to: "/marki/ford" },
  { from: "/marki/HYUNDAI", to: "/marki/hyundai" },
  { from: "/marki/JAGUAR", to: "/marki/jaguar" },
  { from: "/marki/LEXUS", to: "/marki/lexus" },
  { from: "/marki/MASERATI", to: "/marki/maserati" },
  { from: "/marki/PORSCHE", to: "/marki/porsche" },
  { from: "/marki/SKODA", to: "/marki/skoda" },
  { from: "/marki/VOLVO", to: "/marki/volvo" }
];