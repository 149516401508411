import React, { useState } from "react";

import { Icon, Text } from "@mbank-design/design-system/components";
import { ArrowLeft, ArrowRight } from "@mbank-design/design-system/icons";

import "./index.scss";
import { FontColor, IconColor } from "@mbank-design/design-system/enums";
import { useEffect } from "react";

const DetailNavigationContainer = (props) => {
  const [ids, setIds] = useState([]);
  const [currentIndex, setCurrentIndex] = useState();

  useEffect(() => {
    const sessionIds = JSON.parse(sessionStorage.getItem("CommonIds"));
    setIds(sessionIds);
  }, []);

  useEffect(() => {
    ids && setCurrentIndex(findAuctionIdIndex(ids, "carID", props.carId));
  }, [ids]);

  const findAuctionIdIndex = (arr, key, value) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][key] === value) {
        return i;
      }
    }
    return -1;
  };

  const valueOfProvidedIndex = (array, providedIndex) => {
    const foundData = array.find((el, idx) => {
      return idx === providedIndex;
    });
    const hrefString =
      foundData && foundData.carLabel
        ? foundData.carLabel.toLowerCase().replace(/\s/g, "-") + "-id-" + foundData.carID
        : "/";

    const linkString =
      foundData && foundData.carStatus === "Nowy"
        ? "/nowe-samochody/" + hrefString
        : "/samochody-poleasingowe/" + hrefString;
    return linkString;
  };

  return (
    <div className="navigation-container">
      <div className="previous-page-link-container" onClick={() => props.returnToPreviousPage()}>
        <Icon primaryColor={IconColor.SECONDARY_CONTRAST} iconComponent={ArrowLeft} />
        <Text as="p" styledAs={"small"} paddingLeft={8} color={FontColor.DARK}>
          {props.literals && props.literals.prod_back}
        </Text>
      </div>
      {ids && ids.length !== 0 && (
        <div className="prev-next-buttons">
          {currentIndex - 1 >= 0 && (
            <a className="previous-page-link-container" href={valueOfProvidedIndex(ids, currentIndex - 1)}>
              <Icon primaryColor={IconColor.SECONDARY_CONTRAST} iconComponent={ArrowLeft} />
              <Text as="p" styledAs={"small"} paddingLeft={8} color={FontColor.DARK}>
                poprzednie
              </Text>
            </a>
          )}
          {currentIndex + 1 < ids.length && (
            <a className="previous-page-link-container" href={valueOfProvidedIndex(ids, currentIndex + 1)}>
              <Text as="p" styledAs={"small"} paddingRight={8} color={FontColor.DARK}>
                następne
              </Text>
              <Icon primaryColor={IconColor.SECONDARY_CONTRAST} iconComponent={ArrowRight} />
            </a>
          )}
        </div>
      )}
    </div>
  );
};

export default DetailNavigationContainer;
