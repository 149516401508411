import React from "react";
import "./index.scss";
import "../../../assets/styles/calculator.scss";
import { Amount, Button, Icon, Link, Text, Tooltip } from "@mbank-design/design-system/components";
import { FontColor, FontSize, FontWeight, LineHeight } from "@mbank-design/design-system/design-tokens/general/typography";
import HorizontalRadioBox from "../../parts/HorizontalRadioBox";
import uuid from "react-uuid";
import { ChevronDown, ChevronUp, Envelope, InfoRound, Lock, PhoneHandle } from "@mbank-design/design-system/icons";
import BaseCalculator from "../../extendables/calculator";
import AdditionalPackageTile from "../../parts/AdditionalPackageTile";
import CalculatorMobileBaner from "../../parts/CalculatorMobileBaner";
import { numberWithSpaces } from "../../common/number-with-spaces";
import PromotedOfferSticker from "../../parts/PromotedOfferSticker";
import { printOffer } from "../../../services";
import { IS_COMPANY_MOCKED_OPTIONS } from "../../../constants";
import { handleClickCalc } from "../../../scripts/commons/common";
import { setVehicleOfferButtonInsightTag } from "../../common/campaign-data-setter";
import { ButtonType, IconColor, TextAlign } from "@mbank-design/design-system/enums";
import ReservedLabel from "../../parts/ReservedLabel";

export default class Calculator extends BaseCalculator {
  state = {
    tabSelected: 1,
    isCompany: true,
    expandAdditionalPackages: false,
    showCalc: false,
    isCompanyMockedOptions: IS_COMPANY_MOCKED_OPTIONS,
    printingId: '',
    pdfGenerationInProgress: false,
    hideCTAMobile: false,
    calculatorScrollTop: true,
    calculatorScrollBottom: true,
    installmentPriceWithoutPackages: null,
    emitFiltersLoaded: false,
  };

  componentDidMount() {
    // query printing id
    const urlParams = new URLSearchParams(window.location.search);
    const printingId = urlParams.get('printingId');
    const floatingWrapper = document.getElementById('floating-wrapper')

    this.setState(
      {
        CalcOptionsRaw: {
          ...this.props.calcData
        },
        CalcOptions: {
          ...this.state.CalcOptions,
          ...this.props.calcData
        },
        tabSelected: this.getTabSelected(),
        printingId: printingId
      },
      () => {
        this.getInitialCalcForm(false, true);
        this.props.setCalculatorTabSelected(this.state.tabSelected);

        this.getValuesOfPackages();
      }
    );

    window.addEventListener('scroll', e => this.handleCTAMobile(e));
    if (floatingWrapper) {
      floatingWrapper.addEventListener('scroll', e => this.handleCalculator(e));
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.calcData !== this.props.calcData || JSON.stringify(prevProps.calcData) !== JSON.stringify(this.props.calcData)) {
      const packageObj = this.generateCalculatorPackagesState(this.props.calcData.AdditionalServiceItems, true, true, false);

      let packageObjForNewCar = null;
      if (this.isNewCarAndIsRentAvailable()) {
        packageObjForNewCar = this.generateCalculatorPackagesState(this.props.calcData.AdditionalServiceItemsForNewCarRent, true, true, false);
      }

      if (this.props.calcResult) {
        this.getInstalmentPrice(packageObj, packageObjForNewCar);
      }
    }

    if (prevProps.calcResult !== this.props.calcResult || JSON.stringify(prevProps.calcResult) !== JSON.stringify(this.props.calcResult)) {
      this.refreshPrintingPdfObject();
    }
  }

  getInstalmentPrice(packages = null, packagesForNewVehicle = null) {
    const installmentPriceWithoutPackages = this.props.calcResult?.IsPriceFromCalculator ?
      this.props.calcResult.InstallmentPriceFromCalculator : this.props.calcRaw?.InstallmentPrice;
    
    if (this.props.calcResult.IsPriceFromCalculator && this.props.pageType === "new") {
      let prices = [];
      if (this.props.calcResult.params.FinancingMethod === 1 && packagesForNewVehicle) {
        this.props.calcResult.params.Filters.AdditionalServices.map(el => {
          prices.push(...Object.keys(packagesForNewVehicle).filter(key => packagesForNewVehicle[key].Value === el).map(key => {
            return packagesForNewVehicle[key]?.Price;
          }));
        });
      }

      if (this.props.calcResult.params.FinancingMethod === 2 && packages) {
        this.props.calcResult.params.Filters.AdditionalServices.map(el => {
          prices.push(...Object.keys(packages).filter(key => packages[key].Value === el).map(key => {
            return packages[key]?.Price;
          }));
        });
      }

      const installmentPrice = prices.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue);
      }, Number(this.props.calcResult.InstallmentPriceFromCalculator));
      this.setState({
        InstallmentPrice: installmentPrice,
        installmentPriceWithoutPackages
      });
      this.props.handleCurrentCalcPrices(installmentPriceWithoutPackages, installmentPrice)
    } else {
      this.setState({
        InstallmentPrice: this.props.calcResult.InstallmentPriceFromCalculator,
        installmentPriceWithoutPackages
      });
      this.props.handleCurrentCalcPrices(installmentPriceWithoutPackages, this.props.calcResult.InstallmentPriceFromCalculator)
    }
  }

  getValuesOfPackages(data = undefined) {
    const packageObj = this.generateCalculatorPackagesState(this.props.calcData.AdditionalServiceItems, true, true, false);
    let packageObjForNewCar = null;

    if (this.isNewCarAndIsRentAvailable()) {
      packageObjForNewCar = this.generateCalculatorPackagesState(this.props.calcData.AdditionalServiceItemsForNewCarRent, true, true, false);
    }

    this.setState({
      PackageState: packageObj, PackageStateForNewCarRent: packageObjForNewCar
    });
  }

  handleCTAMobile = (e) => {
    const window = e.currentTarget;
    const footer = document.getElementById("footer-container");
    const footerPosition = footer?.getBoundingClientRect();

    if (footerPosition) {
      if (footerPosition.top - window.innerHeight < 0) {
        this.setState({
          hideCTAMobile: true
        })
      } else {
        if (this.state.hideCTAMobile) {
          this.setState({
            hideCTAMobile: false
          })
        }
      }
    }
  };
  
  handleCalculator = (e) => {
    const window = e.currentTarget;

    //scrolled to top
    if (window.scrollTop == 0) {
      if (!this.state.calculatorScrollTop) {
        this.setState({
          calculatorScrollTop: true
        })
      } 
    } else {
      if (this.state.calculatorScrollTop) {
        this.setState({
          calculatorScrollTop: false
        })
      }
    }

    //scrolled to bottom
    if (window.offsetHeight + window.scrollTop >= window.scrollHeight) {
      if (!this.state.calculatorScrollBottom) {
        this.setState({
          calculatorScrollBottom: true
        })
      }
    } else {
      if (this.state.calculatorScrollBottom) {
        this.setState({
          calculatorScrollBottom: false
        })
      }
    }
  };

  getTabSelected() {
    const compareLeaseToRent = sessionStorage.getItem("lowestInstallment");
    return this.props.isLeasing105
      ? 4
      : this.props.calcData.IsLeasingAvailable && compareLeaseToRent === "lease"
      ? 2
      : this.props.calcData.IsRentAvailable
      ? 1
      : this.props.calcData.IsLeasingAvailable
      ? 2
      : 3;
  }

  isNewCarAndSelectedLeasing() {
    return (this.props.pageType === "new" && this.state.tabSelected === 2);
  }
  
  isNewCar() {
    return this.props.pageType === "new";
  }

  isNewCarAndIsRentAvailable() {
    return (this.props.pageType === "new" && this.props.calcData.IsRentAvailable);
  }

  modifyAdditionalServiceItems(additionalServiceItems) {
    const packages = [...additionalServiceItems];
    if (this.isNewCar()) {
      packages.forEach((pkg) => {
        if (pkg.Value === 6) {
          pkg.Disabled = true;
          pkg.SetSelfAsTrue = true;
        }
      });

      this.setState({
        CalcOptionsRaw: {
          ...this.state.CalcOptionsRaw,
          AdditionalServiceItems: packages
        }
      })
    }

    return packages;
  }

  getInitialCalcForm(blockEmitingFilters) {
    let obj = {
      LongTimeRentCalculatorValuesDTO: {
        Periods: [],
        Mileages: [],
        Ows: [],
      },
      LeasingCalculatorValues: {
        Periods: [],
        Rvs: [],
        Ows: [],
      },
    };
    let tempRaw = this.state.CalcOptionsRaw;
    if (tempRaw.LeasingPeriods) {
      for (let item of tempRaw.LeasingPeriods) {
        obj.LeasingCalculatorValues.Periods.push({ ...item.Period });

        if (item.Period.Status === true) {
          for (let el of item.Ows) {
            obj.LeasingCalculatorValues.Ows.push({ ...el.Ow });

            if (el.Ow.Status === true) {
              obj.LeasingCalculatorValues.Rvs = [...el.Rvs];
            }
          }
        }
      }
    }
    if (tempRaw.RentPeriods) {
      for (let item of tempRaw.RentPeriods) {
        obj.LongTimeRentCalculatorValuesDTO.Periods.push({ ...item.Period });

        if (item.Period.Status === true) {
          for (let el of item.Ows) {
            obj.LongTimeRentCalculatorValuesDTO.Ows.push({ ...el.Ow });

            if (el.Ow.Status === true) {
              obj.LongTimeRentCalculatorValuesDTO.Mileages = [...el.Mileages];
            }
          }
        }
      }
    }
    this.setState(
      {
        ...this.state,
        CalcOptions: { ...this.state.CalcOptions, ...obj },
      },
      () => {
        if (!blockEmitingFilters) {
          this.emitFilters();
        }
      }
    );
  }

  setActiveTab(tab) {
    if (
      this.state.isCompany ||
      tab === 3 ||
      (tab === 1 && !this.state.calcData.IsRentAvailable)
    ) {
      this.setState({ ...this.state, tabSelected: tab }, () => {
        this.emitFilters();
      });
      this.props.setCalculatorTabSelected(tab)
    }
  }

  emitFilters() {
    super.emitFilters();
    if (this.state.emitFiltersLoaded === false) {
      this.props.sendDataToParent(
        this.createURLToContactSection(
          this.state.CalcOptions,
          this.state.CalcOptionsRaw,
          this.props.pageType === "new" && this.state.tabSelected === 1
            ? this.state.PackageStateForNewCarRent
            : this.state.PackageState,
          this.state.tabSelected,
          window.location.href.includes("nowe-samochody") ? false : true
        )
      );

      this.setState({ emitFiltersLoaded: true });
    }

    this.setState(
      {
        urlObject: this.createURLToContactSection(
          this.state.CalcOptions,
          this.state.CalcOptionsRaw,
          this.props.pageType === "new" && (this.state.tabSelected === 1) ? 
            this.state.PackageStateForNewCarRent : 
            this.state.PackageState,
          this.state.tabSelected,
          window.location.href.includes("nowe-samochody") ? false : true
        )
      },
      () => {
        this.props.contactUrl(this.state.urlObject);

        if (this.state.tabSelected === 3) {
          this.refreshPrintingPdfObject();
        }
      }
    );
  }

  refreshPrintingPdfObject() {
    this.setState({
      pdfObject: this.createPDFObject(
        this.state.CalcOptions,
        this.state.CalcOptionsRaw,
        this.props.pageType === "new" && this.state.tabSelected === 1 ?
          this.state.PackageStateForNewCarRent :
          this.state.PackageState,
        this.props.calcResult,
        this.state.tabSelected,
        window.location.href.includes("nowe-samochody") ? false : true,
        this.state.InstallmentPrice
      )
    })
  }

  handleActiveTabBehaviour(value) {
    if (!value) {
      this.setActiveTab(3);
    }
  }

  printOffer() {
    if (!this.state.pdfGenerationInProgress) {
      this.refreshPrintingPdfObject()
      this.setState({
        pdfGenerationInProgress: true
      }, () => {
        printOffer(this.state.printingId, this.state.pdfObject).then((res) => {
          if (res.data.base64 && res.data.fileName) {
            const link = res.data.base64;
            const downloadLink = document.createElement('a');
            downloadLink.href = link;
            downloadLink.download = res.data.fileName;
            downloadLink.click();
          }

          if (res.data.errorCode && res.data.errorMessage) {
            alert(res.data.errorMessage);
          }

          this.setState({
            pdfGenerationInProgress: false
          })
        })
      })
    }

  }

  handleIsCompanyChange(isCompany) {
    let updatedOptions = JSON.parse(JSON.stringify(this.state.isCompanyMockedOptions));
    if(isCompany) {
      updatedOptions.find(option => option.Value === 'isCompany').Status = true;
      updatedOptions.find(option => option.Value === 'isPrivate').Status = false;
    } else {
      updatedOptions.find(option => option.Value === 'isCompany').Status = false;
      updatedOptions.find(option => option.Value === 'isPrivate').Status = true;
    }

    this.setState({
      isCompanyMockedOptions: updatedOptions
    });
  }

  launchCalc() {
    this.setState({ showCalc: true });
  }

 setLocalStorageContactPreference() {
   localStorage.setItem("onlyOffer", true);

   // LinkedIn Insight Tag
   setVehicleOfferButtonInsightTag();
 }

  render() {
    let pageType = this.props.pageType;
    let activeTab = this.state.tabSelected;
    let calcOptions = this.state.CalcOptions;
    let isCompany = this.props.isCompany;
    return (
      <>
        <div className={`cta-mobile ${this.state.hideCTAMobile && "hide-cta"}`} id="cta-mobile">
          <div className="cta-mobile__price-container">
            {this.props.calcResult && this.state.InstallmentPrice !== undefined && (
              <>
                <Text as={"p"} styledAs={"small"}>rata netto od</Text>
                {this.props.calcResult.InstallmentPricePromo > 0 && (
                    <Text
                      as={"p"}
                      fontSize={FontSize.SIZE_12}
                      lineHeight={LineHeight.HEIGHT_16}
                      fontWeight={FontWeight.WEIGHT_600}
                      marginRight={16}
                    >
                      <Amount
                        isFractionPartShown={false}
                        currencyDisplay="symbol"
                        currency="PLN"
                        isLineThrough={true}
                        isFractionPartBolded={true}
                        isFractionPartResized={false}
                        value={this.state.InstallmentPrice}
                      />
                    </Text>
                )}
                <Text
                  as="p"
                  styledAs="h2"
                  color={this.props.calcResult.InstallmentPricePromo > 0 ? FontColor.funGreenUI : FontColor.zeusBlackUI}
                  align={TextAlign.RIGHT}
                >
                  <Amount
                    isFractionPartShown={false}
                    currencyDisplay="symbol"
                    currency="PLN"
                    isFractionPartBolded={true}
                    isFractionPartResized={false}
                    value={
                      this.props.calcResult.InstallmentPricePromo > 0
                        ? this.props.calcResult.InstallmentPricePromo
                        : this.state.InstallmentPrice
                    }
                  />
                </Text>
              </>
            )}
          </div>
          <div className="cta-mobile__button-container">
            <a href={this.props.literals && `tel:${this.props.literals.callusbox_tel.replace(/ /g, "")}`}>
              <Button buttonType="secondary">zadzwoń</Button>
            </a>
            <Link onClick={() => handleClickCalc()}>
              <Button>kalkulator</Button>
            </Link>
          </div>
        </div>
        <div className={"floating-wrapper"} id="floating-wrapper">
          {this.props.reservation && !this.state.showCalc && (
            <div className="reservation-info-container">
              <ReservedLabel text={"zarezerwowany"} iconType={Lock} color={"zeusBlackUI"} textAs="p"/>
              <Text as="h2" marginTop={16} marginBottom={24}>
                szukasz podobnego auta?
              </Text>
              <div className="tiles-wrapper">
                <div>
                  <Icon iconComponent={PhoneHandle} primarycolor={IconColor.BLUE} />
                  <Text as="h3" marginTop={8} fontWeight={FontWeight.WEIGHT_600}>
                    zadzwoń
                  </Text>
                  <Text as="p" marginBottom={8}>
                    {this.props.literals && this.props.literals.callusbox_time}
                  </Text>
                  <a href={this.props.literals && `tel:${this.props.literals.callusbox_tel}`}>
                    {this.props.literals && this.props.literals.callusbox_tel}
                  </a>
                </div>
                <div>
                  <Icon iconComponent={Envelope} primarycolor={IconColor.BLUE} />
                  <Text as="h3" marginTop={8} fontWeight={FontWeight.WEIGHT_600}>
                    napisz
                  </Text>
                  <Text as="p" marginBottom={8}>
                    odpowiedź w 24h
                  </Text>
                  <Link href={`/contactForm?${this.state.urlObject}`}>skontaktuj się</Link>
                </div>
              </div>
            </div>
          )}
          {this.props.reservation && !this.state.showCalc && (
            <div className="launch-preview-box">
              <Text as="h3">finansowanie</Text>
              <Text as="p" marginBottom={8}>
                zobacz ile kosztowałby Cię ten samochód
              </Text>
              <Button
                buttonType={ButtonType.TERTIARY}
                onClick={() => {
                  this.launchCalc();
                }}
              >
                oblicz ratę miesieczną
              </Button>
            </div>
          )}
          <div
            id="calculator-container"
            className={
              `calculator-container` +
              `${pageType === "new" ? " new-car-calculator-container" : ""} 
                      ${!this.props.reservation || (this.props.reservation && this.state.showCalc) ? "block" : "none"}
        `
            }
          >
            <CalculatorMobileBaner classes={this.state.calculatorScrollTop ? "scroll-top" : ""} />
            {this.props.reservation && (
              <ReservedLabel text={"zarezerwowany"} iconType={Lock} color={"zeusBlackUI"} textAs="p"/>
            )}
            <div className="calculator-header">
              <Text as="p" fontWeight={FontWeight.WEIGHT_600} fontSize={[FontSize.SIZE_20,FontSize.SIZE_24]} lineHeight={[24,28]} className="hide-on-mobile">
                {this.props.literals && this.props.literals.prod_used_calc_hdr}
              </Text>
              {this.props.calcData.IsCashAvailable === true && (
                <HorizontalRadioBox
                  RadioName={"isCompany"}
                  Options={this.state.isCompanyMockedOptions}
                  handler={(e) => {
                    this.handleActiveTabBehaviour(e.Value === "isCompany");
                    this.handleIsCompanyChange(e.Value === "isCompany");
                    this.props.setIsCompany(e.Value === "isCompany");
                  }}
                  Size={"normal"}
                />
              )}
              {this.props.calcData.IsCashAvailable === false && (
                <Text as="p">
                  oferta tylko dla firm
                </Text>
              )}
            </div>
            {isCompany && (
              <div
                className={`tabs-container ${
                  this.props.calcData && !this.props.calcData.IsRentAvailable ? "two-el-only" : ""
                }`}
              >
                {this.props.calcData && this.props.calcData.IsRentAvailable === true && (
                  <div className={`tab ${activeTab === 1 ? "active" : ""}`} onClick={() => this.setActiveTab(1)}>
                    <Text as="p" fontWeight={FontWeight.WEIGHT_600}>
                      najem
                    </Text>
                  </div>
                )}
              {this.props.calcData &&
                this.props.calcData.IsLeasingAvailable === true && 
                !this.props.isLeasing105 && (
                  <div
                    className={`tab ${activeTab === 2 ? "active" : ""}`}
                    onClick={() => this.setActiveTab(2)}
                  >
                    <Text as="p" fontWeight={FontWeight.WEIGHT_600}>
                      leasing
                    </Text>
                  </div>
                )}
                {this.props.calcData &&
                  this.props.calcData.IsCashAvailable === true && (
                  <div
                    className={`tab ${activeTab === 3 ? "active" : ""}`}
                    onClick={() => this.setActiveTab(3)}
                  >
                    <Text as="p" fontWeight={FontWeight.WEIGHT_600}>
                      gotówka
                    </Text>
                  </div>
                  )
                }
            </div>
          )}
          {this.props.calcData && this.props.isLeasing105 && (
            <div className={`tab ${activeTab === 4 ? "active" : ""}`} onClick={() => this.setActiveTab(4)}>
              <Text as="p" fontWeight={FontWeight.WEIGHT_600}>
                leasing 105%
              </Text>
            </div>
          )}
          <div
            className={
              activeTab !== 3
                ? "content-container"
                : "content-container content-container-cash"
            }
          >
            {activeTab === 1 &&
              calcOptions &&
              calcOptions.LongTimeRentCalculatorValuesDTO && (
                <>
                  <HorizontalRadioBox
                    RadioName={"Periods"}
                    Options={
                      calcOptions.LongTimeRentCalculatorValuesDTO.Periods
                    }
                    Label={"liczba miesięcy najmu"}
                    Parent={"LongTimeRentCalculatorValuesDTO"}
                    handler={(e) => {
                      this.handleRadioBtnChange(e);
                    }}
                    Size={"normal"}
                  />
                  <HorizontalRadioBox
                    RadioName={"Ows"}
                    Options={pageType === "new" ? 
                    calcOptions.LongTimeRentCalculatorValuesDTO.Ows.map(ows => {ows.Name = ows.Price + " zł"; return ows})
                    : calcOptions.LongTimeRentCalculatorValuesDTO.Ows }
                    Label={"opłata wstępna"}
                    handler={(e) => {
                      this.handleRadioBtnChange(e);
                    }}
                    Parent={"LongTimeRentCalculatorValuesDTO"}
                    Size={"normal"}
                    AdditionalInfo={
                      (this.props.calcResult !== undefined &&
                      this.props.calcResult !== null &&
                      this.props.calcResult.Ow !== 0 &&
                      pageType !== "new")
                        ? numberWithSpaces(this.props.calcResult.Ow) +
                          " zł netto"
                        : null
                    }
                  />
                  <HorizontalRadioBox
                    RadioName={"Mileages"}
                    Options={
                      calcOptions.LongTimeRentCalculatorValuesDTO.Mileages
                    }
                    Label={"przebieg roczny (tys. km)"}
                    handler={(e) => {
                      this.handleRadioBtnChange(e);
                    }}
                    Parent={"LongTimeRentCalculatorValuesDTO"}
                    Size={"normal"}
                  />
                </>
              )}
            {activeTab === 2 &&
              calcOptions &&
              calcOptions.LeasingCalculatorValues && (
                <div className="leasing-container">
                  <HorizontalRadioBox
                    RadioName={"Periods"}
                    Options={calcOptions.LeasingCalculatorValues.Periods}
                    Label={"liczba miesięcy leasingu"}
                    handler={(e) => {
                      this.handleRadioBtnChange(e);
                    }}
                    Parent={"LeasingCalculatorValues"}
                    Size={"normal"}
                  />
                  <HorizontalRadioBox
                    RadioName={"Ows"}
                    Options={calcOptions.LeasingCalculatorValues.Ows}
                    Label={"opłata wstępna"}
                    handler={(e) => {
                      this.handleRadioBtnChange(e);
                    }}
                    Parent={"LeasingCalculatorValues"}
                    Size={"normal"}
                    AdditionalInfo={
                      this.props.calcResult !== undefined &&
                      this.props.calcResult !== null &&
                      this.props.calcResult.Ow !== 0
                        ? numberWithSpaces(this.props.calcResult.Ow) + " zł netto"
                        : null
                    }
                  />
                  <HorizontalRadioBox
                    RadioName={"Rvs"}
                    Options={calcOptions.LeasingCalculatorValues.Rvs}
                    Label={"wykup"}
                    handler={(e) => {
                      this.handleRadioBtnChange(e);
                    }}
                    Parent={"LeasingCalculatorValues"}
                    Size={"normal"}
                    AdditionalInfo={
                      this.props.calcResult !== undefined &&
                      this.props.calcResult !== null &&
                      this.props.calcResult.Rv !== 0
                        ? numberWithSpaces(this.props.calcResult.Rv) + " zł netto"
                        : null
                    }
                  />
                </div>
              )}
              {activeTab === 4 && (
                <div className="cash-container">
                  <Text 
                    as="p"
                    styledAs="h3"
                    fontSize={FontSize.SIZE_24}
                    fontWeight={FontWeight.WEIGHT_600}
                    marginBottom={16}
                    lineHeight={LineHeight.HEIGHT_32}
                  > {`W leasingu 105% rata w wysokości `}
                  <Amount 
                    isFractionPartShown={false}
                    type="decimal"
                    currencyDisplay="symbol"
                    currency="PLN"
                    value={this.props.calcResult && (this.props.calcResult.InstallmentPricePromo > 0 ? this.props.calcResult.InstallmentPricePromo : this.props.calcResult.InstallmentPrice)}
                  />
                  {" zł   "}
                  <Tooltip               
                    placement="top-end"
                    hint={"Promocyjne warunki dotyczą wybranych samochodów oznaczonych na mAuto.pl hasłem „Leasing 105%”. Dla parametrów: 20% opłaty wstępnej, 1% wartości wykupu oraz czasu trwania umowy leasingu 36 miesięcy suma opłat leasingowych wyniesie 105% dla auta spalinowego. Dla parametrów: 0% opłaty wstępnej, 27 tys. zł dotacji Mój elektryk, 1% wartości wykupu oraz czas trwania umowy leasingu 36 miesięcy suma opłat leasingowych wyniesie 105% dla auta elektrycznego. Ubezpieczenia, serwis opon ani usługi serwisowe nie wliczają się do sumy opłat leasingowych. Minimalny okres umowy leasingu to 36 miesięcy. Umowa tylko w PLN. Suma opłat 105% dotyczy WIBOR 1M z dnia przedstawienia oferty. W zależności od wahań stopy bazowej przyjętej w ofercie suma opłat może się zmienić. Oznacza to, że końcowa suma opłat może być niższa niż 105%, jeżeli stopa bazowa się zmniejszy lub wyższa niż 105%, jeżeli stopa bazowa się zwiększy. Informacja ma charakter zaproszenia do rokowań i nie stanowi oferty w rozumieniu Kodeksu Cywilnego."}               
                    icon={<Icon iconComponent={InfoRound} primaryColor="primary_contrast" />}
                    maxWidth={320}
                  />
                  </Text>
                </div>
              )}
              {activeTab !== 3 && (
            <div>
                <div className="price-overview">
                {(activeTab !== 1 || pageType !== "new") && (
                    <div>
                      <div>
                        <Text
                          fontSize={FontSize.SIZE_12}
                          as={"p"}
                          fontWeight={FontWeight.WEIGHT_600}
                          lineHeight={LineHeight.HEIGHT_16}
                        >
                          wartość samochodu {isCompany ? "netto" : "brutto"}
                        </Text>
                      </div>
                      <div className="amount-container">
                        {this.state.CalcOptionsRaw && (
                          <Text
                            as={"p"}
                            styledAs={"h4"}
                            color={"doveGrayUI"}
                          >
                            <Amount
                              isFractionPartShown={false}
                              currencyDisplay="symbol"
                              currency="PLN"
                              value={
                                isCompany
                                  ? this.state.CalcOptionsRaw.PriceNetto
                                  : this.state.CalcOptionsRaw.PriceBrutto
                              }
                            />
                          </Text>
                        )}
                      </div>
                    </div>
                )}
                  <div>
                    <div className="price__netto-text">
                      <Text
                        fontSize={FontSize.SIZE_12}
                        as={"p"}
                        fontWeight={FontWeight.WEIGHT_600}
                        lineHeight={LineHeight.HEIGHT_16}
                      >
                        rata finansowa
                      </Text>
                    </div>
                    <div className="amount-container">
                      {(this.props.calcRaw && this.state.installmentPriceWithoutPackages) && (
                        <>
                          {this.props.calcRaw.InstallmentPricePromo > 0 && (
                            <Text
                              as={"p"}
                              styledAs={"h4"}
                              marginRight={16}
                            >
                              <Amount
                                isFractionPartShown={false}
                                currencyDisplay="symbol"
                                currency="PLN"
                                isLineThrough={true}
                                value={this.state.installmentPriceWithoutPackages}
                              />
                            </Text>
                          )}
                          <Text
                            as="p"
                            styledAs="h4"
                            color={
                              this.props.calcRaw.InstallmentPricePromo > 0
                                ? FontColor.funGreenUI
                                : FontColor.zeusBlackUI
                            }
                          >
                            <Amount
                              isFractionPartShown={false}
                              currencyDisplay="symbol"
                              currency="PLN"
                              value={
                                this.props.calcRaw.InstallmentPricePromo > 0
                                  ? this.props.calcRaw.InstallmentPricePromo
                                  : this.state.installmentPriceWithoutPackages
                              }
                            />
                          </Text>
                        </>
                      )}
                      {this.state.installmentPriceWithoutPackages && !this.props.calcRaw && (
                         <Text
                         as="p"
                         styledAs="h4"
                         color={FontColor.zeusBlackUI
                         }
                       >
                         <Amount
                           isFractionPartShown={false}
                           currencyDisplay="symbol"
                           currency="PLN"
                           value={ this.state.installmentPriceWithoutPackages
                           }
                         />
                       </Text>
                      )}
                    </div>
                    </div>
                  </div>
              <div className={`price-container  ${this.state.calculatorScrollBottom ? 'scroll-bottom' : ''}`}>
                </div>
            </div>
            )}
              {activeTab !== 3 && activeTab !== 4 && (
                <div className="rent-container">
                  <div className="additional-packages-container">
                    <div className={`collapsing-container active`}>
                      <div className="content">
                        {this.state.CalcOptionsRaw &&
                          this.state.PackageState &&
                          (pageType !== "new" || activeTab === 2) &&
                          this.state.CalcOptionsRaw.AdditionalServiceItems.map((pkg) => {
                            return (
                              <AdditionalPackageTile
                                package={pkg}
                                key={uuid()}
                                packagesState={this.state.PackageState}
                                changePackageStatus={(pkgName) => this.changePackageStatus(pkgName, pkg)}
                                iconVisible
                                tooltipHidden
                              />
                            );
                          })}
                        {this.state.CalcOptionsRaw &&
                          this.state.PackageStateForNewCarRent &&
                          pageType === "new" &&
                          activeTab === 1 &&
                          this.state.CalcOptionsRaw.AdditionalServiceItemsForNewCarRent.map((pkg) => {
                            return (
                              <AdditionalPackageTile
                                package={pkg}
                                key={uuid()}
                                packagesState={this.state.PackageStateForNewCarRent}
                                changePackageStatus={(pkgName) => this.changePackageStatus(pkgName, pkg)}
                                iconVisible
                                tooltipHidden
                              />
                            );
                          }
                        )}

                    </div>
                  </div>
                </div>
              </div>
            )}
            {activeTab === 3 && (
              <>
                <div className="cash-container">
                  <Text
                    as="p"
                    styledAs="h3"
                    fontSize={FontSize.SIZE_24}
                    fontWeight={FontWeight.WEIGHT_600}
                    marginBottom={16}
                    lineHeight={LineHeight.HEIGHT_32}
                  >
                    {this.props.literals &&
                      this.props.literals.calc_used_cash_hdr}
                  </Text>
                </div>
                <div className={`price-container price-container--cash ${this.state.calculatorScrollBottom ? 'scroll-bottom' : ''}`}>
                  <div className="price">
                    <div>
                      <Text
                        fontSize={FontSize.SIZE_12}
                        as={"p"}
                        fontWeight={FontWeight.WEIGHT_600}
                        lineHeight={LineHeight.HEIGHT_16}
                      >
                        wartość samochodu {isCompany ? "netto" : "brutto"}
                      </Text>
                    </div>
                    <div>
                      {this.state.CalcOptionsRaw && (
                        <Text
                          as={"p"}
                          fontSize={FontSize.SIZE_12}
                          fontWeight={FontWeight.WEIGHT_600}
                        >
                          <Amount
                            isFractionPartShown={false}
                            currencyDisplay="symbol"
                            currency="PLN"
                            value={
                              isCompany
                                ? this.state.CalcOptionsRaw.PriceNetto
                                : this.state.CalcOptionsRaw.PriceBrutto
                            }
                          />
                        </Text>
                      )}
                    </div>
                  </div>
                  <div className="calc-buttons-container">
                    <a href={
                      this.props.literals &&
                      `tel:${this.props.literals.callusbox_tel.replace(/ /g, "")}`
                    }
                       className="calc-buttons-container__call"
                    >
                      <Button buttonType="secondary">zadzwoń</Button>
                    </a>             
                      <Button onClick={()=>{this.setLocalStorageContactPreference(); this.props.handleContactModal(this.state.urlObject)}}>
                        {this.props.literals &&
                          this.props.literals.calc_used_cash_cta}
                      </Button>
                    {this.state.printingId && <Button buttonType={ButtonType.SECONDARY} onClick={() => this.printOffer()}>{this.state.pdfGenerationInProgress ? 'proszę czekać' : 'generuj pdf'}</Button>}
                  </div>
                </div>
              </>
            )}
          </div>
          {activeTab !== 3 && (
            <div className={`price-container price-container--bottom  ${this.state.calculatorScrollBottom ? 'scroll-bottom' : ''}`}>
              <div className="price price__summary">
                <div>
                  <div className="price__netto-text price__netto-text--big">
                    {this.props.isProgramMyElectric ?
                      <Text
                        fontSize={FontSize.SIZE_12}
                        as={"p"}
                        fontWeight={FontWeight.WEIGHT_600}
                        lineHeight={LineHeight.HEIGHT_16}
                      >
                        rata miesięczna netto z dotacją „<a href={`/moj-elektryk`}>Mój elektryk</a>” przy odliczeniu 100% VAT
                      </Text> :
                      <Text
                        fontSize={FontSize.SIZE_12}
                        as={"p"}
                        fontWeight={FontWeight.WEIGHT_600}
                        lineHeight={LineHeight.HEIGHT_16}
                      >
                        rata łącznie netto
                      </Text>
                    }
                  </div>
                  <div className="amount-container">
                    {this.props.calcResult &&
                      this.state.InstallmentPrice !== undefined && (
                        <>
                          <Text
                            as="p"
                            styledAs="h1"
                            color={FontColor.zeusBlackUI}
                          >
                            <Amount
                              isFractionPartShown={false}
                              currencyDisplay="symbol"
                              currency="PLN"
                              value={this.state.InstallmentPrice}
                            />
                          </Text>
                        </>
                      )}
                  </div>
                </div>
              </div>
                <div className={"calc-buttons-container"}>
                    <Button onClick={()=>{this.setLocalStorageContactPreference(); this.props.handleContactModal(this.state.urlObject)}} buttonType="primary">
                      zapytaj o ofertę
                    </Button>
                  {this.state.printingId && (
                    <Button buttonType={ButtonType.SECONDARY} onClick={() => this.printOffer()}>
                      {this.state.pdfGenerationInProgress ? "proszę czekać" : "generuj pdf"}
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}
