import React, { useEffect } from "react";


const SiteMap = (props) => {

    useEffect(() => {
        window.location.href = process.env.REACT_APP_API_ENDPOINT + "/api/sitemap.xml"
      });
    

    return (
        <>
            
        </>
    )
}

export default SiteMap;