import React from "react";
import PropTypes from "prop-types";
import {Icon, Text} from "@mbank-design/design-system/components";
import * as ALL_ICONS from "@mbank-design/design-system/icons";
import {
  FontColor,
  FontSize,
  LineHeight
} from "@mbank-design/design-system/design-tokens/general/typography";
import "./index.scss";
import { IconColor } from "@mbank-design/design-system/enums";

const AdditionalPackageInfoTile = (props) => {
  AdditionalPackageInfoTile.propTypes = {
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    isFinancingPage: PropTypes.boolean,
  };

  return (
    <div className={`additional-package-info-tile-container ${props.useClass} ${props.isFinancingPage ? "financing-page" : ""}`}>
      <div className="image-container">
        <span className="show-on-desktop">
          <Icon
            primaryColor={IconColor.SECONDARY_CONTRAST}
            iconComponent={ALL_ICONS[props.icon]}
            size={props.isFinancingPage ? 32 : 24}
          />
        </span>
        <span className="show-on-mobile">
          <Icon
            primaryColor={IconColor.SECONDARY_CONTRAST}
            iconComponent={ALL_ICONS[props.icon]}
            size={props.isFinancingPage ? 32 : 24}
          />
        </span>
      </div>
      <div className="text-container">
        <div className="title-container">
          {props.addStar &&
          <span className="gold-star">
            </span>
          }
          {!props.forceDarkMode &&
          <Text as="p" styledAs="h3" align="left">
            {props.title}
          </Text>
          }
          {props.forceDarkMode &&
          <Text as="p" styledAs="h3" align="left" color={FontColor.white30}>
            {props.title}
          </Text>
          }
        </div>
        {!props.forceDarkMode && <Text as="p"
                                      lineHeight={LineHeight.HEIGHT_24}
                                      fontSize={FontSize.SIZE_16}
                                      align="left"
                                      color={FontColor.MONO_600}>
          {props.description}
        </Text>}
        {props.forceDarkMode && <Text as="p"
                                      lineHeight={LineHeight.HEIGHT_24}
                                      fontSize={FontSize.SIZE_16}
                                      color={FontColor.MONO_600}
                                      align="left">
          {props.description}
        </Text>}
      </div>
    </div>
  );
};

export default AdditionalPackageInfoTile;
