import React from "react";
import "./index.scss";

const ContactIcons = (props) => {
  return (
    <div className="contact-icons-container">
      {/* <div className="icons-container">
          <div className="icon-container reminder">
            <div className="icon"></div>
            <div className="icon-description">
              <Text>
                ustaw przypomnienie
              </Text>
            </div>
          </div>
          <div className="icon-container call">
            <div className="icon"></div>
            <div className="icon-description"></div>
          </div>
          <div className="icon-container text-us">
            <div className="icon"></div>
            <div className="icon-description"></div>
          </div>
      </div>
      <div className="page-background"></div> */}
    </div>
  );
};

export default ContactIcons;
