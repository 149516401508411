import React, { useEffect, useState } from "react";
import "./index.scss";
import { Button, Icon, IconButton } from "@mbank-design/design-system/components";
import { IconButtonIconSize, IconButtonType, IconColor } from "@mbank-design/design-system/enums";
import { Heart } from "@mbank-design/design-system/icons";
import { FavouriteCarsContext } from "../../../scripts/commons/contexts";

const FavouriteButton = ({ carData, callbackAfterUnlike, moduleType }) => {
  const [isFavouriteCar, setIsFavouriteCar] = useState(false);
  useEffect(() => {
    checkIfCarIsFavourite();
  });

  const addFavouriteCar = (updateFavouriteCarsList) => {
    let favoriteCarList = JSON.parse(localStorage.getItem("favoriteCar"));
    if (!favoriteCarList) {
      favoriteCarList = [];
    }
    favoriteCarList.push(carData);
    localStorage.setItem("favoriteCar", JSON.stringify(favoriteCarList));
    updateFavouriteCarsList(favoriteCarList);
  };
  
  const deleteFavouriteCar = (updateFavouriteCarsList) => {
    let favoriteCarList = JSON.parse(localStorage.getItem("favoriteCar"));
    if (favoriteCarList && favoriteCarList.length && carData && carData.AuctionId) {
      let updatedFavoriteCarList = favoriteCarList.filter((car) => car.AuctionId !== carData.AuctionId);
      localStorage.setItem("favoriteCar", JSON.stringify(updatedFavoriteCarList));
      updateFavouriteCarsList(updatedFavoriteCarList);
      callbackAfterUnlike && callbackAfterUnlike();
    }
  };

  const checkIfCarIsFavourite = () => {
    let favoriteCarList = JSON.parse(localStorage.getItem("favoriteCar"));
    if (favoriteCarList && carData && carData.AuctionId) {
      setIsFavouriteCar(favoriteCarList.find((car) => car.AuctionId === carData.AuctionId) ? true : false);
    }
  };

  const handleFavoritesButtonClick = (updateFavouriteCarsList) => {
    isFavouriteCar ? deleteFavouriteCar(updateFavouriteCarsList) : addFavouriteCar(updateFavouriteCarsList);
    checkIfCarIsFavourite();
  };

  return (
    <FavouriteCarsContext.Consumer>
      {({ updateFavouriteCarsList }) => {
        return (
          <>
            {moduleType === "DETAIL" ? (
              <>
                <IconButton
                  className={`icon-button button-transparent button-favorite ${isFavouriteCar ? "active" : ""}`}
                  iconComponent={Heart}
                  size={32}
                  buttonType={IconButtonType.TRANSPARENT}
                  iconColor={IconColor.SECONDARY_CONTRAST}
                  iconSize={IconButtonIconSize.SIZE_16}
                  background={"attention"}
                  onClick={(e) => {
                    handleFavoritesButtonClick(updateFavouriteCarsList);
                    e.preventDefault();
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                  }}
                />
              </>
            ) : (
              <></>
            )}
            {moduleType === "TILE" ? (
              <div
                className="icon-container"
                onClick={(e) => {
                  handleFavoritesButtonClick(updateFavouriteCarsList);
                  e.preventDefault();
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                }}
              >
                <Icon
                  iconComponent={Heart}
                  primaryColor={isFavouriteCar ? IconColor.SECONDARY_CONTRAST : IconColor.LIGHT}
                  __setDangerouslyInnerClassName={{ className: isFavouriteCar ? "favourite-button--filled" : "" }}
                />
              </div>
            ) : (
              <></>
            )}
            {moduleType === "SOLD_TILE" ? (
              <>
                <Button
                  size="normal"
                  buttonType="tertiary"
                  onClick={(e) => {
                    handleFavoritesButtonClick(updateFavouriteCarsList);
                    checkIfCarIsFavourite();
                    e.preventDefault();
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                  }}
                >
                  usuń
                </Button>
              </>
            ) : (
              <></>
            )}
          </>
        );
      }}
    </FavouriteCarsContext.Consumer>
  );
};

export default FavouriteButton;
