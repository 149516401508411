import { Media, Text } from "@mbank-design/design-system/components";
import { FontSize, LineHeight } from "@mbank-design/design-system/design-tokens/general/typography";
import React from "react";
import "./index.scss";

/**
 * @param {object} props
 * @param {string} props.pathname
 * @param {{mainTextLiterals: {mainTitle: string, mainText: string}, subTextLiterals: {subLiteralTitle: string, subLiteralText: string}[]}} props.dynamicSeoTexts
 */

const MobileHeaderTitle = ({ pathname, dynamicSeoTexts }) => {
  const bodyTypeLinks = ["/sedan", "/hatchback", "/kombi", "/suv", "/bus", "/van"];
  const isMobile = Media.useMobileOnlyMedia()
  if(isMobile && pathname.includes("/marki/") || bodyTypeLinks.includes(pathname)) {
    return (
      <div className='mobile-header-title-container mleasing-main-container'>
          <Text as="h1" styledAs={"h2"} fontSize={FontSize.SIZE_40} lineHeight={LineHeight.HEIGHT_48}>
            {dynamicSeoTexts.mainTextLiterals.mainTitle}
          </Text>
      </div>
    )
  } else if( isMobile && pathname === "/nowe-samochody" || pathname === "/samochody-poleasingowe" || pathname === "/samochody-elektryczne" || pathname === "/aukcje-samochodowe") {
    return (
      <div className='mobile-header-title-container mleasing-main-container'>
        {pathname === "/nowe-samochody" && (
          <Text as='h2' fontSize={FontSize.SIZE_40} lineHeight={LineHeight.HEIGHT_48}>
            Nowe samochody w mAuto
          </Text>
        )}
        {pathname === "/samochody-poleasingowe" && (
          <Text as='h2' fontSize={FontSize.SIZE_40} lineHeight={LineHeight.HEIGHT_48}>
            Samochody poleasingowe w mAuto
          </Text>
        )}
        {pathname === "/samochody-elektryczne" && (
          <Text as='h2' fontSize={FontSize.SIZE_40} lineHeight={LineHeight.HEIGHT_48}>
            Samochody elektryczne w mAuto
          </Text>
        )}
        {pathname === "/aukcje-samochodowe" && (
          <Text as='h2' fontSize={FontSize.SIZE_40} lineHeight={LineHeight.HEIGHT_48}>
            Aukcje samochodów poleasingowych – znajdź atrakcyjną ofertę dla siebie
          </Text>
        )}
      </div>
    );
  } else {
    return <></>
  }
};

export default MobileHeaderTitle;
