import React from "react";
import PropTypes from "prop-types";
import "./index.scss";
import { Icon, Text } from "@mbank-design/design-system/components";
import { Tick } from "@mbank-design/design-system/icons";
import { IconColor } from "@mbank-design/design-system/enums";

function ToastComponent(props) {
  return (
    <>
      <div className={"toast-wrapper"}>
        <div style={{ background: props.color }} className="main-container">
          {props.toastIcon === "Tick" && (
            <Icon primaryColor={IconColor.WHITE} iconComponent={Tick} />
          )}
          <div><Text as="p">{props.message}</Text></div>
          <div onClick={() => props.toggleToast()}><Text as="p">{props.buttonText}</Text></div>
        </div>
      </div>
    </>
  );
}

ToastComponent.propTypes = {
  color: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  toastIcon: PropTypes.string.isRequired
};

export default ToastComponent;
