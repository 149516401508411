export const historyStates = {
  DONE: "DONE",
  INACTIVE: "INACTIVE",
  LOADING: "LOADING"
};

export const availabilityStates = {
  OFFLINE: "offline",
  ONLINE: "online"
};

export const POST_CHAT_FORM_ID = "postchat_form";
export const PRE_CHAT_FORM_ID = "prechat_form";
export const TICKET_FORM_ID = "ticket_form";

export const EVENT_HAS_SEEN_ID = "event-has-seen";
export const EVENT_HAS_SENT_ID = "event-has-sent";
export const EVENT_HAS_NOT_SENT_ID = "event-not-has-sent";
export const EVENT_HAS_DELIVERED_ID = "event-has-delivered";

export const FILE_EXTENSIONS = ".txt,.doc,.docx,.pdf,.rar,.zip,.xls,.xlsx";
export const IMAGE_EXTENSIONS = ".jpeg,.bmp,.gif,.png,.jpg";
export const ALL_ACCEPT_FILE_EXTENSIONS = FILE_EXTENSIONS + "," + IMAGE_EXTENSIONS;

export const MOBILE = 425;
