import React, { useEffect, useState } from "react";
import "./index.scss";
import { Button, Chip, Icon } from "@mbank-design/design-system/components";
import { ChipVariant, IconColor } from "@mbank-design/design-system/enums";
import { MagnifyingGlass } from "@mbank-design/design-system/icons";

const SearchInput = ({ label, options, onSubmit, handleSubmit, handleValueChange, autocompleteInputRef, isMobile }) => {
  const [myOptions, setMyOptions] = useState(JSON.parse(JSON.stringify(options)));
  const [myFilteredOptions, setMyFilteredOptions] = useState([]);
  const [mySelectedOptions, setMySelectedOptions] = useState([]);
  const [numberOfSelectedCars, setNumberOfSelectedCars] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [isInputFocus, setIsInputFocus] = useState(false);

  useEffect(() => {
    myOptions.forEach((car) => {
      car.Make.Selected = false;

      car.Models.forEach((model) => {
        model.Selected = false;
        model.ParentMake = car.Make.Value;
      });
    });
    setMyFilteredOptions(myOptions);
  }, []);

  // FILTER BY INPUT
  const filterOptions = (filterBy, updatedOptions) => {
    let filterByArr = filterBy.split(" ");
    let filterByMake = filterByArr.length > 1 && filterByArr[0];
    let filterByModel = filterByArr.length > 1 && filterByArr.slice(1).join(" ");
    setInputValue(filterBy);

    if (filterBy[filterBy.length - 1] === " ") {
      filterBy = filterBy.slice(0, -1);
    }
    let options = updatedOptions || myOptions;

    let filteredOptions = options.map((item) => ({
      Make: item.Make,
      Models: item.Models.filter(
        (model) =>
          model.Name.toLowerCase().startsWith(filterBy.toLowerCase()) ||
          model.ParentMake.toLowerCase().startsWith(filterBy.toLowerCase()) ||
          (filterByModel &&
            model.Name.toLowerCase().startsWith(filterByModel.toLowerCase()) &&
            filterByMake &&
            model.ParentMake.toLowerCase().startsWith(filterByMake.toLowerCase()))
      )
    }));

    // highlight
    filteredOptions = filteredOptions.map((make) => {
      let highlightedMake = "";
      if (make.Make.Value.toLowerCase().startsWith(filterBy.toLowerCase())) {
        let start;
        start = make.Make.Value.toLowerCase().indexOf(filterBy.toLowerCase());

        highlightedMake =
          make.Make.Value.slice(0, start) +
          `<span class='highlighted'>${make.Make.Value.slice(start, start + filterBy.length)}</span>` +
          make.Make.Value.slice(start + filterBy.length);
      } else if (filterByMake && make.Make.Value.toLowerCase().startsWith(filterByMake.toLowerCase())) {
        let start;
        start = make.Make.Value.toLowerCase().indexOf(filterByMake.toLowerCase());

        highlightedMake =
          make.Make.Value.slice(0, start) +
          `<span class='highlighted'>${make.Make.Value.slice(start, start + filterByMake.length)}</span>` +
          make.Make.Value.slice(start + filterByMake.length);
      }
      let myModels = make.Models.map((model) => {
        let highlightedModel = "";

        if (model.Name.toLowerCase().startsWith(filterBy.toLowerCase())) {
          let start;
          start = model.Name.toLowerCase().indexOf(filterBy.toLowerCase());

          highlightedModel =
            model.Name.slice(0, start) +
            `<span class='highlighted'>${model.Name.slice(start, start + filterBy.length)}</span>` +
            model.Name.slice(start + filterBy.length);
        } else if (filterByModel && model.Name.toLowerCase().startsWith(filterByModel.toLowerCase())) {
          let start;
          start = model.Name.toLowerCase().indexOf(filterByModel.toLowerCase());

          highlightedModel =
            model.Name.slice(0, start) +
            `<span class='highlighted'>${model.Name.slice(start, start + filterByModel.length)}</span>` +
            model.Name.slice(start + filterByModel.length);
        }
        return { ...model, Name: highlightedModel || model.Name };
      });

      return {
        Make: { ...make.Make, Value: highlightedMake || make.Make.Value },
        Models: myModels
      };
    });
    setMyFilteredOptions(filteredOptions);
  };

  const findSelectedOptions = (options) => {
    const selectedOptions = options.map((item) => ({
      Make: item.Make,
      Models: item.Models.filter((model) => model.Selected === true)
    }));
    setMySelectedOptions(selectedOptions);
    countSelectedCars(selectedOptions);
  };

  const countSelectedCars = (selectedOptions) => {
    let countedSelectedCars = 0;
    let makes = [];

    selectedOptions.forEach((make) => {
      let activeModels = 0;
      make.Models.forEach((model) => {
        if (model.Selected) {
          activeModels++;
        }
      });
      if (activeModels !== 0) {
        makes.push(make.Make.Value);
      }
    });

    let models = [];
    selectedOptions.forEach((make) =>
      make.Models.forEach((model) => {
        if (model.Selected) {
          models.push(model.Value);
          countedSelectedCars += model.Count;
        }
      })
    );
    setNumberOfSelectedCars(countedSelectedCars);
  };

  // SELECT ELEMENT
  const selectCarValue = (valueName, parentMake) => {
    let myUpdatedOptions = JSON.parse(JSON.stringify(myOptions));
    let myUpdatedFilteredOptions = JSON.parse(JSON.stringify(myFilteredOptions));

    myUpdatedOptions.forEach((car) => {
      if (car.Make.Value === valueName) {
        let isMakeSelected = car.Make.Selected;
        car.Make.Selected = !isMakeSelected;
        car.Models.forEach((model) => {
          model.Selected = !isMakeSelected;
        });
      } else {
        let isAllSelected = true;
        car.Models.forEach((model) => {
          if (model.Value === valueName && model.ParentMake == parentMake) {
            model.Selected = !model.Selected;
          }
          if (model.Selected == false) {
            isAllSelected = false;
          }
        });

        car.Make.Selected = isAllSelected;
      }
    });

    myUpdatedFilteredOptions.forEach((car) => {
      if (car.Make.Value === valueName) {
        let isMakeSelected = car.Make.Selected;
        car.Make.Selected = !isMakeSelected;
        car.Models.forEach((model) => {
          model.Selected = !isMakeSelected;
        });
      } else {
        let isAllSelected = true;
        car.Models.forEach((model) => {
          if (model.Value === valueName && model.ParentMake == parentMake) {
            model.Selected = !model.Selected;
          }
          if (model.Selected == false) {
            isAllSelected = false;
          }
        });

        car.Make.Selected = isAllSelected;
      }
    });

    isMobile && handleValueChange(myUpdatedOptions);
    setMyOptions(myUpdatedOptions);
    findSelectedOptions(myUpdatedOptions);
    filterOptions("", myUpdatedOptions);
  };

  const ckeckStatus = (make) => {
    let status = "NOTACTIVE";
    if (make.Make.Selected) {
      status = "ACTIVE";
    }
    let activeModels = 0;
    make.Models.forEach((model) => {
      if (model.Selected) {
        activeModels++;
      }
    });
    if (activeModels !== 0 && activeModels !== make.Models.length) {
      status = "SEMIACTIVE";
    }
    return status;
  };

  const sendRequest = (e) => {
    if (!e || e.key === "Enter") {
      handleSubmit(mySelectedOptions);
    }
  };
  return (
    <div className="search-input">
      <div className="text-field-container">
        <input
          ref={autocompleteInputRef}
          type="text"
          label={label}
          placeholder="wpisz czego szukasz np. marka, model"
          value={inputValue}
          onChange={(e) => filterOptions(e.target.value)}
          class="search-input__input"
          onFocus={() => setIsInputFocus(true)}
          onKeyPress={(e) => sendRequest(e)}
          autoFocus
          onBlur={() => {
            if (!isMobile) {
              setIsInputFocus(false);
              sendRequest();
            }
          }}
        />
        {isMobile && (
          <Icon className="myIcon" iconComponent={MagnifyingGlass} primaryColor={IconColor.SECONDARY_CONTRAST} />
        )}
      </div>
      <div
        className={`dropdown-field ${
          (!isInputFocus || inputValue === "") && !mySelectedOptions.length ? "dropdown-field--hide" : ""
        }`}
      >
        {isMobile && (
          <div className="dropdown-field__header">
            <p>marki i modele</p>
          </div>
        )}
        {!!mySelectedOptions.length && !isMobile && (
          <div className="selected-items-list">
            {mySelectedOptions.map((car) => (
              <>
                {car.Models.map((model) => (
                  <>
                    {model.Selected && (
                      <Chip
                        variant={ChipVariant.DENSE}
                        isCloseIconVisible
                        className="selected-item"
                        isSelected
                        onClick={() => selectCarValue(model.Value, model.ParentMake)}
                      >
                        {model.Name}
                      </Chip>
                    )}
                  </>
                ))}
              </>
            ))}
          </div>
        )}
        <ul className="items-list">
          {myFilteredOptions.map((car) => (
            <>
              {car.Models.length !== 0 && (
                <li
                  className={`make-item ${car.Make.Selected == true ? "active" : ""}`}
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => {
                    selectCarValue(car.Make.Name.replaceAll(' ','-'));
                  }}
                >
                  <div className="left">
                    <MyCheckbox status={ckeckStatus(car)} />
                    <span dangerouslySetInnerHTML={{ __html: car.Make.Value }} />
                  </div>
                  <span>{car.Make.Count}</span>
                </li>
              )}
              {car.Models.map((model) => (
                <li
                  className="model-item"
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => selectCarValue(model.Value, model.ParentMake)}
                >
                  <div className="left">
                    <MyCheckbox status={model.Selected ? "ACTIVE" : "NOTACTIVE"} />
                    <span dangerouslySetInnerHTML={{ __html: model.Name }} />
                  </div>
                  <span>{model.Count}</span>
                </li>
              ))}
            </>
          ))}
        </ul>
        {!!numberOfSelectedCars && (
          <div className="dropdown-field__button">
            <Button onClick={() => handleSubmit(mySelectedOptions)}>pokaż wyniki ({numberOfSelectedCars})</Button>
          </div>
        )}
      </div>
    </div>
  );
};

const MyCheckbox = ({ status }) => {
  return <div className={`myCheckbox ${status}`}></div>;
};

export default SearchInput;
