import React from "react";
import { IconButton } from "@mbank-design/design-system/components";
import { Share } from "@mbank-design/design-system/icons";
import { IconButtonIconSize, IconButtonType, IconColor } from "@mbank-design/design-system/enums";
import { ToastContext } from "../../../scripts/commons/contexts";

function ButtonCopyLink() {
  async function copyLink(toastState) {
    await toastState.changeToastState("message", "skopiowano link do schowka");
    await toastState.changeToastState("buttonText", "OK");
    await toastState.toggleToast();
    const el = document.createElement("textarea");
    el.value = window.window.location.href;
    el.setAttribute("display", "none");
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    setTimeout(() => {
      if (toastState.returnToastState("isOpen")) {
        toastState.toggleToast();
      }
    }, 3000);
  }

  return (<ToastContext.Consumer>
    {(toastState) => {
      return (
        <IconButton
          className="button-copy-link icon-button button-transparent"
          iconComponent={Share}
          buttonType={IconButtonType.TRANSPARENT}
          onClick={() => {
            copyLink(toastState);
          }}
          iconColor={IconColor.SECONDARY_CONTRAST}
          iconSize={IconButtonIconSize.SIZE_16}
        />);
    }}
  </ToastContext.Consumer>);
}

export default ButtonCopyLink;