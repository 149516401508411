import React from "react";
import { Box, Icon, Text } from "@mbank-design/design-system/components";
import { FontColor, FontWeight } from "@mbank-design/design-system/design-tokens/general/typography";
import { Pin } from "@mbank-design/design-system/icons";
import { BoxBackgroundColor, IconColor } from "@mbank-design/design-system/enums";

const ReservedLabel = (props) => {
  const text = props.text;
  let iconType = props.iconType ? props.iconType : Pin;
  let color = props.color ? props.color : FontColor.zeusBlackUI;
  return (
    <div className="reserved-label">
      <Box display={"flex"} alignItems={"center"}>
        <Box
          background={BoxBackgroundColor.MONO_900}
          padding={8}
          borderRadius={"small"}
          display={"flex"}
          alignItems={"center"}
        >
          <Icon iconComponent={iconType} size={16} primaryColor={"white30"} />
        </Box>
        <Text as={props.textAs || "small"} paddingLeft={8} fontWeight={FontWeight.WEIGHT_600} color={color}>
          {text}
        </Text>
      </Box>
    </div>
  );
};

export default ReservedLabel;
