import React, { useEffect, useState } from "react";
import { HorizontalLine, Icon, Text } from "@mbank-design/design-system/components";
import { FontColor, FontSize, FontWeight } from "@mbank-design/design-system/design-tokens/general/typography";
import "./index.scss";
import { ChevronDown } from "@mbank-design/design-system/icons";
import { getSiteRules } from "../../../services";
import rulesCheaper from "../../../assets/files/Taniej z mAuto - edycja 3.pdf";
import giftedFuelRules from "../../../assets/files/Regulamin-Paliwo-w-prezencie.pdf";
import { IconColor } from "@mbank-design/design-system/enums";

let columns = [
  {
    name: "mAuto",
    data: [
      {
        name: "o nas",
        link: "/o-nas"
      },
      {
        name: "finansowanie",
        link: "/finansowanie",
      },
      {
        name: 'program "mój elektryk"',
        link: "/moj-elektryk"
      },
      {
        name: "pytania i odpowiedzi",
        link: "/faq"
      },
      {
        name: "regulamin mAuto",
        type: "ruleMAuto"
      }
    ]
  },
  {
    name: "promocje",
    data: [
      {
        name: "mAuto – Hyundai",
        link: "/lp-hyundai",
      },
      {
        name: "mAuto – BMW",
        link: "/lp-bmw",
      },
      {
        name: "taniej z mAuto",
        link: "/lp-taniej-z-mAuto",
      },
      {
        name: "regulamin Taniej z mAuto",
        link: rulesCheaper,
      },
      {
        name: "regulamin Paliwo w prezencie",
        link: giftedFuelRules,
      }
    ]
  },
  {
    name: "polecane",
    data: [
      {
        name: "samochody nowe",
        link: "/nowe-samochody"
      },
      {
        name: "samochody używane",
        link: "/samochody-poleasingowe"
      },
      {
        name: "samochody elektryczne",
        link: "/samochody-elektryczne"
      }
    ]
  },
  {
    name: "serwisy",
    data: [
      {
        name: "zaloguj się",
        link: "/login"
      },
      // {
      //   name: "portal aukcyjny",
      //   link: "/aukcje-samochodowe"
      // },
      {
        name: "mLeasing",
        link: "https://mleasing.pl/"
      }
    ]
  }
];

const FooterCategoriesLinks = () => {
  const [stateOfColumns, setStateOfColumns] = useState({});

  function clickAnchorWithRegulations(res) {
    const a = document.createElement("a");
    a.href = res.data.Base64;
    a.download = res.data.Name;
    a.target = "_blank";
    a.click();
    a.remove();
  }

  function fetchFile(type) {
    if (!type) return;

    if (type === "ruleMAuto") {
      getSiteRules().then((res) => {
        clickAnchorWithRegulations(res);
      });
    }
  }

  useEffect(() => {
    const stateOfColumnsObj = {};
    columns.forEach((column) => (stateOfColumnsObj[column.name] = false));

    setStateOfColumns(stateOfColumnsObj);
  }, []);

  return (
    <div className="categories-links">
      {columns.map((column, key) => (
        <>
          <div className="categories-links__column">
            <div
              className="categories-links__column-header"
              onClick={() => setStateOfColumns({ ...stateOfColumns, [column.name]: !stateOfColumns[column.name] })}
            >
              <Text as="p" fontWeight={FontWeight.WEIGHT_600} fontSize={FontSize.SIZE_20}>
                {column.name}
              </Text>
              <div className={"mobile-only centered rotable " + (stateOfColumns[column.name] ? "upside-down" : "")}>
                <Icon iconComponent={ChevronDown} primaryColor={IconColor.BLUE} />
              </div>
            </div>
            {!(columns.length === key + 1 && !stateOfColumns[column.name]) && (
              <HorizontalLine className="mobile-only" />
            )}
            <div
              className={`categories-links__column-elements ${
                stateOfColumns[column.name] ? "" : "hide-column-elements"
              }`}
            >
              {column.data.map((elem, key) => (
                <>
                  <a
                    onClick={elem.type ? () => fetchFile(elem.type) : null}
                    href={elem.link}
                    target={elem.target ? elem.target : ""}
                    rel={elem.target ? "noreferrer" : ""}
                    className="categories-links__column-element"
                  >
                    <Text as="p" color={FontColor.DARK}>
                      {elem.name}
                    </Text>
                  </a>
                  {!(column.data.length === key + 1) && <HorizontalLine className="mobile-only" />}
                </>
              ))}
            </div>
          </div>
        </>
      ))}
    </div>
  );
};

export default FooterCategoriesLinks;
