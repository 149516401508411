import React from "react";
import "./index.scss";
import { Icon, Text } from "@mbank-design/design-system/components";
import * as ALL_ICONS from "@mbank-design/design-system/icons";
import {
  FontWeight,
} from "@mbank-design/design-system/design-tokens/general/typography";
import { IconColor, IconSize } from "@mbank-design/design-system/enums";

const Tooltip = (props) => {
  return (
    <div className={`tooltip-container ${props.darkMode ? 'dark' : ''}`}>
      <Icon iconComponent={ALL_ICONS['InfoRound']} size={IconSize.SMALL} />
      <div className="tooltip-text-container">
        <div className="title">
          <Icon iconComponent={ALL_ICONS[props.icon || 'ShieldTick']} primaryColor={IconColor.BLUE} />
          <Text as="p" fontWeight={FontWeight.WEIGHT_600}>
            {props.title}
          </Text>
        </div>
        <Text as="p">{props.description}</Text>
      </div>
    </div>
  );
};

export default Tooltip;
