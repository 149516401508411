import React from "react";
import PropTypes from "prop-types";
import {
  FontColor,
  FontWeight,
} from "@mbank-design/design-system/design-tokens/general/typography";
import { Text, Icon } from "@mbank-design/design-system/components";

import "./index.scss";
import { IconColor } from "@mbank-design/design-system/enums";

const NavBarLogOutLinkWithIcon = (props) => {
  NavBarLogOutLinkWithIcon.propTypes = {
    linkString: PropTypes.string,
    iconComponent: PropTypes.any.isRequired,
    text: PropTypes.string.isRequired,
  };

  return (
    <>
        <div className="link-with-icon">
          <Icon
            iconComponent={props.iconComponent}
            primaryColor={IconColor.BLUE}
            size={24}
          />
          <Text
            as="p"
            className={
              window.location.href.includes(props.linkString)
                ? "highlighted"
                : ""
            }
            fontWeight={FontWeight.WEIGHT_600}
            color={FontColor.zeusBlackUI}
          >
            {props.text}
          </Text>
        </div>
    </>
  );
};

export default NavBarLogOutLinkWithIcon;
