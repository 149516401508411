import React, { useRef, useState } from "react";
import PropTypes from "prop-types";

import { Text } from "@mbank-design/design-system/components";
import { FontColor, FontSize, FontWeight } from "@mbank-design/design-system/design-tokens/general/typography";
import "./index.scss";
import { Link } from "react-router-dom";

CharacteristicFeaturesList.propTypes = {
  list: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  isTechnicalDataList: PropTypes.bool,
  isMobile: PropTypes.bool,
  disableShowMore: PropTypes.bool,
  id: PropTypes.string
};

function CharacteristicFeaturesList(props) {
  const [isShowMoreEnabled, setIsShowMoreEnabled] = useState(props.isMobile || false);
  const scrollTargetContainer = useRef();
  const isoDateTransform = (isoDate) => {
    const months = [
      "styczeń",
      "luty",
      "marzec",
      "kwiecień",
      "maj",
      "czerwiec",
      "lipiec",
      "sierpień",
      "wrzesień",
      "październik",
      "listopad",
      "grudzień"
    ];
    const dateObj = new Date(isoDate);

    const monthIndex = dateObj.getMonth();
    const year = dateObj.getFullYear();
    const formattedDate = `${months[monthIndex]} ${year}`;

    return formattedDate;
  }
  const isIsoDateTimeFormat = (str) => {
    const isoDateTimePattern = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;

    return isoDateTimePattern.test(str.trim());
  }

  const handleClickShowMore = () => {
    setIsShowMoreEnabled(!isShowMoreEnabled);
    scrollTargetContainer.current.scrollIntoView();
  };

  const jsxCondition = (item, index) => {
    //if item includes <b></b> marks we want to display it with diffrent styles
    if (item && item.label.includes("<b>") && item.label.includes("</b>")) {
      return (
        <li className="no-dot-point" key={index} ref={props.refEl ? props.refEl : null}>
          {props.isTechnicalDataList ? (
            <div className={"technical-information" + (item.url ? " with-url" : "")}>
              <Text
                fontSize={FontSize.SIZE_16}
                dangerouslySetInnerHTML={{ __html: item.label.substring(0, item.label.indexOf(":")) }}
              ></Text>

              {item.url ? (
                <Link to={"/" + item.url.toLowerCase().replace(/ /g, "-")}>
                  <Text
                    color={FontColor.endeavourBlueUI}
                    fontSize={FontSize.SIZE_16}
                    fontWeight={600}
                    dangerouslySetInnerHTML={{
                      __html: item.label.substring(item.label.indexOf(":") + 1, item.label.length)
                    }}
                  ></Text>
                </Link>
              ) : (
                <Text
                  fontSize={FontSize.SIZE_16}
                  fontWeight={600}
                  dangerouslySetInnerHTML={{
                    __html: item.label.substring(item.label.indexOf(":") + 1, item.label.length)
                  }}
                ></Text>
              )}
            </div>
          ) : (
            <Text fontSize={FontSize.SIZE_16} dangerouslySetInnerHTML={{ __html: item.label }}></Text>
          )}
        </li>
      );
    } else {
      return (
        <li key={index} ref={props.refEl ? props.refEl : null}>
          {props.isTechnicalDataList ? (
            <div className={"technical-information" + (item.url ? " with-url" : "")}>
              <Text
                fontSize={FontSize.SIZE_14}
                fontWeight={FontWeight.WEIGHT_500}
                className="technical-information__key"
                color={FontColor.MONO_600}
              >
                {item.label.substring(0, item.label.indexOf(":"))}
              </Text>

              {item.url ? (
                <Link to={"/" + item.url.toLowerCase().replace(/ /g, "-")}>
                  <Text fontSize={FontSize.SIZE_20} color={FontColor.DARK} fontWeight={600}>
                    {item.label.substring(item.label.indexOf(":") + 1, item.label.length)}
                  </Text>
                </Link>
              ) : (
                <Text fontSize={FontSize.SIZE_20} fontWeight={600}>
                  {item.label.substring(item.label.indexOf(":") + 1, item.label.length).includes("on")
                    ? item.label.substring(item.label.indexOf(":") + 1, item.label.length).toUpperCase()
                    : item.label.substring(item.label.indexOf(":") + 1, item.label.length)}
                </Text>
              )}
            </div>
          ) : (
            <>
              {props.title === "lokalizacja" ? (
                <div className={"technical-information" + (item.url ? " with-url" : "")}>
                  <Text
                    fontSize={FontSize.SIZE_14}
                    fontWeight={FontWeight.WEIGHT_500}
                    className="technical-information__key"
                    color={FontColor.MONO_600}
                  >
                    miasto
                  </Text>
                  <Text fontSize={FontSize.SIZE_20} fontWeight={600}>
                    {item.label.substring(item.label.indexOf(":") + 1, item.label.length)}
                  </Text>
                </div>
              ) : props.title === "lokalizacja i dostępność" ? (
                <>
                  <Text
                    fontSize={FontSize.SIZE_14}
                    fontWeight={FontWeight.WEIGHT_500}
                    className="technical-information__key"
                    color={FontColor.MONO_600}
                  >
                    {item.label.substring(0, item.label.indexOf(":"))}
                  </Text>
                  <Text fontSize={FontSize.SIZE_20} fontWeight={600}>
                    {isIsoDateTimeFormat(item.label.substring(item.label.indexOf(":") + 1, item.label.length))
                      ? isoDateTransform(item.label.substring(item.label.indexOf(":") + 1, item.label.length))
                      : item.label.substring(item.label.indexOf(":") + 1, item.label.length)}
                  </Text>
                </>
              ) : (
                <Text>{item.label}</Text>
              )}
            </>
          )}
        </li>
      );
    }
  };

  const list = isShowMoreEnabled ? props.list : props.list.slice(0, 8);
  let isMiddle = Math.ceil(list.length / 2);
  if (list && isMiddle && list[isMiddle - 1]) {
    if (list[isMiddle - 1].label.includes("<b>") && list[isMiddle - 1].label.includes("</b>")) {
      isMiddle += 1;
    }
  }

  return (
    <div className={`characteristic-features-list-container`} id={props.id}>
      <Text as={"h2"} ref={scrollTargetContainer} className="title">
        {props.title}
      </Text>
      <div className="list-container">
        {list && !!list.length && (
          <ul className={props.list && props.list.length === 1 ? "list-with-one-item" : ""}>
            {list.map((item, index) => jsxCondition(item, index))}
          </ul>
        )}
      </div>
      {!props.isMobile && !props.disableShowMore && (
        <div className="show-more-button" onClick={() => handleClickShowMore()} onKeyDown={() => handleClickShowMore()}>
          {isShowMoreEnabled ? "pokaż mniej" : "pokaż więcej"}
        </div>
      )}
    </div>
  );
}

export default CharacteristicFeaturesList;
