import { Component } from "react";

export default class BaseCalculator extends Component {
  generateCalculatorPackagesState(
    additionalServices,
    addPrice,
    addConditionalVisibility,
    queryObjectWithState
  ) {
    const stateObj = {};
    for (let pkg of additionalServices) {
      let obj = { isChecked: !!pkg.SetSelfAsTrue, Value: pkg.Value };

      if (addPrice) {
        obj.Price = Array.isArray(pkg.Price) ? pkg.Price[0].Value : pkg.Price || 0;
      }

      if (addConditionalVisibility) {
        obj.isVisible = !(pkg.Visibility && pkg.Visibility.length > 0);
      }

      if (queryObjectWithState) {
        if (queryObjectWithState[`Package${pkg.Value}`]) {
          obj.isChecked =
            queryObjectWithState[`Package${pkg.Value}`] === "true";
          if (obj.isChecked) {
            obj.isVisible = true;
          }
        }
      }

      stateObj[pkg.Name.split(" ").join("_")] = obj;
    }

    return stateObj;
  }

  emitFilters() {
    let filterToSend = {
      FinancingMethod:
        this.state.tabSelected !== undefined ? this.state.tabSelected : 1,
      Filters: {
        AdditionalServices: [],
        Price: this.state.CalcOptionsRaw.PriceBrutto,
        HP: this.state.CalcOptionsRaw.HP,
      },
    };

    let iterableObject =
      filterToSend.FinancingMethod === 1
        ? this.state.CalcOptions.LongTimeRentCalculatorValuesDTO
        : this.state.CalcOptions.LeasingCalculatorValues.Periods.length
        ? this.state.CalcOptions.LeasingCalculatorValues
        : {};


    for (let name of Object.keys(iterableObject)) {
      filterToSend.Filters[name.substring(0, name.length - 1)] = iterableObject[
        name
      ].find((x) => x.Status === true).Value;
    }
    // in if cuz of new car
    if(this.props.pageType === "new" && this.state.tabSelected === 1){
      for (let pkgName of Object.keys(this.state.PackageStateForNewCarRent)) {
        const pkg = this.state.PackageStateForNewCarRent[pkgName];
        if (pkg.isChecked) {
          filterToSend.Filters.AdditionalServices.push(pkg.Value);
        }
      }
    } else {
      for (let pkgName of Object.keys(this.state.PackageState)) {
        const pkg = this.state.PackageState[pkgName];
        if (pkg.isChecked) {
          filterToSend.Filters.AdditionalServices.push(pkg.Value);
        }
      }
    }
    this.props.handler(filterToSend);
  }

  changePackageStatus(pkgName, pkg) {
    if (this.props.pageType === "new" && this.state.tabSelected === 1) {
      let pkgState = { ...this.state.PackageStateForNewCarRent };
      if (pkg.Dependency && pkg.Dependency.length > 0) {
        pkg.Dependency.forEach(dep => {
          if (dep.action === "TOGGLE_SELF_WITH_DEP") {
            //There is a problem with given excel file (it's not filled properly) so we need to handle case when price is null
            let pkgItem = this.props.calcData.AdditionalServiceItemsForNewCarRent.find(item => item.Name === pkg.Name);
            let depItem = this.props.calcData.AdditionalServiceItemsForNewCarRent.find(item => item.Name.split(" ").join("_") === dep.packageStateName);
            if (pkgItem.Price === null) {
              pkgState[pkg.Name.split(" ").join("_")].isChecked = false;
            }
            if (depItem.Price === null) {
              pkgState[dep.packageStateName].isChecked = false;
            }

            if (pkgItem.Price !== null && depItem.Price !== null) {
              pkgState[pkg.Name.split(" ").join("_")].isChecked = !pkgState[pkg.Name.split(" ").join("_")].isChecked;
              pkgState[dep.packageStateName].isChecked = !pkgState[dep.packageStateName].isChecked;
            }
          }
        });
      } else {
        if(!pkg.CantBeDeactivated) {
          pkgState[pkg.Name.split(' ').join('_')].isChecked = !pkgState[pkg.Name.split(' ').join('_')].isChecked;
        }
      }

      this.setState({
        PackageStateForNewCarRent: pkgState
      }, () => {
        this.emitFilters();
      });
    } else {
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            PackageState: {
              ...prevState.PackageState,
              [pkgName]: {
                ...prevState.PackageState[pkgName],
                isChecked: pkg.CantBeDeactivated
                  ? prevState.PackageState[pkgName].isChecked
                  : !prevState.PackageState[pkgName].isChecked,
              },
            },
          };
        },
        () => {
          this.emitFilters();
        }
      );
    }
  }

  collapseAdditionalPackage() {
    this.setState({
      ...this.state,
      expandAdditionalPackages: !this.state.expandAdditionalPackages,
    });
  }

  handleRadioBtnChange(eventHandlerObject) {
    let newTempRaw = { ...this.state.CalcOptionsRaw };
    let parent =
      eventHandlerObject.Parent === "LongTimeRentCalculatorValuesDTO"
        ? "RentPeriods"
        : "LeasingPeriods";

    switch (eventHandlerObject.RadioName) {
      case "Periods": {
        // get branch name
        let ext = parent === "RentPeriods" ? "Mileages" : "Rvs";

        // change old period
        let oldSelectedPeriod = newTempRaw[parent].find(
          (x) => x.Period.Status === true
        );
        oldSelectedPeriod.Period.Status = false;

        //get old period data
        let oldSelectedOw = oldSelectedPeriod.Ows.find(
          (x) => x.Ow.Status === true
        );

        // choose new period
        let newSelectedPeriod = newTempRaw[parent].find(
          (x) => x.Period.Value === eventHandlerObject.Value
        );
        newSelectedPeriod.Period.Status = true;

        //   apply old period options to the new one
        this.rewriteOwsOptions(
          null,
          parent,
          newTempRaw,
          ext,
          oldSelectedOw,
          true
        );

        break;
      }
      case "Ows": {
        let ext = parent === "RentPeriods" ? "Mileages" : "Rvs";
        let oldOw = newTempRaw[parent]
          .find((x) => x.Period.Status === true)
          .Ows.find((x) => x.Ow.Status === true);
        this.rewriteOwsOptions(
          eventHandlerObject,
          parent,
          newTempRaw,
          ext,
          oldOw
        );

        break;
      }
      case "Mileages": {
        newTempRaw[parent]
          .find((x) => x.Period.Status === true)
          .Ows.find((x) => x.Ow.Status === true)
          .Mileages.find((x) => x.Status === true).Status = false;

        newTempRaw[parent]
          .find((x) => x.Period.Status === true)
          .Ows.find((x) => x.Ow.Status === true)
          .Mileages.find(
            (x) => x.Value === eventHandlerObject.Value
          ).Status = true;
        break;
      }
      case "Rvs": {
        newTempRaw[parent]
          .find((x) => x.Period.Status === true)
          .Ows.find((x) => x.Ow.Status === true)
          .Rvs.find((x) => {
            return x.Status === true;
          }).Status = false;

        newTempRaw[parent]
          .find((x) => x.Period.Status === true)
          .Ows.find((x) => x.Ow.Status === true)
          .Rvs.find((x) => {
            return x.Value === eventHandlerObject.Value;
          }).Status = true;
        break;
      }
      default:
        console.err("Event not found!");
        break;
    }
    this.setState(
      (prevState) => ({
        ...prevState,
        CalcRawData: newTempRaw,
      }),
      () => this.getInitialCalcForm()
    );
  }

  rewriteOwsOptions(
    event,
    parent,
    rawCalcOptions,
    ext,
    oldOw,
    rewriteExtByName
  ) {
    // disable old one
    oldOw.Ow.Status = false;

    // save old extension
    let oldSelectedExt = oldOw[ext].find((x) => x.Status === true);

    // make false old selected OW
    let oldSelectedOw = rawCalcOptions[parent]
      .find((x) => x.Period.Status === true)
      .Ows.find((x) => x.Ow.Status === true);
    if (oldSelectedOw) {
      oldSelectedOw.Ow.Status = false;
    }

    // find the new ow and set it true
    let eventValue = event ? event.Value : oldOw.Ow.Value;
    let newSelectedPeriod = rawCalcOptions[parent].find((x) => x.Period.Status === true);
    let newSelectedOw = newSelectedPeriod.Ows.find((x) => x.Ow.Value === eventValue);

    if (!newSelectedOw) {
      newSelectedOw = newSelectedPeriod.Ows[0];
    }

    newSelectedOw.Ow.Status = true;

    // disable old extension option status if exist
    if (newSelectedOw[ext].find((x) => x.Status === true)) {
      newSelectedOw[ext].find((x) => x.Status === true).Status = false;
    }

    // enable new extension option
    // sometimes you want to rewrite ows by name (for example - in max mileage per yer on calc) so that's the reason why you need rewriteExtByName
    if (rewriteExtByName) {
      let newSelectedExt = newSelectedOw[ext].find(
        (x) => oldSelectedExt.Name == x.Name
      );
      newSelectedExt
        ? (newSelectedExt.Status = true)
        : (newSelectedOw[ext][0].Status = true);
    } else {
      let newSelectedExt = newSelectedOw[ext].find(
        (x) => oldSelectedExt.Value === x.Value
      );
      newSelectedExt
        ? (newSelectedExt.Status = true)
        : (newSelectedOw[ext][0].Status = true);
    }
  }

  createURLToContactSection(
    calcOptions,
    calcOptionsRaw,
    packageList,
    selectedMethod,
    isAfterLease
  ) {
    let url = "";
    let paramObject = {
      AuctionId: calcOptionsRaw.AuctionId,
      FinancingMethod: selectedMethod,
      AfterLease: isAfterLease,
    };
    if (selectedMethod === 1) {
      paramObject.Period = calcOptions.LongTimeRentCalculatorValuesDTO.Periods.find(
        (x) => x.Status === true
      ).Value;
      paramObject.Mileages = calcOptions.LongTimeRentCalculatorValuesDTO.Mileages.find(
        (x) => x.Status === true
      ).Value;
      paramObject.Ows = calcOptions.LongTimeRentCalculatorValuesDTO.Ows.find(
        (x) => x.Status === true
      ).Value;
    } else if (selectedMethod === 2) {
      paramObject.Rvs = calcOptions.LeasingCalculatorValues.Rvs.find(
        (x) => x.Status === true
      ).Value;
      paramObject.Period = calcOptions.LeasingCalculatorValues.Periods.find(
        (x) => x.Status === true
      ).Value;
      paramObject.Ows = calcOptions.LeasingCalculatorValues.Ows.find(
        (x) => x.Status === true
      ).Value;
    } else if (selectedMethod === 4) {
      paramObject.Rvs = 1
      paramObject.Period = 36
      paramObject.Ows = 20
    } else if (calcOptions.LeasingCalculatorValues.Periods.length) {
      paramObject.Rvs = calcOptions.LeasingCalculatorValues.Rvs.find(
        (x) => x.Status === true
      ).Value;
      paramObject.Period = calcOptions.LeasingCalculatorValues.Periods.find(
        (x) => x.Status === true
      ).Value;
      paramObject.Ows = calcOptions.LeasingCalculatorValues.Ows.find(
        (x) => x.Status === true
      ).Value;
    }

    if (selectedMethod !== 4) {
      for (let pkg of Object.keys(packageList)) {
        const obj = packageList[pkg];
        paramObject["Package" + obj.Value] = obj.isChecked;
      }
    }
    url = new URLSearchParams(paramObject).toString();
    return url;
  }

  createPDFObject(
    calcOptions,
    calcOptionsRaw,
    packageList,
    calcResult,
    selectedMethod,
    isAfterLease,
    installment) {
    let paramObject = {
      AuctionId: calcOptionsRaw.AuctionId,
    };

    if (calcResult) {
      let calcFormData = {
        Ow: calcResult.Ow,
        Rv: calcResult.Rv,
        InstallmentPriceNetto: installment,
        TotalFeesPercent: calcResult.TotalFees,
        IsAuction: false,
        IsLeasing: selectedMethod === 2 ? true : false,
        IsNew: !isAfterLease,
        TotalPriceBrutto: calcOptions.PriceBrutto,
        AdditionalServices: []
      };

      switch (selectedMethod) {
        case 1: {
          calcFormData.Period = calcOptions.LongTimeRentCalculatorValuesDTO.Periods.find(
            (x) => x.Status === true
          ).Value;
          calcFormData.Mileage = calcOptions.LongTimeRentCalculatorValuesDTO.Mileages.find(
            (x) => x.Status === true
          ).Value;
          calcFormData.OwPercent = calcOptions.LongTimeRentCalculatorValuesDTO.Ows.find(
            (x) => x.Status === true
          ).Value;
          // reset unusedValues
          calcFormData.RvPercent = -1;
          calcFormData.Rv = -1
          break;
        }
        case 2: {
          calcFormData.RvPercent = calcOptions.LeasingCalculatorValues.Rvs.find(
            (x) => x.Status === true
          ).Value;
          calcFormData.Period = calcOptions.LeasingCalculatorValues.Periods.find(
            (x) => x.Status === true
          ).Value;
          calcFormData.OwPercent = calcOptions.LeasingCalculatorValues.Ows.find(
            (x) => x.Status === true
          ).Value;
          calcFormData.Mileage = -1;
          break;
        }
        case 3: {
          calcFormData.Ow = -1;
          calcFormData.OwPercent = -1;
          calcFormData.Mileage = -1;
          calcFormData.Period = -1;
          calcFormData.Rv = -1;
          calcFormData.RvPercent = -1;
          calcFormData.InstallmentPriceNetto = -1;
          calcFormData.TotalFeesPercent = -1;
          break;
        }
        case 4: {
          calcFormData.Rvs = 1
          calcFormData.Period = 36
          calcFormData.Ows = 20
          calcFormData.Mileage = -1;
          break;
        }
        default:
          break;
      }

      if (selectedMethod !== 3 || selectedMethod !== 4) {
        for (let pkg of Object.keys(packageList)) {
          const obj = packageList[pkg];
          if (obj.isChecked) {
            calcFormData.AdditionalServices.push(obj.Value);
          }
        }
      }

      paramObject.CalculatorContactFormData = calcFormData;
    }

    return paramObject;
  }
}
