import React, { useEffect } from "react";

import "./index.scss";
import DetailModalContactForm from "../../pages/DetailModalContactForm";
import { Icon } from "@mbank-design/design-system/components";
import { Cross } from "@mbank-design/design-system/icons";

export const ContactModal = (props) => {
  useEffect(() => {
    blockScroll(true);

    return () => {
      blockScroll(false);
    };
  }, []);

  const blockScroll = (block) => {
    let htmlEl = document.getElementsByTagName("html")[0];
    if (htmlEl) {
      htmlEl.style.overflowY = block ? "hidden" : "unset";
    }
  };

  return (
    <>
      <div
        className="contact-modal__shadow"
        onClick={(e) => {
          props.handleContactModal();
          e.stopPropagation();
        }}
      >
        <div
          className="contact-modal"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="content-wrapper">
            <div className="contact-modal__tile">
              <div
                className="contact-modal__close"
                onClick={() => {
                  props.handleContactModal();
                }}
                role="button"
                tabIndex={0}
              >
                <Icon iconComponent={Cross} primaryColor="blue30" />
              </div>
              <div>
                {props.urlObject && props.calcCurrentPrices && (
                  <DetailModalContactForm
                    urlObject={props.urlObject}
                    pageType={props.pageType}
                    chips={props.chips}
                    calcPrice={props.calcPrice}
                    calcCurrentPrices={props.calcCurrentPrices}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
