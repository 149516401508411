import "./index.scss";

import React, { useEffect, useState } from "react";
import { Box, Text } from "@mbank-design/design-system/components";
import { palette } from "@mbank-design/design-system/design-tokens/general/palette";

function SpecialPromotions(props) {
  const [promotions, setPromotions] = useState({
    labels: [],
    specialOfferIconLink: null
  });

  useEffect(() => {
    setPromotions(handleSpecialPromotions());
  }, [props.promotions]);

  function handleSpecialPromotions() {
    let promotions = {
      labels: [],
      specialOfferIconLink: null
    };

    if (!props.promotions) {
      return promotions;
    }

    for (let promo of props.promotions) {
      if (promo.PhotoLink && !promotions.specialOfferIconLink) {
        promotions.specialOfferIconLink = promo.PhotoLink;
      }

      promotions.labels.push({
        fontColor: promo.TextColor || "white30",
        backgroundColor: promo.BackgroundColor || "simpleRed",
        text: promo.Text,
        icon: promo.PhotoLink
      });
    }

    return promotions;
  }

  return <div className="special-promotions">
    {promotions.labels?.map((promo, key) => {
        return (
          <Box key={key} className="promotion" background={palette[promo.backgroundColor]}>
            {promo.icon && <img src={promo.icon} alt="icon" width={16} height={16}/>}
            <Text as={"p"} styledAs={"small"} color={promo.fontColor}>{promo.text}</Text>
          </Box>
        );
      }
    )}
  </div>;
}

export default SpecialPromotions;
