import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";

import { HorizontalLine, Icon, Media, Text } from "@mbank-design/design-system/components";
import { FontWeight, LineHeight } from "@mbank-design/design-system/design-tokens/general/typography";
import { Briefcase, Envelope, PhoneHandle } from "@mbank-design/design-system/icons";
import { Mauto40StripeMass } from "@mbank-design/design-system/logos";
import "./index.scss";
import FooterCategoriesLinks from "./../../parts/FooterCategoriesLinks";
import ContactFormModal from "../ContactFormModal";
import { FontSize } from "@mbank-design/design-system/design-tokens/general/typography";
import { IconColor, IconSize } from "@mbank-design/design-system/enums";

const Footer = () => {
  const location = useLocation();
  const isMobile = Media.useMobileOnlyMedia();
  const [activeModal, setActiveModal] = useState(false);

  const hideFooterPagesList = ["/lp-q7", "/lp-tesla-3", "/lp-hyundai", "/lp-tesla-y", "/lp-afi", "/lp-bmw", "/targi", "/konkurs", "/lp-taniej-z-mauto", "/lp-2000-na-paliwo"];

  if (hideFooterPagesList.includes(location.pathname.toLowerCase())) {
    return null;
  }
  return (
    <div className="mleasing-main-container footer-container" id="footer-container">
      {activeModal && (
        <ContactFormModal setActiveModal={setActiveModal} />
      )}
      <div className="footer-wrapper">
        <div className="content-wrapper">
          {location.pathname !== "/kontakt" && location.pathname !== "/o-nas" && (
            <div className="contact-container">
              <div className="contact-container__item">
                <div className="contact-container__item-icon">
                  <Icon
                    primaryColor={IconColor.SECONDARY_CONTRAST}
                    iconComponent={PhoneHandle}
                    size={IconSize.NORMAL}
                  />
                </div>
                <div className="contact-container__item-text">
                  <Text
                    fontSize={FontSize.SIZE_16}
                    fontWeight={FontWeight.WEIGHT_600}
                    lineHeight={LineHeight.HEIGHT_16}
                  >
                    {isMobile ? (
                      <a href="tel:+48 605 41 50 50">+48 605 41 50 50</a>
                    ) : (
                      "+48 605 41 50 50"
                    )}
                  </Text>
                  <Text
                    fontSize={FontSize.SIZE_12}
                    color={"doveGrayUI"}
                    fontWeight={FontWeight.WEIGHT_600}
                    lineHeight={LineHeight.HEIGHT_16}
                  >
                    pon - pt 9:00 - 19:00
                  </Text>
                </div>
              </div>
              <div className="contact-container__item">
                <div className="contact-container__item-icon">
                  <Icon primaryColor={IconColor.SECONDARY_CONTRAST} iconComponent={Envelope} size={IconSize.NORMAL} />
                </div>
                <Text fontSize={FontSize.SIZE_16} fontWeight={FontWeight.WEIGHT_600}>
                    <a href="mailto:mauto@mleasing.pl">mauto@mleasing.pl</a>
                </Text>
              </div>
              <div className="contact-container__item contact-container__item-contact">
                <div className="contact-container__item-icon">
                  <Icon primaryColor={IconColor.SECONDARY_CONTRAST} iconComponent={Briefcase} size={IconSize.NORMAL} />
                </div>
                <Text fontSize={FontSize.SIZE_16} fontWeight={FontWeight.WEIGHT_600}>
                  <p onClick={()=>setActiveModal(true)}>zapytaj o ofertę</p>
                </Text>
              </div>
            </div>
          )}

          <div className="links-container">
            <FooterCategoriesLinks />
          </div>
          <HorizontalLine />
          <div className="bottom-container">
            <div className="bottom-container__left">
              <Mauto40StripeMass className="bottom-container__logo" />
              <Text fontSize={FontSize.SIZE_16} fontWeight={FontWeight.WEIGHT_500}>
                &copy; {moment().year()} mLeasing Sp. z o.o.
              </Text>
            </div>
            <div className="bottom-container__right">
              <div className="bottom-container__icons">
                <a
                  className="icon-container"
                  target="_blank"
                  href={"https://www.facebook.com/mLeasing-108841810505965"}
                >
                  <div className="icon facebook"></div>
                </a>
                <a className="icon-container" target="_blank" href={"https://www.instagram.com/mleasing.pl"}>
                  <div className="icon instagram"></div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
