import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import "./index.scss";
import { FontColor, FontSize, LineHeight } from "@mbank-design/design-system/design-tokens/general/typography";
import { Amount, Text, Tile, Tooltip, Tag, Button } from "@mbank-design/design-system/components";
import PromotedOfferSticker from "../../parts/PromotedOfferSticker";
import uuid from "react-uuid";
import { Lock, Pin, InfoRound, FuelPump, Engine, Gearbox, Leaf, Gauge, Car } from "@mbank-design/design-system/icons";
import { mBankNext } from "@mbank-design/design-system/themes";
import { AuctionWebSocketContext } from "../../../scripts/commons/contexts";
import { Link } from "react-router-dom";
import { determineChipColor } from "../../common/chip-color-determiner";
import ImgWithFallback from "../../parts/ImgWithFallback";
import { generateUrlImage, IMAGE_SIZE } from "../../common/generate-url-image";
import { Icon } from "@mbank-design/design-system/components";
import { prettyNumber, replaceSpecialCharsets } from "../../../scripts/commons/common";
import SpecialPromotions from "../../parts/SpecialPromotions";
import FavouriteButton from "../FavouriteButton";
import no_photo from "./img/no_photo.png";
import { DesignSystemThemeProvider } from "@mbank-design/design-system/context";
import { TileBackgroundColor, TileBorderRadius } from "@mbank-design/design-system/enums";
import ReservedLabel from "../../parts/ReservedLabel";

class CarOfferTile extends Component {
  state = {
    specialOfferObj: [],
    isFavouriteCar: false
  };

  determinePriceColor(side, promo, disabled) {
    let color = "";
    if (this.props.disabled) {
      color = FontColor.MONO_600;
    } else if (promo) {
      color = FontColor.POSITIVE_CONTRAST;
    } else if (side) {
      color = "doveGrayUI";
    } else {
      color = FontColor.DARK;
    }
    return color;
  }

  lowestInstallmentPrice = (data) => {
    const leaseInstallmentValue = data.LeasePrice;
    const leaseInstallmentValueDiscount = data.LeasePriceDiscount;
    const rentInstallmentValue = data.RentPriceNetto;
    const rentInstallmentValueDiscount = data.RentPriceNettoDiscount;
    if (data.IsLeasingAvailable && data.IsRentAvailable) {
      if (
        leaseInstallmentValueDiscount &&
        !rentInstallmentValueDiscount &&
        leaseInstallmentValueDiscount < rentInstallmentValue
      ) {
        return { installmentValue: leaseInstallmentValue, installmentValueDiscount: leaseInstallmentValueDiscount };
      } else if (
        rentInstallmentValueDiscount &&
        !leaseInstallmentValueDiscount &&
        rentInstallmentValueDiscount < leaseInstallmentValue
      ) {
        return { installmentValue: rentInstallmentValue, installmentValueDiscount: rentInstallmentValueDiscount };
      } else if (leaseInstallmentValueDiscount < rentInstallmentValueDiscount) {
        return { installmentValue: leaseInstallmentValue, installmentValueDiscount: leaseInstallmentValueDiscount };
      } else if (rentInstallmentValueDiscount < leaseInstallmentValueDiscount) {
        return { installmentValue: rentInstallmentValue, installmentValueDiscount: rentInstallmentValueDiscount };
      } else if (leaseInstallmentValue < rentInstallmentValue) {
        return { installmentValue: leaseInstallmentValue, installmentValueDiscount: leaseInstallmentValueDiscount };
      } else {
        return { installmentValue: rentInstallmentValue, installmentValueDiscount: rentInstallmentValueDiscount };
      }
    }
    else if (data.IsLeasingAvailable || !data.IsRentAvailable) {
      return { installmentValue: leaseInstallmentValue, installmentValueDiscount: leaseInstallmentValueDiscount };
    }
    else {
      return { installmentValue: rentInstallmentValue, installmentValueDiscount: rentInstallmentValueDiscount };
    }
  };
  // cant save param into url due to canonical link demand
  saveComparisonToSessionStorage = () => {
    const data = this.props.data
    const leaseInstallmentValue = data.LeasePrice;
    const leaseInstallmentValueDiscount = data.LeasePriceDiscount;
    const rentInstallmentValue = data.RentPriceNetto;
    const rentInstallmentValueDiscount = data.RentPriceNettoDiscount;
    if (data.IsLeasingAvailable && data.IsRentAvailable) {
      if (
        leaseInstallmentValueDiscount &&
        !rentInstallmentValueDiscount &&
        leaseInstallmentValueDiscount < rentInstallmentValue
      ) {
        return sessionStorage.setItem("lowestInstallment", "lease");;
      } else if (
        rentInstallmentValueDiscount &&
        !leaseInstallmentValueDiscount &&
        rentInstallmentValueDiscount < leaseInstallmentValue
      ) {
        return sessionStorage.setItem("lowestInstallment", "rent");
      } else if (leaseInstallmentValueDiscount < rentInstallmentValueDiscount) {
        return sessionStorage.setItem("lowestInstallment", "lease");
      } else if (rentInstallmentValueDiscount < leaseInstallmentValueDiscount) {
        return sessionStorage.setItem("lowestInstallment", "rent");
      } else if (leaseInstallmentValue < rentInstallmentValue) {
        return sessionStorage.setItem("lowestInstallment", "lease");
      } else {
        return sessionStorage.setItem("lowestInstallment", "rent");
      }
    }
    else if (data.IsLeasingAvailable || !data.IsRentAvailable) {
      return sessionStorage.setItem("lowestInstallment", "lease");
    }
    else {
      return sessionStorage.setItem("lowestInstallment", "rent");
    }
  };

  transfomFuelTypeName=(fuelTypeName)=>{
    let newfuelTypeName=fuelTypeName;
    if(newfuelTypeName==='EL'){
      newfuelTypeName='elektryczny'
    }else if(newfuelTypeName==='H'){
      newfuelTypeName='hybryda'

    }
    return newfuelTypeName;
  }

  fullPriceValue = (data) => {
    let discountFullPrice;
    if (this.props.isCompany) {
      if (data.TotalPriceNettoDiscount) {
        discountFullPrice = data.TotalPriceNettoDiscount;
      } else {
        discountFullPrice = data.TotalPriceNetto;
      }
    } else {
      if (data.TotalPriceBruttoDiscount) {
        discountFullPrice = data.TotalPriceBruttoDiscount;
      } else {
        discountFullPrice = data.TotalPriceBrutto;
      }
    }

    return discountFullPrice;
  };

  render() {
    const data = this.props.data;
    const fullPriceDiscounted=(this.props.isCompany&&data.TotalPriceNettoDiscount)||(!this.props.isCompany&&data.TotalPriceBruttoDiscount)
    let marketingLabels = data.MarketingLabels ? JSON.parse(data.MarketingLabels) : [];

    const hrefString = data.MakeModel
      ? data.MakeModel.toLowerCase().replace(/\s/g, "-") + "-id-" + data.AuctionId
      : "/";

    const tileInstallment = data && this.lowestInstallmentPrice(data);
    return (
      <AuctionWebSocketContext.Consumer>
        {({ auctionWebSockets, webSocketInit }) => {
          const imgUrl = generateUrlImage(data.MainPhotoUrl, IMAGE_SIZE.MEDIUM);
          return (
            <DesignSystemThemeProvider theme={mBankNext}>
              <Tile
                href={
                  this.props.data.Avaiable === false
                    ? undefined
                    : data.Status === "Nowy"
                    ? "/nowe-samochody/" + hrefString
                    : "/samochody-poleasingowe/" + hrefString
                }
                component="a"
                borderRadius={[TileBorderRadius.LARGE, TileBorderRadius.EXTRA_LARGE]}
                backgroundColor={
                  this.props.backgroundTheme ? TileBackgroundColor.MONO_200 : TileBackgroundColor.MONO_100
                }
                onClick={() => {
                  this.saveComparisonToSessionStorage();
                }}
              >
                <div
                  id={"carId" + (!this.props.isPromoted ? data.AuctionId : "Promoted" + data.AuctionId)}
                  className={`car-offer-tile ${this.props.useClass || ""} ${
                    this.props.data.Avaiable === false ? "car-offer-tile--sold" : ""
                  }`}
                >
                  <div className={`car-image-container ${data.MovePhoto ? "move-photo" : ""}`}>
                    {this.props.data.Avaiable === false ? (
                      <div className="sold-car-buttons">
                        <div className="sold-car-buttons__top">
                          <Text color={FontColor.LIGHT}>ofetra wygasła</Text>
                        </div>
                        <div className="sold-car-buttons__bottom">
                          <FavouriteButton
                            carData={this.props.data}
                            callbackAfterUnlike={this.props.callbackAfterUnlike}
                            moduleType="SOLD_TILE"
                          />
                          <Link to={"/lista-pojazdow"}>
                            <Button buttonType="primary">pokaż podobne</Button>
                          </Link>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {this.props.data.Avaiable === false ? (
                      <></>
                    ) : (
                      <div className="icon">
                        <FavouriteButton
                          carData={this.props.data}
                          callbackAfterUnlike={this.props.callbackAfterUnlike}
                          moduleType="TILE"
                        />
                      </div>
                    )}
                    <div className={`car-image ${data.MovePhoto ? "move-photo" : ""}`}>
                      {this.props.data.Avaiable === false ? (
                        <picture>
                          <img src={no_photo} />
                        </picture>
                      ) : (
                        <ImgWithFallback
                          src={imgUrl}
                          fallback={imgUrl}
                          generateExtensions={true}
                          isWebP={data.IsWebP}
                          alt={data.MakeModel}
                        />
                      )}
                    </div>
                    <div className="upper-container">
                      <div className="left-wing">
                        {!data.Reservation && data.SpecialPromotions && (
                          <SpecialPromotions promotions={data.SpecialPromotions} iconName={"Gift"} />
                        )}
                        {this.props.isPromoted && (!data.SpecialPromotions || data.SpecialPromotions.length === 0) && (
                          <PromotedOfferSticker text={"promowana oferta"} iconType={Pin} />
                        )}
                        {data.Reservation && (
                          <ReservedLabel text={"zarezerwowany"} iconType={Lock} color={"zeusBlackUI"} />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="car-informations-container">
                    <div className={`left-wing decreased-width`}>
                      <div>
                        <div className="left-wing-labels">
                          {data.Status === "Nowy" || data.Status === "Poleasingowy" ? (
                            <Tag
                              textColor={data.Status === "Nowy" ? "zeusBlackUI" : FontColor.LIGHT}
                              key={uuid()}
                              backgroundColor={determineChipColor(data.Status === "Nowy" ? "yellow" : "grey")}
                            >
                              {data.Status === "Nowy" ? "nowy" : "poleasingowy"}
                            </Tag>
                          ) : (
                            <></>
                          )}
                          {marketingLabels &&
                            marketingLabels.map((label) => {
                              return (
                                <Tag key={uuid()} isBorder>
                                  {label.Text}
                                </Tag>
                              );
                            })}
                          {data.IsCertified && (
                            <Tag key={uuid()} isBorder>
                              certyfikowany
                            </Tag>
                          )}
                        </div>
                        <Text as="p" styledAs={["h3", "h2"]} className={"tile-header"} paddingVertical={8}>
                          {data.MakeModel}
                        </Text>
                        <Text as="p" className={"tile-header"} styledAs={["small", "body"]}>
                          {replaceSpecialCharsets(data.Type)}
                        </Text>
                      </div>
                      <div>
                        <div className="data-icons__container">
                          <div className="data-icons__item">
                            <Icon iconComponent={FuelPump} />
                            <Text as={"p"} styledAs={["small", "body"]} color={"doveGrayUI"}>
                              {this.transfomFuelTypeName(data.FuelType)}
                            </Text>
                          </div>
                          <div className="data-icons__item">
                            <Icon iconComponent={Gearbox} />
                            <Text as={"p"} styledAs={["small", "body"]} color={"doveGrayUI"}>
                              {data.GearBoxType}
                            </Text>
                          </div>
                          {data.Status === "Nowy" ? (
                            <div className="data-icons__item">
                              <Icon iconComponent={Engine} />
                              <Text as={"p"} styledAs={["small", "body"]} color={"doveGrayUI"}>
                                {data.PowerHP} KM
                              </Text>
                            </div>
                          ) : (
                            <div className="data-icons__item">
                              <Icon iconComponent={Car} />
                              <Text as={"p"} styledAs={["small", "body"]} color={"doveGrayUI"}>
                                {data.Year} r.
                              </Text>
                            </div>
                          )}
                          {data.Status === "Nowy" && data.Co2Emission !== "0" && (
                            <div className="data-icons__item">
                              <Icon iconComponent={Leaf} />
                              <Text as={"p"} styledAs={["small", "body"]} color={"doveGrayUI"}>
                                {data.Co2Emission} CO<sub>2</sub>/km
                              </Text>
                            </div>
                          )}
                          {data.Status !== "Nowy" && (
                            <div className="data-icons__item">
                              <Icon iconComponent={Gauge} />
                              <Text as={"p"} styledAs={["small", "body"]} color={"doveGrayUI"}>
                                <Amount type="decimal" value={data.Mileage} isFractionPartShown={false}></Amount> km
                              </Text>
                            </div>
                          )}
                        </div>
                        <div className="price-with-tooltip">
                          {data && (
                            <>
                              {data.IsLeasingAvailable ? (
                                <div className={"price-container" + (!this.props.isCompany ? " with-toottip" : "")}>
                                  <Text as="p" styledAs={["small", "body"]}>
                                    rata od
                                  </Text>
                                  <div className="price">
                                    <Text
                                      as="p"
                                      styledAs={ !!tileInstallment.installmentValueDiscount ? "h2" : "h1"}
                                      color={this.determinePriceColor(false, tileInstallment.installmentValueDiscount)}
                                    >
                                      <Amount
                                        currency="PLN"
                                        currencyDisplay="symbol"
                                        isFractionPartShown={false}
                                        value={
                                          tileInstallment.installmentValueDiscount || tileInstallment.installmentValue
                                        }
                                      />
                                    </Text>
                                    <div className="old-price">
                                    <div className="tooltip-wrapper">
                                        {!!tileInstallment.installmentValueDiscount && (
                                          <div
                                            onClick={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                            }}
                                          >
                                            <Tooltip
                                              icon={<Icon iconComponent={InfoRound} size={16} />}
                                              isInfotip={window.innerWidth < 1024}
                                              hint={
                                                <>
                                                  najniższa rata z ostatnich 30 dni przed obniżką:&nbsp;
                                                  <span style={{ whiteSpace: "nowrap" }}>
                                                    {prettyNumber(tileInstallment.installmentValue)} zł
                                                  </span>
                                                </>
                                              }
                                            />
                                          </div>
                                        )}
                                      </div>
                                      {tileInstallment.installmentValueDiscount !== 0 &&
                                        tileInstallment.installmentValue !== tileInstallment.installmentValueDiscount &&
                                        tileInstallment.installmentValueDiscount !== undefined &&
                                        tileInstallment.installmentValueDiscount !== null && (
                                          <Text
                                            as="p"
                                            styledAs={"small"}
                                            lineHeight={LineHeight.HEIGHT_20}
                                            color={FontColor.MONO_600}
                                          >
                                            <Amount
                                              currency="PLN"
                                              currencyDisplay="symbol"
                                              isFractionPartShown={false}
                                              isFractionPartResized={false}
                                              isLineThrough={true}
                                              styledAsTextTagName="p"
                                              value={tileInstallment.installmentValue}
                                            />
                                          </Text>
                                        )}

                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className={"price-container" + (!this.props.isCompany ? " with-toottip" : "")}>
                                  <Text as="p" styledAs={["small", "body"]}>
                                    rata od
                                  </Text>
                                  <div className="price">
                                    <Text
                                      as="p"
                                      styledAs={ !!tileInstallment.installmentValueDiscount ? "h2" : "h1"}
                                      color={this.determinePriceColor(false, tileInstallment.installmentValueDiscount)}
                                    >
                                      <Amount
                                        currency="PLN"
                                        currencyDisplay="symbol"
                                        isFractionPartShown={false}
                                        value={
                                          tileInstallment.installmentValueDiscount || tileInstallment.installmentValue
                                        }
                                      />
                                    </Text>
                                    <div className="old-price">
                                    <div className="tooltip-wrapper">
                                        {!!tileInstallment.installmentValueDiscount && (
                                          <div
                                            onClick={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                            }}
                                          >
                                            <Tooltip
                                              icon={<Icon iconComponent={InfoRound} size={16} />}
                                              isInfotip={window.innerWidth < 1024}
                                              hint={
                                                <>
                                                  najniższa rata z ostatnich 30 dni przed obniżką:&nbsp;
                                                  <span style={{ whiteSpace: "nowrap" }}>
                                                    {prettyNumber(tileInstallment.installmentValue)} zł
                                                  </span>
                                                </>
                                              }
                                            />
                                          </div>
                                        )}
                                      </div>
                                      {tileInstallment.installmentValueDiscount !== 0 &&
                                        tileInstallment.installmentValueDiscount !== undefined &&
                                        tileInstallment.installmentValueDiscount !== null && (
                                          <Text
                                            as="p"
                                            styledAs={"small"}
                                            lineHeight={LineHeight.HEIGHT_20}
                                            color={FontColor.MONO_600}
                                          >
                                            <Amount
                                              currency="PLN"
                                              currencyDisplay="symbol"
                                              isFractionPartShown={false}
                                              isFractionPartResized={false}
                                              isLineThrough={true}
                                              styledAsTextTagName="p"
                                              value={tileInstallment.installmentValue}
                                            />
                                          </Text>
                                        )}

                                    </div>
                                  </div>
                                </div>
                              )}

                              {data.IsCashAvailable&&
                          // {true&&
                                <div className={`full-price-container ${fullPriceDiscounted?'full-price-container--discounted':''}  price-container` + (!this.props.isCompany ? " with-toottip" : "")}>
                                  <Text as="p" styledAs={["small", "body"]}>
                                    cena auta
                                  </Text>
                                  <div className="price">
                                    <Text
                                      as="p"
                                      styledAs={fullPriceDiscounted?"h2":"h1"}
                                      color={this.determinePriceColor(false, fullPriceDiscounted)}
                                    >
                                      <Amount
                                        currency="PLN"
                                        currencyDisplay="symbol"
                                        isFractionPartShown={false}
                                        value={this.fullPriceValue(data)}
                                      />
                                    </Text>
                                    {fullPriceDiscounted &&
                                    <div className="old-price">
                                      <div className="tooltip-wrapper">
                                          <div
                                            onClick={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                            }}
                                          >
                                            <Tooltip
                                              icon={<Icon iconComponent={InfoRound} size={16} />}
                                              isInfotip={window.innerWidth < 1024}
                                              
                                              hint={
                                                <>
                                                  najniższa cena z ostatnich 30 dni przed obniżką:&nbsp;
                                                  <span style={{ whiteSpace: "nowrap" }}>
                                                    {prettyNumber(this.props.isCompany?data.TotalPriceNetto:data.TotalPriceBrutto)} zł
                                                  </span>
                                                </>
                                              }
                                            />
                                          </div>
                                      </div>
                                          <Text
                                            as="p"
                                            styledAs={"small"}
                                            lineHeight={LineHeight.HEIGHT_20}
                                            color={FontColor.MONO_600}
                                          >
                                            <Amount
                                              currency="PLN"
                                              currencyDisplay="symbol"
                                              isFractionPartShown={false}
                                              isFractionPartResized={false}
                                              isLineThrough={true}
                                              styledAsTextTagName="p"
                                              value={this.props.isCompany?data.TotalPriceNetto:data.TotalPriceBrutto}
                                            />
                                          </Text>
                                    </div>}
                                  </div>
                              </div>}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tile>
            </DesignSystemThemeProvider>
          );
        }}
      </AuctionWebSocketContext.Consumer>
    );
  }
}

export default withRouter(CarOfferTile);
