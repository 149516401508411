import React, { useEffect, useState } from "react";
import "./index.scss";
import { Text, Media } from "@mbank-design/design-system/components";
import { isWebpSupported as webpSupported } from "../../../scripts/commons/support-webp";

import backgroundImageWeb from "./img/competition_web.png";
import backgroundImageMobile from "./img/competition_mobile.png";
import backgroundImageWebWebP from "./img/competition_web.webp";
import backgroundImageMobileWebP from "./img/competition_mobile.webp";
import { FontColor, FontSize, FontWeight, LineHeight } from "@mbank-design/design-system/design-tokens/general/typography";
import { clearCampaignData, setCampaignData } from "../../common/campaign-data-setter";

const LandingPageKonkurs = ({ literals }) => {
  const isWebpSupported = webpSupported();
  const bottomText = 'Informujemy, iż mLeasing Sp. z o.o. z siedzibą w Warszawie przy ul. Prostej 18 z chwilą wysłania powyższego formularza będzie administratorem Pani(a) danych osobowych. Przekazane za pośrednictwem formularza dane osobowe będą przetwarzane przez mLeasing Sp. z o.o. w celach umożliwienia korzystania Pani(u) z Serwisu mAuto na warunkach zgodnych z Regulaminem, w tym w celach przedstawienia zamówionej informacji handlowej na wskazany adres e-mail lub numer telefonu, w celach marketingu własnych produktów i usług mLeasing sp. z o.o., a także w celach statystycznych i analitycznych. Na warunkach przewidzianych w RODO, osobie, której dane dotyczą przysługuje prawo żądania od mLeasing dostępu do jej Danych osobowych, ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo wniesienia sprzeciwu wobec dalszego przetwarzania danych z przyczyn związanych ze szczególną sytuacją osoby której dane dotyczą, lub gdy są one przetwarzane na potrzeby marketingu, w tym profilowania, w zakresie, w jakim przetwarzanie jest związane z takim marketingiem bezpośrednim. Przysługuje również prawo do cofnięcia wyrażonej przez siebie zgody na przetwarzanie danych, w każdym momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem. Osobie której dane dotyczą przysługuje w związku z przetwarzaniem przez mLeasing Danych osobowych prawo wniesienia skargi do organu nadzorczego, którym jest Prezes Urzędu Ochrony Danych Osobowych. Podanie danych jest dobrowolne jednakże niezbędne do realizacji w/w celów. Więcej informacji o zasadach przetwarzania danych osobowych otrzymasz kontaktując się z nami pod adresem daneosobowe@mleasing.pl'
  useEffect(()=>{
    insertScript();
    clearCampaignData();
    setCampaignData()
  },[])

  const insertScript = () => {
    const script = document.createElement("script");
    script.innerHTML = `  (function(m, o, n, t, e, r, _){
      m['__GetResponseAnalyticsObject'] = e;m[e] = m[e] || function() {(m[e].q = m[e].q || []).push(arguments)};
      r = o.createElement(n);_ = o.getElementsByTagName(n)[0];r.async = 1;r.src = t;r.setAttribute('crossorigin', 'use-credentials');_.parentNode .insertBefore(r, _);
  })(window, document, 'script', 'https://an.gr-wcon.com/script/a5fef2c4-24d3-4c4e-afcb-5c34cfe8d69c/ga.js', 'GrTracking');`;
    document.head.insertBefore(script, document.head.lastElementChild);
    return () => {
      document.head.removeChild(script);
    };
  };


  return (
    <div className="landingPageKonkurs">
      <div className="landingPageKonkurs__top">
        <div className="landingPageKonkurs__left">
          <Media.DesktopOnly>
            <img
              src={isWebpSupported ? backgroundImageWebWebP : backgroundImageWeb}
              alt="tesla car"
              className="landingPageKonkurs__background-image"
            />
          </Media.DesktopOnly>
          <Media.MobileOnly>
            <img
              src={isWebpSupported ? backgroundImageMobileWebP : backgroundImageMobile}
              alt="tesla car"
              className="landingPageKonkurs__background-image"
            />
          </Media.MobileOnly>
          <Media.DesktopOnly>
            <div className="landingPageKonkurs__bottom-text">
              <Text
                fontSize={FontSize.SIZE_12}
                as="p"
                fontWeight={FontWeight.WEIGHT_600}
                lineHeight={LineHeight.HEIGHT_16}
                color={FontColor.doveGrayUI}
                marginBottom={16}
              >
                {bottomText}
              </Text>
            </div>
          </Media.DesktopOnly>
        </div>

        <div className="landingPageKonkurs__contact-form-section">
          <getresponse-form form-id="c246b06b-9234-4e4a-af0f-f8881477713f" e="0"></getresponse-form>
        </div>
        <Media.MobileOnly>
          <div className="landingPageKonkurs__bottom-text">
            <Text
              fontSize={FontSize.SIZE_12}
              as="p"
              fontWeight={FontWeight.WEIGHT_600}
              lineHeight={LineHeight.HEIGHT_16}
              color={FontColor.doveGrayUI}
              marginBottom={16}
            >
              {bottomText}
            </Text>
          </div>
        </Media.MobileOnly>
      </div>
    </div>
  );
};

export default LandingPageKonkurs;
