import "./index.scss";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Heart } from "@mbank-design/design-system/icons";
import { Button } from "@mbank-design/design-system/components";
import { OBSERVED_RESULTS_KEY } from "../../../constants";
import { ToastContext } from "../../../scripts/commons/contexts";
import { getUrlBasedOnFilters } from "../../../scripts/commons/common";

function ObserveVehicleButton(props) {
  const [active, setActive] = useState(false);
  const [isMobile] = useState(window.innerWidth < 1024);

  useEffect(() => {
    updateObserveVehicleResults();
  }, [props.filtersValues]);

  function updateObserveVehicleResults() {
    const observeVehicleResults = JSON.parse(localStorage.getItem(OBSERVED_RESULTS_KEY)) || [];
    const { pathname } = getUrlBasedOnFilters(props.filtersValues, props.isCompany, props.financialMethod);

    if (observeVehicleResults.find((v) => v.url === pathname)) {
      setActive(true);
    } else {
      setActive(false);
    }
  }

  async function handleToast(toastState, color, message) {
    await toastState.changeToastState("message", message);
    await toastState.changeToastState("color", color);
    await toastState.toggleToast();
    setTimeout(() => {
      toastState.toggleToast();
    }, 2000);
  }

  async function handleObserveVehicleResults(toastState) {
    const observeVehicleResults = JSON.parse(localStorage.getItem(OBSERVED_RESULTS_KEY)) || [];
    const { search, pathname } = getUrlBasedOnFilters(props.filtersValues, props.isCompany, props.financialMethod);
    const observeVehicleResult = {
      url: pathname,
      search: search,
      filtersValues: props.filtersValues,
      count: props.countFilters,
      isCompany: !(localStorage.getItem("isCompany") === "false")
    };

    const observeVehicleResultIndex = observeVehicleResults.findIndex((v) => v.url === observeVehicleResult.url);

    if (observeVehicleResultIndex === -1) {
      handleToast(toastState, "green", "dodano do ulubionych wyszukiwań");
      localStorage.setItem(OBSERVED_RESULTS_KEY, JSON.stringify([...observeVehicleResults, observeVehicleResult]));
      setActive(true);
    } else {
      handleToast(toastState, "red", "usunięto z obserwowanych");
      localStorage.setItem(
        OBSERVED_RESULTS_KEY,
        JSON.stringify(observeVehicleResults.filter((_, idx) => idx !== observeVehicleResultIndex))
      );
      setActive(false);
    }
  }

  return (
    <ToastContext.Consumer>
      {(toastState) => {
        return (
          <Button
            className={`observe-vehicle-button ${active ? "active" : ""} ${isMobile ? "mobile-icon" : ""}`}
            iconComponent={Heart}
            buttonType="tertiary"
            iconColor={`${isMobile ? "primary_contrast" : "secondary_contrast"}`}
            color="dark"
            onClick={() => {
              handleObserveVehicleResults(toastState);
            }}
          >
            obserwuj wyniki
          </Button>
        );
      }}
    </ToastContext.Consumer>
  );
}

ObserveVehicleButton.propTypes = {
  filtersValues: PropTypes.object.isRequired,
  countFilters: PropTypes.number,
  isCompany: PropTypes.bool,
  financialMethod: PropTypes.string
};

export default ObserveVehicleButton;
