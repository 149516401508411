import React from 'react'
import './index.scss'
import carSvg from './images/car.svg';
import screenSvg from './images/screen.svg';
import wrenchSvg from './images/wrench.svg';
import penSvg from './images/pen.svg';
import carPng from './images/car.png';
import logoSvg from './images/logo.svg';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import rulesCheaper from "../../../assets/files/Taniej z mAuto - edycja 3.pdf";

const LandingPageTaniejZmAuto = () => {

  return (
    <div className='lp-taniej-z-mauto'>
        <Helmet>
            <title>Taniej z mAuto</title>
            <meta name="description" content="" />
        </Helmet>
            
        <header className="header">
            <div className="container">
                <div className="border header__border"></div>
                <h1 className="letter-spacing header__title">rabat <span className="text-orange">2000&nbsp;zł</span> na&nbsp;auto dla klientów mBanku</h1>
                <p className="letter-spacing header__text">teraz z&nbsp;rachunkiem firmowym otrzymasz tańszy leasing</p>
                <a className="btn btn--lg" href="/samochody-poleasingowe" title="kliknij, aby kupić auto">wybierz auto</a>
            </div>
        </header>
        
        <main>
            <section className="section benefits">
                <div className="container">
                    <h2 className="text-center letter-spacing title benefits__title">Na tym korzyści dla Ciebie i&nbsp;Twojej firmy się nie kończą!</h2>
                    <p className="text-center letter-spacing benefits__subtitle">Zobacz, co&nbsp;jeszcze zyskujesz:</p>
                    <ul className="benefits__items">
                        <li className="benefits__item">
                            <div className="box box--1">
                                <h3 className="text-center letter-spacing box__title">Duży wybór aut uznanych marek</h3>
                                <img className="box__img" src={carSvg} alt=""/>
                                <p className="text-center letter-spacing box__text">sprawdzonych pod kątem technicznym, objętych certyfikatem oraz wyłącznie z&nbsp;polskich salonów</p>
                            </div>
                        </li>
                        <li className="benefits__item">
                            <div className="box box--2">
                                <h3 className="text-center letter-spacing box__title">Szybkość i&nbsp;komfort 24h/7</h3>
                                <img className="box__img" src={screenSvg} alt=""/>
                                <p className="text-center letter-spacing box__text">Ty wybierasz samochód w&nbsp;naszym internetowym salonie <a href="/" target="_blank" title="wejdź na mauto.pl">mauto.pl</a>, a&nbsp;my dostarczamy Ci go wprost pod Twoje drzwi</p>
                            </div>
                        </li>
                        <li className="benefits__item">
                            <div className="box box--3">
                                <h3 className="text-center letter-spacing box__title">Serwis za&nbsp;1&nbsp;zł oraz kartę paliwową</h3>
                                <img className="box__img" src={wrenchSvg} alt=""/>
                                <p className="text-center letter-spacing box__text">gwarantujemy także niezmienną składkę ubezpieczenia przez cały okres trwania umowy</p>
                            </div>
                        </li>
                    </ul>
                    <div className="text-center benefits__btn">
                        <a className="btn" href="/samochody-poleasingowe" title="kliknij, aby kupić auto">wybierz auto</a>
                    </div>
                </div>
            </section>
        
            <section className="section offer">
                <div className="container">
                    <h2 className="text-center letter-spacing title offer__title">A zatem nie zwlekaj i&nbsp;skorzystaj już dziś!</h2>
                    <div className="offer__content">
                        <img className="offer__img" src={penSvg} alt=""/>
                        <p className="letter-spacing offer__text">wystarczy, że&nbsp;podpiszesz umowę finansowania oraz oświadczenie o&nbsp;przystąpieniu do&nbsp;promocji</p>
                    </div>
                    <div className="text-center">
                        <a className="btn" href="/formularz-kontaktowy" title="kliknij, aby zapytać o ofertę">zapytaj o ofertę</a>
                    </div>
                </div>
            </section>
        
            <section className="section car">
                <div className="container">
                    <div className="border car__border"></div>
                    <h2 className="text-center letter-spacing title car__title">Wejdź już teraz na&nbsp;<a href="/" target="_blank" title="wejdź na mAuto.pl">mAuto.pl</a>,</h2>
                    <p className="text-center letter-spacing car__text">wybierz idealny samochód poleasingowy dla siebie i&nbsp;zyskaj <span className="text-red font-weight-semibold">2000&nbsp;zł rabatu</span></p>
                    <img className="car__img" src={carPng} alt="auto"/>
                    <div className="text-center">
                        <a className="btn" href="/samochody-poleasingowe" title="kliknij, aby kupić auto">wybierz auto</a>
                    </div>
                </div>
            </section>
        
            <div className="overlay">
                <div className="container">
                    <div className="overlay__content">
                        <a href="/" target="_blank" title="wejdź na mAuto.pl">
                            <img className="overlay__logo" src={logoSvg} alt="mAuto"/>
                        </a>
                    </div>
                </div>
            </div>
        </main>
        
        <footer class="footer">
	<div class="container footer__container">
		<ul class="footer__list">
			<li class="footer__item"><a class="letter-spacing footer__link footer__link--lp" href={rulesCheaper} target="_blank">Regulamin promocji</a></li>
		</ul>
		<p class="letter-spacing footer__text">To jest informacja handlowa, która nie stanowi oferty w&nbsp;rozumieniu kodeksu cywilnego. Ma charakter jedynie poglądowy i&nbsp;pomocniczy w&nbsp;przygotowaniu właściwej umowy i&nbsp;ustaleniu jej warunków. Umowa dotycząca używania pojazdu wymaga formy pisemnej pod rygorem nieważności i&nbsp;może być zawarta nie wcześniej, niż po podjęciu przez mLeasing Sp. z&nbsp;o.o. pozytywnej decyzji o&nbsp;finansowaniu. mAuto jest serwisem internetowym należącym do mLeasing Sp. z&nbsp;o.o., stroną umowy będzie mLeasing Sp. z&nbsp;o.o.</p>
		<p class="letter-spacing footer__text">Dane techniczne pojazdów i&nbsp;ich oznaczenia pochodzą z&nbsp;systemu Eurotax i&nbsp;mogą się różnić od danych rzeczywistych. Prezentacja pojazdów na stronie nie gwarantuje ich dostępności. Proces weryfikacji dostępności odbywa się po wysłaniu formularza kontaktowego.</p>
	</div>
</footer>
        <script src="./scripts/vendor.bundle.js"></script>
        <script src="./scripts/main.bundle.js"></script>
    </div>
  )
}

export default LandingPageTaniejZmAuto