import React from "react";
import "./index.scss";
import { Button, Illustration, Text } from "@mbank-design/design-system/components";
import { IllustrationSize } from "@mbank-design/design-system/enums";
import * as Illustrations from "@mbank-design/design-system/illustrations";
import { setVehicleOfferButtonInsightTag } from "../../common/campaign-data-setter";
const PromoBanner = ({ isAfterLease, literals, urlToContactSection, handleContactModal }) => {
  if (
    !literals.promo_label_new_car_title ||
    !literals.promo_label_new_car_subtitle ||
    !literals.promo_label_new_car_illustration ||
    !literals.promo_label_afterlease_car_title ||
    !literals.promo_label_afterlease_car_subtitle ||
    !literals.promo_label_afterlease_car_illustration
  ) {
    return;
  }
  const handleButtonClick = () => {
    setLocalStorageContactPreference();
    handleContactModal(urlToContactSection);
  };

  const setLocalStorageContactPreference = () => {
    localStorage.setItem("onlyOffer", true);
    // LinkedIn Insight Tag
    setVehicleOfferButtonInsightTag();
  };

  const convertColorLiteral = (literal) => {
    literal = literal.replaceAll("[SPAN]", "<span>");
    literal = literal.replaceAll("[/SPAN]", "</span>");

    literal = literal.replaceAll("[SPAN", "<span");
    literal = literal.replaceAll('"]', '">');
    return literal;
  };
  return (
    <div className="promo-banner">
      <div className="promo-banner__left">
        <div className="promo-banner__title">
          {isAfterLease ? (
            <Text
              as={"h1"}
              marginBottom={16}
              dangerouslySetInnerHTML={{ __html: convertColorLiteral(literals.promo_label_afterlease_car_title) }}
            ></Text>
          ) : (
            <Text
              as={"h1"}
              marginBottom={16}
              dangerouslySetInnerHTML={{ __html: convertColorLiteral(literals.promo_label_new_car_title) }}
            ></Text>
          )}
        </div>
        <div className="promo-banner__subtitle">
          {isAfterLease ? (
            <Text as={"p"} marginBottom={24}>
              {literals.promo_label_afterlease_car_subtitle}
            </Text>
          ) : (
            <Text as={"p"} marginBottom={24}>
              {literals.promo_label_new_car_subtitle}
            </Text>
          )}
        </div>
        <div className="promo-banner__button">
          <Button onClick={handleButtonClick}>zapytaj o ofertę</Button>
        </div>
      </div>
      <div className="promo-banner__right">
        <Illustration
          size={IllustrationSize.MEDIUM}
          illustrationComponent={
            isAfterLease
              ? Illustrations[literals.promo_label_afterlease_car_illustration]
              : Illustrations[literals.promo_label_new_car_illustration]
          }
        />
      </div>
    </div>
  );
};

export default PromoBanner;
