import React from "react";

import { HorizontalLine, Icon, IconButton, Text } from "@mbank-design/design-system/components";
import { Briefcase, HumanHeadset, PhoneHandle, SpeechBubble } from "@mbank-design/design-system/icons";

import "./index.scss";
import { IconColor, IconSize } from "@mbank-design/design-system/enums";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export const ContactCard = (props) => {
  const chatToggleHandler = () => {
    const chatButton = document.getElementById("lc-button");
    if (chatButton) {
      chatButton.click();
    }
  };

  return (
    <div className="contact-card">
      <div className="contact-card__header">
        <Icon
          iconComponent={HumanHeadset}
          size={IconSize.EXTRA_LARGE}
          primaryColor={IconColor.SECONDARY_CONTRAST}
        ></Icon>
        <Text as={"h3"} styledAs={"h3"}>
          {!props.isMobile ? "nie wiesz co wybrać?" : "masz pytania?"}
        </Text>
        <Text styledAs={"body"}>skontaktuj się z nami</Text>
      </div>
      <div className="contact-card__divider"><HorizontalLine/></div>
      <div className="contact-card__buttons">
        <div className="contact-card-btn">
          {props.isMobile ? (
            <a href={"tel:+48 605 41 50 50"}>
              <IconButton
                iconComponent={PhoneHandle}
                buttonType="secondary"
                isInline
                primaryColor={IconColor.SECONDARY_CONTRAST}
                style={{ cursor: "default" }}
              >
                <IconButton.Label>+48 605 41 50 50</IconButton.Label>
              </IconButton>
            </a>
          ) : (
            <>
              <IconButton
                iconComponent={PhoneHandle}
                buttonType="secondary"
                isInline
                primaryColor={IconColor.SECONDARY_CONTRAST}
                style={{ cursor: "default" }}
              >
                <IconButton.Label>+48 605 41 50 50</IconButton.Label>
              </IconButton>
            </>
          )}
        </div>
        <div className="contact-card-btn" role="button" tabIndex={0}>
          <IconButton
            iconComponent={SpeechBubble}
            buttonType="secondary"
            isInline
            primaryColor={IconColor.SECONDARY_CONTRAST}
            onClick={() => {
              chatToggleHandler();
              if (!props.isMobile) {
                props.toggleSearchMenuState();
              }
            }}
          >
            <IconButton.Label>chat z doradcą</IconButton.Label>
          </IconButton>
        </div>
        {!props.isMobile && (
          <div className="contact-card-btn" onClick={() => props.toggleSearchMenuState()} role="button" tabIndex={0}>
            <Link to={"/self-financing-form"}>
              <IconButton
                iconComponent={Briefcase}
                buttonType="secondary"
                isInline
                primaryColor={IconColor.SECONDARY_CONTRAST}
              >
                <IconButton.Label>zapytaj o ofertę</IconButton.Label>
              </IconButton>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
