import React from "react";
import "./index.scss";
import { Box, Carousel, Media, Text } from "@mbank-design/design-system/components";
import AdditionalPackageInfoTile from "../../parts/AdditionalPackageInfoTile";

const AdditionalPackagesSection = (props) => {
  const additionalPackages = () => {
    // new&&rent car have different than rest additional services
    if (!props.calcData) return [];
    let additionalServiceItems = props.calcData.AdditionalServiceItems;
    if (props.offerType === "new" && props.calculatorTabSelected === 1) {
      additionalServiceItems = props.calcData.AdditionalServiceItemsForNewCarRent;
    }
    return additionalServiceItems;
  };

  const additionalPackagesArray = additionalPackages();

  if (!(additionalPackagesArray && Array.isArray(additionalPackagesArray) && !!additionalPackagesArray.length)) {
    return <></>;
  }

  return (
    <div id="additional-packages-section" className="additional-packages-section">
      <Text as="p" styledAs="h2" paddingBottom={8}>
        {props.isSeoModel ? props.modelNameForHeaders : ""}{props.literals && props.literals.prod_services_hdr}
      </Text>
      <div className="additional-packages-tiles-section">
        <Media.MobileOnly>
          <Box maxWidth={["100%", 1300]} margin="0 auto">
            <Carousel isNavigationShown visibleSlides={window.innerWidth < 1023 ? 1 : 2}>
              {additionalPackagesArray.map((item, idx) => (
                <AdditionalPackageInfoTile
                  key={idx}
                  icon={item.IconName}
                  title={item.Name}
                  description={item.Tooltip}
                />
              ))}
            </Carousel>
          </Box>
        </Media.MobileOnly>
        <Media.DesktopOnly>
          {additionalPackagesArray.map((item, idx) => (
            <AdditionalPackageInfoTile
              key={idx}
              icon={item.IconName}
              title={item.Name}
              description={item?.EkstraTooltip ? item.Tooltip + item.EkstraTooltip : item.Tooltip}
            />
          ))}
        </Media.DesktopOnly>
      </div>
    </div>
  );
};

export default AdditionalPackagesSection;
