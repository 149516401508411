import React from "react";
import { Icon, Text } from "@mbank-design/design-system/components";
import { ChevronRight, Home } from "@mbank-design/design-system/icons";
import { Link, withRouter } from "react-router-dom/cjs/react-router-dom.min";

import "./index.scss";

const Breadcrumbs = (props) => {
  const { model, type } = props;
  return (
    <div className="breadcrumbs">
      <div className="breadcrumbs__container content-wrapper">
        <Link to={"/"}>
          <Icon iconComponent={Home} primaryColor="doveGrayUI" size={16} />
        </Link>
        <Icon iconComponent={ChevronRight} primaryColor="altoGray" size={16} />
        <Link to={"/lista-pojazdow"}>
          <Text as={"p"} styledAs={"small"} color={"doveGrayUI"}>
            samochody
          </Text>
        </Link>
        <Icon iconComponent={ChevronRight} primaryColor="altoGray" size={16} />
        {type === "new" ? (
          <Link to={"/nowe-samochody"}>
            <Text as={"p"} styledAs={"small"} color={"doveGrayUI"}>
              nowe
            </Text>
          </Link>
        ) : (
          <Link to={"/samochody-poleasingowe"}>
            <Text as={"p"} styledAs={"small"} color={"doveGrayUI"}>
              używane
            </Text>
          </Link>
        )}
        <Icon iconComponent={ChevronRight} primaryColor="altoGray" size={16} />
        <Text as={"p"} styledAs={"small"} color={"doveGrayUI"}>
          {model}
        </Text>
      </div>
    </div>
  );
};

export default withRouter(Breadcrumbs);
