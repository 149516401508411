import React, { useEffect, useRef, useState } from "react";

import "./index.scss";
import "./polyfill";

import { init } from "@livechat/customer-sdk";
import { ArrowScatterColor, Cross, ChevronDown, Paperplane, Paper } from "@mbank-design/design-system/icons";
import { Icon } from "@mbank-design/design-system/components";

import * as DOMElements from "./DOMElements";
import {
  markAsFailedMessage,
  appendMessage,
  clearChat,
  confirmMessageAsDelivered,
  confirmMessageAsSeen,
  confirmMessageAsSent,
  createForm,
  createMessage,
  delegate,
  getDataTime,
  getMessageList,
  hideFilePreviewLoading,
  isAcceptExtension,
  isAcceptImageExtension,
  prependMessages,
  removeElement,
  scrollToBottom,
  sendSystemMessage,
  setErrorFilePreview,
  setImageSrcFilePreview,
  throttle
} from "./DOMOperations";
import {
  historyStates,
  availabilityStates,
  POST_CHAT_FORM_ID,
  PRE_CHAT_FORM_ID,
  ALL_ACCEPT_FILE_EXTENSIONS,
  TICKET_FORM_ID,
  MOBILE
} from "./constants";

const state = {
  chat: null,
  thread: null,
  active: false,
  activating: false,
  users: {},
  pendingMessages: [],
  customerId: null,
  historyStatus: historyStates.INACTIVE,
  history: null,
  availability: availabilityStates.OFFLINE,
  form: null,
  preChatForm: null,
  ticketForm: null,
  postChatForm: null,
  connected: false,
  eventsSeenUpToMap: {},
  agentId: null,
  file: null
};

const sdk = init({
  licenseId: Number(process.env.REACT_APP_LIVE_CHAT_LICENSE_ID),
  clientId: process.env.REACT_APP_LIVE_CHAT_CLIENT_ID
});
window.sdk = sdk;

function LiveChatWidget() {
  const [agent, setAgent] = useState(null);
  const [isDisconnection, setIsDisconnection] = useState(false);

  const [isWindowClosed, setIsWindowClosed] = useState(true);
  const [isWindowMinimized, setIsWindowMinimized] = useState(false);

  const lcWindowButton = useRef(null);
  const footer = useRef(null);
  const startChatButton = useRef(null);
  const sendButton = useRef(null);
  const textareaWrapper = useRef(null);
  const input = useRef(null);
  const fileInput = useRef(null);
  const fileButton = useRef(null);
  const lcFiles = useRef(null);

  const lcOverlayChat = useRef(null);
  const lcFileOptions = useRef(null);

  useEffect(() => {
    fileInput.current.setAttribute("accept", ALL_ACCEPT_FILE_EXTENSIONS);

    sdk.on("connected", (payload) => {
      const { availability, customer } = payload;
      state.connected = true;
      lcWindowButton.current.style = "";

      if (availability === availabilityStates.OFFLINE) {
        state.availability = availabilityStates.OFFLINE;
      }

      if (availability === availabilityStates.ONLINE) {
        state.availability = availabilityStates.ONLINE;
      }

      sdk.listChats().then(({ chatsSummary, totalChats }) => {
        onConnected();

        if (!!chatsSummary[0]?.length && chatsSummary[0].active !== state.active && !chatsSummary[0].active) {
          const lastEvent = chatsSummary[0].lastEvent;

          if (lastEvent.type === "system_message") {
            const author = state.users[lastEvent.authorId];
            const authorType = author ? (isAgent(author) ? "agent" : "customer") : "system";

            appendMessage(
              createMessage({
                id: lastEvent.id,
                value: getTranslatedMessage(lastEvent),
                authorType: authorType,
                time: getDataTime(lastEvent.createdAt),
                type: lastEvent.type
              })
            );
          }

          chatDeactivated();
        }

        state.active = chatsSummary[0]?.active || false;
        state.eventsSeenUpToMap = chatsSummary[0]?.eventsSeenUpToMap || {};

        if (state.chat) {
          return;
        }

        if (totalChats === 0) {
          return;
        }

        state.chat = chatsSummary[0].id;

        if (state.active) {
          if (chatsSummary[0].users) {
            const agent = chatsSummary[0].users.filter((user) => user.present && user.type === "agent");

            if (agent.length) {
              appendAgent(agent[0]);
            }
          }

          loadInitialHistory().then(() => {
            showChat();
            toggleChatWindow();

            showFooter();
            scrollToBottom();
          });
        }
      });
    });

    sdk.on("incoming_typing_indicator", (payload) => {
      const id = `typing-indicator-${payload.typingIndicator.authorId}`;
      const clearInterval = 12000;
      let clearTimerId;

      if (payload.typingIndicator.isTyping) {
        if (!document.getElementById(id)) {
          appendMessage(
            createMessage({
              id: id,
              value: `<span>.</span><span>.</span><span>.</span>`,
              authorType: "agent",
              time: false,
              type: "message",
              className: "indicator"
            })
          );
          scrollToBottom();

          clearTimeout(clearTimerId);
          clearTimerId = setTimeout(() => {
            if (document.getElementById(id)) {
              removeElement(id);
            }
          }, clearInterval);
        }
      } else {
        if (document.getElementById(id)) {
          removeElement(id);
        }
      }
    });

    sdk.on("queue_position_updated", (payload) => {
      sendSystemMessage(`Jeden z naszych przedstawicieli wkrótce do Ciebie dołączy. Jesteś ${
        payload.queue.position
      } w kolejce. 
      Czas oczekiwania wynosi ok. ${Math.floor(payload.queue.waitTime / 60)} minut.`);
    });

    sdk.on("user_added_to_chat", (payload) => {
      const { user } = payload;

      if (user.type === "agent" && user.present) {
        appendAgent(user, true);
      }
    });

    sdk.on("customer_id", (id) => {
      state.customerId = id;
    });

    sdk.on("disconnected", ({ reason }) => {
      switch (reason) {
        case "inactivity_timeout":
          sdk.connect();
          break;
        default:
      }

      console.error("disconnected", reason);
      onConnectionLost();
    });

    sdk.on("user_data", (user) => {
      state.users[user.id] = user;
    });

    sdk.on("events_marked_as_seen", (payload) => {
      const { userId, seenUpTo } = payload;

      state.eventsSeenUpToMap[userId] = seenUpTo;

      if (isAgent({ id: userId })) {
        const lastCustomerMessage = document.querySelector(".message-container.customer:last-child");

        if (lastCustomerMessage) {
          confirmMessageAsSeen(lastCustomerMessage.id, true);
          scrollToBottom();
        }
      }
    });

    sdk.on("incoming_chat", (payload) => {
      const { chat } = payload;

      handleChatStart(chat);
    });

    sdk.on("incoming_event", ({ event }) => {
      if (!state.chat || !filterEvents(event)) {
        return;
      }

      const author = state.users[event.authorId];
      const authorType = author ? (isAgent(author) ? "agent" : "customer") : "system";

      if (event.type === "filled_form") {
        appendMessage(
          createMessage({
            id: event.id,
            value: event.fields,
            authorType: "agent",
            time: getDataTime(event.createdAt),
            type: event.type
          })
        );
        return;
      }

      if (event.type === "file") {
        appendMessage(
          createMessage({
            id: event.id,
            value: event,
            authorType: authorType,
            time: getDataTime(event.createdAt),
            type: event.type
          })
        );
        return;
      }

      if (event.type === "system_message") {
        appendMessage(
          createMessage({
            id: event.id,
            value: getTranslatedMessage(event),
            authorType: authorType,
            time: getDataTime(event.createdAt),
            type: event.type
          })
        );
        return;
      }

      appendMessage(
        createMessage({
          id: event.id,
          value: event.text,
          authorType: authorType,
          time: getDataTime(event.createdAt),
          type: event.type
        })
      );
    });

    sdk.on("availability_updated", ({ availability }) => {
      if (availability === state.availability) {
        return;
      }

      if (availability === availabilityStates.OFFLINE) {
        state.availability = availabilityStates.OFFLINE;

        if (!state.active) {
          if (document.getElementById(PRE_CHAT_FORM_ID)) {
            state.preChatForm = false;
            removeElement(PRE_CHAT_FORM_ID);
          }

          if (!state.postChatForm && !state.ticketForm) {
            state.ticketForm = true;
            getForm(0, "ticket");
            clearChat();
            hideStartChatButton();
          }
        }
      }

      if (availability === availabilityStates.ONLINE) {
        state.availability = availabilityStates.ONLINE;

        if (!state.active) {
          if (document.getElementById(TICKET_FORM_ID)) {
            state.ticketForm = false;
            removeElement(TICKET_FORM_ID);
          }

          if (!state.preChatForm && !state.postChatForm) {
            state.preChatForm = true;
            getForm(0, "prechat");
            clearChat();
            hideStartChatButton();
          }
        }
      }
    });

    sdk.on("chat_deactivated", () => {
      chatDeactivated();
    });

    startChatButton.current.onclick = () => startChat();
    sendButton.current.onclick = handleMessage;
    fileButton.current.onclick = openFileOptions;

    input.current.oninput = (e) => activatingSendButton(e.target.value);
    fileInput.current.oninput = (e) => activatingSendButton(e.target.value);

    input.current.onfocus = () => {
      if (window.innerWidth <= MOBILE) {
        setTimeout(function () {
          scrollToBottom();
        }, 200);
      }
    };

    input.current.onkeydown = (e) => {
      const code = e.keyCode || e.which;

      if (code === 13 && !e.shiftKey) {
        e.preventDefault();
        handleMessage();
      }
    };

    input.current.onkeyup = delay((event) => {
      if (event.target.value) {
        sdk.setSneakPeek({
          chatId: state.chat,
          sneakPeekText: event.target.value
        });
      }
    }, 1000);

    fileInput.current.onchange = (event) => {
      const file = event.target.files[0];
      const stringArr = file.name.split(".");
      const fileName = file.name;
      const fileExtension = "." + stringArr[stringArr.length - 1];

      if (isAcceptExtension(fileExtension)) {
        const { promise, cancel } = sdk.uploadFile({
          file: event.target.files[0]
        });

        const id = `${Math.random() * 1000}`;
        showPreviewFiles();
        previewFile(fileName, removePreviewFiles, {
          id,
          extension: fileExtension
        });
        disableSendButton();
        promise
          .then((response) => {
            state.file = response.url;

            if (isAcceptImageExtension(fileExtension)) {
              setImageSrcFilePreview(id, response.url);
            }
          })
          .catch((error) => {
            setErrorFilePreview(id, error.message);
          })
          .finally(() => {
            hideFilePreviewLoading(id);
            enableSendButton();
          });
      } else {
        showPreviewFiles();
        previewFile(
          `Niepoprawny format pliku. Obsługiwane formaty: ${ALL_ACCEPT_FILE_EXTENSIONS.replaceAll(",", ", ")}`,
          removePreviewFiles,
          {
            isError: true
          }
        );
      }

      event.target.value = null;
    };

    delegate(
      "#lc",
      ".chat",
      "mousewheel",
      throttle(300, function loadMore() {
        const chatId = state.chat;

        if (this.scrollTop < 50 && state.historyStatus === historyStates.INACTIVE) {
          loadHistory(chatId);
        }
      })
    );

    document.addEventListener("click", (event) => {
      if (!lcFileOptions.current.classList.contains("hide")) {
        if (!lcFileOptions.current.contains(event.target) && !fileButton.current.contains(event.target)) {
          closeFileOptions();
        }
      }
    });

    window.addEventListener("online", () => {
      onConnected();
    });
    window.addEventListener("offline", () => {
      onConnectionLost();
    });
    window.addEventListener("beforeunload", sdk.disconnect);
  }, []);

  useEffect(() => {
    if (window.innerWidth <= MOBILE) {
      if (isWindowClosed || isWindowMinimized) {
        document.body.removeAttribute("style");
      } else {
        document.body.style.position = "fixed";
        document.body.style.height = "100%";
        document.body.style.width = "100%";
        document.body.style.overflowY = "hidden";
        document.body.style.inset = "0px";
      }
    }
  }, [isWindowClosed, isWindowMinimized]);

  const isAgent = (user) => user.id !== state.customerId;

  const toggleChatWindow = () => {
    toggleChatWindowDOM();

    if (!state.active && state.thread) {
      state.thread = null;
    }

    if (!state.connected) {
      if (!document.getElementById("disconnected-message")) {
        sendSystemMessage(
          "Wystąpił błąd z połączeniem do serwera. W razie problemów skontaktuj się z administratorem.",
          "disconnected-message",
          false
        );
      }
      return;
    }

    scrollToBottom();

    if (!state.active && !state.ticketForm && state.availability === availabilityStates.OFFLINE) {
      state.ticketForm = true;

      clearChat();
      getForm(0, "ticket");

      return;
    }

    if (!state.active && !state.preChatForm && !state.postChatForm && !state.ticketForm) {
      state.preChatForm = true;

      clearChat();
      getForm(0, "prechat");
    }
  };

  const toggleChatWindowDOM = () => {
    setIsWindowClosed((prev) => !prev);

    if (isWindowMinimized) {
      setIsWindowMinimized((prev) => !prev);
    }
  };

  const chatDeactivated = () => {
    addPostChatForm();
    resetStateChat();
    setAgent(null);
    hidePreviewFiles();
    scrollToBottom();
    hideChat();
  };

  const addPostChatForm = () => {
    state.postChatForm = true;
    getForm(0, "postchat");
  };

  const onConnectionLost = (error) => {
    state.connected = false;
    setIsDisconnection(true);
    disableInput(error);
    disabledFields();
    disableSendButton();
  };

  const onConnected = () => {
    setIsDisconnection(false);
    enableInput();
    enableFields();
    enableSendButton();
  };

  const handleChatStart = (chat) => {
    state.chat = chat.id;
    state.thread = chat.thread.id;
    state.active = true;
    state.activating = false;
    state.pendingMessages = [];

    const agent = chat.users.filter((user) => user.type === "agent");
    if (agent && agent[0] && agent[0].present) {
      appendAgent(agent[0], true);
    }

    const { messages, lastCustomerEventId, lastAgentEventId } = getMessagesFromThreads([chat.thread]);
    messages.forEach((message) => appendMessage(message));

    if (lastCustomerEventId && lastCustomerEventId !== lastAgentEventId) {
      confirmMessageAsDelivered(lastCustomerEventId);
    }

    if (lastAgentEventId) {
      confirmMessageAsSeen(lastAgentEventId);
    }

    if (chat.thread.queue) {
      sendSystemMessage(`Jeden z naszych przedstawicieli wkrótce do Ciebie dołączy. Jesteś ${
        chat.thread.queue.position
      } w kolejce. 
        Czas oczekiwania wynosi ok. ${Math.floor(chat.thread.queue.waitTime / 60)} minut.`);
    }

    showChat();
    scrollToBottom();
    showFooter();
  };

  const appendAgent = (agent, isSendMessage = false) => {
    setAgent(agent);
    state.agentId = agent.id;

    if (isSendMessage) {
      sendSystemMessage(`Twoim konsultantem jest ${agent.name}!`);
      scrollToBottom();
    }
  };

  const sendMessage = (chat, id, message, type = "message") => {
    const value = type === "file" ? { url: message } : { text: message };
    const event = { customId: id, ...value, type };

    sdk
      .sendEvent({ chatId: chat, event })
      .then((confirmedMessage) => {
        if (type === "file") {
          appendMessage(
            createMessage({
              id: id,
              value: confirmedMessage,
              authorType: "customer",
              time: getDataTime(),
              type: "file"
            })
          );
          scrollToBottom();
        }

        confirmMessageAsDelivered(id);
      })
      .catch(() => {
        markAsFailedMessage(id);
        scrollToBottom();
      });
  };

  const getForm = (groupId, type) => {
    sdk
      .getForm({
        groupId: groupId,
        type: type
      })
      .then((response) => {
        if (response.enabled) {
          state.form = response.form;

          switch (type) {
            case "postchat":
              const customerName = state.users[state.customerId].name;

              createForm(response.form, sendPostChatForm, groupId, type, POST_CHAT_FORM_ID, "wyślij", customerName);
              break;
            case "ticket":
              createForm(response.form, sendTicketForm, groupId, type, TICKET_FORM_ID, "zostaw wiadomość");
              break;
            case "prechat":
              createForm(response.form, sendPreChatForm, groupId, "filled_form", PRE_CHAT_FORM_ID, "rozpocznij czat");
              break;
            default:
          }

          scrollToBottom();
        }
      });
  };

  const startChat = (events = []) => {
    state.activating = true;

    const threadEvents = [
      ...events,
      ...state.pendingMessages.map((event) => {
        return {
          type: "message",
          text: event.message,
          messageId: event.id
        };
      })
    ];

    const payload = {
      chat: {
        ...(state.chat && { id: state.chat }),
        thread: {
          events: threadEvents
        }
      }
    };

    const action = state.chat ? sdk.resumeChat : sdk.startChat;
    action(payload)
      .then(({ chat }) => {
        handleChatStart(chat);
      })
      .catch((error) => {
        if (error.message === "Groups offline") {
          if (!state.ticketForm) {
            state.ticketForm = true;
            getForm(0, "ticket");
            hideStartChatButton();
          }
        }

        state.activating = false;
        state.pendingMessages.forEach(({ messageId: id }) => markAsFailedMessage(id));
        state.pendingMessages = [];
      })
      .finally(() => {
        scrollToBottom();
      });
  };

  const deactivateChat = () => {
    if (!state.chat) {
      return;
    }

    sdk.deactivateChat({ id: state.chat });
  };

  const handleMessage = () => {
    if (state.file) {
      handleFile();

      return;
    }

    const text = input.current.value;
    input.current.value = "";
    input.current.focus();
    activatingSendButton();

    if (!text) {
      return;
    }

    const messageId = `${Math.random() * 1000}`;

    if (state.active) {
      sendMessage(state.chat, messageId, text);
    } else {
      if (!state.activating) {
        startChat();
      }
      state.pendingMessages.push({ messageId, text });
    }

    appendMessage(
      createMessage({
        id: messageId,
        value: text,
        authorType: "customer",
        time: getDataTime()
      })
    );
    confirmMessageAsSent(messageId);
    scrollToBottom();
  };

  const handleFile = () => {
    const file = state.file;
    activatingSendButton();

    if (!file) {
      return;
    }

    const messageId = `${Math.random() * 1000}`;

    if (state.active) {
      sendMessage(state.chat, messageId, file, "file");
    } else {
      if (!state.activating) {
        startChat();
      }
      state.pendingMessages.push({ messageId, file, type: "file" });
    }

    state.file = null;
    hidePreviewFiles();
  };

  const resetStateChat = () => {
    state.historyStatus = historyStates.INACTIVE;
    state.active = false;
    state.activating = false;
    state.pendingMessages = [];
  };

  const sendPostChatForm = (e) => {
    e.preventDefault();

    if (!state.chat || !state.connected) {
      return;
    }

    const form = e.target;
    let event = {
      type: "filled_form",
      formId: form.dataset.id,
      fields: getFormData(form),
      properties: {
        lc2: {
          form_type: "postchat"
        }
      }
    };

    sdk.sendEvent({ chatId: state.chat, attachToLastThread: true, event }).then((event) => {
      removeElement(POST_CHAT_FORM_ID);
      showStartChatButton();
      state.postChatForm = false;

      appendMessage(
        createMessage({
          id: event.id,
          value: event.fields,
          authorType: "agent",
          time: getDataTime(event.createdAt),
          type: event.type
        })
      );

      const rating = getFormDataByName("rating", form);

      if (rating) {
        const properties = {
          rating: {
            score: rating >= 4 ? 1 : 0
          }
        };

        sdk.updateThreadProperties({
          chatId: state.chat,
          threadId: state.thread,
          properties
        });
      }
    });
  };

  const sendPreChatForm = (e) => {
    e.preventDefault();

    if (!state.connected) {
      return;
    }

    const form = e.target;
    const name = new FormData(form).get("name");

    if (name) {
      loadInitialHistory().then(() => {});

      updateCustomer(name).then(() => {
        const events = [
          {
            properties: {
              lc2: {
                form_type: "prechat"
              }
            },
            type: "filled_form",
            formId: form.dataset.id,
            fields: getFormData(form)
          }
        ];

        if (!state.active) {
          if (!state.activating) {
            removeElement(form.parentNode.id);
            state.preChatForm = false;
            state.form = null;

            startChat(events);
          }
        }
      });
    }
  };

  const sendTicketForm = (e) => {
    e.preventDefault();

    if (!state.connected) {
      return;
    }

    const form = e.target;
    let payload = {
      groupId: form.dataset.groupId,
      filledForm: {
        type: form.dataset.type,
        formId: form.dataset.id,
        fields: getFormData(form)
      }
    };

    sdk.sendTicketForm(payload).then(() => {
      removeElement(form.parentNode.id);
      state.ticketForm = false;
      sendSystemMessage(
        "Dziękujemy za wysłanie formularza. Nasz konsultant skontaktuje się z Tobą w godzinach pracy: pon. – pt., 9:00 – 19:00"
      );
    });
  };

  const getFormDataByName = (name, form) => {
    const formData = new FormData(form);

    return formData.get(name);
  };

  const getFormData = (form) => {
    if (!state.form && !Array.isArray(state.form?.fields)) {
      return [];
    }

    let fields = [];

    const formData = new FormData(form);
    for (let field of state.form.fields) {
      if (field.type === "header") {
        continue;
      }

      if (field.type === "checkbox") {
        fields.push({
          ...field,
          answers: field.options.map((option) => option)
        });

        continue;
      }

      if (field.type === "radio") {
        const value = formData.get(`radio_${field.id}`);

        fields.push({
          ...field,
          answer: field.options.filter((option) => option.label === value)[0]
        });

        continue;
      }

      if (field.type === "rating") {
        continue;
      }

      fields.push({
        ...field,
        answer: formData.get(field.type)
      });
    }

    return fields;
  };

  const toggleOverlayClosingChat = () => {
    if (state.active) {
      toggleClosingChatModal();
      return;
    }

    if (state.postChatForm && !state.active) {
      state.postChatForm = false;
      state.form = null;
      removeElement(POST_CHAT_FORM_ID);
    }

    hideStartChatButton();
    toggleChatWindowDOM();
  };

  const closeChat = () => {
    if (state.active) {
      deactivateChat();
    }

    toggleClosingChatModal();
  };

  const chooseFile = () => {
    fileInput.current.click();
  };

  const getTranslatedMessage = (event) => {
    let translatedMessage = event.text;
    if (event.systemMessageType === "manual_archived_customer") {
      translatedMessage = "Czat został zakończony";
    }

    if (event.systemMessageType === "archived_customer_disconnected") {
      translatedMessage = `${event.textVars.customer} opuścił czat`;
    }

    if (event.systemMessageType === "routing.archived_disconnected") {
      translatedMessage = `${event.textVars.agent} utracił połączenie z internetem. Czat został zakończony`;
    }

    if (event.systemMessageType === "routing.unassigned_disconnected") {
      translatedMessage = `${event.textVars.agent} utracił połączenie z internetem. Jeden z naszych przedstawicieli wkrótce do Ciebie dołączy`;
    }

    if (event.systemMessageType === "routing.archived_offline") {
      translatedMessage = `Czat został zakończony z powodu braku dostępnych konsultantów`;
    }

    if (event.systemMessageType === "routing.archived_inactive") {
      translatedMessage = "Czat został zamknięty z powodu długiego okresu braku aktywności użytkownika";
    }

    if (event.systemMessageType === "chat_transferred") {
      translatedMessage = `Przekierowaliśmy Twoja rozmowę do konsultanta ${event.textVars.targets}`;
    }

    if (event.systemMessageType === "routing.assigned_inactive") {
      translatedMessage = `Przekierowaliśmy Twoja rozmowę do konsultanta ${event.textVars.agent_added}`;
    }

    if (event.systemMessageType === "routing.assigned_disconnected") {
      translatedMessage = `${event.textVars.agent_removed} utracił połączenie z internetem. Przekierowaliśmy Twoja rozmowę do konsultanta ${event.textVars.agent_added}`;
    }

    if (event.systemMessageType === "rating.chat_rated") {
      translatedMessage = `Ocena rozmowy: ${
        event.textVars.score === "good" ? "dobra" : "zła"
      }. Dziękujemy za podzielenie się opinią!`;
    }

    return translatedMessage;
  };

  const enableFields = () => {
    fileInput.current.disabled = false;
    fileButton.current.disabled = false;
  };

  const disabledFields = () => {
    fileInput.current.disabled = true;
    fileButton.current.disabled = true;
  };

  const disableInput = (text) => {
    if (text) {
      input.current.placeholder = text;
    }
    input.current.disabled = true;
  };

  const enableInput = () => {
    input.current.placeholder = "Napisz wiadomość...";
    input.current.disabled = false;
  };

  const disableSendButton = () => {
    sendButton.current.disabled = true;
  };

  const enableSendButton = () => {
    sendButton.current.disabled = false;
  };

  const toggleMinimized = () => {
    setIsWindowMinimized((prev) => !prev);
  };

  const closeFileOptions = () => {
    if (!lcFileOptions.current.classList.contains("hide")) {
      lcFileOptions.current.classList.add("hide");
    }
  };

  const openFileOptions = () => {
    if (lcFileOptions.current.classList.contains("hide")) {
      lcFileOptions.current.classList.remove("hide");
    }
  };

  const toggleClosingChatModal = () => {
    if (isWindowMinimized) {
      toggleMinimized();
    }

    lcOverlayChat.current.classList.toggle("hide");
  };

  const hideChatModal = () => {
    lcOverlayChat.current.classList.add("hide");
  };

  const showChat = () => {
    startChatButton.current.style.display = "none";
    textareaWrapper.current.style.display = "flex";

    input.current.focus();
  };

  const hideChat = () => {
    textareaWrapper.current.style.display = "none";
  };

  const showFooter = () => {
    footer.current.style.display = "block";
  };

  const showStartChatButton = () => {
    startChatButton.current.style.display = "inline-block";
  };

  const hideStartChatButton = () => {
    startChatButton.current.style.display = "none";
  };

  const activatingSendButton = (value = "") => {
    if (value && !sendButton.current.classList.contains("active")) {
      sendButton.current.classList.add("active");

      return;
    }

    if (!value && sendButton.current.classList.contains("active")) {
      sendButton.current.classList.remove("active");
    }
  };

  const previewFile = (name, onClick, { id, extension } = {}) => {
    lcFiles.current.replaceChildren();
    const fileContainer = document.createElement("div");
    const contentFileContainer = document.createElement("div");
    const iconContainer = document.createElement("button");
    const fileName = document.createElement("div");
    const loader = document.createElement("div");
    loader.classList.add("lc-loader");
    let image;

    fileContainer.id = id;
    fileContainer.append(loader);
    fileContainer.classList.add("loading");
    iconContainer.classList.add("lc-files-remove-icon");
    iconContainer.id = "lc-files-remove-icon";
    iconContainer.onclick = () => onClick(fileContainer.id);
    iconContainer.disabled = true;
    fileName.innerText = name;
    fileName.classList.add("lc-file-name");

    if (isAcceptImageExtension(extension)) {
      fileContainer.classList.add("lc-image-container");
      contentFileContainer.classList.add("lc-image");
      image = document.createElement("img");
      image.alt = name;
      image.height = 130;
      image.style.opacity = "0";

      contentFileContainer.style.height = "130px";
      contentFileContainer.append(image);
      fileContainer.append(contentFileContainer);
    } else {
      fileContainer.classList.add("lc-file-container");
      contentFileContainer.classList.add("lc-file");
      contentFileContainer.innerHTML = DOMElements.fileIcon;
      fileContainer.append(contentFileContainer);
    }

    fileContainer.append(fileName);

    iconContainer.innerHTML = DOMElements.binIcon;
    fileContainer.append(iconContainer);
    lcFiles.current.append(fileContainer);
    scrollToBottom();
  };

  const removePreviewFile = () => {
    lcFiles.current.replaceChildren();
  };

  const showPreviewFiles = () => {
    lcFiles.current.style.display = "flex";
    disableInput();
  };

  const hidePreviewFiles = () => {
    lcFiles.current.style.display = "none";
    enableInput();
  };

  const delay = (callback, ms) => {
    var timer = 0;
    return function () {
      var context = this,
        args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function () {
        callback.apply(context, args);
      }, ms || 0);
    };
  };

  const removePreviewFiles = (id) => {
    const file = document.getElementById(id);

    if (!file) return;

    file.remove();
    state.file = null;
    activatingSendButton();
    hidePreviewFiles();
  };

  const updateCustomer = (name) => {
    const properties = {
      name: name,
      sessionFields: {
        custom_property: "",
        any_key_is_ok: ""
      }
    };

    return sdk.updateCustomer(properties);
  };

  const loadHistory = (chat) => {
    if (!state.history) {
      return;
    }

    return new Promise((resolve, reject) => {
      if (!state.connected) {
        return;
      }

      if (!state.active) {
        return;
      }

      state.historyStatus = historyStates.LOADING;
      state.history.next().then(
        ({ value: { threads }, done }) => {
          if (!threads) {
            return;
          }

          const activeThread = threads.filter((thread) => thread.active);
          if (activeThread && activeThread.length) {
            state.thread = activeThread[0].id;
          }

          const { messages, lastCustomerEventId, lastAgentEventId } = getMessagesFromThreads(threads);
          const messageList = getMessageList(chat);

          const fromTheBottom = messageList.scrollHeight - (messageList.scrollTop + messageList.clientHeight);

          prependMessages(chat, messages);

          if (lastCustomerEventId && lastCustomerEventId !== lastAgentEventId) {
            confirmMessageAsDelivered(lastCustomerEventId);
          }

          if (lastAgentEventId) {
            confirmMessageAsSeen(lastAgentEventId);
          }

          messageList.scrollTop = messageList.scrollHeight - messageList.clientHeight - fromTheBottom;

          state.historyStatus = done ? historyStates.DONE : historyStates.INACTIVE;
          resolve();
        },
        (err) => {
          state.historyStatus = historyStates.INACTIVE;
          reject(err);
        }
      );
    });
  };

  const filterEvents = (event) => {
    return (
      event.type === "message" ||
      event.type === "file" ||
      (event.type === "system_message" && event.systemMessageType !== "transcript_requested") ||
      (event.type === "filled_form" && event.properties?.lc2?.form_type === "postchat")
    );
  };

  const getMessagesFromThreads = (threads) => {
    const lastCustomerEvent = state.eventsSeenUpToMap[state.customerId];
    const lastAgentEvent = state.eventsSeenUpToMap[state.agentId];
    let lastCustomerEventId = null;
    let lastAgentEventId = null;

    const messages = threads
      .map(({ events }) => events || [])
      .reduce((acc, current) => [...acc, ...current], [])
      .filter((event) => filterEvents(event))
      .map((event) => {
        // const author = users[event.authorId];
        const author = state.users[event.authorId];
        const authorType = author ? (isAgent(author) ? "agent" : "customer") : "system";

        if (lastCustomerEvent === event.createdAt) {
          lastCustomerEventId = event.id;
        }

        if (Date.parse(lastAgentEvent) > Date.parse(event.createdAt)) {
          lastAgentEventId = event.id;
        }

        if (event.type === "filled_form") {
          return createMessage({
            id: event.id,
            value: event.fields,
            authorType: "agent",
            time: getDataTime(event.createdAt),
            type: event.type
          });
        }

        if (event.type === "file") {
          return createMessage({
            id: event.id,
            value: event,
            authorType: authorType,
            time: getDataTime(event.createdAt),
            type: event.type
          });
        }

        if (event.type === "system_message") {
          return createMessage({
            id: event.id,
            value: getTranslatedMessage(event),
            authorType: authorType,
            time: getDataTime(event.createdAt),
            type: event.type
          });
        }

        return createMessage({
          id: event.id,
          value: event.text,
          authorType: authorType,
          time: getDataTime(event.createdAt),
          type: event.type
        });
      });

    return { messages, lastCustomerEventId, lastAgentEventId };
  };

  const loadInitialHistory = () => {
    const chatId = state.chat;

    state.history = sdk.getChatHistory({ chatId });

    const loadLatestHistory = () => loadHistory(chatId).then(() => scrollToBottom());

    return loadLatestHistory().catch(() => loadLatestHistory());
  };

  return (
    <>
      <div
        id="lc-button"
        ref={lcWindowButton}
        onClick={() => toggleChatWindow()}
        className={`lc-button ${isWindowClosed ? "closed" : ""} ${isWindowMinimized ? "minimized" : ""}`}
        style={{ opacity: 0, width: 0 }}
      >
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="8" y="8" width="48" height="48" rx="24" fill="#008F20" />
          <path
            d="M43 22.5V38.5L38 41.522V38.5H21V22.5H43ZM37 29.5C36.816 29.5 36.645 29.55 36.496 29.636C36.199 29.81 36 30.131 36 30.5C36 31.052 36.448 31.5 37 31.5C37.552 31.5 38 31.052 38 30.5C38 30.272 37.924 30.062 37.796 29.895C37.613 29.655 37.324 29.5 37 29.5ZM32 29.5C31.447 29.5 31 29.948 31 30.5C31 31.052 31.447 31.5 32 31.5C32.553 31.5 33 31.052 33 30.5C33 29.948 32.553 29.5 32 29.5ZM27 29.5C26.447 29.5 26 29.948 26 30.5C26 31.052 26.447 31.5 27 31.5C27.553 31.5 28 31.052 28 30.5C28 29.948 27.553 29.5 27 29.5Z"
            stroke="white"
            strokeWidth="1.5"
          />
          <rect
            className="border-rect"
            x="4"
            y="4"
            width="56"
            height="56"
            rx="28"
            stroke="#008F20"
            strokeOpacity="0.25"
            strokeWidth="8"
          />
        </svg>
      </div>
      <div id="lc" className={`lc ${isWindowClosed ? "closed" : ""} ${isWindowMinimized ? "minimized" : ""}`}>
        <div ref={lcOverlayChat} className="lc-overlay-wrapper lc-overlay-close-chat hide">
          <div className="lc-overlay" />
          <div className="lc-overlay-content closing-chat-content">
            <div className="closing-chat-content__icon">
              <Icon iconComponent={ArrowScatterColor} />
            </div>
            <div className="closing-chat-content__content">czy chcesz opuścić aktualny czat?</div>
            <button onClick={() => closeChat()} className="lc-btn closing-chat-content__button">
              zamknij czat
            </button>
          </div>
          <button onClick={() => hideChatModal()} className="lc-overlay-close">
            <Icon iconComponent={Cross} />
          </button>
        </div>
        <div id="lc-header">
          <div>czat mAuto</div>
          <div>
            <button id="minimize" onClick={() => toggleMinimized()}>
              <Icon iconComponent={ChevronDown} />
            </button>
            <button id="lc-header-close" onClick={() => toggleOverlayClosingChat()}>
              <Icon iconComponent={Cross} />
            </button>
          </div>
        </div>
        <div className="lc-agent-container">
          <div id="lc-agent">
            {agent && (
              <div className="agent-container">
                {agent.avatar && <img src={agent.avatar} className="agent-avatar" alt={agent.name} />}
                <div className="agent-name">{agent.name}</div>
              </div>
            )}
          </div>
          {isDisconnection && (
            <div id="lc-connection-status" className="lc-connection-status">
              ponowne łączenie…
            </div>
          )}
        </div>
        <div id="chat" className="chat" />
        <div ref={footer} className="lc-footer" style={{ display: "none" }}>
          <div ref={lcFiles} className="lc-files" style={{ display: "none" }} />
          <div ref={textareaWrapper} className="textarea-wrapper" style={{ display: "none" }}>
            <textarea ref={input} placeholder="Napisz wiadomość..." className="message-input" disabled />
            <div className="lc-footer__icons">
              <input ref={fileInput} className="file-input" type="file" style={{ width: 0, height: 0 }} />
              <button ref={fileButton} className="file-button" disabled>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14.5 5.54392C14.4294 4.54063 13.9695 3.60612 13.2204 2.94378C12.4712 2.28144 11.4935 1.94489 10.5 2.00737C9.50649 1.94489 8.52878 2.28144 7.77964 2.94378C7.0305 3.60612 6.57059 4.54063 6.5 5.54392V16.1536M9.5 7.486V16.5143C9.5 17.1843 9.76339 17.8268 10.2322 18.3005C10.7011 18.7743 11.337 19.0404 12 19.0404C12.663 19.0404 13.2989 18.7743 13.7678 18.3005C14.2366 17.8268 14.5 17.1843 14.5 16.5143V5.27616M6.5 15.9636V16.4426C6.5 17.9165 7.07946 19.33 8.11091 20.3723C9.14236 21.4145 10.5413 22 12 22C13.4587 22 14.8576 21.4145 15.8891 20.3723C16.9205 19.33 17.5 17.9165 17.5 16.4426V7.26168"
                    stroke="#6E6E6E"
                    strokeWidth="1.5"
                  />
                </svg>
              </button>
              <button ref={sendButton} id="send-button" disabled>
                <Icon iconComponent={Paperplane} />
              </button>
            </div>
          </div>
          <div ref={lcFileOptions} className="lc-options-list hide">
            <div onClick={() => chooseFile()} className="lc-option">
              <Icon iconComponent={Paper} />
              <button className="lc-option__title">wyślij plik</button>
            </div>
          </div>
          <div className="start-chat-button">
            <button ref={startChatButton} className="lc-btn" style={{ display: "none" }}>
              otwórz czat ponownie
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default LiveChatWidget;
