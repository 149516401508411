import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import "./index.scss";
import {
  Text,
  Icon,
  Stripe,
} from "@mbank-design/design-system/components";
import {
  FontColor,
  FontSize,
  FontWeight,
} from "@mbank-design/design-system/enums";
import { IconColor, IconSize } from "@mbank-design/design-system/enums";
import {
  HamburgerMenu,
  Heart,
  MagnifyingGlass,
  Filter,
  CalculatorPercent,
  Leaf,
  PhoneHandle,
} from "@mbank-design/design-system/icons";
import { Mauto40StripeMassDarkTheme, Mauto40StripeMass } from "@mbank-design/design-system/logos";
import NavBarLinks from "../NavBarLinks";
import NavBarSubLinks from "../NavBarSubLinks";
import { MobileFiltersContext, MobileSearchContext, FavouriteCarsContext } from "../../../scripts/commons/contexts";
import { countCharacters } from "../../../scripts/commons/common";
import { ContactCard } from "../../parts/ContactCard/index"

class NavBar extends Component {
  state = {
    isMobileMenuOpen: false,
    isMobileFiltersOpen: false,
    isDesktopLoggedMenuOpen: false,
    isLogged: this.props.isLogged,
    offerSublinksExpanded: false,
    brandSublinksExpanded: false,
  };

  componentDidMount() {
    window.addEventListener("click", this.handleClickAnyWhere);
  }
  componentWillUnmount() {
    // TODO - fix "2 arguments required, but only 1 is present", Jasiek's TODOS
    // window.removeEventListener(this.handleClickAnyWhere);
  }

  changeDocumentBodyOverflow(hideOverflow) {
    let root = document.getElementById('root');
    if(root) {
      root.style.overflowY = hideOverflow ? "hidden" : "unset";
    }
  }

  handleClickHamburger() {
    this.setState({ 
      isMobileMenuOpen: !this.state.isMobileMenuOpen,
      offerSublinksExpanded: false,
      brandSublinksExpanded: false
    }, () => {
      this.changeDocumentBodyOverflow(this.state.isMobileMenuOpen);
    });
  }
  toggleFiltersModal() {
    this.setState({ isMobileFiltersOpen: !this.state.isMobileFiltersOpen });
  }

  handleClickCalc() {
    if (document.querySelector(".floating-wrapper")) {
      document.querySelector(".floating-wrapper").style.display = "block";
      this.changeDocumentBodyOverflow(true);
    }
    if (document.querySelector(".reservation-info-container")) {
      document.querySelector(".reservation-info-container").style.dispaly =
        "none";
    }

    if (document.querySelector(".calculator-container")) {
      document.querySelector(".calculator-container").style.display = "block";
    }
  }

  toggleOfferSublinksExpansion() {
    this.setState({
      offerSublinksExpanded: !this.state.offerSublinksExpanded,
      brandSublinksExpanded: !this.state.offerSublinksExpanded ? false : this.state.brandSublinksExpanded
    });
  }

  toggleBrandSublinksExpansion() {
    this.setState({
      brandSublinksExpanded: !this.state.brandSublinksExpanded,
      offerSublinksExpanded: !this.state.brandSublinksExpanded ? false : this.state.offerSublinksExpanded
    });
  }

  collapseSublinks() {
    this.setState({
      brandSublinksExpanded: false,
      offerSublinksExpanded: false,
      isMobileMenuOpen: false
    }, () => {
      this.changeDocumentBodyOverflow(false);
    });
  }

  handleClickAnyWhere = (e) => {
    if (
      (e.target && e.target.classList.value.includes("desktop-logged-nav")) ||
      (e.target.parentElement &&
        e.target.parentElement.classList.value.includes(
          "desktop-logged-nav"
        )) ||
      (e.target &&
        e.target.classList.value.includes("logged-desktop-button")) ||
      (e.target.parentElement &&
        e.target.parentElement.classList.value.includes(
          "logged-desktop-button"
        ))
    ) {
      return;
    }
    if (this.state.isDesktopLoggedMenuOpen) {
      this.setState({ isDesktopLoggedMenuOpen: false });
    }
    if (
      (e.target && e.target.classList.value.includes("highlighted")) ||
      (e.target.parentElement &&
        e.target.parentElement.classList.value.includes(
          "nav-bar-logo-anchor"
        )) ||
      (e.target.parentElement && e.target.parentElement.localName === "g") ||
      (e.target.parentElement &&
        e.target.parentElement.classList.value.includes("favs-button"))
    ) {
      this.setState({ isMobileMenuOpen: false });
    }
  };
  toggleLoggedMenu() {
    this.setState({
      isDesktopLoggedMenuOpen: !this.state.isDesktopLoggedMenuOpen,
    });
  }


  isListPage() {
    const listPageUrls = [
      "/list",
      "/samochody-poleasingowe",
      "/samochody-elektryczne",
      "/nowe-samochody",
      // "/aukcje-samochodowe",
      "/sedan",
      "/hatchback",
      "/kombi",
      "/suv",
      "/bus",
      "/van",
    ];

    const isListUrl = listPageUrls.some((listPageUrl) =>
      this.props.location.pathname.includes(listPageUrl)
    );

    return (countCharacters("/", this.props.location.pathname) === 1 && isListUrl) || this.props.location.pathname.includes('/marki');
  }

  render() {
    const { location } = this.props;

    const hideNavBarPagesList = ["/lp-q7", "/lp-tesla-3", "/lp-hyundai", "/lp-tesla-y", "/lp-afi", "/lp-bmw", "/targi", "/konkurs", "/lp-taniej-z-mauto", "/lp-2000-na-paliwo"];

    if (hideNavBarPagesList.includes(location.pathname.toLowerCase())) {
      return null;
    }

    let offerSublinksExpanded = this.state.offerSublinksExpanded;
    let brandSublinksExpanded = this.state.brandSublinksExpanded;
    const IconComponent = this.props.isDarkMode
      ? Mauto40StripeMassDarkTheme
      : Mauto40StripeMass;
    return (
      <>
        <FavouriteCarsContext.Consumer>
          {({ favourireCarsList }) => {
            return (
              <MobileSearchContext.Consumer>
                {({ toggleMobileSearch }) => {
                  return (
                    <MobileFiltersContext.Consumer>
                      {({ isMobileFiltersOpen, toggleMobileFilters }) => {
                        return (
                          <div
                            className={`nav-bar-container-with-stripes`}
                          >
                            <Stripe />
                              {!this.state.isMobileMenuOpen && (
                              <div className="nav_bar-container">
                                <div
                                  className={`nav_bar-container-logo-and-links navbar-wrapper`}
                                >
                                  <Link className="nav-bar-logo-anchor" to="/" onClick={() => this.collapseSublinks()}>
                                    <IconComponent
                                      className="nav_bar-logo"
                                      key={
                                        this.props.isDarkMode
                                          ? "Mauto40StripeMassDarkTheme"
                                          : "Mauto40StripeMass"
                                      }
                                    />
                                  </Link>
                                  <div className="nav_bar-navigations-buttons-container">
      
                                      <div 
                                        className="nav_bar-mobile-button-container search-nav-button" 
                                        onClick={()=>toggleMobileSearch()}
                                      >
                                        <Icon
                                          iconComponent={MagnifyingGlass}
                                          size={IconSize.NORMAL}
                                          primaryColor={IconColor.SECONDARY_CONTRAST}
                                        ></Icon>
                                      </div>
                                    <a 
                                    href="tel:+48605415050"
                                    className="nav_bar-mobile-button-container search-nav-button" >
                                      <Icon 
                                      iconComponent={PhoneHandle}
                                      size={IconSize.NORMAL}
                                      primaryColor={IconColor.SECONDARY_CONTRAST}
                                      />
                                    </a>
                                    <Link
                                      to="/ulubione/oferty"
                                      className="nav_bar-mobile-button-container fav-nav-button"
                                    >
                                      <div className="viewed-cars-button-container centered">
                                        <Icon
                                          iconComponent={Heart}
                                          size={IconSize.NORMAL}
                                          primaryColor={IconColor.SECONDARY_CONTRAST}
                                        ></Icon>
                                        {favourireCarsList.length ? (
                                          <div className="fav-nav-button__fav-number">
                                            <span>{favourireCarsList.length}</span>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </Link>
                                    <div
                                      onClick={() => this.handleClickHamburger()}
                                      className="nav_bar-mobile-button-container hamburger-container"
                                    >
                                      <Icon
                                        iconComponent={HamburgerMenu}
                                        size={IconSize.NORMAL}
                                        primaryColor={IconColor.SECONDARY_CONTRAST}
                                      ></Icon>
                                    </div>
                                  </div>
                                  <div className="nav_bar-links-container">
                                    <div className="nav_bar-links">
                                      <NavBarLinks
                                        handleClickHamburger={() => this.handleClickHamburger()}
                                        collapseSublinks={() => this.collapseSublinks()}
                                        toggleBrandSublinksExpansion = {() => this.toggleBrandSublinksExpansion()}
                                        toggleOfferSublinksExpansion = {() => this.toggleOfferSublinksExpansion()}
                                        offerSublinksExpanded = {offerSublinksExpanded}
                                        brandSublinksExpanded = {brandSublinksExpanded}
                                        isLogged={this.props.isLogged}
                                        setIsLogged={(v) => this.props.setIsLogged(v)}
                                        isDesktopLoggedMenuOpen={
                                          this.state.isDesktopLoggedMenuOpen
                                        }
                                        toggleLoggedMenu={() => this.toggleLoggedMenu()}
                                        favoriteCarAmount={favourireCarsList.length}
                                        toggleSearchMenuState={this.props.toggleSearchMenuState}
                                      />
                                    </div>
                                    {(brandSublinksExpanded || offerSublinksExpanded) && (
                                      <div className="nav_bar-sublinks-container">
                                        <NavBarSubLinks
                                          offersFiltersDataFromApi={this.props.offersFiltersDataFromApi}
                                          vehicleBrandLinks = {this.props.vehicleBrandLinks}
                                          offerSublinksExpanded = {offerSublinksExpanded}
                                          brandSublinksExpanded = {brandSublinksExpanded}
                                          collapseSublinks={() => this.collapseSublinks()}
                                          toggleBrandSublinksExpansion = {() => this.toggleBrandSublinksExpansion()}
                                          toggleOfferSublinksExpansion = {() => this.toggleOfferSublinksExpansion()}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              )}
                            {!this.state.isMobileMenuOpen && (brandSublinksExpanded || offerSublinksExpanded) && (
                              <div className="nav_bar-sublinks-background" onClick={() => this.collapseSublinks()}></div>
                            )}
                            {this.state.isMobileMenuOpen && (
                              <>
                                <div className="nav_bar-mobile-modal-container mobile-hamburger-menu">
                                  <div className="nav_bar-container">
                                    <div className="nav_bar-container-logo-and-links">
                                    <Link className="nav-bar-logo-anchor" to="/" onClick={() => this.collapseSublinks()}>
                                      <IconComponent
                                        className="nav_bar-logo"
                                        key={
                                          this.props.isDarkMode
                                            ? "Mauto40StripeDarkTheme"
                                            : "Mauto40Stripe"
                                        }
                                      />
                                    </Link>
                                    <div className="nav_bar-navigations-buttons-container">
                                        <div
                                          className="nav_bar-mobile-button-container search-nav-button"
                                          onClick={()=>toggleMobileSearch()}
                                        >
                                          <Icon
                                            iconComponent={MagnifyingGlass}
                                            size={IconSize.NORMAL}
                                            primaryColor={IconColor.SECONDARY_CONTRAST}
                                          ></Icon>
                                        </div>
                                        <a
                                          href="tel:+48605415050"
                                          className="nav_bar-mobile-button-container search-nav-button"
                                        >
                                          <Icon
                                            iconComponent={PhoneHandle}
                                            size={IconSize.NORMAL}
                                            primaryColor={IconColor.SECONDARY_CONTRAST}
                                          />
                                        </a>
                                        <Link
                                          to="/ulubione/oferty"
                                          className="nav_bar-mobile-button-container fav-nav-button"
                                        >
                                          <div className="viewed-cars-button-container centered">
                                            <Icon
                                              iconComponent={Heart}
                                              size={IconSize.NORMAL}
                                              primaryColor={IconColor.SECONDARY_CONTRAST}
                                            ></Icon>
                                            {favourireCarsList.length ? (
                                              <div className="fav-nav-button__fav-number">
                                                <span>{favourireCarsList.length}</span>
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </Link>
                                        <div
                                        onClick={() => this.handleClickHamburger()}
                                        className="nav_bar-mobile-button-container hamburger-container"
                                        >
                                          <Icon
                                            iconComponent={HamburgerMenu}
                                            size={IconSize.NORMAL}
                                            primaryColor={IconColor.SECONDARY_CONTRAST}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <NavBarLinks
                                    isMobile
                                    collapseSublinks={() => this.collapseSublinks()}
                                    toggleBrandSublinksExpansion = {() => this.toggleBrandSublinksExpansion()}
                                    toggleOfferSublinksExpansion = {() => this.toggleOfferSublinksExpansion()}
                                    offerSublinksExpanded = {offerSublinksExpanded}
                                    brandSublinksExpanded = {brandSublinksExpanded}
                                    setIsLogged={(v) => this.props.setIsLogged(v)}
                                    handleClickHamburger={() => this.handleClickHamburger()}
                                    isLogged={this.props.isLogged}
                                    favoriteCarAmount={favourireCarsList.length}
                                  />
                                  <NavBarSubLinks
                                    isMobile
                                    vehicleBrandLinks = {this.props.vehicleBrandLinks}
                                    offerSublinksExpanded = {offerSublinksExpanded}
                                    brandSublinksExpanded = {brandSublinksExpanded}
                                    collapseSublinks={() => this.collapseSublinks()}
                                    toggleBrandSublinksExpansion = {() => this.toggleBrandSublinksExpansion()}
                                    toggleOfferSublinksExpansion = {() => this.toggleOfferSublinksExpansion()}
                                  />
                                  {(brandSublinksExpanded || offerSublinksExpanded) ? <></> : <ContactCard isMobile />}
                                </div>
                              </>
                            )}
                          </div>
                        );
                      }}
                    </MobileFiltersContext.Consumer>
                  )
                }}
              </MobileSearchContext.Consumer>
            )
            
          }}
        </FavouriteCarsContext.Consumer>
      </>
    )
  }
}

export default withRouter(NavBar);
