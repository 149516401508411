import axios from "axios";
import { LocalStorageService } from "./jwt";
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

//Literals

export const getLiterals = async () => {
  return await axios.get("/api/Literals/Get");
};

// HomePage
export const getNewVehicles = (filtersModel) => {
  return axios({
    method: "POST",
    url: "/api/Home/NewVehicles",
    data: filtersModel,
    validateStatus: () => true,
  });
};
export const getAfterLeaseVehicles = (filtersModel) => {
  return axios({
    method: "POST",
    url: "/api/Home/AfterLease",
    data: filtersModel,
    validateStatus: () => true,
  });
};
export const getEndingAuctions = (filtersModel) => {
  return axios({
    method: "POST",
    url: "/api/Home/EndingAuctions",
    data: filtersModel,
    validateStatus: () => true,
  });
};
export const getElectricVehicles = (filtersModel) => {
  return axios({
    method: "POST",
    url: "/api/Home/ElectricVehicles",
    data: filtersModel,
    validateStatus: () => true,
  });
};
export const getMyElectricVehicles = (filtersModel) => {
  return axios({
    method: "POST",
    url: "/api/Home/MyElectricVehicles",
    data: filtersModel,
    validateStatus: () => true,
  });
};
export const getBodyTypesByMake = (selectedMake) => {
  return axios({
    method: "POST",
    url: "/api/OffersFilterData",
    data: selectedMake,
    validateStatus: ()=> true,
  })
};

// List Page
export const getNewVehiclesOffers = (filtersModel) => {
  return axios.post("/api/Offers/NewVehicles", filtersModel);
};
export const getAfterLeaseVehiclesOffers = (filtersModel) => {
  return axios.post("/api/Offers/AfterLease", filtersModel);
};

export const getOffersCount = (filtersModel) => {
  return axios({
    method: "POST",
    url: "/api/Offers/Count",
    data: filtersModel,
    validateStatus: () => true,
  });
};

export const getOffersFiltersData = () => {
  return axios({
    method: "GET",
    url: "/api/OffersFilterData",
    data: null,
    validateStatus: () => true,
  });
};

// Details
export const getLeaseDetailsData = (payload) => {
  return axios({
    method: "GET",
    url: `/api/OfferDetails/AfterLease?AuctionId=${payload.id}`,
    data: null,
    validateStatus: () => true,
  });
};

export const getNewDetailsData = (payload) => {
  return axios.get(`/api/OfferDetails/NewVehicles?AuctionId=${payload.id}`);
};

export const getAuctionsDetailsData = (payload) => {
  return axios.get(`/api/OfferDetails/Auctions?AuctionId=${payload.id}`);
};

export const getCalculatorProperties = (
  maxLeasingPeriod = 80,
  maxAvailableRentPeriod = 80
) => {
  return axios.get(
    "/api/InstallmentCalculator/CalculatorProperties?maxAvailableLeasingPeriod=" +
      maxLeasingPeriod +
      "&maxAvailableRentPeriod=" +
      maxAvailableRentPeriod
  );
};

export const getInsurancePrice = (payload) => {
  return axios.get(
    `/api/InstallmentCalculator/InsurancePrice?price=${payload.price}&period=${payload.period}&hp=${payload.hp}&isNew=${payload.isNew}&isElectic=${payload.isElectic}&isTruck=${payload.isTruck}&idAuction=${payload.auctionId}`
  );
};

export const printOffer = (id, payload) => {
  return axios.post('/api/OfferDetails/Auctions/PrintOffer?printingId=' + id, payload);
}

export const getLongTimeRentPrice = (payload) => {
  return axios.post("/api/InstallmentCalculator/LongTimeRent", payload);
};

export const getLeasingPrice = (payload) => {
  return axios.post("/api/InstallmentCalculator/Leasing", payload);
};

export const leasing105Check = (refNumber) => {
  return axios.get(`/api/Leasing105/IsInProgram?refNumber=${refNumber}`)
}
export const leasing105Data = (refNumber) => {
  return axios.get(`/api/InstallmentCalculator/Leasing105?refNumber=${refNumber}`)
}

export const getSimplifiedVehicleData = (auctionId, vehicleStatus) => {
  let vehicleStatusCode = 0;
  switch (vehicleStatus) {
    case "new":
      vehicleStatusCode = 0;
      break;
    case "afterLease":
      vehicleStatusCode = 1;
      break;
    case "auction":
      vehicleStatusCode = 2;
      break;
    default:
      vehicleStatusCode = 0;
      break;
  }

  return axios.get(
    `/api/OfferDetails/SimplifiedVehicleData?AuctionId=${auctionId}&vehicleSaleStatus=${vehicleStatusCode}`
  );
};

// Favorite
export const getFavoriteNewVehicle = (payload) => {
  let string = "?ids=";
  for (let i = 0; i < payload.IdArr.length; i++) {
    string += i === 0 ? `${payload.IdArr[i]}` : `%2C${payload.IdArr[i]}`;
  }
  return axios.get(`/api/Favorites/NewVehicles${string}`);
};

export const getFavoriteUsedVehicle = (payload) => {
  let string = "?ids=";
  for (let i = 0; i < payload.IdArr.length; i++) {
    string += i === 0 ? `${payload.IdArr[i]}` : `%2C${payload.IdArr[i]}`;
  }
  return axios.get(`/api/Favorites/AfterLease${string}`);
};

export const getFavoriteAuctionVehicle = (payload) => {
  let string = "?ids=";
  for (let i = 0; i < payload.IdArr.length; i++) {
    string += i === 0 ? `${payload.IdArr[i]}` : `%2C${payload.IdArr[i]}`;
  }
  return axios.get(`/api/Favorites/Auctions${string}`);
};

export const getFavoriteAllVehicles = (payload) => {
  let string = "?ids=";
  for (let i = 0; i < payload.IdArr.length; i++) {
    string += i === 0 ? `${payload.IdArr[i]}` : `%2C${payload.IdArr[i]}`;
  }
  return axios({
    method: "GET",
    url: `/api/Favorites/GetAll${string}`,
    data: null,
    validateStatus: () => true,
  });
};

// Gallery
/**
 * 
 * @param {number} width 
 * @param {number} height 
 * @param {string} ids Separated with comma photo ids
 */
export const getMultiplePhotos = (width, height, ids) => {
  return axios.get(
    `/api/OfferImages/GetMany?attachmentIds=${ids}&width=${width}&height=${height}`
  );
};

export const getSinglePhoto = (width, height, id) => {
  return axios.get(
    `/api/OfferImages/Get?attachmentId=${id}&width=${width}&height=${height}`
  );
};

export const getPhotoByUrl = (width, height, url) => {
  return axios.get(
    `/api/OfferImages/GetManyByUrl?urls=${url}&width=${width}&height=${height}`
  );
};

// Contact Form
export const sendContactForm = (obj) => {
  return axios.post("/api/Contact/SendQuestion", obj);
};

export const sendContactMarketingForm = (obj) => {
  return axios.post("/api/Contact/SendMarketingMail", obj);
};

/**
 * @param {{PhoneNumber: string,  ConversationTopic: string}} obj
 */
export const sendFastCallRequest = (obj) => {
  return axios.post("/api/FastCall/Post", obj);
}

// Account stuff

export const accountLogin = (payload) => {
  return axios.post("/api/Account/Login", payload);
};

export const accountLogout = () => {
  return axios.post("/api/Account/Logout", null);
};

export const changePassword = (obj) => {
  return axios({
    method: "POST",
    url: "/api/Account/ChangePassword",
    data: obj,
    validateStatus: () => true,
  });
};

export const resetPassword = (obj) => {
  return axios({
    method: "POST",
    url: "/api/Account/ResetPassword",
    data: obj,
    validateStatus: () => true,
  });
};

// Registration

export const accountRegister = (payload) => {
  return axios({
    method: "POST",
    url: "/api/Register",
    data: payload,
    validateStatus: () => true,
  });
};

export const formDataRegister = (payload) => {
  return axios.get("/api/Register", null);
};

export const getDataByNIP = (payload) => {
  return axios.get(`/api/Register/Contractor?nip=${payload}`, null);
};

export const activateAccount = (payload) => {
  return axios({
    method: "GET",
    url: `/api/Register/Activate?guid=${payload}`,
    data: null,
    validateStatus: () => true,
  });
};

export const updateStatement = (payload) => {
  return axios({
    method: "PUT",
    url: `/api/Account/UpdateStatement`,
    data: payload,
    validateStatus: () => true,
  });
};

// Logged views
export const getWonAuctions = () => {
  return axios({
    method: "GET",
    url: "/api/WonAuctions/Get",
    data: null,
    validateStatus: () => true,
  });
};
export const getUserTakeingPartAuctions = () => {
  return axios({
    method: "GET",
    url: "/api/UserTakeingPartAuctions/Get",
    data: null,
    validateStatus: () => true,
  });
};
export const getIncommingAuctions = () => {
  return axios({
    method: "GET",
    url: "/api/IncommingAuctions/Get",
    data: null,
    validateStatus: () => true,
  });
};

export const sendBid = (auctionId, price, id) => {
  return axios({
    method: "POST",
    url: "/api/Bidding/Bid?timestamp=" + new Date().getDate(),
    data: {
      AuctionId: auctionId,
      Price: price,
      ConnectionId: id,
    },
    validateStatus: () => true,
  });
};
export const getBiddingHistory = (auctionId) => {
  return axios.get("/api/Bidding/BiddingHistory?auctionId=" + auctionId, null);
};

export const getOstForAuction = (auctionId) => {
  return axios.get("/api/Ost/GetForAuction?auctionId=" + auctionId, null);
};

// Recommended
export const getRecommendedNewVehicles = (auctionId) => {
  return axios.get(`/api/Recommended/NewVehicles?AuctionId=${auctionId}`, null);
};

export const getRecommendedAfterLeaseVehicles = (auctionId) => {
  return axios.get(`/api/Recommended/AfterLease?AuctionId=${auctionId}`, null);
};

export const getRecommendedEndingAuctionsVehicles = (auctionId) => {
  return axios.get(
    `/api/Recommended/EndingAuctions?AuctionId=${auctionId}`,
    null
  );
};

// settings
export const getAccountSettings = () => {
  return axios({
    method: "GET",
    url: `/api/Account`,
    data: null,
    validateStatus: () => true,
  });
};

export const updateAccountContractorSettings = (payload) => {
  return axios.patch(`/api/Account/UpdateContractor`, payload);
};

export const updateAccountSettings = (payload) => {
  return axios.put(`/api/Account/Update`, payload);
};

export const updateMarketingAgreement = () => {
  return axios.put(`/api/Account/UpdateMarketing`);
};

export const updateNotificationsAgreement = () => {
  return axios.put(`/api/Account/UpdateNotifications`);
};

export const acceptNewRules = () => {
  return axios.put(`/api/Account/AcceptNewRules`);
};

// global
export const getSiteRules = () => {
  return axios.get("/api/Regulations");
};

export const getAuctionRules = () => {
  return axios.get("/api/Regulations/auctionRules");
};

export const getNotFound = () => {
  return axios.get("/api/NotFound");
};

export const getMultipleVariantsInfo = () => {
  return axios.get('/api/Offers/NewVehiclesMultipleVariantsInfo');
}

// refresh token manually
export const refreshTokenManually = (redirect) => {
  //find better way of doing this
  return axios
    .post("/api/Account/RefreshManually", {
      ExpiredAccessToken: LocalStorageService.getAccessToken(),
      RefreshToken: LocalStorageService.getRefreshToken(),
    })
    .then((res) => {
      if (res.status === 200) {
        LocalStorageService.setToken(res.data);
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + LocalStorageService.getAccessToken();
        if (redirect) {
          window.location.href = redirect;
        }
        return Promise.resolve(true);
      } else {
        LocalStorageService.clearToken();
      }
      return Promise.reject(false);
    })
    .catch(() => {});
};

//Alert
export const getAlertData = () => {
  return axios.get("/api/CarAlert");
};

export const sendAlertData = (payload) => {
  return axios.post("/api/CarAlert", payload);
};
export const activateAlert = (guid) => {
  return axios.patch(`/api/CarAlert?guid=${guid}`);
};
export const deactivateAlert = (guid) => {
  return axios.delete(`/api/CarAlert?guid=${guid}`);
};
