import React from "react";
import PropTypes from "prop-types";

import CarsSection from "../../modules/CarsSection";
import SectionTitleComponent from "../../modules/SectionTitleComponent";
import { Box, Button, Carousel, Illustration, Media, Text } from "@mbank-design/design-system/components";
import CarOfferTile from "../../modules/CarOfferTile";
import { Link } from "react-router-dom/cjs/react-router-dom";
import SliderSkeleton from "../SliderSkeleton";
import { ButtonType, FontColor } from "@mbank-design/design-system/enums";
import SvgCarHighwayMass from "@mbank-design/design-system/illustrations/CarHighwayMass";
import { CONTAINER_WIDTH } from "../../../constants";
import { ButtonColor } from "@mbank-design/design-system/components/Button/Button.constants";
import SpecificTile, { SpecificTilesEnum } from "../SpecificTile";

const CarsCarousel = (props) => {
  CarsCarousel.propTypes = {
    carsCount: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(["trwa ładowanie"])]),
    carsList: PropTypes.array,
    changeHandler: PropTypes.func,
    buttonText: PropTypes.string,
    buttonLink: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    controller: PropTypes.func,
    carsType: PropTypes.string,
    theme: PropTypes.string
  };

  const {
    carsCount,
    carsList,
    changeHandler,
    buttonText,
    buttonLink,
    sectionTitle,
    sectionDescription,
    controller,
    carsType,
    theme
  } = props;
  
  const isDesktop = Media.useDesktopMedia();

  const getCarouselVisibleSlides = (numberOfCars) => {
    let visibleSlides = 0;
    if (window.innerWidth > 1024) {
      visibleSlides = 3;
      if (numberOfCars === 2) {
        visibleSlides = 2.33;
      }
      if (numberOfCars === 1) {
        visibleSlides = 1.33;
      }
    } else if (window.innerWidth >= 768) {
      visibleSlides = 2.25;
    } else if (window.innerWidth > 410) {
      visibleSlides = 1.15;
    } else if (window.innerWidth >= 375) {
      visibleSlides = 1.1;
    } else {
      visibleSlides = 1;
    }

    return visibleSlides;
  };

  const carouselKeyGen = (id, index) => {
    let keyObj = {};
    if (carsType === "afterLease") {
      return (keyObj = {
        wrapperKey: "AfterLeaseCarTileWrapperKey_" + id + "_" + index,
        tileKey: "AfterLeaseCarTileKey_" + id + "_" + index,
        carouselKey: "AfterLeaseCarsCarouselKey"
      });
    } else if (carsType === "new") {
      return (keyObj = {
        wrapperKey: "NewCarsCarouselKey" + id + "_" + index,
        tileKey: "NewCarTileWrapperKey_" + id + "_" + index,
        carouselKey: "NewCarTileKey_"
      });
    } else if (carsType === "electric") {
      return (keyObj = {
        wrapperKey: "ElectricCarWrapperKey_" + id + "_" + index,
        tileKey: "ElectricCarTileKey_" + id + "_" + index,
        carouselKey: "ElectricCarsCarouselKey"
      });
    } else {
      return (keyObj = {
        wrapperKey: "RecommendedWrapperKey_" + id + "_" + index,
        tileKey: "RecommendedTileKey_" + id + "_" + index,
        carouselKey: "RecommendedCarouselKey"
      });
    }
  };

  return (
    <CarsSection buttonText={buttonText} buttonLink={carsCount ? buttonLink : ""} theme={theme}>
      <SectionTitleComponent title={sectionTitle} description={sectionDescription} />
      <div className="cars-container">
        {carsList && carsList.length > 0 ? (
          <Box maxWidth={[window.innerWidth, CONTAINER_WIDTH]} margin="0 auto" position={"relative"}>
            <Carousel
              key={carouselKeyGen().carouselKey}
              onChange={changeHandler}
              isNavigationShown
              isPaginationDynamic
              spacing={[12, 24]}
              step={1}
              visibleSlides={getCarouselVisibleSlides(carsList.length)}
              controller={controller || null}
            >
              {carsList.slice(0, 14).map((el, index) => (
                <div className={`car-offer-wrapper`} key={carouselKeyGen(el.AuctionId, index).wrapperKey}>
                  <CarOfferTile key={carouselKeyGen(el.AuctionId, index).tileKey} isCompany={true} data={el} backgroundTheme={props.theme} />
                </div>
              ))}
              {carsType && (
                <SpecificTile tileType={SpecificTilesEnum.NoFindTile} />
              )}
            </Carousel>
          </Box>
        ) : carsCount !== 0 ? (
          <SliderSkeleton />
        ) : (
          <Box margin={16} display={"flex"} justifyContent={"center"}>
            <Text styleAs={"h3"}>obecnie nie ma pojazdów</Text>
          </Box>
        )}
      </div>
    </CarsSection>
  );
};

export default CarsCarousel;
