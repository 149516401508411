import * as moment from 'moment';


export function clearCampaignData() {
  localStorage.removeItem('utm_source')
  localStorage.removeItem('utm_medium')
  localStorage.removeItem('utm_campaign')
  localStorage.removeItem('utm_content')
  localStorage.removeItem('dclid')
  localStorage.removeItem('mAutoCampaign')
  localStorage.removeItem('mAutoGCLID')
  localStorage.removeItem('mAutoclick_id')
}

export function setCampaignData() {
  let urlParams =
    window.location.search[0] === "?"
      ? window.location.search.slice(1)
      : window.location.search;
  if (urlParams) {
    let parsedParams = new URLSearchParams(urlParams);
    let tempObj = {};
    for (let obj of parsedParams) {
      tempObj[obj[0]] = obj[1];
    }


    if (tempObj.utm_source) {
      localStorage.setItem('utm_source', tempObj.utm_source)
    }
    if (tempObj.utm_medium) {
      localStorage.setItem('utm_medium', tempObj.utm_medium)
    }
    if (tempObj.utm_campaign) {
      localStorage.setItem('utm_campaign', tempObj.utm_campaign)
    }
    if (tempObj.utm_content) {
      localStorage.setItem('utm_content', tempObj.utm_content)
    }
    if (tempObj.dclid) {
      localStorage.setItem('dclid', tempObj.dclid)
    }
    if (tempObj.kampania) {
      localStorage.setItem('mAutoCampaign', tempObj.kampania)
    }
    if (tempObj.gclid) {
      localStorage.setItem('mAutoGCLID', tempObj.gclid)
    }
    if (tempObj.click_id) {
      localStorage.setItem('mAutoclick_id', tempObj.click_id)
    }
      if (
        tempObj.utm_source ||
        tempObj.utm_medium ||
        tempObj.utm_campaign ||
        tempObj.utm_content ||
        tempObj.dclid||
        tempObj.kampania ||
        tempObj.gclid ||
        tempObj.click_id
      ) {
        localStorage.setItem("mAutoCampaignCreationDate", moment().toISOString());
      }
  }
}

export function setCampaignFormSentData() {
  if (localStorage.getItem('mAutoCampaign')) {
    localStorage.setItem('mAutoFormDate', moment().toISOString());
  }
}

export function setCampaignFormSentAuctionId(auctionId) {
  if (localStorage.getItem('mAutoCampaign')) {
    localStorage.setItem('mAutoFormAuctionId', auctionId || '');
  }
}

export function checkCampaignExpirationDate() {
  let isoString = localStorage.getItem('mAutoCampaignCreationDate');
  if (isoString) {
    let parsedData = moment(isoString);
    if (parsedData.add(2, 'hours').isBefore(moment())) {
      clearCampaignData();
    }
  } else {
    clearCampaignData();
  }
}

export function deleteCampaignData() {
  if (localStorage.getItem("mAutoCampaign")) {
    localStorage.removeItem("mAutoCampaign");
  }

  if (localStorage.getItem("mAutoDCLID")) {
    localStorage.removeItem("mAutoDCLID");
  }

  if (localStorage.getItem("mAutoGCLID")) {
    localStorage.removeItem("mAutoGCLID");
  }

  if (localStorage.getItem("mAutoclick_id")) {
    localStorage.removeItem("mAutoclick_id");
  }

  if (localStorage.getItem("mAutoFormAuctionId")) {
    localStorage.removeItem("mAutoFormAuctionId");
  }

  if (localStorage.getItem("mAutoFormDate")) {
    localStorage.removeItem("mAutoFormDate");
  }

  if (localStorage.getItem("mAutoCampaignCreationDate")) {
    localStorage.removeItem("mAutoCampaignCreationDate");
  }
}

export function setVehicleOfferFormInsightTag() {
  window.lintrk("track", { conversion_id: 13983618 });
}

export function setVehicleOfferButtonInsightTag() {
  window.lintrk("track", { conversion_id: 13983602 });
}

export function setVehicleViewContentInsightTag() {
  window.lintrk("track", { conversion_id: 13983610 });
}