// import { refreshAuctionHistoryData } from "../../services/other";

export const utf_to_b64 = (obj) => {
  return window.btoa(encodeURIComponent(escape(obj)));
};

export const b64_to_utf = (str) => {
  return unescape(decodeURIComponent(window.atob(str)));
};

export const addAdvert = (arr, advertIndex, advertName) => {
  return [
    ...arr.slice(0, advertIndex),
    ...[{ isAdvert: true, advertName: advertName }],
    ...(arr[advertIndex] ? arr.slice(advertIndex) : []),
  ];
};

export const moveElInArray = (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
};

export const addCarToViewedHistory = (car) => {
  let historyArrJSON = localStorage.getItem("viewedCarObjectsArray");
  let historyArr = !historyArrJSON ? [] : JSON.parse(historyArrJSON);
  let index = historyArr.findIndex((x) => x.AuctionId === car.AuctionId);
  if (index !== -1) {
    historyArr = moveElInArray(historyArr, index, 0);
  } else {
    if (historyArr.length >= 10) {
      historyArr.splice(-1, 1);
    }

    historyArr.unshift(car);
  }
  localStorage.removeItem("viewedCarObjectsArray");
  localStorage.setItem("viewedCarObjectsArray", JSON.stringify(historyArr));
};

// export const refreshViewedHistory = () => {
//   let historyArrJson = localStorage.getItem("viewedCarObjectsArray");
//   let historyArr = [];
//   if (historyArrJson) {
//     historyArr = JSON.parse(historyArrJson);
//     let stringId = "";

//     for (let [index, item] of historyArr.entries()) {
//       stringId +=
//         index === 0 ? `?auctionIds=${item.AuctionId}` : `,${item.AuctionId}`;
//     }
//     stringId.replace("?auctionIds=undefined", "").replace("%2Cundefined", "");
//     if (stringId) {
//       refreshAuctionHistoryData(stringId).then(res => {
//         for (let [index, item] of historyArr.entries()) {
//           let elIndex = res.data.findIndex(x => x.AuctionId === item.AuctionId);
//           let historyArrElIndex = historyArr.findIndex(
//             x => x.AuctionId === item.AuctionId
//           );
//           if (elIndex !== -1) {
//             historyArr[historyArrElIndex] = {
//               ...historyArr[historyArrElIndex],
//               DiscountInstallmentPrice:
//                 res.data[elIndex].DiscountInstallmentPrice,
//               InstallmentPriceNetto: res.data[elIndex].InstallmentPriceNetto,
//               TotalPriceBrutto: res.data[elIndex].TotalPriceBrutto
//             };
//           } else {
//             historyArr = [
//               ...historyArr.slice(0, index),
//               ...historyArr.slice(index + 1)
//             ];
//           }
//         }
//         localStorage.setItem(
//           "viewedCarObjectsArray",
//           JSON.stringify(historyArr)
//         );
//       });
//     }
//   }
// };

export const compareArrays = (arr1, arr2) => {
  return arr1.sort().toString() === arr2.sort().toString();
};

export const numberWithSpaces = (x) => {
  if (!x) return "0";
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const isOdd = (num) => {
  return num % 2;
};

export const cutIdFromMathParams = (string) => {
  if (string.includes("-id-")) {
    return parseInt(string.substring(string.lastIndexOf("-id-") + 4));
  } else if (string.includes("_id_")) {
    return parseInt(string.substring(string.lastIndexOf("_id_") + 4));
  } else if (string.includes("--")) {
    let splittedString = string.toLowerCase().split("--");
    return (
      splittedString[0].replace(/-/, "") +
      "--" +
      splittedString[1].split(" ").join("").split(".").join("").toLowerCase()
    );
  } else {
    return string;
  }
};

export const replaceSpecialSignsByDash = (string) => {
  return (
    string
      .substring(0, string.lastIndexOf("-id-"))
      .replace(" ", "-")
      .replace("_", "")
      .replace(/\W+(?!$)/g, "-")
      .toLowerCase() + string.substring(string.lastIndexOf("-id-"))
  );
};

export const countCharacters = (char, string) => {
  return string.split("").reduce((acc, ch) => (ch === char ? acc + 1 : acc), 0);
};

export const changeDocumentBodyOverflow = (hideOverflow) => {
  let root = document.getElementById("root");
  
  if (root) {
    root.style.overflowY = hideOverflow ? "hidden" : "unset";
  }
}

export const handleClickCalc = () => {
  if (document.querySelector(".floating-wrapper")) {
    document.querySelector(".floating-wrapper").style.display = "block";
    changeDocumentBodyOverflow(true)
  }
  if (document.querySelector(".reservation-info-container")) {
    document.querySelector(".reservation-info-container").style.dispaly =
      "none";
  }

  if (document.querySelector(".calculator-container")) {
    document.querySelector(".calculator-container").style.display = "block";
  }
}

export const dataLayerObj = (leadId, formType) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "lead_complete",
    lead_id:leadId,
    form_type: formType
  });
  sessionStorage.setItem("leadId", leadId);
};

export const replaceSpecialCharsets = (string) => {
  if (!string) return ""

  return string.replace(/&amp;/g, "&");
};

export const sortByName = ( firstElem, secondElem ) => {
  if ( firstElem.Name < secondElem.Name ){
    return -1;
  }
  if ( firstElem.Name > secondElem.Name ){
    return 1;
  }
  return 0;
};

export const prettyNumber = (value) => {
  if (!value) return "";

  let number = value.toString();

  return number.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export const imitationClickForCloseAllTooltips = () => {
  const tooltips = document.querySelectorAll('[data-component="Grow"]');
  
  if (tooltips && !!tooltips.length) {
    document.body.click();
  }
}

export const removeDuplicatesByKeyFromArrayOfObject = (array, key) => {
  const uniqueValues = {};
  return array.filter((obj) => {
    if (!uniqueValues[obj[key]]) {
      uniqueValues[obj[key]] = true;
      return true;
    }
    return false;
  });
};

export function getTabNamesByFilterValues(key, value, offersFiltersDataFromApi) {
  let tabs = [];

  if (Array.isArray(value)) {
    if (value.length) {
      if (key === "bodyType") {
        tabs.push(
          ...value.map((elem) => {
            return offersFiltersDataFromApi.BodyTypes.find((el) => el.Value === elem)?.Name.toLowerCase();
          })
        );
      } else if (key === "fuelType") {
        tabs.push(
          ...value.map((elem) => {
            return offersFiltersDataFromApi.FuelTypes.find((el) => el.Value === elem)?.Name.toLowerCase();
          })
        );
      } else if (key === "gearBoxType") {
        tabs.push(
          ...value.map((elem) => {
            return offersFiltersDataFromApi.GearBoxTypes.find((el) => el.Value === elem)?.Name.toLowerCase();
          })
        );
      } else if (key === "status") {
        tabs.push(
          ...value.map((elem) => {
            return offersFiltersDataFromApi.Statuses.find((el) => el.Value === elem)?.Name.toLowerCase();
          })
        );
      } else if (key === "doorsCount") {
        tabs.push(
          ...value.map((elem) => {
            return `${offersFiltersDataFromApi.DoorsCount.find(
              (el) => el.Value.toString() === elem
            )?.Name.toLowerCase()} drzwi`;
          })
        );
      } else if (key === "location") {
        tabs.push(
          ...value.map((elem) => {
            return offersFiltersDataFromApi.Locations.find((el) => el.Value === elem)?.Name.toLowerCase();
          })
        );
      } else if (key === "seatsCount") {
        tabs.push(
          ...value.map((elem) => {
            return `${offersFiltersDataFromApi.SeatsCount.find(
              (el) => el.Value.toString() === elem
            )?.Name.toLowerCase()} miejsc`;
          })
        );
      } else {
        tabs.push(...value);
      }
    }
  } else {
    if (value) {
      if (key === "capacityFrom") {
        tabs.push(`pojemność od ${prettyNumber(value)}cm3`);
      } else if (key === "capacityTo") {
        tabs.push(`pojemność do ${prettyNumber(value)}cm3`);
      } else if (key === "installmentFrom") {
        tabs.push(`rata od ${prettyNumber(value)}pln`);
      } else if (key === "installmentTo") {
        tabs.push(`rata do ${prettyNumber(value)}pln`);
      } else if (key === "milleageFrom") {
        tabs.push(`od ${prettyNumber(value)}km`);
      } else if (key === "milleageTo") {
        tabs.push(`do ${prettyNumber(value)}km`);
      } else if (key === "powerFrom") {
        tabs.push(`moc od ${prettyNumber(value)}KM`);
      } else if (key === "powerTo") {
        tabs.push(`moc do ${prettyNumber(value)}KM`);
      } else if (key === "priceFrom") {
        tabs.push(`cena od ${prettyNumber(value)}pln`);
      } else if (key === "priceTo") {
        tabs.push(`cena do ${prettyNumber(value)}pln`);
      } else if (key === "productionFrom") {
        tabs.push(`produkcja od ${prettyNumber(value)}`);
      } else if (key === "productionTo") {
        tabs.push(`produkcja do ${prettyNumber(value)}`);
      } else {
        tabs.push(value);
      }
    }
  }

  return tabs;
}

export function getUrlBasedOnFilters(object, isCompany = true, financialMethod = "all", selectedSortingValue = "0") {
  const _search = getSearchBasedOnFilters(object);
  let search = "?" + _search;

  if (_search) {
    search += "&";
  }

  search += `isCompany=${isCompany}&financialMethod=${financialMethod}&selectedSortingValue=${selectedSortingValue}`;

  return {
    search: search,
    pathname: "/lista-pojazdow" + search
  };
}

function getSearchBasedOnFilters(object) {
  return Object.keys(object)
    .map((key, index) => {
      if (object[key].length) {
        return key + "=" + object[key] + "&";
      }
    })
    .join("")
    .slice(0, -1);
}