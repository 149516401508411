import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Autocomplete, Icon, Stripe, Text } from "@mbank-design/design-system/components";

import "./index.scss";
import { Mauto40StripeMass } from "@mbank-design/design-system/logos";
import { Clock, Cross, Star } from "@mbank-design/design-system/icons";
import { IconColor } from "@mbank-design/design-system/enums";
import { ContactCard } from "../../parts/ContactCard";
import SearchInput from "../SearchInput";

export const handleAddSearchToLocalStorage = (selectedModels, url) => {
  let newSearch = { name: selectedModels, url };
  let rawPrevSearchMenuAskList = localStorage.getItem("searchMenuAsk");
  if (rawPrevSearchMenuAskList === null) {
    localStorage.setItem("searchMenuAsk", JSON.stringify([newSearch]));
  } else {
    let prevSearchMenuAskList = JSON.parse(rawPrevSearchMenuAskList);
    if (prevSearchMenuAskList.length === 15) {
      prevSearchMenuAskList.shift();
    }
    prevSearchMenuAskList.push(newSearch);
    localStorage.setItem("searchMenuAsk", JSON.stringify(prevSearchMenuAskList));
  }
};

const defaultViewCount = 3;

const SearchMenu = (props) => {
  const [cars, setCars] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState("");
  const [viewHistory, setViewHistory] = useState([]);
  const [isHistoryExpanded, setIsHistoryExpanded] = useState(false);
  const [selectedModels, setSelectedModels] = useState([]);
  const [selectedMakes, setSelectedMakes] = useState([]);
  const [isCompany, setIsCompany] = useState(true);

  useEffect(() => {
    let sortedMakesAndModels = [];
    props.availableMakesAndModels.forEach((el) => {
      sortedMakesAndModels.push([el.Make.Value, el.Models.map((el) => el.Value)]);
    });
    setCars(new Map(sortedMakesAndModels));
    const savedHistory = JSON.parse(localStorage.getItem("searchMenuAsk"));

    savedHistory && setViewHistory(savedHistory.toReversed());
  }, []);

  useEffect(() => {
    let selectedMakesArray = [];
    props.availableMakesAndModels.forEach((el) => {
      el.Models.forEach((model) => {
        if (selectedModels.includes(model.Value)) {
          selectedMakesArray.push(el.Make.Value);
        }
      });
      setSelectedMakes([...new Set(selectedMakesArray)]);
    });
  }, [selectedModels]);

  const findMatchingLengths = (makeVal, reference) => {
    const correspondingMakeCars = props.availableMakesAndModels.find((item) => item.Make.Value === makeVal);
    return correspondingMakeCars.Models.length === reference.length;
  };

  const handleSubmit = (mySelectedOptions) => {
    let isCompany = true;
    let url = "";
    let makes = [];

    mySelectedOptions.forEach((make) => {
      if (make.Models.length) {
        makes.push(make.Make.Value);
      }
    });

    let models = [];
    mySelectedOptions.forEach((make) => make.Models.forEach((model) => models.push(model.Value)));

    if (!makes.length && !models.length) {
      return;
    }

    if (makes.length === 1 && findMatchingLengths(makes[0], models)) {
      url = `/marki/${makes[0].toLowerCase()}`;
    } else {
      url = `/lista-pojazdow?${makes.length ? `makes=${makes}&` : ""}${
        models.length ? `models=${models}&` : ""
      }isCompany=${isCompany}&financialMethod=${isCompany ? "all" : "cash"}&selectedSortingValue=0`;
    }

    if (models.length) {
      handleAddSearchToLocalStorage(models, url);
    }

    setTimeout(() => {
      props.history.push(url);
      props.toggleSearchMenuState();
    }, 0);
  };

  const handleValueChange = (value) => {
    setSelectedModels(value);
    localStorage.setItem("tempVehicle", JSON.stringify(value));
  };

  const recomendedSearch = [
    { name: "Nowe samochody", url: "/nowe-samochody" },
    { name: "Poleasingowe samochody", url: "/samochody-poleasingowe" },
    { name: "Elektryczne samochody", url: "/samochody-elektryczne" }
  ];

  return (
    <>
      <div className="search-menu__shadow" onClick={props.toggleSearchMenuState}></div>
      <div className="search-menu">
        <Stripe />
        <div className={"search-menu__container"}>
          <div className="search-menu__left-wing">
            <Mauto40StripeMass onClick={() => props.toggleSearchMenuState()} />
          </div>
          <div className="search-menu__content">
            <div className="search-menu__top">
              <SearchInput
                label={props.literals && props.literals.hp_srch_lbl}
                options={props.availableMakesAndModels}
                onSubmit={handleValueChange}
                handleSubmit={handleSubmit}
              />
              <div className="close-btn" onClick={() => props.toggleSearchMenuState()}>
                <Icon iconComponent={Cross} primaryColor={IconColor.SECONDARY_CONTRAST} />
              </div>
            </div>
            <div className="search-menu__bottom">
              <div className="tabs">
                <div>
                  <div className="tab">
                    <div className="tab__label">
                      <Text styledAs={"h3"}>historia</Text>
                      {viewHistory.length > defaultViewCount && (
                        <span
                          onClick={() => setIsHistoryExpanded(!isHistoryExpanded)}
                          className="tab__label--blue"
                          role="button"
                          tabIndex={0}
                        >
                          <Text styledAs={"h4"}>{!isHistoryExpanded ? "wszystkie" : "zwiń"}</Text>
                        </span>
                      )}
                    </div>
                    {viewHistory && viewHistory.length !== 0 ? (
                      viewHistory.map((historyElement, idx) => {
                        if (!isHistoryExpanded && idx >= defaultViewCount) {
                          return;
                        }
                        return (
                          <li key={idx} className="tab__item">
                            <Icon iconComponent={Clock} />
                            <a href={historyElement.url}>
                              <Text>{historyElement.name.toString().replaceAll(",", ", ")}</Text>
                            </a>
                          </li>
                        );
                      })
                    ) : (
                      <p>Brak pojazdów w historii</p>
                    )}
                  </div>
                  <div className="recommended">
                    <div className="tab">
                      <div className="tab__label">
                        <Text styledAs={"h3"}>polecane</Text>
                      </div>
                      {recomendedSearch.map((search, idx) => {
                        return (
                          <li key={idx} className="tab__item">
                            <Icon iconComponent={Star} />
                            <a href={search.url}>
                              <Text>{search.name}</Text>
                            </a>
                          </li>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <ContactCard toggleSearchMenuState={props.toggleSearchMenuState} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(SearchMenu);
