import React from "react";
import PropTypes from "prop-types";
import "./index.scss";
import {Icon, Text, Amount} from "@mbank-design/design-system/components";
import {FontColor, FontSize, IconColor} from "@mbank-design/design-system/enums";

const CarDetailsInformationTile = (props) => {
  CarDetailsInformationTile.propTypes = {
    icon: PropTypes.func.isRequired,
    text: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    format: PropTypes.string,
    darkMode: PropTypes.bool
  };
  let iconColor = IconColor.DARK;
  return (
    <div className="cdit-container">
      <Icon size={16}
            primaryColor={iconColor}
            iconComponent={props.icon}/>
      {props.format === "mileage" && (
        <Text color={FontColor.MONO_600} 
              as="p"
              styledAs={"small"}>
          <Amount isFractionPartShown={false}
                  type="decimal"
                  value={props.text}/> km</Text>
      )}
      {!props.format && (
        <Text color={FontColor.MONO_600} 
              as="p"
              styledAs={"small"}>{props.text}</Text>
      )}
    </div>
  );
};

export default CarDetailsInformationTile;
