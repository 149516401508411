import React from "react";
import { Box, Media } from "@mbank-design/design-system/components";
import { Skeleton } from "@mbank-design/design-system/components/Skeleton/Skeleton";
import { SkeletonVariant } from "@mbank-design/design-system/components/Skeleton/Skeleton.constants";
import { CONTAINER_WIDTH } from "../../../constants";

const SliderSkeleton = () => {
  const isMobile = Media.useMobileOnlyMedia();
  
  if (isMobile) {
    return (
      <Box>
        <Box maxWidth={["100%", 1300]} margin="0 auto" display={"flex"}>
          <Box maxWidth={1023}>
            <Skeleton variant={SkeletonVariant.RECTANGULAR} height={400} width={1023} />
          </Box>
        </Box>
        <Box marginTop={6} display={"flex"} justifyContent={"center"}>
          <Skeleton variant={SkeletonVariant.RECTANGULAR} height={60} width={250}/>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box maxWidth={["100%", CONTAINER_WIDTH]} margin="0 auto" display={"flex"}>
        <Box maxWidth={420} padding={12}>
          <Skeleton variant={SkeletonVariant.RECTANGULAR} height={560} width={400} />
        </Box>
        <Box maxWidth={420} padding={12}>
          <Skeleton variant={SkeletonVariant.RECTANGULAR} height={560} width={400} />
        </Box>
        <Box maxWidth={420} padding={12}>
          <Skeleton variant={SkeletonVariant.RECTANGULAR} height={560} width={400} />
        </Box>
      </Box>
      <Box paddingTop={14} display={"flex"} justifyContent={"center"}>
        <Skeleton variant={SkeletonVariant.RECTANGULAR} height={44} width={250} />
      </Box>
    </Box>
  );
};

export default SliderSkeleton;
