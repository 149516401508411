import React from "react";
import "./index.scss";
import { Icon, Text } from "@mbank-design/design-system/components";
import { ArrowLeft, Cross } from "@mbank-design/design-system/icons";
import { IconSize, IconColor } from "@mbank-design/design-system/enums";
import { withRouter } from "react-router-dom";

const CalculatorMobileBaner = (props) => {

  const changeDocumentBodyOverflow = (hideOverflow) => {
    let root = document.getElementById('root');
    if(root) {
      root.style.overflowY = hideOverflow ? "hidden" : "unset";
    }
  }
  
  const hideCalculator = () => {
    if (document.querySelector(".floating-wrapper")) {
      document.querySelector(".floating-wrapper").style.display = "none";
      changeDocumentBodyOverflow(false);
    }
  };
  return (
    <div className={`mobile-list-baner-navbar ${props.classes}`}>
      <div
        className="nav_bar-mobile-button-container"
        onClick={() => hideCalculator()}
      >
        <Icon
          iconComponent={ArrowLeft}
          size={IconSize.NORMAL}
          primaryColor={IconColor.SECONDARY_CONTRAST}
        ></Icon>
        <Text as={"p"} styledAs={"small"}>powrót</Text>
      </div>
    </div>
  );
};

export default withRouter(CalculatorMobileBaner);
