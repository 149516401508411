import React from "react";
import PropTypes from "prop-types";

const Paper = (props) => {
  Paper.propTypes = {
    className: PropTypes.string,
  };

  return <div className={`paper ${props.className}`}>{props.children}</div>;
};

export default Paper;
