import { Icon } from "@mbank-design/design-system/components";
import { IconColor } from "@mbank-design/design-system/enums";
import {
  PhoneHandleRinging,
  Bell
} from "@mbank-design/design-system/icons";
import React from "react";
import "./index.scss";


const FastButtons = (props) => {
  const handleClickAlert = () => {
    window.location.href = "/alert";
  };

  return (
    <div className={`fast-buttons`}>
      <a 
        id="fastCallFloatingButton"
        href="#cp-widget"
        style={{ backgroundColor: "#ae0000" }}
        className="fast-button red"
      >
        <Icon primaryColor={IconColor.LIGHT} iconComponent={PhoneHandleRinging} />
      </a>
      <div
        id="setAlertFloatingButton"
        onClick={handleClickAlert}
        style={{ backgroundColor: "#0065b1" }}
        className="fast-button blue"
      >
        <Icon primaryColor={IconColor.LIGHT} iconComponent={Bell} />
      </div>
    </div>
  );
};

export default FastButtons;
