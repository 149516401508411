import React, { useEffect, useState } from "react";

const ImgWithFallback = ({
  src,
  fallback,
  type = "image/webp",
  generateExtensions = false,
  isWebP = false,
  className,
  ...delegated
}) => {
  const [errored, setErrored] = useState(false);
  const [srcUpdated, setSrcUpdated] = useState(false);
  const [srcSet, setSrcSet] = useState(src);
  const [sourceType, setSourceType] = useState(type);
  const [fallbackImg, setFallbackImg] = useState(fallback);

  useEffect(() => {
    if (generateExtensions) {
      if (isWebP) {
        setSrcSet(src + ".webp");
        setFallbackImg(fallback + ".jpg");
      } else {
        const isPNG = fallback.includes(".png");
        const isJPG = fallback.includes(".jpg");

        if (isPNG || isJPG) {
          setSrcSet(src);

          if (isPNG) {
            setSourceType("image/png");
          }

          if (isJPG) {
            setSourceType("image/jpg");
          }
        } else {
          setSrcSet(src + ".jpg");
          setSourceType("image/jpg");
          setFallbackImg(fallback + ".jpg");
        }
      }
    }
    setSrcUpdated(true)
  }, [src, fallback, generateExtensions, isWebP]);

  const onError = () => {
    if (!errored) {
      setErrored(true);
      setSrcSet(fallbackImg);
    }
  };
  if(!srcUpdated){
    return;
  }
  return (
    <picture>
      <source srcSet={srcSet} type={sourceType} />
      <img
        className={className}
        src={fallbackImg}
        {...delegated}
        loading={"lazy"}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          onError();
        }}
      />
    </picture>
  );
};

export default ImgWithFallback;
