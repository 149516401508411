import React, { Component } from "react";
import PropTypes from "prop-types";
import "./index.scss";
import {
  Button,
} from "@mbank-design/design-system/components";
import { Link } from "react-router-dom";

class CarsSection extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    buttonText: PropTypes.string,
    buttonLink: PropTypes.string,
    pages: PropTypes.number,
    theme: PropTypes.string,
  };

  state = {
    slide: 1,
  };

  setActiveSlide(slide) {
    this.setState({ slide: slide }, () => {
      this.props.handleChangePage(slide);
    });
  }

  setNextSlide() {
    if (this.state.slide < 4 && this.state.slide < this.props.pages) {
      this.setState({ slide: this.state.slide + 1 }, () =>
        this.props.handleChangePage(this.state.slide)
      );
    }
  }

  setPreviousSlide() {
    if (this.state.slide > 1) {
      this.setState({ slide: this.state.slide - 1 }, () =>
        this.props.handleChangePage(this.state.slide)
      );
    }
  }

  render() {
    return (
      <div
        className={`cs-container ${
          this.props.theme === "white" ? "cs-container-white" : "cs-container-wild-sand-grey"
        }`}
      >
        {this.props.children}
        {this.props.buttonLink && (
          <Link to={this.props.buttonLink} className="cars-section-button">
            <Button>{this.props.buttonText}</Button>
          </Link>
        )}
      </div>
    );
  }
}

export default CarsSection;
