import React from "react";

import { Download, TickRound } from "@mbank-design/design-system/icons";
import { IconColor } from "@mbank-design/design-system/enums";
import { Icon, Link, Stack, Text } from "@mbank-design/design-system/components";

import "./index.scss";

export const CertificateSummary = (props) => {
  return (
    <div className="certificate-summary-container">
      <Stack spacing={16}>
        <Icon iconComponent={TickRound} width={48} primaryColor={IconColor.GREEN} size={48} />
        <Text as="h2">udało się, otrzymujesz dostęp do certyfikatu</Text>
        <Text>
          dostęp do niego został przesłany, na podany przez Ciebie w formularzu adres email. Sprawdź swoją
          pocztę. Jeśli wiadomość nie dotarła, zajrzyj również do folderu “spam”.
        </Text>
      </Stack>
    </div>
  );
};

export default CertificateSummary;
