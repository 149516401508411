import "./index.scss";

import React from "react";
import { Link } from "react-scroll";
import PropTypes from "prop-types";
import { Media } from "@mbank-design/design-system/components";

function DetailsNavigation(props) {
  const isMobile = Media.useMobileOnlyMedia();
  const navigationLinkList = [
    {
      id: "technicalInformation",
      title: "dane techniczne",
      offset: isMobile ? -120 : -80
    },
    {
      id: "standardEquipment",
      title: props.isNewVehicle ? "wyposażenie standardowe" : "wyposażenie",
      offset: isMobile ? -100 : props.isNewVehicle ? -60 : -50
    },
    {
      id: "additionalEquipment",
      title: "wyposażenie dodatkowe",
      offset: isMobile ? -100 : -60,
      hidden: !props.isNewVehicle || !props.additionalEquipment
    },
    {
      id: "certifiedDetailsContainer",
      title: "ocena stanu technicznego",
      offset: isMobile ? -100 : -50,
      hidden: !props.isCertified
    },
    {
      id: "additionalServices",
      title: "usługi dodatkowe",
      offset: isMobile ? -100 : -50
    }
  ];

  return (
    <div className={`mleasing-main-container without-max-width bg-white details-navigation`}>
      <div className="content-wrapper">
        <div className="details-list">
          {navigationLinkList.map((nav, key) => {
            if (nav.hidden) {
              return;
            }

            return (
              <Link
                key={key}
                to={nav.id}
                activeClass="active"
                spy={true}
                smooth={true}
                offset={nav.offset}
                duration={500}
              >
                {nav.title}
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}

DetailsNavigation.propTypes = {
  isNewVehicle: PropTypes.bool.isRequired,
  isCertified: PropTypes.bool.isRequired,
  additionalEquipment: PropTypes.bool
};

export default DetailsNavigation;
