import React from "react";

export const MobileFiltersContext = React.createContext({
  isMobileFiltersOpen: false,
  toggleMobileFilters: () => {},
});

export const ToastContext = React.createContext({
  toatsState: {},
  toggleToast: () => {},
  changeToastState: () => {},
  returnToastState: () => {},
});

export const AuctionWebSocketContext = React.createContext({
  auctionWebSockets: [],
  webSocketInit: null,

});

export const MobileSearchContext = React.createContext({
  isMobileSearchOpen: false,
  toggleMobileSearch: () => {},
});

export const FavouriteCarsContext = React.createContext({
  favourireCarsList: [],
  updateFavouriteCarsList: () => {},
});