import { Text } from "@mbank-design/design-system/components";
import awardPng from "./img/award.png";
import "./index.scss"

const AwardBanner = (props) => {
  return (
    <div className={`award-section ${props.whitebg ? "whitebg" : ""}`}>
      <div className="award-section__container">
        <div className="award-section__typo">
          <Text as={"h2"} styledAs={"h1"}>
            mAuto nagrodzone w 13. Fleet Derby 2024 w kategorii <q><span className="highlighted">Usługa finansowa dla MŚP</span></q>
          </Text>
          <Text as={"body"}>
            Głosy Internautów potwierdziły, iż jesteśmy sprawdzoną marką, którą na rynku wyróżniają sprawne procedury i
            doświadczeni doradcy.
            <br />
            <br />
            Dziękujemy za zaufanie!
          </Text>
        </div>
        <div className="award-section__img"><img src={awardPng} alt="award"/></div>
      </div>
    </div>
  );
};

export default AwardBanner;
