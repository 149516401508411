const sortByFromMin = (array, sortByParametr) => {
  return array.sort((a, b) => {
    if (
      !a["FinalPriceBrutto"] &&
      sortByParametr === "FinalPriceBrutto" &&
      a.Status === "Nowy"
    ) {
      a.FinalPriceBrutto = 9999999999;
      if (b.Status === "Nowy") {
        b.FinalPriceBrutto = 9999999999;
      }
    }
    if (!a["Mileage"] && sortByParametr === "Mileage" && a.Status === "Nowy") {
      a.Mileage = 0;
      if (b.Status === "Nowy") {
        b.Mileage = 0;
      }
    }
    if (
      !a["FinalPriceBrutto"] &&
      sortByParametr === "FinalPriceBrutto" &&
      a.Status === "Aukcje"
    ) {
      a.FinalPriceBrutto = a.CurrentPrice;
      if (b.Status === "Aukcje") {
        b.FinalPriceBrutto = b.CurrentPrice;
      }
    }

    return a[sortByParametr] > b[sortByParametr]
      ? 1
      : a[sortByParametr] < b[sortByParametr]
        ? -1
        : 0;
  });
};
const sortByFromMax = (array, sortByParametr) => {
  return array.sort((a, b) => {
    if (
      !a["FinalPriceBrutto"] &&
      sortByParametr === "FinalPriceBrutto" &&
      a.Status === "Nowy"
    ) {
      a.FinalPriceBrutto = 9999999999;
      if (b.Status === "Nowy") {
        b.FinalPriceBrutto = 9999999999;
      }
    }
    if (!a["Mileage"] && sortByParametr === "Mileage" && a.Status === "Nowy") {
      a.Mileage = 0;
      if (b.Status === "Nowy") {
        b.Mileage = 0;
      }
    }
    if (
      !a["FinalPriceBrutto"] &&
      sortByParametr === "FinalPriceBrutto" &&
      a.Status === "Aukcje"
    ) {
      a.FinalPriceBrutto = a.CurrentPrice;
      if (b.Status === "Aukcje") {
        b.FinalPriceBrutto = b.CurrentPrice;
      }
    }

    return a[sortByParametr] < b[sortByParametr]
      ? 1
      : a[sortByParametr] > b[sortByParametr]
        ? -1
        : 0;
  });
};
export const changeSelectSortBy = (value, carsArray) => {
  let carsArraySorted = [...carsArray];
  switch (parseInt(value)) {
    case 1:
      carsArraySorted = sortByFromMin(carsArray, "FinalPriceBrutto");

      break;
    case 2:
      carsArraySorted = sortByFromMax(carsArray, "FinalPriceBrutto");

      break;
    case 3:
      carsArraySorted = sortByFromMin(carsArray, "Mileage");

      break;
    case 4:
      carsArraySorted = sortByFromMax(carsArray, "Mileage");

      break;
    case 5:
      carsArraySorted = sortByFromMin(carsArray, "Year");

      break;
    case 6:
      carsArraySorted = sortByFromMax(carsArray, "Year");

      break;
  }
  return [...carsArraySorted];
};
