// key: make_model
const seoModels = {
  "volvo_xc 60": `<h2>Nowe modele Volvo XC 60</h2>
    <p>
    Nowoczesne i luksusowe Volvo XC 60 w ofercie mAuto. Modele z rocznika 2023 dostępne w
    różnych wariantach finansowania – leasingu i najmie długoterminowym. Wybierz spośród kilku
    wersji wyposażenia i ciesz się wygodą i prestiżem z segmentu premium. Postaw na sprawdzoną markę, która sprosta codziennym wyzwaniom stawianym przed
    autami flotowymi.
    </p>
    <p class="seo-section__header">Volvo XC 60 – dane techniczne</p>
    <p>
    Fabrycznie nowe Volvo XC 60 to dobry wybór, szczególnie jeśli cenisz sobie praktyczne
    rozwiązania połączone z innowacjami technologicznymi. Najważniejsze parametry techniczne
    wyróżniające model Volvo XC 60 to:
    </p>
    <ul>
    <li>wydajny silnik o pojemności dwóch litrów,</li>
    <li>zaawansowany system oczyszczania powietrza z jonizacją wewnątrz pojazdu,</li>
    <li>bezkluczykowy system odpalania samochodu z bezdotykową obsługą bagażnika,</li>
    <li>asystent podjazdu,</li>
    <li>
        system WHIPS, który zmniejsza ryzyko urazu kręgosłupa szyjnego podczas kolizji lub
        wypadku,
    </li>
    <li>reflektory LED,</li>
    <li>układ monitorowania pasa ruchu,</li>
    <li>kolanowe poduszki powietrzne,</li>
    <li>zintegrowane spryskiwacze szyb z piórami wycieraczek,</li>
    <li>wielofunkcyjne radio,</li>
    <li>dynamiczne zawieszenie.</li>
    </ul>
    <p>
    To tylko kilka parametrów, które zachęcają do zakupu Volvo XC 60 do firmowej floty
    samochodów osobowych. Oprócz powyższych poszczególne modele Volvo dostępne w mAuto występują
    z pakietem wyposażenia dodatkowego, np. podgrzewanie foteli przednich i tylnych oraz
    kierownicy.
    </p>
    <p class="seo-section__header">Volvo XC 60 – spalanie</p>
    <p>
    Według informacji od producenta średnie spalanie Volvo XC 60 w cyklu mieszanym nie powinno
    przekraczać poziomu 7,9 litra na 100 kilometrów. To świetny wynik, biorąc pod uwagę
    doskonałe osiągi tego pojazdu oraz moc na poziomie do 250 koni mechanicznych.
    </p>
    <p class="seo-section__header">Volvo XC 60 w ofercie mAuto</p>
    <p>
    Decydując się na zakup dowolnej wersji modelowej Volvo XC 60 z oferty mAuto oprócz
    atrakcyjnej ceny zakupu i przejrzystej formy finansowania, zyskujesz dodatkowo dostęp do
    szerokiego pakietu usług dodatkowych.
    </p>`,
  volvo_xc60: `<h2>Nowe modele Volvo XC 60</h2>
    <p>
    Nowoczesne i luksusowe Volvo XC 60 w ofercie mAuto. Modele z rocznika 2023 dostępne w
    różnych wariantach finansowania – leasingu i najmie długoterminowym. Wybierz spośród kilku
    wersji wyposażenia i ciesz się wygodą i prestiżem z segmentu premium. Postaw na sprawdzoną markę, która sprosta codziennym wyzwaniom stawianym przed
    autami flotowymi.
    </p>
    <p class="seo-section__header">Volvo XC 60 – dane techniczne</p>
    <p>
    Fabrycznie nowe Volvo XC 60 to dobry wybór, szczególnie jeśli cenisz sobie praktyczne
    rozwiązania połączone z innowacjami technologicznymi. Najważniejsze parametry techniczne
    wyróżniające model Volvo XC 60 to:
    </p>
    <ul>
    <li>wydajny silnik o pojemności dwóch litrów,</li>
    <li>zaawansowany system oczyszczania powietrza z jonizacją wewnątrz pojazdu,</li>
    <li>bezkluczykowy system odpalania samochodu z bezdotykową obsługą bagażnika,</li>
    <li>asystent podjazdu,</li>
    <li>
        system WHIPS, który zmniejsza ryzyko urazu kręgosłupa szyjnego podczas kolizji lub
        wypadku,
    </li>
    <li>reflektory LED,</li>
    <li>układ monitorowania pasa ruchu,</li>
    <li>kolanowe poduszki powietrzne,</li>
    <li>zintegrowane spryskiwacze szyb z piórami wycieraczek,</li>
    <li>wielofunkcyjne radio,</li>
    <li>dynamiczne zawieszenie.</li>
    </ul>
    <p>
    To tylko kilka parametrów, które zachęcają do zakupu Volvo XC 60 do firmowej floty
    samochodów osobowych. Oprócz powyższych poszczególne modele Volvo dostępne w mAuto występują
    z pakietem wyposażenia dodatkowego, np. podgrzewanie foteli przednich i tylnych oraz
    kierownicy.
    </p>
    <p class="seo-section__header">Volvo XC 60 – spalanie</p>
    <p>
    Według informacji od producenta średnie spalanie Volvo XC 60 w cyklu mieszanym nie powinno
    przekraczać poziomu 7,9 litra na 100 kilometrów. To świetny wynik, biorąc pod uwagę
    doskonałe osiągi tego pojazdu oraz moc na poziomie do 250 koni mechanicznych.
    </p>
    <p class="seo-section__header">Volvo XC 60 w ofercie mAuto</p>
    <p>
    Decydując się na zakup dowolnej wersji modelowej Volvo XC 60 z oferty mAuto oprócz
    atrakcyjnej ceny zakupu i przejrzystej formy finansowania, zyskujesz dodatkowo dostęp do
    szerokiego pakietu usług dodatkowych.
    </p>`,
  citroen_c3: `<h2>Nowy Citroen C3 </h2>
    <p>
        Poznaj szeroką ofertę modeli Citroena C3 z rocznika 2023. Samochody dostępne w ramach
        atrakcyjnego finansowania w formie najmu dla firm. Poznaj szeroką gamę dostępnych aut i
        wybierz pojazd, który w 100% sprosta Twoim oczekiwaniom i sprawdzi się w firmowej flocie
        samochodów osobowych.
    </p>
    <p class="seo-section__header">Citroen C3 – dane techniczne</p>

    <p>
        Najnowsze modele Citroena C3 to dobry wybór jako auto do firmowej floty, chociażby ze względu
        na doskonałe parametry techniczne oraz wyposażenie. Auta francuskiego producenta łączą w sobie
        cechy małych SUV-ów i kompaktowych hatchbacków. Dzięki temu popularne C3 świetnie sprawdza się
        w warunkach miejskich, a jednocześnie wyróżnia się na tle innych modeli z tego segmentu.
        Najistotniejsze dane techniczne Citroena C3 to:
    </p>
    <ul>
        <li>oszczędny silnik benzynowy,</li>
        <li>pełen pakiet systemów ABS, REF, AFU i ESP, które wpływają na bezpieczeństwo jazdy,</li>
        <li>wspomaganie kierownicy,</li>
        <li>automatyczna klimatyzacja,</li>
        <li>czujnik parkowania,</li>
        <li>tempomat,</li>
        <li>asystent ruszania na wzniesieniach.</li>
    </ul>
    <p>
        Citroen C3 ma znacznie więcej atutów zachęcających do jego zakupu. Jeśli chcesz uzupełnić
        firmową flotę o niezawodne auto, które sprawdzi się prawie w każdych warunkach – postaw na
        tego Citroena.
    </p>
    <p class="seo-section__header">Citroen C3 – spalanie</p>
    <p>
        Średnie spalanie w przypadku modelu Citroena C3 to wynik na poziomie 8 litrów na 100
        kilometrów w cyklu mieszanym. Typowo miejska jazda nie powinna wygenerować spalania większego
        niż 10 litrów na 100 kilometrów. W przypadku użytkowania Citroena C3 poza miastem na trasie
        możesz ograniczyć zużycie paliwa nawet do 5,5 litra na 100 kilometrów przy założeniu, że nie
        jedziesz szybciej niż 90 km/h.
    </p>
    <p class="seo-section__header">Citroen C3 w ofercie mAuto</p>
    <p>
        Nowy Citroen C3 to świetny wybór dla przedsiębiorców w branży handlowej. Idealnie nadaje się
        do jazdy po mieście, a także na trasy międzymiastowe. To auto perfekcyjne dla przedstawiciela
        handlowego. Kupując je w mAuto zyskujesz też pakiet korzyści: ubezpieczenie, wsparcie
        serwisowe oraz kartę paliwową.
    </p>`,
  toyota_yaris: `<h2>Samochody Toyota Yaris</h2>
    <p>
      Przejrzyj bogatą ofertę różnych wersji modelowych auta Toyota Yaris z
      ostatnich roczników. To wyjątkowe auta, które doskonale sprawdzą się jako
      uzupełnienie firmowej floty pojazdów. Wybierz model, który w 100% sprosta
      Twoim oczekiwaniom. Dopasuj nową Toyotę Yaris na podstawie indywidualnych
      preferencji i skorzystaj.
    </p>
    <p class="seo-section__header">Toyota Yaris – dane techniczne</p>
    
    <p>
      Ostatnie modele Toyoty Yaris to świetny wybór jako niewielkie auto miejskie do
      uzupełnienia firmowej floty samochodów. Wyjątkowa trwałość podzespołów,
      wieloletnia gwarancja oraz ponadczasowa linia boczna to tylko trzy wyróżniki
      tego modelu. Koncern Toyota zadbał o to, aby Yaris sprostał oczekiwaniom nawet
      najbardziej wymagających użytkowników. To auto z segmentu pojazdów miejskich,
      które wyróżnia się takimi cechami jak m.in.:
    </p>
    <ul>
      <li>oszczędne jednostki spalinowe i hybrydowe o pojemności 1,5 litra,</li>
      <li>
        system asystenta pasa ruchu oraz automatyczne światła z czujnikiem
        zmierzchu,
      </li>
      <li>elektrycznie sterowane lusterka,</li>
      <li>łączność Bluetooth,</li>
      <li>uchwyty ISOFIX na tylnej kanapie,</li>
      <li>immobilizer,</li>
      <li>nowoczesny system multimedialny wbudowany w kokpit.</li>
    </ul>
    <p>
      To tylko kilka z najważniejszych rozwiązań dostępnych w modelach Toyoty Yaris.
      Oszczędność, ponadczasowa stylistyka i niezawodność to cechy zachęcające do
      włączenia takiego samochodu do floty swojego przedsiębiorstwa.
    </p>
    <p class="seo-section__header">Toyota Yaris – spalanie</p>
    <p>
      Według danych producenta Toyota Yaris osiąga spalanie na poziomie 5,5 litra na
      100 kilometrów. To świetny wynik, który przekłada się na ogromne oszczędności
      podczas eksploatacji auta w przestrzeni miejskiej i nie tylko.
    </p>
    <p class="seo-section__header">Toyota Yaris w ofercie mAuto</p>
    <p>
      Zamierzasz kupić jedną z dostępnych wersji modelowych Toyoty Yaris? W ofercie
      mAuto znajdziesz samochody poleasingowe i fabrycznie nowe dostępne praktycznie
      od ręki. Decydując się na zakup przez mAuto zyskujesz atrakcyjną formę
      finansowania oraz szeroki pakiet usług dodatkowych do wyboru do każdego modelu
      Toyoty Yaris – zarówno hybrydy, jak i jednostki spalinowej.
    </p>`,
  toyota_auris: `<h2>Toyota Auris w ofercie mAuto</h2>
    <p>
    Niezawodne modele Toyoty Auris są dostępne w ofercie mAuto. Przejrzyj auta z
    ostatnich roczników i wybierz model dopasowany do Twoich indywidualnych
    potrzeb. Jeśli szukasz nowych pojazdów do firmowej floty to Toyota Auris
    będzie strzałem w dziesiątkę. Wybierz jeden spośród dostępnych modeli i ciesz
    się autem w rozsądnej cenie.
    </p>
    <p class="seo-section__header">Toyota Auris – dane techniczne</p>

    <p>
    Toyota Auris to samochód, który znajduje zastosowanie zarówno jako pojazd
    użytkowany prywatnie, jak i uzupełnienie firmowej floty. Koncern zajmujący się
    produkcją tych modeli zadbał o każdy szczegół, dzięki czemu wieloletnia
    eksploatacja samochodu tej klasy nie stanowi żadnego problemu. Kluczowe
    parametry techniczne wyróżniające Toyotę Auris spośród innych modeli
    dostępnych w sprzedaży to:
    </p>
    <ul>
    <li>aż 6 poduszek powietrznych wraz z poduszką na kolana kierowcy,</li>
    <li>centralny wyświetlacz pomiędzy zegarami,</li>
    <li>system wspomagania hamowania,</li>
    <li>system stabilizacji toru jazdy,</li>
    <li>wspomaganie układu kierowniczego,</li>
    <li>automatyczna klimatyzacja,</li>
    <li>czujniki parkowania.</li>
    </ul>
    <p>
    Toyota Auris to auto, które naprawdę warto kupić jako uzupełnienie firmowej
    floty. Postaw na niezawodność i zamów poleasingową lub nową Toyotę Auris na
    atrakcyjnych warunkach finansowania.
    </p>
    <p class="seo-section__header">Toyota Auris – spalanie</p>
    <p>
    Ten model Toyoty wyróżnia się spalaniem na poziomie ok. 7,6 litra benzyny na
    100 kilometrów drogi. To świetny wynik, biorąc pod uwagę gabaryty auta oraz
    masę całkowitą. Dla silnika 1.6 w wersji benzynowej to jak najbardziej
    akceptowalne spalanie.
    </p>
    <p class="seo-section__header">Toyota Auris w ofercie mAuto</p>
    <p>
    Jeśli szukasz jednego samochodu, który z łatwością dopasuje się do Twoich
    zmiennych potrzeb, koniecznie przejrzyj modele Toyoty Auris dostępne w ofercie
    mAuto. Wraz z bezawaryjnym autem zyskasz też dostęp do szerokiego pakietu
    korzyści i usług dodatkowych.
    </p>`,
  toyota_corolla: `<h2>Najnowsze modele Toyoty Corolli</h2>
    <p>
    Jeśli potrzebujesz wygodnego, niezawodnego i oszczędnego pojazdu do firmowej
    floty, koniecznie zastanów się nad zakupem Toyoty Corolli. W tym przypadku
    producent zadbał nawet o najmniejsze detale, dostarczając na rynek
    wielofunkcyjne auta. Zdecyduj się na jedną z wielu wersji modeli z ostatnich
    lat Toyoty Corolla i ciesz się całkowicie nowym autem.
    </p>
    <p class="seo-section__header">Toyota Corolla – dane techniczne</p>

    <p>
    Najnowsze modele Toyoty Corolla to kwintesencja to wyjątkowe połączenie
    użyteczności i komfortu podróżowania. Postaw na połączenie innowacji
    technologicznych, klasycznej linii stylistycznej oraz oszczędności – tym
    wyróżnia się najnowsza Toyota Corolla. Poniżej znajdziesz najważniejsze dane
    techniczne dotyczące tego modelu Toyoty:
    </p>
    <ul>
    <li>duża moc przy pojemności silnika na poziomie 1490 m3,</li>
    <li>kolorowy wyświetlacz na tablicy wskaźników,</li>
    <li>przednie i tylne czujniki parkowania,</li>
    <li>automatyczne ściemnianie lusterka wstecznego,</li>
    <li>asystent głosowy,</li>
    <li>7 poduszek powietrznych w systemie SRS,</li>
    <li>ogranicznik prędkości.</li>
    </ul>
    <p>
    Toyota Corolla to samochód, który jest w stanie sprostać oczekiwaniom nawet
    najbardziej wymagających użytkowników. Poszczególne modele auta Toyota
    Corolla, dostępne w mAuto wyróżniają się nie tylko bogatym wyposażeniem
    wnętrza, ale także dostępem do najnowszych systemów bezpieczeństwa.
    </p>
    <p class="seo-section__header">Toyota Corolla – spalanie</p>
    <p>
    Zgodnie z informacjami dostarczonymi przez producenta nowa Toyota Corolla ma
    spalanie na poziomie nawet poniżej 6 litrów na 100 kilometrów drogi w trybie
    mieszanym. Takie osiągi generują ogromne oszczędności, szczególnie jeśli
    Toyota Corolla pojawi się w firmowej flocie samochodów.
    </p>
    <p class="seo-section__header">Toyota Corolla w ofercie mAuto</p>
    <p>
    Zdecyduj się na zakup dowolnie wybranej wersji modelowej auta Toyota Corolla.
    W mAuto zyskasz dostęp do wielu samochodów od ręki, a dodatkowo skorzystasz z
    atrakcyjnych form finansowania dopasowanych do potrzeb i możliwości Twojego
    przedsiębiorstwa.
    </p>`,
  audi_q5: `<h2>Nowe Audi Q5</h2>
    <p>
      Nowoczesne Audi Q5 to jeden z wielu samochodów dostępnych w ofercie mAuto.
      Modele z najnowszych roczników są dostępne w różnych wariantach finansowania –
      leasingu albo najmu długoterminowego. To średniej wielkości SUV, który
      doskonale nada się do jazdy po mieście, jak i po bezdrożach. Postaw na Audi Q5
      i ciesz się prestiżową prezentacją samochodu oraz komfortem eksploatacji
      każdego dnia. Wybierz swoje Audi Q5!
    </p>
    <p class="seo-section__header">Audi Q5 – dane techniczne</p>

    <p>
      Zaawansowany technologicznie i wygodny samochód Audi Q5 łączy w sobie
      najnowocześniejsze rozwiązania oraz systemy bezpieczeństwa, które przekładają
      się na niesamowity komfort podróżowania. Wybierz ten pojazd do firmowej floty,
      a zyskasz ogromną przestrzeń bagażową i niezawodność w jednym. Kluczowe
      wyróżniki tego modelu to:
    </p>
    <ul>
      <li>moc na poziomie ponad 200 KM,</li>
      <li>nowoczesny system Audi parking system plus i drive select,</li>
      <li>wirtualny kokpit z pełną łącznością bezprzewodową,</li>
      <li>kamera cofania,</li>
      <li>automatyczna klimatyzacja 3-strefowa,</li>
      <li>
        nowoczesne światła Matrix LED i innowacyjny układ zmywania reflektorów.
      </li>
    </ul>
    <p>
      Samochody Audi doskonale sprawdzą się jako prestiżowe samochody klasy premium
      w przedsiębiorstwie. Uzupełnij swoją firmową flotę i postaw na najnowsze
      modele Q5.
    </p>
    <p class="seo-section__header">Audi Q5 – spalanie</p>
    <p>
      Według danych producenta spalanie w modelu Audi Q5 spalanie nie przekracza
      poziomu ok. 8,4 litra na 100 kilometrów drogi w trybie mieszanym. Stosunkowo
      niewielkie zużycie paliwa z prędkością maksymalną sięgającą do 240 km/h to
      świetny wynik.
    </p>
    <p class="seo-section__header">Audi Q5 w ofercie mAuto</p>
    <p>
      Zdecyduj się na jeden z modeli Audi Q5 dostępnych w ofercie mAuto i zyskaj
      luksusowy i przestronny samochód w atrakcyjnej cenie. Oprócz możliwości
      skorzystania z leasingu lub najmu długoterminowego możesz też wybrać szeroki
      pakiet korzyści dla klientów mAuto wraz z pełnym ubezpieczeniem OC, AC i NNW.
    </p>`,
  opel_astra: `<h2>Samochody Opel Astra</h2>
    <p>
      Zobacz szeroką propozycję modeli Opla Astry z rocznika 2023 i nie tylko. W
      jednym miejscu znajdziesz najciekawsze wersje modelowe, które są w stanie
      sprostać zmiennym wymaganiom przedsiębiorców. Dopasuj kryteria wyszukiwania do
      swoich preferencji i zdecyduj się na zakup. Nowy Opel Astra doskonale sprawdzi
      się w firmowej flocie aut osobowych. To auto możesz mieć w atrakcyjnym
      leasingu.
    </p>
    <p class="seo-section__header">Opel Astra – dane techniczne</p>

    <p>
      Samochody Opel Astra mogą zaskoczyć każdego użytkownika. Producent zdecydował
      się na wprowadzenie wielu innowacji technologicznych. Na podstawowym
      wyposażeniu Opla Astry znajdziesz przede wszystkim elektrycznie sterowany
      hamulec postojowy oraz komfortowe fotele. Pozostałe parametry wyróżniające
      Opla Astrę w ofercie mAuto to:
    </p>
    <ul>
      <li>aż 10-calowy wyświetlacz kolorowy,</li>
      <li>elektrycznie sterowane szyby przednie i tylne,</li>
      <li>klimatyzacja,</li>
      <li>reflektory w technologii LED,</li>
      <li>kierownica pokryta skórą,</li>
      <li>automatyczne światła z czujnikiem oświetlenia,</li>
      <li>światłoczułe wewnętrzne lusterko.</li>
    </ul>
    <p>
      To tylko kilka parametrów, które powodują, że Opel Astra jest dobrym wyborem
      do firmowej floty pojazdów. Jeśli szukasz niezawodnego auta z eleganckim
      wnętrzem – wybierz Opla Astrę.
    </p>
    <p class="seo-section__header">Opel Astra – spalanie</p>
    <p>
      Opel Astra to samochód osobowy, którego spalanie nie przekracza 6,5 litra na
      100 kilometrów w trybie miejskim, a także 5 litrów w trybie mieszanym. Takie
      wartości są deklarowane przez producenta. To sprawia, że Opel Astra jest tani
      w eksploatacji, a więc doskonale nadaje się do codziennego użytkowania w
      firmowej flocie samochodów osobowych.
    </p>
    <p class="seo-section__header">Opel Astra w ofercie mAuto</p>
    <p>
      Nowy Opel Astra to samochód idealny dla przedsiębiorców zajmujących się
      handlem. Stosunkowo niewielkie spalanie, niezawodność oraz gwarantowany
      komfort jazdy to główne korzyści wynikające z zakupu tego samochodu. Oprócz
      tego, kupując Opla Astrę z oferty mAuto otrzymujesz pakiet udogodnień:
      ubezpieczenie, kartę paliwową oraz atrakcyjne warunki finansowania.
    </p>`,
  skoda_octavia: `<h2>Nowa Skoda Octavia</h2>
    <p>
      Przejrzyj ciekawą propozycję Skody. Model Octavia możesz mieć w cenie
      zaczynającej się już od 383 złote miesięcznie. Skoda Octavia jest dostępna na
      atrakcyjnych warunkach finansowania i w wielu wersjach modelowych. Wybierz
      Skodę Octavię, która w 100% sprosta Twoim oczekiwaniom. To idealne auto do
      uzupełnienia floty samochodów osobowych w przedsiębiorstwie.
    </p>
    <p class="seo-section__header">Skoda Octavia – dane techniczne</p>

    <p>
      Najnowocześniejsze modele Skody Octavia to połączenie ponadczasowego designu
      oraz niesamowitych osiągów. Jeśli szukasz nowych aut do firmowej floty,
      koniecznie weź pod uwagę model Skoda Octavia, który doskonale sprawdza się w
      miejskich i pozamiejskich warunkach. Samochody czeskiego producenta łączą w
      sobie wszystko to, co najlepsze: nienaganny design, doskonałe osiągi oraz
      niezawodność w każdej sytuacji. Kluczowe parametry techniczne modelu Skoda
      Octavia to:
    </p>
    <ul>
      <li>pełne systemy bezpieczeństwa ABS i ASR,</li>
      <li>aż 6 sztuk poduszek powietrznych wraz z kurtynami,</li>
      <li>system regulacji hamowania na zakręcie CBC,</li>
      <li>oprogramowanie przeciwblokujące koła podczas hamowania silnikiem,</li>
      <li>elektryczne podnoszenie szyb z przodu i z tyłu,</li>
      <li>system kontroli ciśnienia w oponach,</li>
      <li>multimedialne radio,</li>
      <li>lampy w technologii LED.</li>
    </ul>
    <p>
      To tylko kilka cech wyróżniających model Skoda Octavia spośród innych
      samochodów dostępnych w ofercie mAuto. Postaw na jakość i niezawodność – kup
      Skodę Ocatvię do swojej firmy w atrakcyjnej ofercie leasingu.
    </p>
    <p class="seo-section__header">Skoda Octavia – spalanie</p>
    <p>
      Zgodnie z danymi producenta spalanie w modelach Skody Octavii nie przekracza
      wartości ok. 5,3 litra na 100 kilometrów podczas cyklu mieszanego. Tak niski
      poziom zużycia paliwa sprawia, że Skoda Octavia jest idealnym wyborem do
      firmowej floty aut, np. dla przedstawicieli handlowych. Niskie spalanie i
      niezawodność to cechy, w które warto zainwestować.
    </p>
    <p class="seo-section__header">Skoda Octavia w ofercie mAuto</p>
    <p>
      Wybierz Skodę Octavię do swojej firmy. Zarówno fabrycznie nowa, jak i
      poleasingowa będzie świetnym uzupełnieniem floty przedsiębiorstwa. To
      perfekcyjne auto nie tylko dla przedstawicieli handlowych, ale także dla kadry
      kierowniczej. Zamów Skodę Octavię w mAuto i zyskaj szeroki pakiet korzyści:
      kartę paliwową, pełen pakiet ubezpieczeń i opiekę serwisową.
    </p>`,
  hyundai_i30: `<h2>Hyundai i30 w ofercie mAuto</h2>
    <p>
      Najnowsze modele Hyundai i30 dostępne praktycznie od ręki w ofercie mAuto.
      Topowe modele z najświeższych roczników kupisz w dobrych cenach. Postaw na
      niezawodność i wybierz Hyundaia i30. Ciesz się prestiżem, niezawodnością oraz
      doskonałymi osiągami. Wybierz samochody sprawdzonej marki, która jest w stanie
      sprostać wyzwaniom, z jakimi zmagają się auta w firmowych flotach. Zobacz
      modele Hyundai i30 dostępne od ręki.
    </p>
    <p class="seo-section__header">Hyundai i30 – dane techniczne</p>
    
    <p>
      Fabrycznie nowy Hyundai i30 to samochód, który doskonale nadają się do
      firmowej floty pojazdów – zarówno dla kadry kierowniczej, jak i dla
      przedstawicieli handlowych. Hyundai i30 to połączenie najnowszych technologii
      z niezawodnymi jednostkami silnikowymi. Kluczowe dane techniczne modeli
      Hyundai i30 dostępnych w ofercie mAuto to:
    </p>
    <ul>
      <li>systemu bezpieczeństwa ABS, ESC i HAC,</li>
      <li>poduszki i kurtyny powietrzne,</li>
      <li>asystent unikania kolizji czołowych,</li>
      <li>immobilizer,</li>
      <li>elektryczne wspomaganie kierownicy,</li>
      <li>multimedialne radio z 10,25-calowym ekranem dotykowym,</li>
      <li>reflektory w technologii LED.</li>
    </ul>
    <p>
      Hyundai i30 to samochód, który ma w sobie praktycznie wszystko to, czego
      możesz potrzebować w firmowej flocie pojazdów.
    </p>
    <p class="seo-section__header">Hyundai i30 – spalanie</p>
    <p>
      Zgodnie z informacjami od producenta Hyundai i30 zużywa nie więcej niż 4,8
      litra paliwa na 100 kilometrów w trybie mieszanym. To w połączeniu z niską
      emisją spalin na poziomie 109 g, a także wysoką mocą jednostki silnika jest
      gwarancją sporych oszczędności na codziennej eksploatacji auta.
    </p>
    <p class="seo-section__header">Hyundai i30 w ofercie mAuto</p>
    <p>
      Zdecyduj się na Hyundaia i30 w ofercie mAuto, a na pewno nie pożałujesz.
      Oprócz atrakcyjnych warunków finansowania zyskasz też dostęp do pełnego
      pakietu ubezpieczeń, karty paliwowej oraz wsparcia serwisowego. Skorzystaj z
      oferty mAuto i postaw na sprawdzone modele Hyundai i30 dostępne od ręki.
    </p>`,
  ford_focus: `<h2>Modele Ford Focus w ofercie mAuto</h2>
    <p>
      Poznaj najnowsze modele Forda Focusa w mAuto. To ponadczasowe, niezawodne i
      oszczędne samochody. Skorzystaj z atrakcyjnej formy finansowania – najmu
      długoterminowego lub leasingu. Ford Focus idealnie uzupełni firmową flotę
      samochodów osobowych. Wybierz jedną z wielu dostępnych wersji wyposażenia
      Forda Focusa dostępnych od ręki.
    </p>
    <p class="seo-section__header">Ford Focus – dane techniczne</p>
    
    <p>
      Nowe i poleasingowe auta takie jak Ford Focus to idealny wybór dla każdego
      przedsiębiorstwa. Jeśli szukasz połączenia niezawodności, doskonałych osiągów
      oraz dostępu do licznych udogodnień – ten samochód będzie idealny.
      Najistotniejsze parametry wyróżniające Forda Focusa to:
    </p>
    <ul>
      <li>najnowsze systemy bezpieczeństwa ESP, TSC, TA, EBA i HSA,</li>
      <li>bezprzewodowe radio z dużym dotykowym wyświetlaczem,</li>
      <li>reflektory w technologii LED,</li>
      <li>relingi bagażnika dachowego na standardowym wyposażeniu,</li>
      <li>wbudowany moduł FordPass Connect,</li>
      <li>trójramienna kierownica,</li>
      <li>system monitorowania ciśnienia w oponach,</li>
      <li>skórzane wykończenie wnętrza,</li>
      <li>elektrycznie regulowane lusterka boczne i szyby,</li>
      <li>poduszki powietrzne i kurtyny w przedniej i tylnej części auta.</li>
    </ul>
    <p>
      To najistotniejsze parametry techniczne z punktu widzenia użytkownika. Wybierz
      Forda Focusa do swojej firmowej floty i ciesz się niezawodnością połączoną z
      komfortem podróżowania.
    </p>
    <p class="seo-section__header">Ford Focus – spalanie</p>
    <p>
      Producent zapewnia, że Ford Focus ma spalanie na poziomie nie większym niż 8,9
      litra na 100 kilometrów w trybie mieszanym. To dobry wynik, biorąc pod uwagę
      wysoką moc jednostki napędowej oraz gabaryty samochodu.
    </p>
    <p class="seo-section__header">Ford Focus w ofercie mAuto</p>
    <p>
      Wybierz nowego lub poleasingowego Forda Focusa w mAuto. Zyskasz atrakcyjne
      warunki finansowania, kartę paliwową oraz pakiet usług serwisowych. Wybierz
      formę finansowania dopasowaną do swoich potrzeb i zdecyduj się na Forda Focusa
      – idealne uzupełnienie firmowej floty aut osobowych.
    </p>`
};

export default seoModels;
