import React from "react";
import PropTypes from "prop-types";
import Paper from "../../parts/Paper";
import InformationTile from "../../parts/InformationTile";
import { Text } from "@mbank-design/design-system/components";
import {
  FontSize,
  FontWeight,
  LineHeight,
} from "@mbank-design/design-system/design-tokens/general/typography";
import "./index.scss";
import { withRouter } from "react-router-dom";
import { EnvelopeColor, PhoneHandleColor } from "@mbank-design/design-system/icons";
import { TextAlign } from "@mbank-design/design-system/enums";

const MainContactSection = (props) => {
  MainContactSection.propTypes = {
    useClass: PropTypes.string,
  };
  return (
    <div className={`contact-section-container ${props.useClass || ""}`}>
      <Text
        as="p"
        styledAs="h3"
        fontSize={FontSize.SIZE_24}
        lineHeight={LineHeight.HEIGHT_32}
        className="section-title"
        fontWeight={FontWeight.WEIGHT_700}
      >
        {props.literals && props.literals.help_hdr}
      </Text>
      {props.description && (
        <Text as="p" marginTop={8} align={TextAlign.CENTER}>
          {props.description}
        </Text>
      )}
      {props.literals && (
        <Paper className="contact-section-paper">
          {props.literals.callusbox_auction_tel &&
            props.literals.callusbox_tel &&
            props.literals.callusbox_hdr &&
            props.literals.callusbox_time && (
              <InformationTile
                titleText={props.literals.callusbox_hdr}
                icon={PhoneHandleColor}
                titleAs="p"
                titleStyledAs="h4"
                description={props.literals.callusbox_time}
                linkText={
                  props.pageType === "auction"
                    ? props.literals.callusbox_auction_tel
                    : props.literals.callusbox_tel
                }
                linkUrl={
                  props.pageType === "auction"
                    ? `tel:${props.literals.callusbox_auction_tel.replace(
                        / /g,
                        ""
                      )}`
                    : `tel:${props.literals.callusbox_tel.replace(/ /g, "")
                      }`
                }
                useClass="general"
              />
            )}
          {props.literals.mailusbox_hdr &&
            props.literals.mailusbox_subhdr &&
            props.literals.mailusbox_auction_mail &&
            props.literals.mailusbox_mail && (
              <InformationTile
                titleText={props.literals.mailusbox_hdr}
                icon={EnvelopeColor}
                titleAs="p"
                titleStyledAs="h4"
                description={props.literals.mailusbox_subhdr}
                linkText={
                  props.pageType === "auction"
                    ? props.literals.mailusbox_auction_mail
                    : props.literals.mailusbox_mail
                }
                linkUrl={
                  props.pageType === "auction"
                    ? `mailto:${props.literals.mailusbox_auction_mail}`
                    : `mailto:${props.literals.mailusbox_mail}`
                }
                useClass="general"
              />
            )}
        </Paper>
      )}
    </div>
  );
};

export default withRouter(MainContactSection);
