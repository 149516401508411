import React, { Component } from "react";
import { Amount, Icon, Switch, Text } from "@mbank-design/design-system/components";
import { FontColor, FontSize, FontWeight, LineHeight } from "@mbank-design/design-system/design-tokens/general/typography";
import Tooltip from "../Tooltip";
import "./index.scss";
import { ShieldTick, Tire, Gear, CreditCard, CarPhoneHandle, Car, Wrench } from "@mbank-design/design-system/icons";
import { SwitchColor } from "@mbank-design/design-system/enums";

export default class AdditionalPackageTile extends Component {
  emitChanges(pkg) {
    if (!this.props.isPreviewOnly) {
      this.props.changePackageStatus(pkg.Name.split(" ").join("_"));
    }
  }

  iconComponentChange = (iconName) => {
    if (iconName === "ShieldTick") {
      return <Icon iconComponent={ShieldTick} primaryColor={"endeavourBlueUI"}/>;
    } else if (iconName === "Tire"){
      return <Icon iconComponent={Tire} primaryColor={"endeavourBlueUI"}/>;
    } else if (iconName === "Gear"){
      return <Icon iconComponent={Wrench} primaryColor={"endeavourBlueUI"}/>;
    } else if (iconName === "CreditCard"){
      return <Icon iconComponent={CreditCard} primaryColor={"endeavourBlueUI"}/>;
    } else if (iconName === "Wrench"){
      return <Icon iconComponent={Wrench} primaryColor={"endeavourBlueUI"}/>;
    } else if (iconName === "CarPhoneHandle"){
      return <Icon iconComponent={CarPhoneHandle} primaryColor={"endeavourBlueUI"}/>;
  };}

  render() {
    let pkg = this.props.package;
    let state = this.props.packagesState;
    let isChecked = null;
    if (pkg) {
      isChecked = this.props.isPreviewOnly
        ? pkg.isChecked
        : state[pkg.Name.split(" ").join("_")].isChecked;
    }
    let price = this.props.useStateAmount
      ? state[pkg.Name.split(" ").join("_")].Price
      : pkg.Price;

    return (
      <>
        {pkg && (
          <div
            className={`additional-package-tile-container ${pkg.Indent && !this.props.isPreviewOnly ? "indent" : ""}`}
          >
            <div className="display-row">
              {this.props.iconVisible && this.iconComponentChange(pkg.IconName)}
              <Text
                as={"p"}
                styledAs={"small"}
                color={this.props.color ? this.props.color : "zeusBlackUI"}
              >
                {pkg.Name}
              </Text>
              {!this.props.tooltipHidden && <Tooltip
                title={pkg.Name}
                icon={pkg.IconName}
                darkMode={this.props.darkMode}
                description={pkg?.EkstraTooltip ? pkg.Tooltip + pkg.EkstraTooltip : pkg.Tooltip}
              />}
            </div>
            <div className="right-wing">
              {!!pkg.Price && (
                <Text
                  as={"p"}
                  styledAs={"small"}
                >
                  {isChecked && price !== undefined ? (
                    <Amount
                      isFractionPartShown={false}
                      currencyDisplay="symbol"
                      currency="PLN"
                      periodSuffix="month"
                      isFractionPartResized={false}
                      styledAsTextTagName={"p"}
                      value={isChecked ? price : "Ładowanie ..."}
                    />
                  ) : null}
                </Text>
              )}
              {!this.props.isPreviewOnly && (
                <Switch
                  checked={isChecked}
                  disabled={this.props.disabled || pkg.Price === null || pkg.Disabled}
                  color={SwitchColor.BLUE}
                  onChange={() => this.emitChanges(pkg)}
                ></Switch>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}
