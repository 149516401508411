import * as DOMElements from "./DOMElements";
import {
  ALL_ACCEPT_FILE_EXTENSIONS,
  EVENT_HAS_DELIVERED_ID,
  EVENT_HAS_NOT_SENT_ID,
  EVENT_HAS_SEEN_ID,
  EVENT_HAS_SENT_ID,
  IMAGE_EXTENSIONS
} from "./constants";

import terms from "../../../assets/files/Regulamin-uslugi-Czat-mAUTO.pdf";

export const getDataTime = (value) => {
  const date = value ? new Date(value) : new Date();
  const hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  const minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();

  return `${hours}:${minutes}`;
};

export const isAtTheBottom = (element, tolerance = 20) =>
  element.scrollTop + element.clientHeight >= element.scrollHeight - tolerance;

export const getMessageList = () => document.getElementById("chat");

export const createMessage = ({ id, value, authorType, time, type = "message", className } = {}) => {
  const messageDivContainer = document.createElement("div");
  messageDivContainer.id = id;

  const classList = ["message-container", authorType];
  if (className) {
    classList.push(className);
  }
  messageDivContainer.classList.add(...classList);

  const messageDiv = document.createElement("div");
  messageDiv.classList.add("message");

  if (time) {
    const topContainer = document.createElement("div");
    topContainer.classList.add("top-container-message");

    const timeDiv = document.createElement("div");
    timeDiv.classList.add("time-message");
    timeDiv.append(time);

    if (authorType === "agent" || authorType === "customer") {
      const authorContainer = document.createElement("div");
      authorContainer.classList.add("author-message");
      authorContainer.innerHTML = authorType === "agent" ? "czat mAuto&nbsp" : "Ty&nbsp";
      topContainer.append(authorContainer);
    }

    topContainer.append(timeDiv);
    messageDiv.append(topContainer);
  }

  const messageText = document.createElement("div");
  messageText.classList.add("message-text");

  if (type === "filled_form") {
    const messageTitle = document.createElement("div");
    messageTitle.classList.add("message-text-title");
    messageTitle.innerText = "Posumowanie rozmowy:";
    messageText.append(messageTitle);

    value.forEach((field) => {
      const messageLabel = document.createElement("div");
      messageLabel.classList.add("message-text-label");
      messageLabel.innerText = `${field.label}:`;
      messageText.append(messageLabel);

      const messageValue = document.createElement("div");
      messageValue.classList.add("message-text-value");
      if (field.type === "radio") {
        messageValue.innerHTML = `${field.answer.label}`;
      } else if (field.type === "checkbox") {
        messageValue.innerHTML = `${field.answers[0].label}`;
      } else {
        messageValue.innerHTML = `${field.answer}`;
      }

      messageText.append(messageValue);
    });

    messageDiv.append(messageText);
  } else if (type === "file") {
    const stringArr = value.name.split(".");
    const extension = "." + stringArr[stringArr.length - 1];
    if (value?.thumbnails && isAcceptImageExtension(extension)) {
      messageText.innerHTML = `<a href="${value.url}" rel="noopener noreferrer nofollow" target="_blank" tabIndex="0">
        <div class="message-image-container">
          <img 
            alt="${value.name}"
            width="${value?.thumbnails?.default.width}"
            height="${value?.thumbnails?.default.height}"
            src="${value?.thumbnails?.default.url}" 
            srcSet="${value?.thumbnails?.default.url} 1x, ${value?.thumbnails?.high.url} 2x"
          />
         </div>
      </a>`;
    } else {
      messageText.innerHTML = `<a href="${value.url}" rel="noopener noreferrer nofollow" target="_blank" tabIndex="0">${value.name}</a>`;
    }

    messageDiv.append(messageText);
  } else {
    if (value.toString().includes("https://") || value.toString().includes("http://")) {
      messageText.innerHTML = urlify(value);
    } else {
      messageText.innerHTML = value;
    }

    messageDiv.append(messageText);
  }

  messageDivContainer.append(messageDiv);
  return messageDivContainer;
};

const urlify = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
};

export const createField = (field, customerName) => {
  const fieldContainer = document.createElement("div");
  let input, label, fieldset, legend;

  switch (field.type) {
    case "textarea":
      input = document.createElement("textarea");
      break;
    case "radio":
      input = document.createElement("radio");
      break;
    default:
      input = document.createElement("input");
      input.type = field.type;
  }

  input.dataset.id = field.id;
  input.name = field.type;
  input.required = field.required;

  if (field.pattern) {
    input.pattern = field.pattern;
  }

  switch (field.type) {
    case "rating":
      fieldset = document.createElement("fieldset");
      legend = document.createElement("legend");
      const stars = document.createElement("div");
      stars.classList.add("stars-list");

      legend.innerText = field.label.replace("%name%", customerName);
      fieldset.append(legend);

      [1, 2, 3, 4, 5].forEach((option) => {
        const optionInput = document.createElement("input");
        optionInput.id = `rating_${option}`;
        optionInput.type = "radio";
        optionInput.name = "rating";
        optionInput.value = option.toString();
        optionInput.required = field.required;

        label = document.createElement("label");
        label.classList.add("rating-label");

        const star = document.createElement("div");
        star.innerHTML += DOMElements.ratingStar;

        label.append(optionInput);
        label.append(star);

        stars.append(label);
      });

      const starNodes = [...stars.childNodes];
      const starClassActive = "rating-label active";
      const starClassInactive = "rating-label";
      const starsLength = starNodes.length;
      let i;
      starNodes.map((star) => {
        star.onclick = () => {
          i = starNodes.indexOf(star);

          if (star.className === starClassInactive) {
            for (i; i >= 0; --i) starNodes[i].className = starClassActive;
          } else {
            for (i; i < starsLength; ++i) starNodes[i].className = starClassInactive;
          }
        };
      });

      fieldset.append(stars);
      fieldContainer.append(fieldset);
      break;
    case "radio":
      fieldset = document.createElement("fieldset");
      legend = document.createElement("legend");

      legend.innerText = field.label;
      fieldset.append(legend);

      field.options.forEach((option) => {
        const optionInput = document.createElement("input");
        optionInput.id = `radio_${option.id}`;
        optionInput.checked = option.checked;
        optionInput.type = field.type;
        optionInput.name = `radio_${field.id}`;
        optionInput.value = option.label;
        optionInput.required = field.required;

        label = document.createElement("label");
        label.classList.add("radio-label");

        label.append(optionInput);
        label.append(document.createElement("span"));
        label.append(option.label);

        fieldset.append(label);
      });

      fieldContainer.append(fieldset);
      break;
    case "checkbox":
      label = document.createElement("div");
      label.classList.add("checkbox-header");
      label.innerHTML = field.label.replace("%terms%", "<a href='" + terms + "' target='_blank'>Czytaj więcej</a>");
      fieldContainer.append(label);

      field.options.forEach((option) => {
        const labelOption = document.createElement("label");
        const inputOption = document.createElement("input");

        labelOption.classList.add("checkbox-label");

        inputOption.required = field.required;
        inputOption.type = field.type;
        inputOption.name = field.type;
        inputOption.id = `checkbox_${option.id}`;
        inputOption.checked = option.checked;

        labelOption.append(inputOption);
        labelOption.append(document.createElement("span"));
        labelOption.append(option.label);

        fieldContainer.append(labelOption);
      });
      break;
    default:
      input.placeholder = field.label;

      fieldContainer.append(input);
  }

  return fieldContainer;
};

export const createForm = (
  form,
  _onsubmit,
  groupId = 0,
  type = "ticket",
  formName = "filled_form",
  buttonText = "wyślij",
  customerName = ""
) => {
  if (!form || !_onsubmit) {
    return;
  }

  const formContainer = document.createElement("div");
  formContainer.id = formName;

  const formElement = document.createElement("form");
  formElement.name = formName;

  formElement.onsubmit = _onsubmit;
  formElement.dataset.id = form.id;
  formElement.dataset.groupId = String(groupId);
  formElement.dataset.type = type;

  if (Array.isArray(form.fields)) {
    form.fields.forEach((field) => {
      if (field.type === "header") {
        formElement.append(
          createMessage({
            id: `form-header-${form.id}`,
            value: field.label,
            authorType: "system"
          })
        );

        return;
      }

      formElement.append(createField(field, customerName));
    });
  }

  const buttonElement = document.createElement("button");
  buttonElement.classList = "lc-btn";
  buttonElement.type = "submit";
  buttonElement.innerText = buttonText;

  formElement.append(buttonElement);

  formContainer.append(formElement);
  appendMessage(formContainer);
};

export const appendMessage = (message) => {
  const messageList = getMessageList();
  const shouldScrollToBottom = isAtTheBottom(messageList);
  messageList.appendChild(message);
  if (shouldScrollToBottom) {
    scrollToBottom();
  }
};

export const clearChat = () => {
  document.getElementById("chat").replaceChildren();
};

export const sendSystemMessage = (text, id, time = getDataTime()) => {
  const notifyId = id ? id : `${Math.random() * 1000}`;

  appendMessage(
    createMessage({
      id: notifyId,
      value: text,
      authorType: "system",
      time
    })
  );
};

export const prependMessages = (chatId, messages) => {
  const messageList = getMessageList(chatId);
  messages.reverse().forEach((message) => {
    const firstMessage = messageList.children[0];
    if (firstMessage) {
      messageList.insertBefore(message, firstMessage);
      return;
    }
    appendMessage(message);
  });
};

export const markAsFailedMessage = (id) => {
  const event = document.getElementById(id);

  const status = document.createElement("div");
  status.id = EVENT_HAS_NOT_SENT_ID;
  status.classList.add("message-status");
  status.innerText = "wiadomość nie wysłana";

  const lastSentEvent = document.getElementById(EVENT_HAS_SENT_ID);

  if (lastSentEvent) {
    removeElement(EVENT_HAS_SENT_ID);
  }

  event.classList.add("message-has-not-sent");
  event.appendChild(status);
};

export const confirmMessageAsSent = (id) => {
  const event = document.getElementById(id);
  const lastSentEvent = document.getElementById(EVENT_HAS_SENT_ID);

  if (lastSentEvent) {
    removeElement(EVENT_HAS_SENT_ID);
  }

  const status = document.createElement("div");
  status.id = EVENT_HAS_SENT_ID;
  status.classList.add("message-status");
  status.innerText = "wysłana";

  event.appendChild(status);
};

export const confirmMessageAsDelivered = (id) => {
  const event = document.getElementById(id);
  const lastSentEvent = document.getElementById(EVENT_HAS_SENT_ID);
  const lastDeliveredEvent = document.getElementById(EVENT_HAS_DELIVERED_ID);

  if (lastSentEvent) {
    removeElement(EVENT_HAS_SENT_ID);
  }

  if (lastDeliveredEvent) {
    removeElement(EVENT_HAS_DELIVERED_ID);
  }

  const status = document.createElement("div");
  status.id = EVENT_HAS_DELIVERED_ID;
  status.classList.add("message-status");
  status.innerText = "dostarczona";

  event.appendChild(status);
};

export const confirmMessageAsSeen = (id, isRemoveDeliveredStatus) => {
  const event = document.getElementById(id);
  const lastSeenEvent = document.getElementById(EVENT_HAS_SEEN_ID);
  const lastDeliveredEvent = document.getElementById(EVENT_HAS_DELIVERED_ID);

  if (lastSeenEvent) {
    removeElement(EVENT_HAS_SEEN_ID);
  }

  if (lastDeliveredEvent && isRemoveDeliveredStatus) {
    removeElement(EVENT_HAS_DELIVERED_ID);
  }

  const status = document.createElement("div");
  status.id = EVENT_HAS_SEEN_ID;
  status.classList.add("message-status");
  status.innerText = "przeczytana";

  event.appendChild(status);
};

export const removeElement = (id) => {
  document.getElementById(id).remove();
};

export const scrollToBottom = () => {
  const messageList = getMessageList();
  messageList.scrollTop = messageList.scrollHeight;
};

export const hideFilePreviewLoading = (id) => {
  const imageContainer = document.getElementById(id);
  const removeImageButton = imageContainer.getElementsByClassName("lc-files-remove-icon")[0];

  imageContainer.classList.remove("loading");
  removeImageButton.disabled = false;
};

export const setErrorFilePreview = (id, message) => {
  const imageContainer = document.getElementById(id);
  const error = document.createElement("div");

  imageContainer.classList.add("lc-file-error");
  error.classList.add("lc-file-name", "lc-error-message");
  error.innerText = message;

  imageContainer.append(error);
};

export const setImageSrcFilePreview = (id, url) => {
  const image = document.getElementById(id).getElementsByTagName("img")[0];

  image.src = url;
  image.style.opacity = "1";
};

const closest = (to, element) => {
  const toClass = to.substr(1);
  let target = element;
  while (target) {
    if (target.classList.contains(toClass)) {
      return target;
    }
    target = target.parentElement;
  }

  return null;
};

export const isAcceptImageExtension = (extension) => {
  return IMAGE_EXTENSIONS.split(",").findIndex((x) => x === extension?.toLowerCase()) !== -1;
};

export const isAcceptExtension = (extension) => {
  return ALL_ACCEPT_FILE_EXTENSIONS.split(",").findIndex((x) => x === extension?.toLowerCase()) !== -1;
};

export const delegate = (from, to, eventName, handler) => {
  const targetSelector = `${to}, ${to} *`;

  document.querySelector(from).addEventListener(eventName, (ev) => {
    if (!ev.target.matches(targetSelector)) {
      return;
    }

    handler.call(closest(to, ev.target), ev);
  });
};

export const throttle = (ms, fn) => {
  let lastCall = Date.now() - 2 * ms;
  let result;
  let trailing;

  function invoke(...args) {
    lastCall = Date.now();
    return (result = fn.apply(this, args));
  }

  return function throttler(...args) {
    const now = Date.now();

    if (now - lastCall >= ms) {
      return invoke.apply(this, args);
    }

    clearTimeout(trailing);
    trailing = setTimeout(() => invoke.apply(this, args), lastCall - now + ms);

    return result;
  };
};
