import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Text,
  TextField,
  Button,
  Accordion,
  Box,
  Checkbox,
  SearchField,
  Switch,
  Badge,
  Tag,
  Stack
} from "@mbank-design/design-system/components";
import { withRouter } from "react-router-dom";
import { replaceTemplateValue } from "../../../literals/templateValueChanger";
import "./index.scss";
import ObserveVehicleButton from "../../parts/ObserveVehicleButton";

class MobileFilters extends Component {
  static propTypes = {
    filters: PropTypes.object.isRequired,
    isCompany: PropTypes.bool,
    filtersValues: PropTypes.object.isRequired,
    modelsArr: PropTypes.array,
    makesArr: PropTypes.array,
    literals: PropTypes.object,
    countFilters: PropTypes.number,
    selectOnChange: PropTypes.func.isRequired,
    renameAllObjectsKeysInArray: PropTypes.func.isRequired,
    textFieldOnChange: PropTypes.func.isRequired,
    keypressHandler: PropTypes.func.isRequired,
    filterOnBlur: PropTypes.func.isRequired,
    searchCars: PropTypes.func.isRequired,
    handleIsCompanyChange: PropTypes.func.isRequired,
    setContractorType: PropTypes.func.isRequired,
    changeFinancialMethod: PropTypes.func.isRequired,
    toggleMobileFilters: PropTypes.func.isRequired,
    financialMethod: PropTypes.string,
    searchPhrase: PropTypes.string,
    filterMakesListOnTagClick: PropTypes.func,
    statusesFilter: PropTypes.array,
    setChange: PropTypes.func,
    cleanAllFilters: PropTypes.func
  };

  render() {
    const {
      filtersValues,
      filters,
      modelsArr,
      makesArr,
      isCompany,
      literals,
      countFilters,
      financialMethod,
      changeFinancialMethod,
      setContractorType,
      handleIsCompanyChange,
      searchCars,
      searchPhrase,
      filterMakesListOnTagClick,
      selectOnChange,
      renameAllObjectsKeysInArray,
      textFieldOnChange,
      keypressHandler,
      filterOnBlur,
      toggleMobileFilters,
      statusesFilter,
      setChange,
      cleanAllFilters
    } = this.props;

    const isActiveFilter = Boolean(
      Object.values(this.props.filtersValues).find((el) => el !== false && el.length !== 0) || financialMethod !== "all"
    );

    return (
      <div className={`lb-container}`}>
        <div className="lb-shadow" />
        <div className="mobile-list-baner-navbar">
          <Text as="p" styledAs="h3">
            filtruj wyniki
          </Text>
          <Text as={"p"} styledAs={"cta_button"} color={"endeavourBlueUI"} onClick={() => cleanAllFilters()}>
            wyczyść
          </Text>
        </div>
        <div className="mleasing-main-container lb-main-container">
          <div className="contractor-type-container">
            <Text as={"p"} styledAs={"body"}>
              oferty na firmę
            </Text>
            <Switch
              checked={isCompany}
              onChange={(e) => {
                setContractorType(e.target.checked);
                handleIsCompanyChange(e.target.checked);
              }}
            />
          </div>
          <div className="selects-container">
            <Accordion layout="small">
              <div className="filters__accordion-header">
                <Accordion.Item>
                  <Accordion.Header>
                    <Badge count={1} isVisible={financialMethod !== "all"}>
                      forma zakupu
                    </Badge>
                  </Accordion.Header>
                  <Accordion.Content>
                    <Box paddingLeft={3} display={"flex"} alignItems={"center"} gap={16} height={49}>
                      <Checkbox
                        label={"finansowanie"}
                        name={"financing"}
                        onChange={(e) => changeFinancialMethod("financing", e)}
                        checked={financialMethod === "financing"}
                      />
                    </Box>
                    <Box paddingLeft={3} display={"flex"} alignItems={"center"} gap={16} height={49}>
                      <Checkbox
                        label={"gotówka"}
                        name={"cash"}
                        onChange={(e) => changeFinancialMethod("cash", e)}
                        checked={financialMethod === "cash"}
                      />
                    </Box>
                  </Accordion.Content>
                </Accordion.Item>
              </div>
              <div className="filters__accordion-header">
                <Accordion.Item>
                  <Accordion.Header>
                    <Badge count={filtersValues.makes.length} isVisible={filtersValues.makes.length !== 0}>
                      marka
                    </Badge>
                  </Accordion.Header>
                  <Accordion.Content>
                    <>
                      <SearchField onChange={(e) => setChange(e)} />
                      <Stack spacing={8} direction={"row"}>
                        {filtersValues.makes &&
                          filtersValues.makes.map((el) => (
                            <Tag onClick={() => filterMakesListOnTagClick(el, "makes")}>{el}</Tag>
                          ))}
                      </Stack>
                      {makesArr
                        .filter((el) => {
                          return el.label.indexOf(searchPhrase.toUpperCase()) === 0;
                        })
                        .map((el) => (
                          <Box paddingLeft={3} display={"flex"} alignItems={"center"} gap={16} height={49}>
                            <Checkbox
                              label={el.label}
                              name={el.value}
                              onChange={() => selectOnChange(el.value, "makes")}
                              checked={filtersValues.makes.includes(el.value)}
                            />
                          </Box>
                        ))}
                    </>
                  </Accordion.Content>
                </Accordion.Item>
              </div>
              <div className="filters__accordion-header">
                <Accordion.Item>
                  <Accordion.Header>
                    {modelsArr && modelsArr.length !== 0 ? (
                      <Badge count={filtersValues.models.length} isVisible={filtersValues.models.length !== 0}>
                        <Text as={"p"} styledAs={"body"}>
                          model
                        </Text>
                      </Badge>
                    ) : (
                      <Text as={"p"} styledAs={"body"} color={"altoGray"}>
                        model - najpierw wybierz markę
                      </Text>
                    )}
                  </Accordion.Header>
                  <Accordion.Content>
                    <Accordion.Item>
                      {modelsArr && modelsArr.length !== 0
                        ? modelsArr.map((make) => {
                            return (
                              <>
                                <Accordion.Header>{make[0]}</Accordion.Header>
                                <Accordion.Content>
                                  {make[1].map((el) => {
                                    return (
                                      <Box paddingLeft={3} display={"flex"} alignItems={"center"} gap={16} height={49}>
                                        <Checkbox
                                          className={"model"}
                                          label={el.label}
                                          name={el.value}
                                          onChange={() => selectOnChange(el.value, "models")}
                                          checked={filtersValues.models.includes(el.value)}
                                        />
                                      </Box>
                                    );
                                  })}
                                </Accordion.Content>
                              </>
                            );
                          })
                        : null}
                    </Accordion.Item>
                  </Accordion.Content>
                </Accordion.Item>
              </div>
              <div className="filters__accordion-header">
                <Accordion.Item>
                  <Accordion.Header>
                    <Badge count={filtersValues.bodyType.length} isVisible={filtersValues.bodyType.length !== 0}>
                      nadwozie
                    </Badge>
                  </Accordion.Header>
                  <Accordion.Content>
                    {renameAllObjectsKeysInArray(filters.BodyTypes).map((el) => {
                      return (
                        <Box paddingLeft={3} display={"flex"} alignItems={"center"} gap={16} height={49}>
                          <Checkbox
                            label={el.label}
                            name={el.value}
                            onChange={() => selectOnChange(el.value, "bodyType")}
                            checked={filtersValues.bodyType.includes(el.value)}
                          />
                        </Box>
                      );
                    })}
                  </Accordion.Content>
                </Accordion.Item>
              </div>
              <div className="filters__accordion-header">
                <Accordion.Item>
                  <Accordion.Header>
                    <Badge count={filtersValues.status.length} isVisible={filtersValues.status.length !== 0}>
                      stan
                    </Badge>
                  </Accordion.Header>
                  <Accordion.Content>
                    {renameAllObjectsKeysInArray(statusesFilter).map((el) => {
                      return (
                        <Box paddingLeft={3} display={"flex"} alignItems={"center"} gap={16} height={49}>
                          <Checkbox
                            label={el.label}
                            name={el.value}
                            onChange={() => selectOnChange(el.value, "status")}
                            checked={filtersValues.status.includes(el.value)}
                          />
                        </Box>
                      );
                    })}
                  </Accordion.Content>
                </Accordion.Item>
              </div>
              {(financialMethod === "leasing" || financialMethod === "all" || financialMethod === "rent") &&
                filtersValues.status !== "Aukcje" && (
                  <div className="filters__accordion-header">
                    <Accordion.Item>
                      <Accordion.Header>
                        <Badge isVisible={filtersValues.installmentFrom || filtersValues.installmentTo}>
                          wysokość raty
                        </Badge>
                      </Accordion.Header>
                      <Accordion.Content>
                        <div style={{ display: "flex" }}>
                          <div className="textfield-container-small">
                            <TextField
                              value={filtersValues.installmentFrom}
                              onChange={(e) => textFieldOnChange(e, "installmentFrom")}
                              inputProps={{
                                type: "number",
                                onKeyPress: (event) => keypressHandler(event)
                              }}
                              onBlur={filterOnBlur}
                              label="rata od"
                            />
                          </div>{" "}
                          <div className="textfield-container-small">
                            <TextField
                              value={filtersValues.installmentTo}
                              onChange={(e) => textFieldOnChange(e, "installmentTo")}
                              inputProps={{
                                type: "number",
                                onKeyPress: (event) => keypressHandler(event)
                              }}
                              onBlur={filterOnBlur}
                              label="rata do"
                            />
                          </div>{" "}
                        </div>
                      </Accordion.Content>
                    </Accordion.Item>
                  </div>
                )}
              <div className="filters__accordion-header">
                <Accordion.Item>
                  <Accordion.Header>
                    <Badge count={filtersValues.fuelType.length} isVisible={filtersValues.fuelType.length !== 0}>
                      paliwo
                    </Badge>
                  </Accordion.Header>
                  <Accordion.Content>
                    {renameAllObjectsKeysInArray(filters.FuelTypes).map((el) => {
                      return (
                        <Box paddingLeft={3} display={"flex"} alignItems={"center"} gap={16} height={49}>
                          <Checkbox
                            label={el.label}
                            name={el.value}
                            onChange={() => selectOnChange(el.value, "fuelType")}
                            checked={filtersValues.fuelType.includes(el.value)}
                          />
                        </Box>
                      );
                    })}
                  </Accordion.Content>
                </Accordion.Item>
              </div>
              <div className="filters__accordion-header">
                <Accordion.Item>
                  <Accordion.Header>
                    <Badge count={filtersValues.gearBoxType.length} isVisible={filtersValues.gearBoxType.length !== 0}>
                      skrzynia biegów
                    </Badge>
                  </Accordion.Header>
                  <Accordion.Content>
                    {renameAllObjectsKeysInArray(filters.GearBoxTypes).map((el) => {
                      return (
                        <Box paddingLeft={3} display={"flex"} alignItems={"center"} gap={16} height={49}>
                          <Checkbox
                            label={el.label}
                            name={el.value}
                            onChange={() => selectOnChange(el.value, "gearBoxType")}
                            checked={filtersValues.gearBoxType.includes(el.value)}
                          />
                        </Box>
                      );
                    })}
                  </Accordion.Content>
                </Accordion.Item>
              </div>
              {filtersValues.status !== "Nowy" && (
                <div className="filters__accordion-header">
                  <Accordion.Item>
                    <Accordion.Header>cena</Accordion.Header>
                    <Accordion.Content>
                      <div style={{ display: "flex" }}>
                        <div className="textfield-container-small">
                          <TextField
                            value={filtersValues.priceFrom}
                            onChange={(e) => textFieldOnChange(e, "priceFrom")}
                            onBlur={filterOnBlur}
                            inputProps={{
                              type: "number",
                              onKeyPress: (event) => keypressHandler(event)
                            }}
                            label="cena od"
                          />
                        </div>{" "}
                        <div className="textfield-container-small">
                          <TextField
                            value={filtersValues.priceTo}
                            onChange={(e) => textFieldOnChange(e, "priceTo")}
                            onBlur={filterOnBlur}
                            inputProps={{
                              type: "number",
                              onKeyPress: (event) => keypressHandler(event)
                            }}
                            label="cena do"
                          />
                        </div>
                      </div>
                    </Accordion.Content>
                  </Accordion.Item>
                </div>
              )}
              {filtersValues.status !== "Nowy" && (
                <div className="filters__accordion-header">
                  <Accordion.Item>
                    <Accordion.Header>przebieg</Accordion.Header>
                    <Accordion.Content>
                      <div style={{ display: "flex" }}>
                        <div className="textfield-container-small">
                          <TextField
                            value={filtersValues.milleageFrom}
                            onChange={(e) => textFieldOnChange(e, "milleageFrom")}
                            onBlur={filterOnBlur}
                            inputProps={{
                              type: "number",
                              onKeyPress: (event) => keypressHandler(event)
                            }}
                            label="przebieg od"
                          />
                        </div>{" "}
                        <div className="textfield-container-small">
                          <TextField
                            value={filtersValues.milleageTo}
                            onChange={(e) => textFieldOnChange(e, "milleageTo")}
                            onBlur={filterOnBlur}
                            inputProps={{
                              type: "number",
                              onKeyPress: (event) => keypressHandler(event)
                            }}
                            label="przebieg do"
                          />
                        </div>
                      </div>{" "}
                    </Accordion.Content>
                  </Accordion.Item>
                </div>
              )}
              <div className="filters__accordion-header">
                <Accordion.Item>
                  <Accordion.Header>
                    <Badge isVisible={filtersValues.productionFrom || filtersValues.productionTo}>rok produkcji</Badge>
                  </Accordion.Header>
                  <Accordion.Content>
                    <div style={{ display: "flex" }}>
                      <div className="textfield-container-small">
                        <TextField
                          value={filtersValues.productionFrom}
                          onChange={(e) => textFieldOnChange(e, "productionFrom")}
                          onBlur={filterOnBlur}
                          inputProps={{
                            type: "number",
                            onKeyPress: (event) => keypressHandler(event)
                          }}
                          label="produkcja od"
                        />
                      </div>{" "}
                      <div className="textfield-container-small">
                        <TextField
                          value={filtersValues.productionTo}
                          onChange={(e) => textFieldOnChange(e, "productionTo")}
                          onBlur={filterOnBlur}
                          inputProps={{
                            type: "number",
                            onKeyPress: (event) => keypressHandler(event)
                          }}
                          label="produkcja do"
                        />
                      </div>
                    </div>{" "}
                  </Accordion.Content>
                </Accordion.Item>
              </div>
              <div className="filters__accordion-header">
                <Accordion.Item>
                  <Accordion.Header>
                    <Badge isVisible={filtersValues.capacityFrom || filtersValues.capacityTo}>pojemność</Badge>
                  </Accordion.Header>
                  <Accordion.Content>
                    <div style={{ display: "flex" }}>
                      <div className="textfield-container-small">
                        <TextField
                          value={filtersValues.capacityFrom}
                          onChange={(e) => textFieldOnChange(e, "capacityFrom")}
                          onBlur={filterOnBlur}
                          inputProps={{
                            type: "number",
                            onKeyPress: (event) => keypressHandler(event)
                          }}
                          label="pojemność od"
                        />
                      </div>{" "}
                      <div className="textfield-container-small">
                        <TextField
                          value={filtersValues.capacityTo}
                          onChange={(e) => textFieldOnChange(e, "capacityTo")}
                          onBlur={filterOnBlur}
                          inputProps={{
                            type: "number",
                            onKeyPress: (event) => keypressHandler(event)
                          }}
                          label="pojemność do"
                        />
                      </div>
                    </div>{" "}
                  </Accordion.Content>
                </Accordion.Item>
              </div>
              <div className="filters__accordion-header">
                <Accordion.Item>
                  <Accordion.Header>
                    <Badge isVisible={filtersValues.powerFrom || filtersValues.powerTo}>moc</Badge>
                  </Accordion.Header>
                  <Accordion.Content>
                    <div style={{ display: "flex" }}>
                      <div className="textfield-container-small">
                        <TextField
                          value={filtersValues.powerFrom}
                          onChange={(e) => textFieldOnChange(e, "powerFrom")}
                          onBlur={filterOnBlur}
                          inputProps={{
                            type: "number",
                            onKeyPress: (event) => keypressHandler(event)
                          }}
                          label="moc od"
                        />
                      </div>{" "}
                      <div className="textfield-container-small">
                        <TextField
                          value={filtersValues.powerTo}
                          onChange={(e) => textFieldOnChange(e, "powerTo")}
                          onBlur={filterOnBlur}
                          inputProps={{
                            type: "number",
                            onKeyPress: (event) => keypressHandler(event)
                          }}
                          label="moc do"
                        />
                      </div>
                    </div>{" "}
                  </Accordion.Content>
                </Accordion.Item>
              </div>
              <div className="filters__accordion-header">
                <Accordion.Item>
                  <Accordion.Header>
                    <Badge count={filtersValues.location.length} isVisible={filtersValues.location.length !== 0}>
                      lokalizacja
                    </Badge>
                  </Accordion.Header>
                  <Accordion.Content>
                    {renameAllObjectsKeysInArray(filters.Locations).map((el) => {
                      return (
                        <Box paddingLeft={3} display={"flex"} alignItems={"center"} gap={16} height={49}>
                          <Checkbox
                            label={el.label}
                            name={el.value}
                            onChange={() => selectOnChange(el.value, "location")}
                            checked={filtersValues.location.includes(el.value)}
                          />
                        </Box>
                      );
                    })}
                  </Accordion.Content>
                </Accordion.Item>
              </div>
              <div className="filters__accordion-header">
                <Accordion.Item>
                  <Accordion.Header>
                    <Badge count={filtersValues.promotions.length} isVisible={filtersValues.promotions.length !== 0}>
                      promocje
                    </Badge>
                  </Accordion.Header>
                  <Accordion.Content>
                    {renameAllObjectsKeysInArray(filters.SpecialPromotions).map((el) => {
                      return (
                        <Box paddingLeft={3} display={"flex"} alignItems={"center"} gap={16} height={49}>
                          <Checkbox
                            label={el.label}
                            name={el.value}
                            onChange={() => selectOnChange(el.value, "promotions")}
                            checked={filtersValues.promotions.includes(el.value)}
                          />
                        </Box>
                      );
                    })}
                  </Accordion.Content>
                </Accordion.Item>
              </div>
              <div className="filters__accordion-header">
                <Accordion.Item>
                  <Accordion.Header>
                    <Badge isVisible={filtersValues.plateNumber}>numer rejestracyjny</Badge>
                  </Accordion.Header>
                  <Accordion.Content>
                    <TextField
                      value={filtersValues.plateNumber}
                      onChange={(e) => textFieldOnChange(e, "plateNumber")}
                      onBlur={filterOnBlur}
                      inputProps={{
                        type: "text",
                        onKeyPress: (event) => keypressHandler(event)
                      }}
                      label="numer rejestracyjny"
                    />
                  </Accordion.Content>
                </Accordion.Item>
              </div>
            </Accordion>
          </div>
          <div className="search-button-container">
            <div className="button-container">
              {isActiveFilter && (
                <ObserveVehicleButton
                  filtersValues={filtersValues}
                  countFilters={countFilters}
                  isCompany={isCompany}
                  financialMethod={financialMethod}
                />
              )}
              <Button
                onClick={() => {
                  if (isActiveFilter === 0) {
                    toggleMobileFilters();
                  }
                  searchCars(filtersValues, true);
                }}
              >
                {literals && isActiveFilter && countFilters !== 0
                  ? replaceTemplateValue(literals.filters_cta, countFilters)
                  : "wróć"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(MobileFilters);
