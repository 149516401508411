import { getLiterals } from "../services";

export const literalCreator = {
  data: null,
  getData: () => {
    return getLiterals().then((res) => {
      literalCreator.data = res.data;
    });
  },
  getLiterals: () => {
    if (literalCreator.data) return Promise.resolve(literalCreator.data);
    return literalCreator.getData();
  },
};
