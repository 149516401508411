import { useParams, withRouter } from "react-router-dom/cjs/react-router-dom";
import ListPage from "../../pages/ListPage";
import DetailsPage from "../../pages/DetailsPage";

const RedirectWrapper = (props) => {
  const path = props.location.pathname;

  const newRegex = /nowe-samochody\/\d{5}/
  const afterLeaseRegex = /samochody-poleasingowe\/\d{5}/

  function containsOnlyDigits(str) {
    return /^\d+$/.test(str);
  }
  return (
    <>
      {(props && path.includes("-id-")) || containsOnlyDigits(path) || path.includes("new") || path.includes("afterLease") || newRegex.test(path) || afterLeaseRegex.test(path) ? (
        <DetailsPage
          setDarkMode={props.themeDark}
          setDarkTheme={props.setDarkTheme}
          avalibleMakesAndModels={props.avalibleMakesAndModels}
          avalibleBodyTypes={props.avalibleBodyTypes}
        />
      ) : (
        <ListPage
          offersFiltersDataFromApi={props.offersFiltersDataFromApi}
          avalibleMakesAndModels={props.avalibleMakesAndModels}
          multipleVariantsInfo={props.multipleVariantsInfo}
        />
      )}
    </>
  );
};

export default withRouter(RedirectWrapper);
