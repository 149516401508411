import React, { Component } from "react";
import { Text, Button } from "@mbank-design/design-system/components";
import "./index.scss"
import { FontColor, FontSize, LineHeight } from "@mbank-design/design-system/design-tokens/general/typography";
import { ButtonType } from "@mbank-design/design-system/enums";

export default class CookiesInformation extends Component {
  state = {
    cookiesModalHidden: false
  };

  changeBgcPagesList = ['/lp-tesla-y', '/lp-afi', '/lp-bmw']
  
  componentDidMount() {
    if (localStorage.getItem("cookiesModalHidden")) {
      this.setState({ cookiesModalHidden: true });
    }
  }

  acceptCookies() {
    this.setState({ cookiesModalHidden: true }, () => {
      localStorage.setItem("cookiesModalHidden", true);
    });
  }

  render() {
    return (
      <React.Fragment>
        {!this.state.cookiesModalHidden && (
          <div
          className={`cookies-container ${
            this.changeBgcPagesList.includes(window.location.pathname.toLowerCase())
              ? "cookies-container--new-background"
              : ""
          }`}
          >
            <div className="description">
              <Text
                as="span"
                fontSize={FontSize.SIZE_12}
                lineHeight={LineHeight.HEIGHT_20}
              >
                Strona mauto.pl wykorzystuje Pliki cookies w celach
                technicznych, statystycznych oraz marketingowych.{" "}
              </Text>
              {!this.state.showMore && window.innerWidth < 1024 && (
                <Text
                  as="span"
                  fontSize={FontSize.SIZE_12}
                  lineHeight={LineHeight.HEIGHT_20}
                  color={FontColor.deepCeruleanBlueUI}
                  onClick={() => this.setState({ showMore: true })}
                >
                  rozwiń
                </Text>
              )}
              {(this.state.showMore || window.innerWidth > 1023) && (
                <Text
                  as="span"
                  fontSize={FontSize.SIZE_12}
                  lineHeight={LineHeight.HEIGHT_20}
                >
                  Korzystanie ze strony bez zmiany ustawień powoduje, że pliki
                  te będą umieszczane na Twoim urządzeniu końcowym (np.
                  komputerze). Ich wyłączenie może prowadzić do niepoprawnego
                  lub niepełnego działania strony i jej funkcjonalności. W
                  przypadku braku zmiany ustawień Plików Cookies uznamy, że
                  zapoznałeś się z tą wiadomością i akceptujesz nasze pliki
                  cookies.
                </Text>
              )}
            </div>
            <div className="button-container">
              <Button
                onClick={() => this.acceptCookies()}
                buttonType={ButtonType.SECONDARY}
              >
                ok
              </Button>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
