import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import "./index.scss";
import {
  Text,
  TextField,
  Icon,
  Button,
  Menu,
  Chip,
  Checkbox,
  Switch,
  SearchField,
  Box,
  IconButton,
  Stack,
  Badge,
  Accordion
} from "@mbank-design/design-system/components";
import { Cross, TrashBin, Filter, ArrowsUpDown } from "@mbank-design/design-system/icons";
import { withRouter } from "react-router-dom";
import { literalCreator } from "../../../literals/literals";
import { IS_COMPANY_MOCKED_OPTIONS, VOIVODESHIPS_DICT } from "../../../constants";
import { capitalize, sortByName } from "../../../scripts/commons/common";
import uuid from "react-uuid";
import ObserveVehicleButton from "../../parts/ObserveVehicleButton";
import MobileFilters from "../MobileFilters";
import { sortingOptions } from "../../../constants";
import { FontSize } from "@mbank-design/design-system/design-tokens/general/typography";
import { Media } from "@mbank-design/design-system/components";
import { replaceTemplateValue } from "../../../literals/templateValueChanger";

class ListBanner extends Component {
  constructor(props) {
    super(props);
    this.finRef = createRef();
    this.makeRef = createRef();
    this.modelRef = createRef();
    this.bodyTypeRef = createRef();
    this.stateRef = createRef();
    this.fuelRef = createRef();
    this.installmentRef = createRef();
    this.milleageRef = createRef();
    this.gearboxRef = createRef();
    this.manufactureRef = createRef();
    this.capacityRef = createRef();
    this.powerRef = createRef();
    this.locationRef = createRef();
    this.plateRef = createRef();
    this.sortButtonRef = createRef();
    this.priceRef = createRef();
    this.promoRef = createRef();

    this.renameKeys = this.renameKeys.bind(this);
    this.renameAllObjectsKeysInArray = this.renameAllObjectsKeysInArray.bind(this);
    this.changeFinancialMethod = this.changeFinancialMethod.bind(this);
    this.selectOnChange = this.selectOnChange.bind(this);
    this.textFieldOnChange = this.textFieldOnChange.bind(this);
    this.filterOnBlur = this.filterOnBlur.bind(this);
    this.searchCars = this.searchCars.bind(this);
    this.cleanAllFilters = this.cleanAllFilters.bind(this);
    this.filterMakesListOnTagClick = this.filterMakesListOnTagClick.bind(this);
    this.setContractorType = this.setContractorType.bind(this);
    this.handleIsCompanyChange = this.handleIsCompanyChange.bind(this);
  }
  static propTypes = {
    filters: PropTypes.object.isRequired,
    selectedSortingValue: PropTypes.string.isRequired,
    isCompany: PropTypes.bool,
    multipleVariantsInfo: PropTypes.array.isRequired
  };

  initialFilterValues = {
    bodyType: [],
    capacityFrom: "",
    capacityTo: "",
    doorsCount: [],
    fuelType: [],
    gearBoxType: [],
    installmentFrom: "",
    installmentTo: "",
    location: [],
    makes: [],
    milleageFrom: "",
    milleageTo: "",
    models: [],
    plateNumber: "",
    powerFrom: "",
    powerTo: "",
    priceFrom: "",
    priceTo: "",
    productionFrom: "",
    productionTo: "",
    seatsCount: [],
    status: [],
    promotions: []
  };

  state = {
    isCompany: true,
    financialMethod: "all",
    allFilters: false,
    filtersValues: this.initialFilterValues,
    isCompanyMockedOptions: IS_COMPANY_MOCKED_OPTIONS,
    statusesFilter: [],
    searchCriteria: "",
    searchPhrase: "",
    searchLocationPhrase: "",
    menusState: {
      isFinMenuOpen: false,
      isMakesMenuOpen: false,
      isModelsOpen: false,
      isStateMenuOpen: false,
      isBodyTypeMenuOpen: false,
      isFuelMenuOpen: false,
      isInstallmentMenuOpen: false,
      isMilleageMenuOpen: false,
      isPriceMenuOpen: false,
      isGearboxMenuOpen: false,
      isManufactureMenuOpen: false,
      isCapacityMenuOpen: false,
      isPowerMenuOpen: false,
      isLocationMenuOpen: false,
      isPlateMenuOpen: false,
      isSortMenuOpen: false,
      isPromoMenuOpen: false
    }
  };

  bindedSearchOnEnter = this.searchCarsOnEnter.bind(this);

  componentDidMount() {
    this.removeUnavailableMakesFromFilters();
    this.renderDataFromUrlStaticOrQuery();

    this.setState({
      statusesFilter: this.changeListOfStatusesDeremitatedByFinancingMethod(this.props.filters)
    });

    literalCreator.getLiterals().then((res) => {
      this.setState({ literals: literalCreator.data });
    });

    document.addEventListener("keypress", this.bindedSearchOnEnter);
  }

  removeUnavailableMakesFromFilters() {
    this.props.filters.MakesAndModels = this.props.filters.MakesAndModels.filter(
      (makeModel) => makeModel.Make.Count > 0
    );
  }

  renderDataFromUrlStaticOrQuery() {
    const extractParameters = (path) => {
      const segments = path.split("/").filter(Boolean);
      let params = {
        carState: null,
        fuelType: null,
        make: null,
        model: null,
        bodyType: null
      };
    
      const carStateMap = {
        "samochody-poleasingowe": "Poleasingowy",
        "nowe-samochody": "Nowy",
        "samochody-elektryczne": "5"
      };
    
      const bodyTypeMap = {
        hatchback: "1",
        sedan: "3",
        kombi: "4",
        suv: "5",
        bus: "9",
        van: "6"
      };
    
      const multiPartMakes = ["land-rover", "mercedes-benz", "alfa-romeo"];
    
      segments.forEach((segment, index) => {
        if (carStateMap[segment]) {
          if (segment === "samochody-elektryczne") {
            params.fuelType = carStateMap[segment];
          } else {
            params.carState = carStateMap[segment];
          }
        } else if (bodyTypeMap[segment]) {
          params.bodyType = bodyTypeMap[segment];
        } else {
          const previousSegment = segments[index - 1];
          if (multiPartMakes.includes(segment)) {
            params.make = segment;
          } else if (previousSegment && multiPartMakes.includes(`${previousSegment}-${segment}`)) {
            params.make = `${previousSegment}-${segment}`;
          } else {
            const makeAndModel = segment.split("-");
            if (makeAndModel.length > 1) {
              if (makeAndModel[0] === "land" || makeAndModel[0] === "mercedes"|| makeAndModel[0] === "alfa") {
                params.make = makeAndModel[0] + "-" + makeAndModel[1]
                params.model = makeAndModel.slice(2).join("-");
              } else {
                params.make = makeAndModel[0];
                params.model = makeAndModel.slice(1).join("-");
              }
            } else {
              params.make = makeAndModel[0];
            }
          }
        }
      });
      return params;
    };
    const params = extractParameters(this.props.location.pathname);

    const updateFilters = () => {
      let filters = { ...this.initialFilterValues };

      const fullListModels = this.props.filters.MakesAndModels.map((el)=>{return el.Models}).flatMap(innerArray => innerArray.map(item => item.Name))
      const modelName = params.model && fullListModels.find((name)=>name.toUpperCase().replace(" ","-") === params.model.toUpperCase());

      if (params.carState) filters.status = [params.carState];
      if (params.fuelType) filters.fuelType = [params.fuelType];
      if (params.bodyType) filters.bodyType = [params.bodyType];
      if (params.make) filters.makes = [params.make.toUpperCase()];
      if (params.model) filters.models = [modelName];
      this.setState({ filtersValues: filters }, () => {
        this.readDataFromUrlAndSearchCars();
      });
    };

    if (this.props.location.pathname.includes("/promocje")) {
      this.setState(
        {
          filtersValues: {
            ...this.initialFilterValues,
            promotions:
              this.props.filters.SpecialPromotions && this.props.filters.SpecialPromotions.map((promo) => promo.Value)
          }
        },
        () => {
          this.readDataFromUrlAndSearchCars();
        }
      );
    } else {
      let propsSplitted = this.props.location.pathname.toLowerCase().split("/").join("").split("-");
      if (
        propsSplitted &&
        this.props.multipleVariantsInfo &&
        propsSplitted.length === 2 &&
        this.props.multipleVariantsInfo.length > 0
      ) {
        let filteredCar = this.props.multipleVariantsInfo.find(
          (x) => x.Make.toLowerCase() === propsSplitted[0] && x.Model.toLowerCase() === propsSplitted[1]
        );
        if (filteredCar) {
          this.setState(
            {
              filtersValues: {
                ...this.state.filtersValues,
                status: ["Nowy"],
                makes: [filteredCar.Make]
              }
            },
            () => {
              this.setState(
                {
                  filtersValues: {
                    ...this.state.filtersValues,
                    models: [filteredCar.Model]
                  }
                },
                () => {
                  this.readDataFromUrlAndSearchCars();
                }
              );
            }
          );
        } else {
          if (this.props.location.pathname.includes("/lista-pojazdow")) {
            this.readDataFromUrlAndSearchCars();
            return;
          }
          updateFilters();
        }
      } else {
        if (this.props.location.pathname.includes("/lista-pojazdow")) {
          this.readDataFromUrlAndSearchCars();
          return;
        }
        updateFilters();
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.changeFiltersFieldsDataOnStateChange(prevState);

    if (
      this.props.location.search !== prevProps.location.search ||
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      this.renderDataFromUrlStaticOrQuery();
    }
  }

  componentWillUnmount() {
    this.removeUnavailableMakesFromFilters();
    localStorage.removeItem("lastFilterObject");
    document.removeEventListener("keypress", this.bindedSearchOnEnter);
  }

  changeFiltersFieldsDataOnStateChange(prevState) {
    if (prevState.financialMethod !== this.state.financialMethod) {
      switch (this.state.financialMethod) {
        case "cash":
          {
            this.setState(
              {
                filtersValues: {
                  ...this.state.filtersValues,
                  status: ["Poleasingowy"],
                  installmentFrom: "",
                  installmentTo: ""
                },
                statusesFilter: this.changeListOfStatusesDeremitatedByFinancingMethod(this.props.filters)
              },
              () => {
                window.innerWidth > 1023 && this.searchCars(this.state.filtersValues);
                this.props.checkCountOfAvalibleCarsBasedOnCurentFilters(
                  this.state.filtersValues,
                  this.state.isCompany,
                  this.state.financialMethod
                );
              }
            );
          }
          break;
        case "leasing":
          {
            this.setState(
              {
                filtersValues: {
                  ...this.state.filtersValues
                },
                statusesFilter: this.changeListOfStatusesDeremitatedByFinancingMethod(this.props.filters)
              },
              () => {
                window.innerWidth > 1023 && this.searchCars(this.state.filtersValues);
                this.props.checkCountOfAvalibleCarsBasedOnCurentFilters(
                  this.state.filtersValues,
                  this.state.isCompany,
                  this.state.financialMethod
                );
              }
            );
          }
          break;
        case "all":
        case "rent":
          {
            this.setState(
              {
                filtersValues: {
                  ...this.state.filtersValues,
                  status: []
                },
                statusesFilter: this.changeListOfStatusesDeremitatedByFinancingMethod(this.props.filters)
              },
              () => {
                window.innerWidth > 1023 && this.searchCars(this.state.filtersValues);
                this.props.checkCountOfAvalibleCarsBasedOnCurentFilters(
                  this.state.filtersValues,
                  this.state.isCompany,
                  this.state.financialMethod
                );
              }
            );
          }
          break;
      }
    }
    if (!prevState.filtersValues.status.includes("Nowy") && this.state.filtersValues.status.includes("Nowy")) {
      this.setState({
        filtersValues: {
          ...this.state.filtersValues,
          priceFrom: "",
          priceTo: "",
          productionFrom: "",
          productionTo: "",
          milleageFrom: "",
          milleageTo: ""
        }
      });
    }
  }

  readDataFromUrlAndSearchCars() {
    let allParamsString = window.location.href.slice(window.location.href.indexOf("?") + 1);
    const params = [
      "&utm_source",
      "&utm_medium",
      "utm_campaign",
      "utm_content",
      "dclid",
      "mAutoCampaign",
      "mAutoGCLID",
      "mAutoclick_id"
    ];
    params.forEach((param) => {
      if (allParamsString.indexOf(param) !== -1) {
        allParamsString = allParamsString.slice(0, allParamsString.indexOf(param));
      }
    });

    let filtersValues = this.initialFilterValues;
    const urlParams = new URLSearchParams(allParamsString);
    const paramsObj = this.paramsToObject(urlParams.entries());
    if (
      JSON.stringify(filtersValues) === JSON.stringify(this.state.filtersValues) ||
      Object.values(paramsObj).findIndex((paramValue) => paramValue.length > 0) !== -1
    ) {
      Object.keys(paramsObj).forEach((el) => {
        if (
          this.props.location.pathname.includes("samochody-elektryczne") ||
          this.props.location.pathname.includes("samochody-poleasingowe") ||
          this.props.location.pathname.includes("nowe-samochody")
        ) {
          filtersValues = {
            ...this.state.filtersValues,
            [el]: paramsObj[el]
          };
        } else {
          if (el === "financialMethod" || el === "selectedSortingValue") {
            this.setState({ [el]: paramsObj[el] });
          } else if (el === "isCompany") {
            localStorage.setItem("isCompany", JSON.parse(paramsObj[el]).toString());
            this.setState({ [el]: JSON.parse(paramsObj[el]) });
          } else if (
            el === "bodyType" ||
            el === "fuelType" ||
            el === "status" ||
            el === "gearBoxType" ||
            el === "location" ||
            el === "seatsCount" ||
            el === "doorsCount" ||
            el === "makes" ||
            el === "models" ||
            el === "promotions"
          ) {
            filtersValues = {
              ...filtersValues,
              [el]: paramsObj[el].includes(",") ? paramsObj[el].split(",") : [paramsObj[el]]
            };
          } else {
            filtersValues = {
              ...this.state.filtersValues,
              [el]: paramsObj[el]
            };
          }
        }
      });
    } else {
      filtersValues = this.state.filtersValues;
    }

    const modelsToSetAfterMakesSelectInit =
      filtersValues.models && filtersValues.models.length ? Array.from(filtersValues.models) : [];
    filtersValues.models = [];

    this.setState({ filtersValues: filtersValues }, () => {
      this.setState(
        {
          filtersValues: {
            ...filtersValues,
            models: modelsToSetAfterMakesSelectInit
          }
        },
        () => {
          this.handleIsCompanyChange(this.state.isCompany);
          this.props.checkCountOfAvalibleCarsBasedOnCurentFilters(
            this.state.filtersValues,
            this.state.isCompany,
            this.state.financialMethod
          );
          this.props.searchCarsFromApi(this.state.filtersValues, this.state.isCompany, this.state.financialMethod);
          localStorage.setItem("lastFilterObject", JSON.stringify(this.state.filtersValues));
        }
      );
    });
  }

  paramsToObject(entries) {
    let result = {};
    for (let entry of entries) {
      const [key, value] = entry;
      result[key] = value;
    }
    return result;
  }

  keypressHandler(event) {
    if (event.key === "Enter") {
      event.target.blur();
    }
  }

  async selectOnChange(value, name) {
    await this.onChangeModelOrMake(name, value);
    this.setState(
      (prevState) => {
        const updatedValues = [...prevState.filtersValues[name]];
        const valueIndex = updatedValues.indexOf(value);

        if (valueIndex !== -1) {
          updatedValues.splice(valueIndex, 1);
        } else {
          updatedValues.push(value);
        }

        return {
          filtersValues: {
            ...prevState.filtersValues,
            [name]: updatedValues
          }
        };
      },
      () => {
        this.props.checkCountOfAvalibleCarsBasedOnCurentFilters(
          this.state.filtersValues,
          this.state.isCompany,
          this.state.financialMethod
        );
      }
    );
  }

  async onChangeModelOrMake(name, value) {
    if (name === "makes") {
      const modelsArray = [];
      this.props.filters.MakesAndModels.forEach((car) => {
        if (value.includes(car.Make.Value)) {
          return car.Models.forEach((model) => {
            if (this.state.filtersValues.models.includes(model.Name)) {
              modelsArray.push(model.Name);
            }
          });
        }
      });

      await this.setState({
        filtersValues: { ...this.state.filtersValues, models: modelsArray }
      });
    }
  }

  textFieldOnChange(e, name) {
    this.setState({
      filtersValues: { ...this.state.filtersValues, [name]: e.target.value }
    });
  }
  getQueryParams = (url) => {
    const params = new URLSearchParams(url.replace("/lista-pojazdow", ""));
    const keyArr = [];
    for (const key of params.keys()) {
      keyArr.push(key);
    }
    const allowedKeys = [
      "fuelType",
      "makes",
      "models",
      "status",
      "isCompany",
      "financialMethod",
      "selectedSortingValue"
    ];

    if (!keyArr.every((el) => allowedKeys.includes(el))) {
      return undefined;
    }

    return {
      makes: params.get("makes"),
      models: params.get("models"),
      fuelType: params.get("fuelType"),
      status: params.get("status"),
      isCompany: params.get("isCompany"),
      financialMethod: params.get("financialMethod"),
      selectedSortingValue: params.get("selectedSortingValue")
    };
  };

  checkUrlConditions = (url) => {
    let newUrl= '';
    const { makes, models, fuelType, status, ...rest } = this.getQueryParams(url) || {};

    if (!url.includes("&isCompany=true&financialMethod=all&selectedSortingValue=0")) {
      return false;
    }

    if (!makes && !models && !fuelType && !status) {
      return false;
    }

    const makesCount = makes && makes.split(",").length;
    const modelsCount = models && models.split(",").length;
    const fuelsCount = fuelType && fuelType.split(",").length;
    const statusCount = status && status.split(",").length;
    const makeUrl = makes && makes.split(" ").join("-").toLowerCase();
    const modelUrl = models && models.split(" ").join("-").toLowerCase();

    if (makesCount > 1 || modelsCount > 1) {
      return false;
    }

    if (status && fuelType) {
      return false;
    }

    if (makesCount === 1 && fuelsCount === 1 && statusCount !== 1 && fuelType === "5") {
      return modelsCount === 1
        ? newUrl =`/samochody-elektryczne/${makeUrl}-${modelUrl}`
        : newUrl =`/samochody-elektryczne/${makeUrl}`;
    } else if (makesCount === 1 && statusCount === 1 && fuelType !== 1) {
      return modelsCount === 1
        ? newUrl=
            `/${status === "Nowy" ? "nowe-samochody" : "samochody-poleasingowe"}/${makeUrl}-${modelUrl}`
          
        : newUrl=`/${status === "Nowy" ? "nowe-samochody" : "samochody-poleasingowe"}/${makeUrl}`;
    } else if (makesCount === 1 && modelsCount === 1) {
      return newUrl=`/marki/${makeUrl}-${modelUrl}`;
    } else {
      return false;
    }
  };

  addFiltersToUrl(object, selectedSortingValue) {
    const objectURL = Object.keys(object)
      .map((key, index) => {
        if (object[key].length) {
          return key + "=" + object[key] + "&";
        }
      })
      .join("")
      .slice(0, -1);
    const url =
      "/lista-pojazdow?" +
      objectURL +
      "&isCompany=" +
      this.state.isCompany +
      "&financialMethod=" +
      this.state.financialMethod +
      "&selectedSortingValue=" +
      (selectedSortingValue || this.state.selectedSortingValue || "0");
      if(this.checkUrlConditions(url)){
        this.props.history.push(this.checkUrlConditions(url));
      }else{
        this.props.history.push(url);
      }
  }

  searchCars(object, isFromButton) {
    let prevFilterObject = localStorage.getItem("lastFilterObject");
    //we want to trigger funciton only if given object if different than last filterValues state
    if (prevFilterObject !== JSON.stringify(object)) {
      this.addFiltersToUrl(object);
      localStorage.setItem("lastFilterObject", JSON.stringify(object));
      localStorage.removeItem("previousViewedCarId");
      localStorage.removeItem("carsCount");
    }

    if (window.innerWidth < 1024 && isFromButton) {
      this.props.toggleMobileFilters();
    }
  }

  searchCarsOnEnter(e) {
    if (e.target.localName === "input") {
      return;
    }
    if (e.key === "Enter") {
      this.searchCars(this.state.filtersValues);
    }
  }

  cleanAllFilters() {
    this.removeUnavailableMakesFromFilters();
    this.setState(
      {
        filtersValues: this.initialFilterValues,
        financialMethod: "all",
        isCompany: true
      },
      () => {
        this.handleIsCompanyChange(this.state.isCompany);
        this.props.checkCountOfAvalibleCarsBasedOnCurentFilters(
          this.state.filtersValues,
          this.state.isCompany,
          this.state.financialMethod
        );
        this.searchCars(this.state.filtersValues);
      }
    );
  }

  filterOnBlur = () => {
    this.props.checkCountOfAvalibleCarsBasedOnCurentFilters(
      this.state.filtersValues,
      this.state.isCompany,
      this.state.financialMethod
    );
    this.searchCars(this.state.filtersValues);
  };

  setContractorType(isCompany) {
    this.setState({ isCompany: isCompany }, () => {
      if (!this.state.isCompany) {
        this.setState(
          {
            financialMethod: "cash",
            filtersValues: {
              ...this.state.filtersValues,
              status: ["Poleasingowy"]
            }
          },
          () => {
            this.searchCars(this.state.filtersValues);
          }
        );
      } else {
        this.setState(
          {
            financialMethod: "all",
            filtersValues: {
              ...this.state.filtersValues,
              status: []
            }
          },
          () => {
            this.searchCars(this.state.filtersValues);
          }
        );
      }
    });
  }

  handleIsCompanyChange(isCompany) {
    let updatedOptions = JSON.parse(JSON.stringify(this.state.isCompanyMockedOptions));
    if (isCompany) {
      updatedOptions.find((option) => option.Value === "isCompany").Status = true;
      updatedOptions.find((option) => option.Value === "isPrivate").Status = false;
    } else {
      updatedOptions.find((option) => option.Value === "isCompany").Status = false;
      updatedOptions.find((option) => option.Value === "isPrivate").Status = true;
    }

    this.setState({
      isCompanyMockedOptions: updatedOptions
    });
  }

  renameKeys(obj, newKeys) {
    const keyValues = Object.keys(obj).map((key) => {
      if (key === "Value" || key === "Name") {
        const newKey = newKeys[key] || key;
        return { [newKey]: obj[key] };
      }
    });
    return Object.assign({}, ...keyValues);
  }

  renameAllObjectsKeysInArray(array) {
    return array && array.length
      ? array.map((el) => {
          return this.renameKeys(el, { Name: "label", Value: "value" });
        })
      : [];
  }
  changeListOfStatusesDeremitatedByFinancingMethod(filters) {
    let statuses = [];

    if (this.state.financialMethod === "cash" && this.state.isCompany) {
      statuses = filters.Statuses.filter((el) => el.Name === "Poleasingowy");
    } else if (this.state.financialMethod === "cash" && !this.state.isCompany) {
      statuses = filters.Statuses.filter((el) => el.Name === "Poleasingowy");
    } else if (this.state.financialMethod === "leasing") {
      statuses = filters.Statuses.filter((el) => el.Name === "Poleasingowy" || el.Name === "Nowy");
    } else if (this.state.financialMethod === "rent") {
      statuses = filters.Statuses.filter(
        (el) => el.Name === "Poleasingowy" || el.Name === "Nowy" || el.Name === "Wszystkie"
      );
    } else {
      statuses = filters.Statuses;
    }

    statuses = statuses.map((el) => {
      return { ...el, Name: el.Name.toLowerCase() };
    });
    return statuses;
  }

  mapModelsIfMakeIsSelected(filters, filtersValues) {
    const modelsArr = [];
    filters.MakesAndModels.forEach((el) => {
      if (filtersValues.makes.includes(el.Make.Value)) {
        modelsArr.push([el.Make.Name, this.renameAllObjectsKeysInArray(el.Models.sort(sortByName))]);
      }
    });
    return modelsArr;
  }
  createArrOfModelsIfMakeIsSelected(filters, filtersValues) {
    const modelsArr = [];
    filters && filters.MakesAndModels && filters.MakesAndModels.forEach((el) => {});
    return modelsArr;
  }

  changeFinancialMethod(method, e) {
    e.preventDefault();
    if (this.state.financialMethod === method) {
      method = "all";
    }
    if (method === "cash") {
      this.setState(
        {
          filtersValues: {
            ...this.state.filtersValues,
            status: ["Poleasingowy"]
          }
        },
        () => {
          this.setState(
            {
              financialMethod: method
            },
            () => {
              this.props.setSelectedSortingValue("0");
              this.addFiltersToUrl(this.state.filtersValues);
            }
          );
        }
      );
    } else if (method === "financing") {
      this.setState(
        {
          financialMethod: method
        },
        () => {
          this.props.setSelectedSortingValue("0");
          this.addFiltersToUrl(this.state.filtersValues);
        }
      );
    } else {
      this.setState(
        {
          financialMethod: method
        },
        () => {
          this.props.setSelectedSortingValue("0");
          this.addFiltersToUrl(this.state.filtersValues);
        }
      );
    }
  }

  changeConfirmButton() {
    if (window.innerWidth < 1024) {
      setTimeout(() => {
        let confirmButtonContainer = document.querySelector('[data-test-id*="Menu:Container"] button');
        if (confirmButtonContainer) {
          confirmButtonContainer.className += " confirm-button";
          confirmButtonContainer.textContent = "zatwierdź";
          confirmButtonContainer.parentElement.className += " confirm-button-container";
        }

        let listContainer = document.querySelector('[data-test-id*="Menu:Container"] [data-component="List"]');
        if (listContainer) {
          listContainer.className += " list-container";
          let elementsContainer = listContainer.parentElement;

          let bodyHeight = document.querySelector("body").offsetHeight;
          let elementsContainerHeight = elementsContainer.offsetHeight;

          if (elementsContainerHeight >= bodyHeight) {
            elementsContainer.className += " elements-container__full-screen";
          } else {
            elementsContainer.className += " elements-container__not-full-screen";
          }
        }

        let myUl = document.querySelector('[data-test-id*="Menu:Container"] ul');
        if (myUl) {
          let myLi = document.createElement("li");
          myLi.className += " li-elem";
          myUl.appendChild(myLi);
        }
      }, 0);
    }
  }

  createAvailableYearsList = () => {
    const yearsArray = this.props.filters.AvailableProdYears;
    return yearsArray.map((year) => {
      return { Name: year, Value: String(year) };
    });
  };

  filterMakesListOnTagClick = (val, name) => {
    if (name === "makes") {
      const makesArray = this.state.filtersValues.makes.filter((make) => {
        return make !== val;
      });
      this.setState({ filtersValues: { ...this.state.filtersValues, makes: makesArray } });
    } else if (name === "models") {
      const modelsArray = this.state.filtersValues.models.filter((model) => {
        return model !== val;
      });
      this.setState({ filtersValues: { ...this.state.filtersValues, models: modelsArray } });
    } else if (name === "location") {
      const locationsArray = this.state.filtersValues.location.filter((model) => {
        return model !== val;
      });
      this.setState({ filtersValues: { ...this.state.filtersValues, location: locationsArray } });
    }
  };

  hasAllElements = (arr1, arr2) => {
    const referenceArray1 = arr1.reduce((acc, val) => {
      acc[val] = (acc[val] || 0) + 1;
      return acc;
    }, {});

    const referenceArray2 = arr2.reduce((acc, val) => {
      acc[val] = (acc[val] || 0) + 1;
      return acc;
    }, {});

    for (const key of Object.keys(referenceArray2)) {
      if (!(key in referenceArray1) || referenceArray2[key] > referenceArray1[key]) {
        return false;
      }
    }

    return true;
  };

  modelsListChecked = (make) => {
    this.hasAllElements(
      this.state.filtersValues.models,
      make[1].map((car) => car.value)
    )
      ? this.setState({
          filtersValues: {
            ...this.state.filtersValues,
            models: this.state.filtersValues.models.filter((model) => !make[1].map((car) => car.value).includes(model))
          }
        })
      : this.setState({
          filtersValues: {
            ...this.state.filtersValues,
            models: [...this.state.filtersValues.models, ...make[1].map((car) => car.value)]
          }
        });
  };

  setChange = (e) => {
    this.setState({ searchPhrase: e.target.value });
  };

  hasCorrespondingIdValue(arrOfLocations, searchIdVal) {
    for (const obj of arrOfLocations) {
      if (obj.hasOwnProperty("VoivodeshipId") && obj.VoivodeshipId === searchIdVal) {
        return true;
      }
    }
    return false;
  }

  render() {
    const filters = this.props.filters;
    const filtersValues = this.state.filtersValues;

    const makesArr = this.renameAllObjectsKeysInArray(filters.MakesAndModels.map((el) => el.Make));
    const modelsArr = this.mapModelsIfMakeIsSelected(filters, filtersValues);
    const activeFiltersCount = JSON.parse(localStorage.getItem("activeFiltersCount"));
    const availableYears = this.createAvailableYearsList();

    return (
      <>
        <div className="filters">
          <div className="filters__container">
            <div className="filters__top-section">
              <Text as="p" styledAs="h3" fontSize={FontSize.SIZE_24} className="searching-text">
                znaleźliśmy {this.props.countFilters}
                {this.props.countFilters === 1 && " samochód"}
                {this.props.countFilters > 1 && this.props.countFilters <= 4 && " samochody"}
                {(this.props.countFilters > 4 || this.props.countFilters === 0) && " samochodów"}
              </Text>
              <Switch
                labelPlacement="left"
                checked={this.state.isCompany}
                onChange={(e) => {
                  this.setContractorType(e.target.checked);
                  this.handleIsCompanyChange(e.target.checked);
                }}
              >
                oferty na firmę
              </Switch>
            </div>
            {(window.innerWidth >= 1024 || activeFiltersCount > 0) && (
              <div className="filters__chips">
                <div
                  className={`chip-container ${
                    this.state.financialMethod && this.state.financialMethod !== "all" ? "active" : ""
                  }`}
                >
                  <Chip
                    ref={this.finRef}
                    onClick={() => {
                      this.setState({
                        menusState: { ...this.state.menusState, isFinMenuOpen: !this.state.menusState.isFinMenuOpen }
                      });
                    }}
                    isSelected={this.state.financialMethod && this.state.financialMethod !== "all"}
                  >
                    forma zakupu
                  </Chip>
                </div>
                <Menu
                  anchor={this.finRef}
                  isOpen={this.state.menusState.isFinMenuOpen}
                  onClose={() => {
                    this.setState({
                      menusState: { ...this.state.menusState, isFinMenuOpen: false }
                    });
                    this.filterOnBlur();
                    this.setState({ searchPhrase: "" });
                  }}
                >
                  <Menu.Item onClick={(e) => this.changeFinancialMethod("financing", e)}>
                    <Checkbox
                      label={"finansowanie"}
                      name="financing"
                      checked={this.state.financialMethod === "financing"}
                    />
                  </Menu.Item>
                  <Menu.Item onClick={(e) => this.changeFinancialMethod("cash", e)}>
                    <Checkbox label={"gotówka"} name="cash" checked={this.state.financialMethod === "cash"} />
                  </Menu.Item>
                  <Text
                    as={"p"}
                    styledAs={"label"}
                    onClick={() => this.setState({ financialMethod: "all" })}
                    className={"clear-text"}
                  >
                    wyczyść
                  </Text>
                </Menu>
                <div className={`chip-container ${this.state.filtersValues.makes.length > 0 ? "active" : ""}`}>
                  <Chip
                    ref={this.makeRef}
                    onClick={() => {
                      this.setState({
                        menusState: {
                          ...this.state.menusState,
                          isMakesMenuOpen: !this.state.menusState.isMakesMenuOpen
                        }
                      });
                    }}
                    isSelected={this.state.filtersValues.makes.length > 0}
                  >
                    {`marka ${
                      this.state.filtersValues.makes.length > 0 ? `(${this.state.filtersValues.makes.length})` : ""
                    }`}
                  </Chip>
                </div>
                <Menu
                  maxVisibleItems={12}
                  anchor={this.makeRef}
                  isOpen={this.state.menusState.isMakesMenuOpen}
                  onClose={() => {
                    this.setState({
                      menusState: { ...this.state.menusState, isMakesMenuOpen: false }
                    });
                    this.filterOnBlur();
                    this.setState({ searchPhrase: "" });
                  }}
                  minWidth={376}
                >
                  <Box>
                    <SearchField onChange={(e) => this.setState({ searchPhrase: e.target.value })} />
                  </Box>
                  {filtersValues.makes && filtersValues.makes.length !== 0 && (
                    <Box width={376} padding={20}>
                      <Stack spacing={8} direction={"row"} isWrapped>
                        {filtersValues.makes &&
                          filtersValues.makes.map((el) => (
                            <Chip
                              onClick={(e) => {
                                this.filterMakesListOnTagClick(el, "makes");
                                e.stopPropagation();
                                e.nativeEvent.stopImmediatePropagation();
                              }}
                              role={"button"}
                              variant="dense"
                              isSelected
                            >
                              {el.toUpperCase()}&nbsp;
                              <Icon iconComponent={Cross} primaryColor="solidWhite" size={8} />
                            </Chip>
                          ))}
                      </Stack>
                    </Box>
                  )}
                  {makesArr
                    .filter((el) => {
                      return el.label.indexOf(this.state.searchPhrase.toUpperCase()) === 0;
                    })
                    .map((el) => (
                      <Menu.Item
                        onClick={(e) => {
                          this.selectOnChange(el.value, "makes");
                          e.stopPropagation();
                          e.nativeEvent.stopImmediatePropagation();
                        }}
                      >
                        <Checkbox
                          label={el.label.toUpperCase()}
                          name={el.value}
                          onChange={() => this.selectOnChange(el.value, "makes")}
                          checked={filtersValues.makes.includes(el.value)}
                        />
                      </Menu.Item>
                    ))}
                  <Text
                    as={"p"}
                    styledAs={"label"}
                    onClick={() => this.setState({ filtersValues: { ...filtersValues, makes: [] } })}
                    className={"clear-text"}
                  >
                    wyczyść
                  </Text>
                  <Media.MobileOnly>
                    <Box height={50} width={"100%"}></Box>
                    <Box position={"fixed"} bottom={20} display={"flex"} justifyContent={"center"} width={"100%"}>
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          e.nativeEvent.stopImmediatePropagation();
                          this.setState({
                            menusState: { ...this.state.menusState, isMakesMenuOpen: false }
                          });
                          this.searchCars(filtersValues, true);
                        }}
                      >
                        {this.state.literals && this.props.countFilters !== 0
                          ? replaceTemplateValue(this.state.literals.filters_cta, this.props.countFilters)
                          : "wróć"}
                      </Button>
                    </Box>
                  </Media.MobileOnly>
                </Menu>
                <div className={`chip-container ${filtersValues.models.length > 0 ? "active" : ""}`}>
                  <Chip
                    ref={this.modelRef}
                    onClick={() => {
                      this.setState({
                        menusState: { ...this.state.menusState, isModelsOpen: !this.state.menusState.isModelsOpen }
                      });
                    }}
                    isSelected={filtersValues.models.length > 0}
                    disabled={filtersValues.makes.length === 0}
                  >
                    {filtersValues.makes.length === 0
                      ? "model (wybierz markę)"
                      : `model ${filtersValues.models.length ? `(${filtersValues.models.length})` : ""}`}
                  </Chip>
                </div>
                <Menu
                  maxVisibleItems={12}
                  anchor={this.modelRef}
                  isOpen={this.state.menusState.isModelsOpen}
                  onClose={() => {
                    this.setState({
                      menusState: { ...this.state.menusState, isModelsOpen: false }
                    });
                    this.filterOnBlur();
                  }}
                  fixedHeight={700}
                  minWidth={376}
                >
                  <Box>
                    <SearchField onChange={(e) => this.setState({ searchPhrase: e.target.value })} />
                  </Box>
                  {filtersValues.models && filtersValues.models.length !== 0 && (
                    <Box width={376} padding={20}>
                      <Stack spacing={8} direction={"row"} isWrapped>
                        {filtersValues.models &&
                          filtersValues.models.map((el) => (
                            <Chip
                              onClick={(e) => {
                                this.filterMakesListOnTagClick(el, "models"), e.stopPropagation();
                                e.nativeEvent.stopImmediatePropagation();
                              }}
                              role={"button"}
                              variant="dense"
                              isSelected
                            >
                              {el} <Icon iconComponent={Cross} primaryColor="solidWhite" size={8} />
                            </Chip>
                          ))}
                      </Stack>
                    </Box>
                  )}
                  {modelsArr && modelsArr.length !== 0
                    ? modelsArr.map((make) => {
                        return (
                          <div>
                            <Menu.Item
                              onClick={(e) => {
                                e.stopPropagation();
                                e.nativeEvent.stopImmediatePropagation();
                                this.modelsListChecked(make);
                              }}
                            >
                              <Checkbox
                                name={make[0]}
                                label={capitalize(make[0])}
                                isIndeterminate
                                onChange={() => {
                                  this.modelsListChecked(make);
                                }}
                                onClick={(event) => event.stopPropagation()}
                                checked={this.hasAllElements(
                                  filtersValues.models,
                                  make[1].map((car) => car.value)
                                )}
                              />
                            </Menu.Item>
                            {make[1]
                              .filter((el) => {
                                return el.label.indexOf(this.state.searchPhrase.toUpperCase()) === 0;
                              })
                              .map((el) => {
                                return (
                                  <Menu.Item
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      e.nativeEvent.stopImmediatePropagation();
                                      this.selectOnChange(el.value, "models");
                                    }}
                                  >
                                    <div className={"model-checkbox"}>
                                      <Checkbox
                                        label={el.label}
                                        name={el.value}
                                        onChange={() => this.selectOnChange(el.value, "models")}
                                        checked={filtersValues.models.includes(el.value)}
                                      />
                                    </div>
                                  </Menu.Item>
                                );
                              })}
                          </div>
                        );
                      })
                    : null}
                  <Menu.Item>
                    <Text
                      as={"p"}
                      styledAs={"label"}
                      onClick={() => this.setState({ filtersValues: { ...filtersValues, models: [] } })}
                      className={"clear-text"}
                    >
                      wyczyść
                    </Text>
                  </Menu.Item>
                  <Media.MobileOnly>
                    <Box height={50} width={"100%"}></Box>
                    <Box position={"fixed"} bottom={20} display={"flex"} justifyContent={"center"} width={"100%"}>
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          e.nativeEvent.stopImmediatePropagation();
                          this.setState({
                            menusState: { ...this.state.menusState, isModelsOpen: false }
                          });
                          this.searchCars(filtersValues, true);
                        }}
                      >
                        {this.state.literals && this.props.countFilters !== 0
                          ? replaceTemplateValue(this.state.literals.filters_cta, this.props.countFilters)
                          : "wróć"}
                      </Button>
                    </Box>
                  </Media.MobileOnly>
                </Menu>
                <div className={`chip-container ${filtersValues.bodyType.length > 0 ? "active" : ""}`}>
                  <Chip
                    ref={this.bodyTypeRef}
                    onClick={() => {
                      this.setState({
                        menusState: {
                          ...this.state.menusState,
                          isBodyTypeMenuOpen: !this.state.menusState.isBodyTypeMenuOpen
                        }
                      });
                    }}
                    isSelected={filtersValues.bodyType.length > 0}
                  >
                    {`nadwozie ${filtersValues.bodyType.length > 0 ? `(${filtersValues.bodyType.length})` : ""}`}
                  </Chip>
                </div>
                <Menu
                  anchor={this.bodyTypeRef}
                  isOpen={this.state.menusState.isBodyTypeMenuOpen}
                  onClose={() => {
                    this.setState({
                      menusState: {
                        ...this.state.menusState,
                        isBodyTypeMenuOpen: false
                      }
                    });
                    this.filterOnBlur();
                  }}
                >
                  {filters.BodyTypes.map((el) => {
                    return (
                      <Menu.Item onClick={() => this.selectOnChange(el.Value, "bodyType")}>
                        <Checkbox
                          name={el.Value}
                          id={uuid()}
                          label={
                            el.Name === "BUS" || el.Name === "SUV" || el.Name === "VAN"
                              ? el.Name
                              : el.Name.toLowerCase()
                          }
                          onChange={() => this.selectOnChange(el.Value, "bodyType")}
                          checked={filtersValues.bodyType.includes(el.Value)}
                        />
                      </Menu.Item>
                    );
                  })}
                  <Text
                    as={"p"}
                    styledAs={"label"}
                    onClick={() => this.setState({ filtersValues: { ...filtersValues, bodyType: [] } })}
                    className={"clear-text"}
                  >
                    wyczyść
                  </Text>
                </Menu>
                <div className={`chip-container ${this.state.filtersValues.status.length > 0 ? "active" : ""}`}>
                  <Chip
                    ref={this.stateRef}
                    onClick={() => {
                      this.setState({
                        menusState: {
                          ...this.state.menusState,
                          isStateMenuOpen: !this.state.menusState.isStateMenuOpen
                        }
                      });
                    }}
                    isSelected={this.state.filtersValues.status.length > 0}
                  >
                    {`stan ${
                      this.state.filtersValues.status.length > 0 ? `(${this.state.filtersValues.status.length})` : ""
                    }`}
                  </Chip>
                </div>
                <Menu
                  anchor={this.stateRef}
                  isOpen={this.state.menusState.isStateMenuOpen}
                  onClose={() => {
                    this.setState({
                      menusState: { ...this.state.menusState, isStateMenuOpen: false }
                    });
                    this.filterOnBlur();
                  }}
                >
                  {this.renameAllObjectsKeysInArray(this.state.statusesFilter).map((el) => {
                    return (
                      <Menu.Item onClick={() => this.selectOnChange(el.value, "status")}>
                        <Checkbox
                          name={el.value}
                          id={uuid()}
                          label={el.label}
                          onChange={() => this.selectOnChange(el.value, "status")}
                          checked={filtersValues.status.includes(el.value)}
                        />
                      </Menu.Item>
                    );
                  })}
                  <Text
                    as={"p"}
                    styledAs={"label"}
                    onClick={() => this.setState({ filtersValues: { ...filtersValues, status: [] } })}
                    className={"clear-text"}
                  >
                    wyczyść
                  </Text>
                </Menu>
                {this.state.isCompany && (
                  <div
                    className={`chip-container ${
                      filtersValues.installmentFrom || filtersValues.installmentTo ? "active" : ""
                    }`}
                  >
                    <Chip
                      ref={this.installmentRef}
                      onClick={() => {
                        this.setState({
                          menusState: {
                            ...this.state.menusState,
                            isInstallmentMenuOpen: !this.state.menusState.isInstallmentMenuOpen
                          }
                        });
                      }}
                      isSelected={filtersValues.installmentFrom || filtersValues.installmentTo}
                    >
                      rata
                    </Chip>
                  </div>
                )}
                <Menu
                  anchor={this.installmentRef}
                  isOpen={this.state.menusState.isInstallmentMenuOpen}
                  onClose={() => {
                    this.setState({
                      menusState: {
                        ...this.state.menusState,
                        isInstallmentMenuOpen: false
                      }
                    });
                    this.filterOnBlur();
                  }}
                >
                  <div className="textfield-range">
                    <TextField
                      label="rata od"
                      onChange={(e) => this.textFieldOnChange(e, "installmentFrom")}
                      value={filtersValues.installmentFrom}
                      inputProps={{
                        type: "number",
                        onKeyPress: (event) => this.keypressHandler(event)
                      }}
                    />
                    <TextField
                      label="rata do"
                      onChange={(e) => this.textFieldOnChange(e, "installmentTo")}
                      value={filtersValues.installmentTo}
                      inputProps={{
                        type: "number",
                        onKeyPress: (event) => this.keypressHandler(event)
                      }}
                    />
                  </div>
                  <Text
                    as={"p"}
                    styledAs={"label"}
                    onClick={() =>
                      this.setState({ filtersValues: { ...filtersValues, installmentFrom: "", installmentTo: "" } })
                    }
                    className={"clear-text"}
                  >
                    wyczyść
                  </Text>
                </Menu>
                <div className={`chip-container ${filtersValues.fuelType.length > 0 ? "active" : ""}`}>
                  <Chip
                    ref={this.fuelRef}
                    onClick={() => {
                      this.setState({
                        menusState: {
                          ...this.state.menusState,
                          isFuelMenuOpen: !this.state.menusState.isFuelMenuOpen
                        }
                      });
                    }}
                    isSelected={filtersValues.fuelType.length > 0}
                  >
                    {`paliwo ${filtersValues.fuelType.length > 0 ? `(${filtersValues.fuelType.length})` : ""}`}
                  </Chip>
                </div>
                <Menu
                  anchor={this.fuelRef}
                  isOpen={this.state.menusState.isFuelMenuOpen}
                  onClose={() => {
                    this.setState({
                      menusState: {
                        ...this.state.menusState,
                        isFuelMenuOpen: false
                      }
                    });
                    this.filterOnBlur();
                  }}
                >
                  {filters.FuelTypes.map((el) => {
                    return (
                      <Menu.Item onClick={() => this.selectOnChange(el.Value, "fuelType")}>
                        <Checkbox
                          name={el.Value}
                          id={uuid()}
                          label={el.Name.toLowerCase()}
                          onChange={() => this.selectOnChange(el.Value, "fuelType")}
                          checked={filtersValues.fuelType.includes(el.Value)}
                        />
                      </Menu.Item>
                    );
                  })}
                  <Text
                    as={"p"}
                    styledAs={"label"}
                    onClick={() => this.setState({ filtersValues: { ...filtersValues, fuelType: [] } })}
                    className={"clear-text"}
                  >
                    wyczyść
                  </Text>
                </Menu>
                <div className={`chip-container ${filtersValues.gearBoxType.length > 0 ? "active" : ""}`}>
                  <Chip
                    ref={this.gearboxRef}
                    onClick={() => {
                      this.setState({
                        menusState: {
                          ...this.state.menusState,
                          isGearboxMenuOpen: !this.state.menusState.isGearboxMenuOpen
                        }
                      });
                    }}
                    isSelected={filtersValues.gearBoxType.length > 0}
                  >
                    {`skrzynia biegów ${
                      filtersValues.gearBoxType.length > 0 ? `(${filtersValues.gearBoxType.length})` : ""
                    }`}
                  </Chip>
                </div>
                <Menu
                  anchor={this.gearboxRef}
                  isOpen={this.state.menusState.isGearboxMenuOpen}
                  onClose={() => {
                    this.setState({
                      menusState: {
                        ...this.state.menusState,
                        isGearboxMenuOpen: false
                      }
                    });
                    this.filterOnBlur();
                  }}
                >
                  {filters.GearBoxTypes.map((el) => {
                    return (
                      <Menu.Item onClick={() => this.selectOnChange(el.Value, "gearBoxType")}>
                        <Checkbox
                          name={el.Value}
                          id={uuid()}
                          label={el.Name.toLowerCase()}
                          onChange={() => this.selectOnChange(el.Value, "gearBoxType")}
                          checked={filtersValues.gearBoxType.includes(el.Value)}
                        />
                      </Menu.Item>
                    );
                  })}
                  <Text
                    as={"p"}
                    styledAs={"label"}
                    onClick={() => this.setState({ filtersValues: { ...filtersValues, gearBoxType: [] } })}
                    className={"clear-text"}
                  >
                    wyczyść
                  </Text>
                </Menu>
                <div
                  className={`chip-container ${filtersValues.milleageFrom || filtersValues.milleageTo ? "active" : ""}`}
                >
                  <Chip
                    ref={this.milleageRef}
                    onClick={() => {
                      this.setState({
                        menusState: {
                          ...this.state.menusState,
                          isMilleageMenuOpen: !this.state.menusState.isMilleageMenuOpen
                        }
                      });
                    }}
                    isSelected={filtersValues.milleageFrom || filtersValues.milleageTo}
                  >
                    przebieg
                  </Chip>
                </div>
                <Menu
                  anchor={this.milleageRef}
                  isOpen={this.state.menusState.isMilleageMenuOpen}
                  onClose={() => {
                    this.setState({
                      menusState: {
                        ...this.state.menusState,
                        isMilleageMenuOpen: false
                      }
                    });
                    this.filterOnBlur();
                  }}
                >
                  <div className="textfield-range">
                    <TextField
                      label="przebieg od"
                      onChange={(e) => this.textFieldOnChange(e, "milleageFrom")}
                      value={filtersValues.milleageFrom}
                      inputProps={{
                        type: "number",
                        onKeyPress: (event) => this.keypressHandler(event)
                      }}
                    />
                    <TextField
                      label="przebieg do"
                      onChange={(e) => this.textFieldOnChange(e, "milleageTo")}
                      value={filtersValues.milleageTo}
                      inputProps={{
                        type: "number",
                        onKeyPress: (event) => this.keypressHandler(event)
                      }}
                    />
                  </div>
                  <Text
                    as={"p"}
                    styledAs={"label"}
                    onClick={() =>
                      this.setState({ filtersValues: { ...filtersValues, milleageFrom: "", milleageTo: "" } })
                    }
                    className={"clear-text"}
                  >
                    wyczyść
                  </Text>
                </Menu>
                <div className={`chip-container ${filtersValues.priceFrom || filtersValues.priceTo ? "active" : ""}`}>
                  <Chip
                    ref={this.priceRef}
                    onClick={() => {
                      this.setState({
                        menusState: {
                          ...this.state.menusState,
                          isPriceMenuOpen: !this.state.menusState.isPriceMenuOpen
                        }
                      });
                    }}
                    isSelected={filtersValues.priceFrom || filtersValues.priceTo}
                  >
                    cena
                  </Chip>
                </div>
                <Menu
                  anchor={this.priceRef}
                  isOpen={this.state.menusState.isPriceMenuOpen}
                  onClose={() => {
                    this.setState({
                      menusState: {
                        ...this.state.menusState,
                        isPriceMenuOpen: false
                      }
                    });
                    this.filterOnBlur();
                  }}
                >
                  <div className="textfield-range">
                    <TextField
                      label="cena od"
                      onChange={(e) => this.textFieldOnChange(e, "priceFrom")}
                      value={filtersValues.priceFrom}
                      inputProps={{
                        type: "number",
                        onKeyPress: (event) => this.keypressHandler(event)
                      }}
                    />
                    <TextField
                      label="cena do"
                      onChange={(e) => this.textFieldOnChange(e, "priceTo")}
                      value={filtersValues.priceTo}
                      inputProps={{
                        type: "number",
                        onKeyPress: (event) => this.keypressHandler(event)
                      }}
                    />
                  </div>
                  <Text
                    as={"p"}
                    styledAs={"label"}
                    onClick={() => this.setState({ filtersValues: { ...filtersValues, priceFrom: "", priceTo: "" } })}
                    className={"clear-text"}
                  >
                    wyczyść
                  </Text>
                </Menu>
                <div
                  className={`chip-container ${
                    filtersValues.productionFrom || filtersValues.productionTo ? "active" : ""
                  }`}
                >
                  <Chip
                    ref={this.manufactureRef}
                    onClick={() => {
                      this.setState({
                        menusState: {
                          ...this.state.menusState,
                          isManufactureMenuOpen: !this.state.menusState.isManufactureMenuOpen
                        }
                      });
                    }}
                    isSelected={filtersValues.productionFrom || filtersValues.productionTo}
                  >
                    rok produkcji
                  </Chip>
                </div>
                <Menu
                  anchor={this.manufactureRef}
                  isOpen={this.state.menusState.isManufactureMenuOpen}
                  onClose={() => {
                    this.setState({
                      menusState: {
                        ...this.state.menusState,
                        isManufactureMenuOpen: false
                      }
                    });
                    this.filterOnBlur();
                  }}
                >
                  <div className="textfield-range">
                    <TextField
                      label="rok produkcji od"
                      onChange={(e) => this.textFieldOnChange(e, "productionFrom")}
                      value={filtersValues.productionFrom}
                      inputProps={{
                        type: "number",
                        onKeyPress: (event) => this.keypressHandler(event)
                      }}
                    />
                    <TextField
                      label="rok produkcji do"
                      onChange={(e) => this.textFieldOnChange(e, "productionTo")}
                      value={filtersValues.productionTo}
                      inputProps={{
                        type: "number",
                        onKeyPress: (event) => this.keypressHandler(event)
                      }}
                    />
                  </div>
                  <div className="textfield-dropdown-list">
                    <div className="textfield-dropdown">
                      {availableYears.map((el) => {
                        return (
                          <Menu.Item
                            id={uuid()}
                            onClick={() =>
                              this.setState({
                                filtersValues: { ...this.state.filtersValues, productionFrom: el.Value }
                              })
                            }
                          >
                            {el.Name}
                          </Menu.Item>
                        );
                      })}
                    </div>
                    <div className="textfield-dropdown">
                      {availableYears.map((el) => {
                        return (
                          <Menu.Item
                            id={uuid()}
                            disabled={el.Value < Number(filtersValues.productionFrom)}
                            onClick={() =>
                              this.setState({ filtersValues: { ...this.state.filtersValues, productionTo: el.Value } })
                            }
                          >
                            {el.Name}
                          </Menu.Item>
                        );
                      })}
                    </div>
                  </div>
                  <Text
                    as={"p"}
                    styledAs={"label"}
                    onClick={() =>
                      this.setState({ filtersValues: { ...filtersValues, productionFrom: "", productionTo: "" } })
                    }
                    className={"clear-text"}
                  >
                    wyczyść
                  </Text>
                </Menu>
                <div
                  className={`chip-container ${filtersValues.capacityFrom || filtersValues.capacityTo ? "active" : ""}`}
                >
                  <Chip
                    ref={this.capacityRef}
                    onClick={() => {
                      this.setState({
                        menusState: {
                          ...this.state.menusState,
                          isCapacityMenuOpen: !this.state.menusState.isCapacityMenuOpen
                        }
                      });
                      this.filterOnBlur();
                    }}
                    isSelected={filtersValues.capacityFrom || filtersValues.capacityTo}
                  >
                    pojemność
                  </Chip>
                </div>
                <Menu
                  anchor={this.capacityRef}
                  isOpen={this.state.menusState.isCapacityMenuOpen}
                  onClose={() => {
                    this.setState({
                      menusState: {
                        ...this.state.menusState,
                        isCapacityMenuOpen: false
                      }
                    });
                  }}
                >
                  <div className="textfield-range">
                    <TextField
                      label="pojemność od"
                      onChange={(e) => this.textFieldOnChange(e, "capacityFrom")}
                      value={filtersValues.capacityFrom}
                      inputProps={{
                        type: "number",
                        onKeyPress: (event) => this.keypressHandler(event)
                      }}
                    />
                    <TextField
                      label="pojemność do"
                      onChange={(e) => this.textFieldOnChange(e, "capacityTo")}
                      value={filtersValues.capacityTo}
                      inputProps={{
                        type: "number",
                        onKeyPress: (event) => this.keypressHandler(event)
                      }}
                    />
                  </div>
                  <div className="textfield-dropdown-list">
                    <div className="textfield-dropdown">
                      {filters.Capacities.map((el) => {
                        return (
                          <Menu.Item
                            id={uuid()}
                            onClick={() =>
                              this.setState({ filtersValues: { ...this.state.filtersValues, capacityFrom: el.Value } })
                            }
                          >
                            {el.Name}
                          </Menu.Item>
                        );
                      })}
                    </div>
                    <div className="textfield-dropdown">
                      {filters.Capacities.map((el) => {
                        return (
                          <Menu.Item
                            id={uuid()}
                            disabled={el.Value <= Number(filtersValues.capacityFrom)}
                            onClick={() =>
                              this.setState({ filtersValues: { ...this.state.filtersValues, capacityTo: el.Value } })
                            }
                          >
                            {el.Name}
                          </Menu.Item>
                        );
                      })}
                    </div>
                  </div>
                  <Text
                    as={"p"}
                    styledAs={"label"}
                    onClick={() =>
                      this.setState({ filtersValues: { ...filtersValues, capacityFrom: "", capacityTo: "" } })
                    }
                    className={"clear-text"}
                  >
                    wyczyść
                  </Text>
                </Menu>
                <div className={`chip-container ${filtersValues.powerFrom || filtersValues.powerTo ? "active" : ""}`}>
                  <Chip
                    ref={this.powerRef}
                    onClick={() => {
                      this.setState({
                        menusState: {
                          ...this.state.menusState,
                          isPowerMenuOpen: !this.state.menusState.isPowerMenuOpen
                        }
                      });
                    }}
                    isSelected={filtersValues.powerFrom || filtersValues.powerTo}
                  >
                    moc
                  </Chip>
                </div>
                <Menu
                  anchor={this.powerRef}
                  isOpen={this.state.menusState.isPowerMenuOpen}
                  onClose={() => {
                    this.setState({
                      menusState: {
                        ...this.state.menusState,
                        isPowerMenuOpen: false
                      }
                    });
                    this.filterOnBlur();
                  }}
                >
                  <div className="textfield-range">
                    <TextField
                      label="moc od"
                      onChange={(e) => this.textFieldOnChange(e, "powerFrom")}
                      value={filtersValues.powerFrom}
                      inputProps={{
                        type: "number",
                        onKeyPress: (event) => this.keypressHandler(event)
                      }}
                    />
                    <TextField
                      label="moc do"
                      onChange={(e) => this.textFieldOnChange(e, "powerTo")}
                      value={filtersValues.powerTo}
                      inputProps={{
                        type: "number",
                        onKeyPress: (event) => this.keypressHandler(event)
                      }}
                    />
                  </div>
                  <div className="textfield-dropdown-list">
                    <div className="textfield-dropdown">
                      {filters.PowerList.map((el) => {
                        return (
                          <Menu.Item
                            id={uuid()}
                            onClick={() =>
                              this.setState({ filtersValues: { ...this.state.filtersValues, powerFrom: el.Value } })
                            }
                          >
                            {el.Name}
                          </Menu.Item>
                        );
                      })}
                    </div>
                    <div className="textfield-dropdown">
                      {filters.PowerList.map((el) => {
                        return (
                          <Menu.Item
                            id={uuid()}
                            disabled={el.Value <= Number(filtersValues.powerFrom)}
                            onClick={() =>
                              this.setState({ filtersValues: { ...this.state.filtersValues, powerTo: el.Value } })
                            }
                          >
                            {el.Name}
                          </Menu.Item>
                        );
                      })}
                    </div>
                  </div>
                  <Text
                    as={"p"}
                    styledAs={"label"}
                    onClick={() => this.setState({ filtersValues: { ...filtersValues, powerFrom: "", powerTo: "" } })}
                    className={"clear-text"}
                  >
                    wyczyść
                  </Text>
                </Menu>
                <div className={`chip-container ${filtersValues.location.length > 0 ? "active" : ""}`}>
                  <Chip
                    ref={this.locationRef}
                    onClick={() => {
                      this.setState({
                        menusState: {
                          ...this.state.menusState,
                          isLocationMenuOpen: !this.state.menusState.isLocationMenuOpen
                        }
                      });
                    }}
                    isSelected={filtersValues.location.length > 0}
                  >
                    {`lokalizacja ${filtersValues.location.length > 0 ? `(${filtersValues.location.length})` : ""}`}
                  </Chip>
                </div>
                <Menu
                  maxVisibleItems={12}
                  anchor={this.locationRef}
                  isOpen={this.state.menusState.isLocationMenuOpen}
                  onClose={() => {
                    this.setState({
                      menusState: {
                        ...this.state.menusState,
                        isLocationMenuOpen: false
                      }
                    });
                    this.filterOnBlur();
                    this.setState({ searchLocationPhrase: "" });
                  }}
                  fixedHeight={176}
                  minWidth={376}
                >
                  <Box>
                    <SearchField onChange={(e) => this.setState({ searchLocationPhrase: e.target.value })} />
                  </Box>
                  {filtersValues.location && filtersValues.location.length !== 0 && (
                    <Box width={376} padding={20}>
                      <Stack spacing={8} direction={"row"} isWrapped>
                        {filtersValues.location &&
                          filtersValues.location.map((el) => (
                            <Chip
                              onClick={() => this.filterMakesListOnTagClick(el, "location")}
                              role={"button"}
                              variant="dense"
                              isSelected
                            >
                              {el} <Icon iconComponent={Cross} primaryColor="solidWhite" size={8} />
                            </Chip>
                          ))}
                      </Stack>
                    </Box>
                  )}
                  {!this.state.searchLocationPhrase.length ? (
                    <Box paddingX={8}>
                      <Accordion layout="small">
                        {VOIVODESHIPS_DICT.sort(sortByName).map((voivodeship) => {
                          const accumulatedCarCount = filters.Locations.filter((city) => {
                            return city.VoivodeshipId === voivodeship.Id;
                          }).reduce((acc, el) => {
                            return acc + el.Count;
                          }, 0);
                          return (
                            voivodeship.Name !== "" &&
                            this.hasCorrespondingIdValue(filters.Locations, voivodeship.Id) && (
                              <Accordion.Item>
                                <Accordion.Header>{`${voivodeship.Name} (${accumulatedCarCount})`}</Accordion.Header>
                                <Accordion.Content>
                                  {filters.Locations.sort(sortByName)
                                    .filter((city) => city.VoivodeshipId === voivodeship.Id)
                                    .map((location) => {
                                      return (
                                        <Menu.Item>
                                          <Checkbox
                                            name={location.Value}
                                            id={uuid()}
                                            label={`${location.Name} (${location.Count})`}
                                            onChange={() => this.selectOnChange(location.Value, "location")}
                                            checked={filtersValues.location.includes(location.Value)}
                                          />
                                        </Menu.Item>
                                      );
                                    })}
                                </Accordion.Content>
                              </Accordion.Item>
                            )
                          );
                        })}
                      </Accordion>
                    </Box>
                  ) : (
                    <div>
                      {filters.Locations.filter((el) => {
                        return el.Name.toUpperCase().indexOf(this.state.searchLocationPhrase.toUpperCase()) === 0;
                      })
                        .sort(sortByName)
                        .map((el) => {
                          return (
                            <Menu.Item onClick={() => this.selectOnChange(el.Value, "location")}>
                              <Checkbox
                                name={el.Value}
                                id={uuid()}
                                label={`${el.Name} (${el.Count})`}
                                onChange={() => this.selectOnChange(el.Value, "location")}
                                checked={filtersValues.location.includes(el.Value)}
                              />
                            </Menu.Item>
                          );
                        })}
                    </div>
                  )}
                  <Menu.Item>
                    <Text
                      as={"p"}
                      styledAs={"label"}
                      onClick={() => this.setState({ filtersValues: { ...filtersValues, location: [] } })}
                      className={"clear-text"}
                    >
                      wyczyść
                    </Text>
                  </Menu.Item>
                </Menu>
                <div className={`chip-container ${filtersValues.promotions.length > 0 ? "active" : ""}`}>
                  <Chip
                    ref={this.promoRef}
                    onClick={() => {
                      this.setState({
                        menusState: {
                          ...this.state.menusState,
                          isPromoMenuOpen: !this.state.menusState.isPromoMenuOpen
                        }
                      });
                    }}
                    isSelected={filtersValues.promotions.length > 0}
                  >
                    {`promocje ${filtersValues.promotions.length > 0 ? `(${filtersValues.promotions.length})` : ""}`}
                  </Chip>
                </div>
                <Menu
                  anchor={this.promoRef}
                  isOpen={this.state.menusState.isPromoMenuOpen}
                  onClose={() => {
                    this.setState({
                      menusState: {
                        ...this.state.menusState,
                        isPromoMenuOpen: false
                      }
                    });
                    this.filterOnBlur();
                  }}
                >
                  {filters.SpecialPromotions.map((el) => {
                    return (
                      <Menu.Item onClick={() => this.selectOnChange(el.Value, "promotions")}>
                        <Checkbox
                          name={el.Value}
                          id={uuid()}
                          label={el.Name.toLowerCase()}
                          onChange={() => this.selectOnChange(el.Value, "promotions")}
                          checked={filtersValues.promotions.includes(el.Value)}
                        />
                      </Menu.Item>
                    );
                  })}
                  <Text
                    as={"p"}
                    styledAs={"label"}
                    onClick={() => this.setState({ filtersValues: { ...filtersValues, promotions: "" } })}
                    className={"clear-text"}
                  >
                    wyczyść
                  </Text>
                </Menu>
                <div className={`chip-container ${filtersValues.plateNumber ? "active" : ""}`}>
                  <Chip
                    ref={this.plateRef}
                    onClick={() => {
                      this.setState({
                        menusState: {
                          ...this.state.menusState,
                          isPlateMenuOpen: !this.state.menusState.isPlateMenuOpen
                        }
                      });
                    }}
                    isSelected={filtersValues.plateNumber}
                  >
                    numer rejestracyjny
                  </Chip>
                </div>
                <Menu
                  anchor={this.plateRef}
                  isOpen={this.state.menusState.isPlateMenuOpen}
                  onClose={() => {
                    this.setState({
                      menusState: {
                        ...this.state.menusState,
                        isPlateMenuOpen: false
                      }
                    });
                    this.filterOnBlur();
                  }}
                  fixedHeight={176}
                  minWidth={376}
                >
                  <Box padding={16}>
                    <TextField
                      value={filtersValues.plateNumber}
                      onChange={(e) => this.textFieldOnChange(e, "plateNumber")}
                      onBlur={this.filterOnBlur}
                      inputProps={{
                        type: "text",
                        onKeyPress: (event) => this.keypressHandler(event)
                      }}
                      label="numer rej./ref."
                    />
                  </Box>
                  <Text
                    as={"p"}
                    styledAs={"label"}
                    onClick={() => this.setState({ filtersValues: { ...filtersValues, plateNumber: "" } })}
                    className={"clear-text"}
                  >
                    wyczyść
                  </Text>
                </Menu>
                <div className={`chip-container`}>
                  <IconButton
                    id="clear-all-filters"
                    iconComponent={TrashBin}
                    iconColor="secondary_contrast"
                    buttonType="secondary"
                    iconSize={16}
                    onClick={() => this.cleanAllFilters()}
                  />
                </div>
              </div>
            )}
            <div className="filters__sorting-section">
              {window.innerWidth < 1024 && (
                <Badge isVisible={activeFiltersCount > 0}>
                  <Button
                    iconComponent={Filter}
                    buttonType="tertiary"
                    iconColor="secondary_contrast"
                    color="dark"
                    onClick={this.props.toggleMobileFilters}
                  >
                    filtruj
                  </Button>
                </Badge>
              )}
              <Button
                ref={this.sortButtonRef}
                iconComponent={ArrowsUpDown}
                buttonType="tertiary"
                iconColor="secondary_contrast"
                color="dark"
                onClick={() => {
                  this.setState({
                    menusState: { ...this.state.menusState, isSortMenuOpen: !this.state.menusState.isSortMenuOpen }
                  });
                }}
              >
                sortuj
              </Button>
              {window.innerWidth > 1023 && (
                <ObserveVehicleButton
                  filtersValues={this.state.filtersValues}
                  countFilters={this.props.countFilters}
                  isCompany={this.state.isCompany}
                  financialMethod={this.state.financialMethod}
                />
              )}
              <Menu
                anchor={this.sortButtonRef}
                isOpen={this.state.menusState.isSortMenuOpen}
                onClose={() => {
                  this.setState({
                    menusState: { ...this.state.menusState, isSortMenuOpen: false }
                  });
                }}
                variant={"selectableMenu"}
                selectedIndex={Number(this.props.selectedSortingValue)}
              >
                {sortingOptions.map((el) => {
                  return (
                    <Menu.Item
                      onClick={() => {
                        this.setState(
                          {
                            menusState: {
                              ...this.state.menusState,
                              isSortMenuOpen: !this.state.menusState.isSortMenuOpen,
                              selectedSortingValue: el.value
                            }
                          },
                          () => {
                            this.addFiltersToUrl(this.state.filtersValues, el.value);
                          }
                        );
                        this.props.changeSelectSortBy(el.value);
                      }}
                    >
                      {el.label}
                    </Menu.Item>
                  );
                })}
              </Menu>
            </div>
          </div>
        </div>
        {this.props.isMobileFiltersOpen && (
          <div className="nav_bar-mobile-modal-container">
            <MobileFilters
              filters={filters}
              filtersValues={filtersValues}
              financialMethod={this.state.financialMethod}
              modelsArr={modelsArr}
              makesArr={makesArr}
              literals={this.state.literals}
              isCompany={this.state.isCompany}
              countFilters={this.props.countFilters}
              selectOnChange={this.selectOnChange}
              renameAllObjectsKeysInArray={this.renameAllObjectsKeysInArray}
              textFieldOnChange={this.textFieldOnChange}
              keypressHandler={this.keypressHandler}
              filterOnBlur={this.filterOnBlur}
              searchCars={this.searchCars}
              handleIsCompanyChange={this.handleIsCompanyChange}
              setContractorType={this.setContractorType}
              changeFinancialMethod={this.changeFinancialMethod}
              toggleMobileFilters={this.props.toggleMobileFilters}
              searchPhrase={this.state.searchPhrase}
              filterMakesListOnTagClick={this.filterMakesListOnTagClick}
              statusesFilter={this.state.statusesFilter}
              setChange={this.setChange.bind(this)}
              cleanAllFilters={this.cleanAllFilters}
            />
          </div>
        )}
      </>
    );
  }
}

export default withRouter(ListBanner);
