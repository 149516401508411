import React from "react";

import { Text } from "@mbank-design/design-system/components";
import {
  FontWeight,
  FontSize,
  LineHeight,
  FontColor,
} from "@mbank-design/design-system/design-tokens/general/typography";

import "../../../assets/styles/components/question-text-section-container.scss";

const QuestionTextSectionNewCar = () => {
  return (
    <div className='question-text-section-container mleasing-main-container'>
      <Text
        as='h5'
        fontSize={FontSize.SIZE_24}
        lineHeight={LineHeight.HEIGHT_32}
      >
        Nowe auto dla Twojej firmy? Jest w zasięgu ręki
      </Text>
      <Text
        as='p'
        fontSize={FontSize.SIZE_12}
        lineHeight={LineHeight.HEIGHT_16}
        fontWeight={FontWeight.WEIGHT_600}
        color={"doveGrayUI"}
      >
        Jeszcze do niedawna, gdy chciałeś jeździć nowym samochodem, jedyną opcją
        był zakup za gotówkę lub wzięcie kredytu. Jednak nie każdej firmie takie
        rozwiązanie się opłacało. Konieczność zamrożenia kapitału albo obniżenie
        zdolności kredytowej blokowały inicjatywy rozwojowe małych biznesów.
        Dlatego postanowiliśmy zaproponować Ci alternatywę w postaci najmu.
        Platforma mAuto powstała w odpowiedzi na najczęstsze problemy
        przedsiębiorców – naszym celem jest ułatwienie klientom wyboru
        odpowiedniego samochodu na warunkach optymalnych finansowo.
      </Text>
      <Text
        as='p'
        fontSize={FontSize.SIZE_12}
        lineHeight={LineHeight.HEIGHT_16}
        fontWeight={FontWeight.WEIGHT_600}
        color={"doveGrayUI"}
      >
        Nowe auta z naszej oferty można użytkować już od kilkuset złotych
        miesięcznie. A co najlepsze – po okresie umowy możesz po prostu oddać
        dotychczasowy pojazd i wymienić go na model nowszy lub lepiej
        odpowiadający wymaganiom Twojej rozwijającej się firmy. To elastyczne
        rozwiązanie, które zapewnia Ci wygodę, minimum zobowiązań i
        bezpieczeństwo nawet w niepewnych czasach.
      </Text>
      <Text
        as='h5'
        fontSize={FontSize.SIZE_24}
        lineHeight={LineHeight.HEIGHT_32}
      >
        Prosty proces zamawiania nowego samochodu
      </Text>
      <Text
        as='p'
        fontSize={FontSize.SIZE_12}
        lineHeight={LineHeight.HEIGHT_16}
        fontWeight={FontWeight.WEIGHT_600}
        color={"doveGrayUI"}
      >
        mAuto jest wirtualnym salonem samochodowym, w którym możesz wybrać nowy
        samochód dla swojej firmy bez wychodzenia z domu. Każda z naszych ofert
        jest szczegółowo opisana – wszystko po to, by maksymalnie ułatwić Ci decyzję.
        Jak znaleźć nowe auto odpowiednie dla swoich potrzeb? Pomoże Ci w tym
        zaawansowana wyszukiwarka, dzięki której możesz filtrować dostępne
        oferty np. po istotnych dla Ciebie parametrach technicznych (takich jak
        typ nadwozia, rodzaj skrzyni biegów, paliwa, moc i pojemność silnika i
        in.), a także po wysokości raty, marce lub modelu auta. Oferty, które
        wstępnie Cię zainteresowały, możesz dodać do ulubionych, by łatwo do
        nich później wrócić i je porównać. Wszystkie koszty możesz skonfigurować
        samodzielnie, korzystając z wygodnego kalkulatora warunków najmu na
        stronie oferty. Wystarczy wskazać preferowany okres najmu, opłatę
        wstępną, roczny limit kilometrów oraz wybrać usługi dodatkowe, takie jak
        ubezpieczenia, opcje serwisu czy karta paliwowa. Dzięki temu jeszcze
        przed kontaktem z doradcą zorientujesz się w wysokości rat i dopasujesz
        je do swoich możliwości. Udało się wybrać auto? Teraz złóż zapytanie o
        ofertę, a doradca mAuto skontaktuje się z Tobą, by dopełnić formalności
        związanych z umową. Pamiętaj, że zawsze możesz też skorzystać z jego
        pomocy wcześniej, jeszcze na etapie wyboru. Po podpisaniu umowy
        pozostaje Ci już tylko czekać na swój nowy samochód, który dostarczymy
        Ci pod wskazany adres. Prawda, że proste?
      </Text>

      <Text
        as='h5'
        fontSize={FontSize.SIZE_24}
        lineHeight={LineHeight.HEIGHT_32}
      >
        Najem samochodów nowych – na czym polega i jakie daje korzyści?
      </Text>
      <Text
        as='p'
        fontSize={FontSize.SIZE_12}
        lineHeight={LineHeight.HEIGHT_16}
        fontWeight={FontWeight.WEIGHT_600}
        color={"doveGrayUI"}
      >
        Nowe samochody z oferty mAuto są dostępne wyłącznie w usłudze najmu.
        Dzięki rabatom flotowym u naszych partnerów możemy zaproponować Ci
        niskie raty i korzystne warunki użytkowania pojazdów, a także usługi
        dodatkowe w konkurencyjnych cenach. Najem nowych aut jest nowoczesną
        formą finansowania, która podobnie jak leasing w ostatnich latach
        znacznie zyskuje na popularności. Największą korzyścią z najmu jest
        fakt, że użytkowanie samochodu w tej formie nie wiąże się z
        koniecznością jego wykupu po okresie umownym. Jak dokładnie to działa?
        Jeśli zdecydujesz się na najem w mAuto, po 24, 36 czy 48 miesiącach
        (zależnie od podpisanej umowy), możesz po prostu zwrócić auto bez
        dalszych zobowiązań – i w razie potrzeby wybrać sobie kolejne. Natomiast
        jeżeli użytkowany model przypadł Ci do gustu i nie chcesz się z nim
        rozstawać – wtedy możesz auto wykupić. Zawsze jednak jest to Twoja
        decyzja. Kolejnymi zaletami najmu nowego samochodu są przewidywalność
        kosztów i niska miesięczna opłata. Wynika ona z tego, że w ratach
        spłacasz wyłącznie utratę wartości auta, a nie jak w wypadku leasingu
        całą wartość pojazdu. Dodatkowo na ratę najmu wpływa limit kilometrów.
        Jeśli więc użytkujesz auto tylko na krótkich trasach, np. w obrębie
        swojego miasta, nie przepłacasz. Samochód w najmie łatwo wliczysz w
        koszty firmy, a co najważniejsze – nie blokujesz sobie zasobów
        gotówkowych ani nie obniżasz zdolności kredytowej. To szczególnie
        istotne dla przedsiębiorców, którzy mają w planach na przykład kredyt
        inwestycyjny. Ponadto dzięki rabatom, które oferuje mAuto, możesz
        obniżyć koszty eksploatacji oraz zyskać zdecydowanie lepsze niż rynkowe
        warunki ubezpieczeń. Nie bez znaczenia jest też wygoda tego rozwiązania
        – zdejmujemy z Twoich barków wiele formalności, których musiałbyś
        dopełnić przy zakupie nowego auta.
      </Text>

      <Text
        as='h5'
        fontSize={FontSize.SIZE_24}
        lineHeight={LineHeight.HEIGHT_32}
      >
        Nowe samochody – duża różnorodność modeli
      </Text>
      <Text
        as='p'
        fontSize={FontSize.SIZE_12}
        lineHeight={LineHeight.HEIGHT_16}
        fontWeight={FontWeight.WEIGHT_600}
        color={"doveGrayUI"}
      >
        W ofercie mAuto znajdziesz nowe samochody odpowiednie dla różnych
        biznesów. W zależności od potrzeb swojej działalności możesz wybrać auto
        typu sedan, hatchback, kombi, SUV, van, a nawet bus. Wszystkie pojazdy
        cechuje nowoczesność, wysoka jakość i bezpieczeństwo. Niezależnie od
        tego, czy zdecydujesz się na nowy samochód osobowy do jazdy po mieście,
        czy na SUV-a ułatwiającego poruszanie się w terenie, każde auto
        dostarczymy Ci pod wskazany adres w ramach usługi door-to-door, która
        jest darmowa. W serwisie mAuto prezentujemy nowe samochody ponad 20
        marek. Zależnie od aktualnej dostępności poszczególnych modeli możesz tu
        znaleźć zarówno solidne auta marek najchętniej wybieranych przez firmy z
        sektora MŚP, m.in. Volkswagen, Ford, Skoda czy Opel, jak i marek
        będących synonimami prestiżu, jak BMW czy Mercedes-Benz.
      </Text>
      <Text
        as='h5'
        fontSize={FontSize.SIZE_24}
        lineHeight={LineHeight.HEIGHT_32}
      >
        Najem nowego auta? Najwygodniej w pakiecie z usługami dodatkowymi
      </Text>
      <Text
        as='p'
        fontSize={FontSize.SIZE_12}
        lineHeight={LineHeight.HEIGHT_16}
        fontWeight={FontWeight.WEIGHT_600}
        color={"doveGrayUI"}
      >
        Wybierając nowy samochód w mAuto, zyskujesz również dostęp do usług
        dodatkowych, które znacznie ułatwią Ci jego użytkowanie. Wygodną opcją
        jest skorzystanie z pełnego pakietu, który zapewni Ci m.in. niezbędne
        ubezpieczenia i szeroki zakres obsługi serwisowej. Jeśli jednak
        interesują Cię tylko konkretne usługi – możesz skomponować je zgodnie z
        własnymi potrzebami. 
        <br></br><br></br>
        Naszym klientom proponujemy: 
        <ol>
          <li>Ubezpieczenie – wieloletnie ubezpieczenie OC, AC, NNW oraz Autoszyba to kompleksowa ochrona z gwarancją niezmiennej składki</li>
          <li>
            Assistance – szybka pomoc w 24 h na dobę. Pakiet zawiera m.in.: 
            holowanie, naprawę w miejscu zdarzenia, pomoc w podróży, samochód zastępczy i pomoc medyczną
          </li>
          <li>
            Pakiet serwisowy Basic – przeglądy okresowe oraz wymiana filtrów i płynów eksploatacyjnych. 
            Doradca umówi Cię w serwisie oraz będzie służyć pomocą, jeśli pojawi się potrzeba dodatkowych napraw
          </li>
          <li>
            Pakiet serwisowy Comfort – dostęp do wszystkich usług ujętych w wariancie Basic 
            z możliwością wymiany elementów układu hamulcowego – klocki i tarcze hamulcowe masz w cenie pakietu
          </li>
          <li>
            Pakiet Opony – kupimy dla Ciebie ich drugi komplet oraz zorganizujemy wymianę ogumienia dwa razy do roku. 
            Zapasowe opony przechowamy do następnego sezonu
          </li>
          <li>
            Karta paliwowa – to wygoda płatności bezgotówkowych i przejrzyste rozliczenia. 
            Zbiorczą refakturę za tankowanie otrzymasz w kolejnym miesiącu
          </li>
        </ol>
      </Text>
      <Text
        as='h5'
        fontSize={FontSize.SIZE_24}
        lineHeight={LineHeight.HEIGHT_32}
      >
        Nowy samochód od mAuto – komfort i oszczędność dla firm
      </Text>
      <Text
        as='p'
        fontSize={FontSize.SIZE_12}
        lineHeight={LineHeight.HEIGHT_16}
        fontWeight={FontWeight.WEIGHT_600}
        color={"doveGrayUI"}
      >
        Zaletą najmu nowego samochodu w mAuto jest duża elastyczność oferty i
        możliwość optymalizacji kosztów firmowych. Atrakcyjne stawki
        wynegocjowane z naszymi partnerami oraz sama konstrukcja tego typu
        finansowania sprawiają, że pod wieloma względami może to być rozwiązanie
        bardziej korzystne od zakupu z rynku za gotówkę. Dodatkowo w wypadku
        osób, które nie planują wykupu pojazdu po okresie umowy albo chcą często
        zmieniać modele aut na nowsze, najem jest też doskonałą alternatywą dla
        leasingu. Wszystkie prezentowane na stronie modele aut są dostępne od
        ręki lub w bardzo krótkim terminie. Całą procedurę zamówienia samochodu
        możesz przejść online, na koniec korzystając ze wsparcia doradcy. Jeśli
        jednak zależy Ci, by obejrzeć nowe auto, zanim dostarczymy Ci je pod
        dom, oraz odbyć jazdę próbną, możesz odwiedzić nasz plac.
      </Text>
    </div>
  );
};

export default QuestionTextSectionNewCar;
