import { Media, Text } from "@mbank-design/design-system/components";
import { FontSize } from "@mbank-design/design-system/design-tokens/general/typography";
import React from "react";
import "./index.scss";
import { useDesktopMedia } from "@mbank-design/design-system/components/Media/useDesktopMedia";
/**
 * @param {object} props
 * @param {string} props.pathname
 * @param {{mainTextLiterals: {mainTitle: string, mainText: string}, subTextLiterals: {subLiteralTitle: string, subLiteralText: string}[]}} props.dynamicSeoTexts
 */

const HeaderTextSection = ({ pathname, dynamicSeoTexts }) => {
  const bodyTypeLinks = ["/sedan", "/hatchback", "/kombi", "/suv", "/bus", "/van"];
  const isDesktop = Media.useDesktopMedia()
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const modifyMetaString = (string) => {
    const seg = string && string.split("-");
    return seg && seg.map((word) => capitalizeFirstLetter(word)).join(" ");
  };

  const segments = pathname.split("/").filter(Boolean);
  let params = {
    carState: null,
    fuelType: null,
    make: null,
    model: null,
    bodyType: null
  };
  const carStateMap = {
    "samochody-poleasingowe": "Samochody poleasingowe",
    "samochody-nowe": "Nowe samochody",
    "samochody-elektryczne": "Samochody elektryczne"
  };

  segments.forEach((segment) => {
    if (carStateMap[segment]) {
      if (segment === "samochody-elektryczne") {
        params.fuelType = carStateMap[segment];
      } else {
        params.carState = carStateMap[segment];
      }
    } else {
      const makeAndModel = segment.split("-");
      if (makeAndModel.length > 1) {
        if (makeAndModel[0] === "land" || makeAndModel[0] === "mercedes" || makeAndModel[0] === "alfa") {
          params.make = makeAndModel[0] + "-" + makeAndModel[1];
          params.model = makeAndModel.slice(2).join("-");
        } else {
          params.make = makeAndModel[0];
          params.model = makeAndModel.slice(1).join("-");
        }
      } else {
        params.make = makeAndModel[0];
      }
    }
  });
  
  if (
      (isDesktop &&
      !pathname.includes("/lista-pojazdow") &&
      params.model &&
      pathname &&
      pathname !== "/nowe-samochody" &&
      pathname !== "/samochody-poleasingowe")
  )
   {
    return (
      <>
        <div className="header-text-section-container mleasing-main-container">
          <div className="content-wrapper">
            <Text as="h1" styledAs={"h2"} paddingBottom={10} fontSize={FontSize.SIZE_40}>
              {params.carState ? params.carState : params.fuelType ? params.fuelType : ""}{" "}
              {modifyMetaString(params.make)} {modifyMetaString(params.model ? params.model : "")}
            </Text>
            <Text as="p">{""}</Text>
          </div>
        </div>
      </>
    );
  } else if ((isDesktop && pathname.includes("/marki/")) || bodyTypeLinks.includes(pathname)) {
    return (
      <div className="header-text-section-container mleasing-main-container">
        <div className="content-wrapper">
          <>
            <Text as="h1" styledAs={"h2"} paddingBottom={20} fontSize={FontSize.SIZE_40}>
              {dynamicSeoTexts?.mainTextLiterals.mainTitle}
            </Text>
            <Text as="p">{dynamicSeoTexts.mainTextLiterals.mainText}</Text>
          </>
        </div>
      </div>
    );
  } else if (
    pathname === "/nowe-samochody" ||
    pathname === "/samochody-poleasingowe" ||
    pathname === "/samochody-elektryczne" ||
    pathname === "/aukcje-samochodowe"
  ) {
    return (
      <div className="header-text-section-container mleasing-main-container">
        <div className="content-wrapper">
          {pathname === "/nowe-samochody" && (
            <>
              <Text as="h1" styledAs={"h2"} paddingBottom={20} fontSize={FontSize.SIZE_40}>
                Nowe samochody w mAuto
              </Text>
              <Text as="p">
                Nowy samochód, za który nie musisz płacić fortuny? Mamy na to sposób. Jeśli w Twoim biznesie ważną rolę
                odgrywa prestiżowy wizerunek lub po prostu wolisz jeździć autem, które nie było użytkowane przez nikogo
                przed Tobą, poznaj możliwości, jakie daje Ci mAuto. Nowe samochody z naszej oferty są dostępne w
                wygodnej usłudze najmu, a wybrany model dostarczymy Ci w formule
                <Text as="span"> door-to-door.</Text>
              </Text>
            </>
          )}
          {pathname === "/samochody-poleasingowe" && (
            <>
              <Text as="h2" paddingBottom={10} fontSize={FontSize.SIZE_40}>
                Samochody poleasingowe w mAuto
              </Text>
              <Text as="p">
                mAuto powstało, by ułatwić przedsiębiorcom dostęp do najlepszych samochodów w atrakcyjnych cenach.
                Oferujemy m.in. auta poleasingowe wielu lubianych marek – z niskim przebiegiem i gwarancją doskonałego
                stanu technicznego. Przetestuj naszą wygodną wyszukiwarkę, przejrzyj transparentne oferty, skorzystaj ze
                wsparcia doradcy, a potem… wypatruj przez okno swojego auta, bo dostarczymy Ci je pod dom.
              </Text>
            </>
          )}
          {pathname === "/samochody-elektryczne" && (
            <>
              <Text as="h2" paddingBottom={10} fontSize={FontSize.SIZE_40}>
                Samochody elektryczne w mAuto
              </Text>
              <Text as="p">
                Proekologiczne działania z roku na rok nabierają większego znaczenia – także w biznesie. Marki, które
                chcą budować odpowiedzialny wizerunek, inwestują w nowoczesne rozwiązania, przyjazne dla środowiska.
                Jednym z nich może być wymiana floty – z aut z tradycyjnym napędem na samochody elektryczne. Poznaj
                modele dostępne w wirtualnym salonie mAuto i wybierz atrakcyjną ofertę korzystną zarówno dla otoczenia,
                jak i dla firmowego budżetu.
              </Text>
            </>
          )}
          {pathname === "/aukcje-samochodowe" && (
            <>
              <Text as="h2" paddingBottom={10} fontSize={FontSize.SIZE_40}>
                Aukcje samochodów poleasingowych – znajdź atrakcyjną ofertę dla siebie
              </Text>
              <Text as="p">
                W przypadku zakupu samochodu poleasingowego duże znaczenie mają dwa czynniki – koszty oraz stan
                techniczny auta. Wydatki poniesione na pojazd nie powinny nadwerężać firmowych finansów, a jednocześnie
                auto musi być w stanie sprostać codziennym zadaniom, z którymi mierzą się przedsiębiorcy. Atrakcyjnym
                rozwiązaniem w takiej sytuacji może okazać się skorzystanie z aukcji samochodów poleasingowych. Dowiedz
                się, na czym polegają takie aukcje i dlaczego warto wziąć w nich udział.
              </Text>
            </>
          )}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default HeaderTextSection;
