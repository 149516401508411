import { Text } from "@mbank-design/design-system/components";
import {
  FontColor,
  FontSize,
  FontWeight,
  LineHeight,
} from "@mbank-design/design-system/design-tokens/general/typography";
import React from "react";
import "./index.scss";

/**
 * @param {object} props
 * @param {string} props.pathname
 * @param {{mainTextLiterals: {mainTitle: string, mainText: string}, subTextLiterals: {subLiteralTitle: string, subLiteralText: string}[]}} props.dynamicSeoTexts
 */

const MobileHeaderTextSection = ({ pathname, dynamicSeoTexts }) => {
  const paragraphMarginTop = 12;
  const bodyTypeLinks = ["/sedan", "/hatchback", "/kombi", "/suv", "/bus", "/van"];
  if(pathname.includes("/marki/") || bodyTypeLinks.includes(pathname)) {
    return (
      <div className='mobile-header-text-section-container mleasing-main-container'>
        <Text
          as='p'
          marginTop={paragraphMarginTop}
          fontSize={FontSize.SIZE_12}
          lineHeight={LineHeight.HEIGHT_16}
          fontWeight={FontWeight.WEIGHT_600}
          color={"doveGrayUI"}
        >
          {dynamicSeoTexts.mainTextLiterals.mainText}
        </Text>
      </div>
    )
  } else if(pathname === "/nowe-samochody" || pathname === "/samochody-poleasingowe" || pathname === "/samochody-elektryczne" || pathname === "/aukcje-samochodowe") {
    return (
      <div className='mobile-header-text-section-container mleasing-main-container'>
        {pathname === "/nowe-samochody" && (
          <>
            <Text
              as='p'
              marginTop={paragraphMarginTop}
              fontSize={FontSize.SIZE_12}
              lineHeight={LineHeight.HEIGHT_16}
              fontWeight={FontWeight.WEIGHT_600}
              color={"doveGrayUI"}
            >
              Nowy samochód, za który nie musisz płacić fortuny? Mamy na to
              sposób. Jeśli w Twoim biznesie ważną rolę odgrywa prestiżowy
              wizerunek lub po prostu wolisz jeździć autem, które nie było
              użytkowane przez nikogo przed Tobą, poznaj możliwości, jakie daje Ci
              mAuto. Nowe samochody z naszej oferty są dostępne w wygodnej usłudze
              najmu, a wybrany model dostarczymy Ci w formule
              <Text
                as='span'
                fontSize={FontSize.SIZE_12}
                lineHeight={LineHeight.HEIGHT_16}
                fontWeight={FontWeight.WEIGHT_600}
                color={"doveGrayUI"}
              >
                {" "}door-to-door.
              </Text>
            </Text>
          </>
        )}
        {pathname === "/samochody-poleasingowe" && (
          <>
            <Text
              as='p'
              marginTop={paragraphMarginTop}
              fontSize={FontSize.SIZE_12}
              lineHeight={LineHeight.HEIGHT_16}
              fontWeight={FontWeight.WEIGHT_600}
              color={"doveGrayUI"}
            >
              mAuto powstało, by ułatwić przedsiębiorcom dostęp do najlepszych
              samochodów w atrakcyjnych cenach. Oferujemy m.in. auta poleasingowe
              wielu lubianych marek – z niskim przebiegiem i gwarancją doskonałego
              stanu technicznego. Przetestuj naszą wygodną wyszukiwarkę, przejrzyj
              transparentne oferty, skorzystaj ze wsparcia doradcy, a potem…
              wypatruj przez okno swojego auta, bo dostarczymy Ci je pod dom.
            </Text>
          </>
        )}
        {pathname === "/samochody-elektryczne" && (
          <>
            <Text
              as='p'
              marginTop={paragraphMarginTop}
              fontSize={FontSize.SIZE_12}
              lineHeight={LineHeight.HEIGHT_16}
              fontWeight={FontWeight.WEIGHT_600}
              color={"doveGrayUI"}
            >
              Proekologiczne działania z roku na rok nabierają większego znaczenia – także w biznesie. Marki, które chcą
              budować odpowiedzialny wizerunek, inwestują w nowoczesne rozwiązania, przyjazne dla środowiska. Jednym z nich
              może być wymiana floty – z aut z tradycyjnym napędem na samochody elektryczne. Poznaj modele dostępne w
              wirtualnym salonie mAuto i wybierz atrakcyjną ofertę korzystną zarówno dla otoczenia, jak i dla firmowego
              budżetu.
            </Text>
          </>
        )}
        {pathname === "/aukcje-samochodowe" && (
          <>
            <Text
              as='p'
              marginTop={paragraphMarginTop}
              fontSize={FontSize.SIZE_12}
              lineHeight={LineHeight.HEIGHT_16}
              fontWeight={FontWeight.WEIGHT_600}
              color={"doveGrayUI"}
            >
              W przypadku zakupu samochodu poleasingowego duże znaczenie mają
              dwa czynniki – koszty oraz stan techniczny auta. Wydatki
              poniesione na pojazd nie powinny nadwerężać firmowych finansów, a
              jednocześnie auto musi być w stanie sprostać codziennym zadaniom,
              z którymi mierzą się przedsiębiorcy. Atrakcyjnym rozwiązaniem w
              takiej sytuacji może okazać się skorzystanie z aukcji samochodów
              poleasingowych. Dowiedz się, na czym polegają takie aukcje i
              dlaczego warto wziąć w nich udział.
            </Text>
          </>
        )}
      </div>
    );
  } else {
    return <></>
  }
};

export default MobileHeaderTextSection;
