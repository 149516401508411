import axios from "axios";

export const LocalStorageService = (() => {
  let service;

  function getService() {
    if (!service) {
      service = this;
      return service;
    }
    return service;
  }

  const setToken = (tokenObj) => {
    localStorage.setItem("access_token_m_Auto", tokenObj.access_token);
    localStorage.setItem("refresh_token_m_Auto", tokenObj.refresh_token);
    localStorage.setItem("utoken_m_Auto", tokenObj.utoken);
  };
  const getAccessToken = () => {
    return localStorage.getItem("access_token_m_Auto");
  };
  const getRefreshToken = () => {
    return localStorage.getItem("refresh_token_m_Auto");
  };
  const clearToken = () => {
    localStorage.removeItem("access_token_m_Auto");
    localStorage.removeItem("refresh_token_m_Auto");
    localStorage.removeItem("utoken_m_Auto");
    localStorage.removeItem("userName");
  };
  return {
    getService: getService,
    setToken: setToken,
    getAccessToken: getAccessToken,
    getRefreshToken: getRefreshToken,
    clearToken: clearToken,
  };
})();
const localStorageService = LocalStorageService.getService();

export const refreshToken = (originalRequest, error) => {
  return axios
  .post("/api/Account/RefreshManually", {
    ExpiredAccessToken: localStorageService.getAccessToken(),
    RefreshToken: localStorageService.getRefreshToken(),
  })
  .then((res) => {
    if (res.status === 200) {
      localStorageService.setToken(res.data);
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorageService.getAccessToken();
      return axios(originalRequest);
    } else {
      localStorageService.clearToken();
    }
    return Promise.reject(error);
  })
  .catch((errRes) => {
    localStorageService.clearToken();
    localStorage.setItem("isMautoAccessDenied", true);
    window.location.href = "/login";
  });
};

axios.interceptors.request.use(
  (config) => {
    const token = localStorageService.getAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    const originalRequest = response.config;

    if (
      response &&
      response.status === 401 &&
      originalRequest.url === "/api/Account/RefreshManually"
    ) {
      return Promise.reject(response);
    }
    if (response && response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      if (response.data !== "Invalid credentials") {
        return refreshToken(originalRequest, response);
      }
    }
    if (response && response.status === 400) {
      if (response.data.ErrorMessage) {
        alert(
          response.data.ErrorMessage +
            ". W razie problemów skontaktuj się z administratorem."
        );
      }
      if (response.data.length) {
        alert(
          response.data +
            ". W razie problemów skontaktuj się z administratorem."
        );
      }
      if (!response.data) {
        alert(
          "Wystąpił błąd. W razie problemów skontaktuj się z administratorem."
        );
      }
    }
    if (response && response.status === 500) {
      alert(
        "Wystąpił błąd po stronie serwera. W razie problemów skontaktuj się z administratorem."
      );
    }
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      originalRequest.url === "/api/Account/RefreshManually"
    ) {
      return Promise.reject(error);
    }
    if (error.response && error.response.status === 409) {
      localStorageService.clearToken();
      localStorage.setItem("isMautoAccessDenied", true);
      window.location.href = "/login";
      return Promise.reject(error);
    }
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      switch (error.response.data.ErrorCode.toString()) {
        case '44':
        case '45':
        case '46':
          // 45 - token not found
          // 44 - token revoked
          // 46 - invalid token
          localStorageService.clearToken();
          localStorage.setItem("isMautoAccessDenied", true);
          window.location.href = "/login";
          return Promise.reject(error);
        case '35':
        case '47':
        case '48':
          // 35 - bad credentials(login)
          // 47 - account is not approved by admin(login)
          // 48 - account not activated by the link
          break;
        default:
          return refreshToken(originalRequest, error);
      }
      // if (error.response.data.ErrorCode == 44 ||
      //   error.response.data.ErrorCode == 45 |
      //   error.response.data.ErrorCode == 46) {
      //   // 45 - token not found
      //   // 44 - token revoked
      //   // 46 - invalid token
      //   localStorageService.clearToken();
      //   localStorage.setItem("isMautoAccessDenied", true);
      //   window.location.href = "/login";
      //   return Promise.reject(error);
      // }
      // if (error.response.data.ErrorCode == 35 || error.response.data.ErrorCode == 47) {
      //   // 35 - bad credentials(login)
      //   // 47 - account is not approved by admin(login)
      // }
    }

    if (
      error.response &&
      error.response.status === 403
    ) {
      if (error.response.data.ErrorCode == 40 || error.response.data.ErrorCode == 49) {
        // 40 - too many login attempts
        // 49 - account is blocked by admin
      }
    }

    if (error.response && error.response.status === 400) {
      alert(
        error.response.data.ErrorMessage +
          ". W razie problemów skontaktuj się z administratorem."
      );
    }
    if (error.response && error.response.status === 500) {
      alert(
        "Wystąpił błąd po stronie serwera. W razie problemów skontaktuj się z administratorem."
      );
    }

    return Promise.reject(error);
  }
);
