import {LocalStorageService} from "../../../services/jwt";

export function endpointGuard(errCode) {
  switch (errCode.toString()) {
    case '100':
      // accept rules
      window.location.href = '/zaakceptuj-regulamin';
      break;
    case '101':
      // add statement
      window.location.href = '/ustawienia?editmode=true';
      break;
    case '44':
    case '45':
    case '46':
      LocalStorageService.clearToken();
      localStorage.setItem("isMautoAccessDenied", true);
      window.location.href = "/login";
      break;
    // case '102':
    //   // add statement
    //   window.location.href = '/add-statement';
    //   break;
    default:
      window.location.href = '/404';
      break;
  }
}
