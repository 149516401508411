export const generateUrlImage = (url, size) => {
  if (!url) return '';
  
  if (size) {
    return url.replace('[size]', size)
  } else {
    return url.replace('/[size]', '')
  }
};

export const IMAGE_SIZE = {
  SMALL: 320,
  MEDIUM: 640,
  LARGE: 1280,
}
