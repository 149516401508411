import React from "react";

import { Text } from "@mbank-design/design-system/components";
import {
  FontWeight,
  FontSize,
  LineHeight,
  FontColor,
} from "@mbank-design/design-system/design-tokens/general/typography";

import "../../../assets/styles/components/question-text-section-container.scss";

/**
 * @param {object} props
 * @param {string} props.pathname
 * @param {{mainTextLiterals: {mainTitle: string, mainText: string}, subTextLiterals: {subLiteralTitle: string, subLiteralText: string}[]}} props.dynamicSeoTexts
 */

const DynamicQuestionTextSection = ({pathname, dynamicSeoTexts}) => {
  const bodyTypeLinks = ["/sedan", "/hatchback", "/kombi", "/suv", "/bus", "/van"];

  const segments = pathname.split("/").filter(Boolean);
  let params = {
    carState: null,
    fuelType: null,
    make: null,
    model: null,
    bodyType: null
  };
  const carStateMap = {
    "samochody-poleasingowe": "Samochody poleasingowe",
    "samochody-nowe": "Nowe samochody",
    "samochody-elektryczne": "Samochody elektryczne"
  };

  segments.forEach((segment) => {
    if (carStateMap[segment]) {
      if (segment === "samochody-elektryczne") {
        params.fuelType = carStateMap[segment];
      } else {
        params.carState = carStateMap[segment];
      }
    } else {
      const makeAndModel = segment.split("-");
      if (makeAndModel.length > 1) {
        if (makeAndModel[0] === "land" || makeAndModel[0] === "mercedes" || makeAndModel[0] === "alfa") {
          params.make = makeAndModel[0] + "-" + makeAndModel[1];
          params.model = makeAndModel.slice(2).join("-");
        } else {
          params.make = makeAndModel[0];
          params.model = makeAndModel.slice(1).join("-");
        }
      } else {
        params.make = makeAndModel[0];
      }
    }
  });

  if((pathname.includes("/marki/") || bodyTypeLinks.includes(pathname)) && !params.model) {
    return (
      <div className='question-text-section-container mleasing-main-container'>
        {
          dynamicSeoTexts.subTextLiterals.map(subLiteral => {
            return (
              <>
                <Text
                as='h2'
                styledAs={"h5"}
                fontSize={FontSize.SIZE_24}
                lineHeight={LineHeight.HEIGHT_32}
                dangerouslySetInnerHTML={{__html: subLiteral.subLiteralTitle}}
                >
                </Text>
                <Text
                as='p'
                fontSize={FontSize.SIZE_12}
                lineHeight={LineHeight.HEIGHT_16}
                fontWeight={FontWeight.WEIGHT_600}
                color={"doveGrayUI"}
                dangerouslySetInnerHTML={{__html: subLiteral.subLiteralText}}
                >
                </Text>
              </>
            );
          })
        }
      </div>
    );
  }
};

export default DynamicQuestionTextSection;
