import React, { Component } from "react";
import PropTypes from "prop-types";
import Paper from "../../parts/Paper";
import Validator from "../../../scripts/commons/validator";
import {
  Button,
  Checkbox,
  Text,
  TextField,
} from "@mbank-design/design-system/components";
import {
  FontSize,
  FontWeight,
  LineHeight,
  FontColor,
} from "@mbank-design/design-system/design-tokens/general/typography";
import { Stack } from "@mbank-design/design-system/components/Stack/Stack";
import {
  getSiteRules,
  sendContactForm,
  sendContactMarketingForm,
} from "../../../services";
import { LoadingSpinner } from "../../parts/LoadingSpinner";
import {
  regexEmail,
  regexName,
  regexPhone,
  regexSurname,
} from "../../../scripts/commons/regex/regex";
import "./index.scss";
import ReCAPTCHA from "react-google-recaptcha";
import { literalCreator } from "../../../literals/literals";
import {
  setCampaignFormSentAuctionId,
  setCampaignFormSentData, setVehicleOfferFormInsightTag
} from "../../common/campaign-data-setter";
import { dataLayerObj } from "../../../scripts/commons/common";
import { withRouter, Link } from "react-router-dom";
import { Mauto40StripeMass, MbankLeasingMass } from "@mbank-design/design-system/logos";
import { Alignment, CheckboxLabelSize, Direction } from "@mbank-design/design-system/enums";

const recaptchaRef = React.createRef();

class ContactFormForm extends Component {
  static propTypes = {
    auctionID: PropTypes.string,
  };

  componentDidMount() {
    this.setState({specialTile:sessionStorage.getItem("specialTile")}, ()=>{
      sessionStorage.removeItem("specialTile");
    })
    literalCreator.getLiterals().then((res) => {
      this.setState({ literals: literalCreator.data });
    });
    getSiteRules().then((res) => {
      let rulesAnchor = document.getElementById("siteRules");
      if (rulesAnchor) {
        rulesAnchor.download = res.data.Name;
        rulesAnchor.href = res.data.Base64;
        rulesAnchor.target = "_blank";
      }
    });
    this.getClientContactPreference();
    const removeEkoFleet = () => {
      sessionStorage.removeItem("ekoFleet");
    };

    this.unlisten = this.props.history.listen(() => {
      removeEkoFleet();
    });
  }
  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
    }
  }

  state = {
    name: "",
    nameError: "",
    surName: "",
    surNameError: "",
    email: "",
    emailError: "",
    phoneNumber: "",
    phoneNumberError: "",
    question: "",
    questionError: "",
    acceptRules: null,
    acceptMarketingAgreement: null,
    acceptTradeAgreement: false,
    isSending: false,
    showMore: false,
    isOnlyOffer: false,
    showFullAgreement: false,
    hideCaptcha: false,
    specialTile: null
  };

  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }
  handleSurNameChange(e) {
    this.setState({ surName: e.target.value });
  }
  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }
  handleMessageChange(e) {
    this.setState({ question: e.target.value });
  }
  handlePhoneChange(e) {
    this.setState({ phoneNumber: e.target.value });
  }
  handleAgreement(e) {
    this.setState({ [e.target.name]: e.target.checked });
  }

  getClientContactPreference(){
    const offerState = localStorage.getItem('onlyOffer')
    this.setState({isOnlyOffer: offerState})
  }

  clearClientContactPreference(){
    localStorage.removeItem('onlyOffer')
    sessionStorage.removeItem('ekoFleet')
    this.setState({isOnlyOffer:false})
  }

  validateField(fieldName, validators) {
    let isError = false;
    let errorMsg = "";
    let fieldValue =
      typeof this.state[fieldName] === "string"
        ? this.state[fieldName].toString().trim()
        : this.state[fieldName];

    if (fieldValue && validators.regex) {
      let regexTestPassed = true;
      if (validators.regex === "email") {
        let regex =
          /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        regexTestPassed = regex.test(fieldValue);
        if (!regexTestPassed) {
          errorMsg = "Email jest nieprawidłowy";
          isError = true;
        }
      }
      if (validators.regex === "name") {
        let regex =
        /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ ]+$/i;
        regexTestPassed = regex.test(fieldValue);
        if (!regexTestPassed) {
          errorMsg = "Imię może zawierać tylko litery";
          isError = true;
        }
      }
      if (validators.regex === "surName") {
        let regex =
        /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ -]+$/i;
        regexTestPassed = regex.test(fieldValue);
        if (!regexTestPassed) {
          errorMsg = "Nazwisko może zawierać tylko litery";
          isError = true;
        }
      }

      if(validators.regex === "phoneNumber") {
        let regexResult = this.state.phoneNumber.split(" ").join("").split("-").join("").match(regexPhone);
        let isValid = regexResult && regexResult[0] === regexResult.input;
        if(!isValid) {
          errorMsg = "Numer telefonu jest nieprawidłowy";
          isError = true;
        }
      }
    }

    if (fieldValue && validators.exactLength) {
      if (fieldValue.toString().length !== validators.exactLength) {
        isError = true;
        errorMsg = `Pole przyjmuje ${validators.exactLength} znaków`;
      }
    }

    if (fieldValue && validators.maxLength) {
      if (fieldValue.toString().length > validators.maxLength) {
        isError = true;
        errorMsg = `Pole przyjmuje maksymalnie ${validators.maxLength} znaków`;
      }
    }

    if (fieldValue && validators.minLength) {
      if (fieldValue.toString().length < validators.minLength) {
        isError = true;
        errorMsg = `Pole wymaga minimum ${validators.minLength} znaków`;
      }
    }

    if (validators.required) {
      if (!fieldValue) {
        isError = true;
        errorMsg = "Pole wymagane";
      }
    }
    this.setState({ [fieldName]: fieldValue, [fieldName + "Error"]: errorMsg });
    return isError;
  }

  afterLeaseContactForm(stateRecaptchaValue) {
    let packages = [];
    for (let pkg of this.props.properties.AdditionalServices) {
      if (pkg.isChecked) {
        packages.push(pkg.Value);
      }
    }
    let isCash = Number(this.props.properties.FinancingMethod.Value) === 3;
    sendContactForm({
      SendCertificateData: this.props.offerData?.IsCertified,
      AuctionId: this.props.auctionID,
      CptResp: stateRecaptchaValue,
      Name: this.state.name,
      Surname: this.state.surName,
      Email: this.state.email,
      PhoneNumber: this.state.phoneNumber.split(" ").join("").split("-").join(""),
      MessageBody: this.state.question,
      Utm_source: localStorage.getItem("utm_source") ? localStorage.getItem("utm_source") : "",
      Utm_medium: localStorage.getItem("utm_medium") ? localStorage.getItem("utm_medium") : "",
      Utm_campaign: localStorage.getItem("utm_campaign") ? localStorage.getItem("utm_campaign") : "",
      Utm_content: localStorage.getItem("utm_content") ? localStorage.getItem("utm_content") : "",
      Dclid: localStorage.getItem("dclid") ? localStorage.getItem("dclid") : "",
      CapaginId: localStorage.getItem("mAutoCampaign") ? localStorage.getItem("mAutoCampaign") : "",
      CapaginCmId: localStorage.getItem("mAutoclick_id") ? localStorage.getItem("mAutoclick_id") : "",
      CapaginSaId: localStorage.getItem("mAutoGCLID") ? localStorage.getItem("mAutoGCLID") : "",
      CarDetails: {
        Make: this.props.offerData.Make,
        Model: this.props.offerData.Model,
        PlateNumber: this.props.offerData.PlateNumber,
        ProductionYear: parseInt(this.props.offerData.Year),
        CarMileage: parseInt(this.props.offerData.Mileage),
        GearBoxType: this.props.offerData.GearBoxType,
        FuelType: this.props.offerData.FuelType
      },
      CalculatorContactFormData: this.props.properties
        ? {
            IsLeasing: isCash ? false : Number(this.props.properties.FinancingMethod.Value) !== 1,
            IsNew: false,
            Period: isCash ? -1 : this.props.properties.Periods.Value,
            Ow: isCash ? -1 : this.props.properties.CalculationResult.Ow,
            OwPercent: isCash ? -1 : this.props.properties.Ows.Value,
            Rv: isCash ? -1 : this.props.properties.CalculationResult.Rv,
            RvPercent: isCash ? -1 : this.props.properties.Rvs ? this.props.properties.Rvs.Value : -1,
            Mileage: isCash ? -1 : this.props.properties.Mileages ? this.props.properties.Mileages.Value : -1,
            InstallmentPriceNetto: isCash
              ? -1
              : this.props.properties.CalculationResult.InstallmentPricePromo > 0
              ? this.props.properties.CalculationResult.InstallmentPricePromo
              : this.props.properties.CalculationResult.InstallmentPrice,
            TotalFeesPercent: isCash ? -1 : this.props.properties.CalculationResult.TotalFees,
            TotalPriceBrutto: this.props.offerData.TotalPriceBrutto,
            AdditionalServices: isCash ? [] : packages
          }
        : {}
    })
      .then((res) => {
        dataLayerObj(res.data.LeadId, "afterLease_contact_form");
        setCampaignFormSentData();
        setVehicleOfferFormInsightTag();
        setCampaignFormSentAuctionId(this.props.auctionID);
        if (!this.props.offerData?.IsCertified) {
        window.location.href = "/wyslano-pomyslnie";
        this.clearClientContactPreference();
        }
      });
  }

  newContactForm(stateRecaptchaValue) {
    let packages = [];
    if (this.props.properties.AdditionalServices) {
      for (let pkg of this.props.properties.AdditionalServices) {
        if (pkg.isChecked) {
          packages.push(pkg.Value);
        }
      }
    }

    // IsLeasing - calculator
    // IsNew - new car
    sendContactForm({
      AuctionId: this.props.offerData.AuctionId,
      CptResp: stateRecaptchaValue,
      Name: this.state.name,
      Surname: this.state.surName,
      Email: this.state.email,
      PhoneNumber: this.state.phoneNumber.split(" ").join("").split("-").join(""),
      MessageBody: this.state.question,
      Utm_source: localStorage.getItem("utm_source") ? localStorage.getItem("utm_source") : "",
      Utm_medium: localStorage.getItem("utm_medium") ? localStorage.getItem("utm_medium") : "",
      Utm_campaign: localStorage.getItem("utm_campaign") ? localStorage.getItem("utm_campaign") : "",
      Utm_content: localStorage.getItem("utm_content") ? localStorage.getItem("utm_content") : "",
      Dclid: localStorage.getItem("dclid") ? localStorage.getItem("dclid") : "",
      CapaginId: localStorage.getItem("mAutoCampaign") ? localStorage.getItem("mAutoCampaign") : "",
      CapaginCmId: localStorage.getItem("mAutoclick_id") ? localStorage.getItem("mAutoclick_id") : "",
      CapaginSaId: localStorage.getItem("mAutoGCLID") ? localStorage.getItem("mAutoGCLID") : "",
      CarDetails: {
        Make: this.props.offerData.Make,
        Model: this.props.offerData.Model,
        PlateNumber: this.props.offerData.PlateNumber,
        ProductionYear: parseInt(this.props.offerData.Year),
        CarMileage: parseInt(this.props.offerData.Mileage),
        GearBoxType: this.props.offerData.GearBoxType,
        FuelType: this.props.offerData.FuelType
      },
      CalculatorContactFormData: this.props.properties
        ? {
            IsLeasing: Number(this.props.properties.FinancingMethod.Value) === 2 || Number(this.props.properties.FinancingMethod.Value) === 4,
            IsNew: true,
            Period: Number(this.props.properties.FinancingMethod.Value) === 4 ? 36 : this.props.properties.Periods.Value,
            Ow: (this.props.offerData.FuelType === "EL" && Number(this.props.properties.FinancingMethod.Value) === 4) ? 27000 : this.props.properties.CalculationResult.Ow,
            OwPercent: Number(this.props.properties.FinancingMethod.Value) === 4 ? 20 : this.props.properties.Ows.Value,
            Rv: this.props.properties.CalculationResult.Rv,
            RvPercent: Number(this.props.properties.FinancingMethod.Value) === 4 ? 1 : this.props.properties.Rvs?.Value ?? -1,
            Mileage: this.props.properties.Mileages ? this.props.properties.Mileages.Value : 0,
            InstallmentPriceNetto:
              this.props.properties.CalculationResult.InstallmentPricePromo > 0
                ? this.props.properties.CalculationResult.InstallmentPricePromo
                : this.props.properties.CalculationResult.InstallmentPrice,
            TotalFeesPercent: this.props.properties.CalculationResult.TotalFees,
            TotalPriceBrutto: this.props.offerData.TotalPriceBrutto,
            AdditionalServices: Number(this.props.properties.FinancingMethod.Value) === 4 ? [] : packages
          }
        : {}
    }).then((res) => {
      dataLayerObj(res.data.LeadId, "new_contact_form");
      setCampaignFormSentData();
      setVehicleOfferFormInsightTag();
      setCampaignFormSentAuctionId(this.props.offerData.AuctionId);
      if (!this.props.offerData?.IsCertified) {
        window.location.href = "/wyslano-pomyslnie";
        this.clearClientContactPreference();
      }
    })
      .catch((res) => {});
  }

  sendSelfFinancingForm(stateRecaptchaValue) {
    sendContactMarketingForm({
      Email: this.state.email,
      CptResp: stateRecaptchaValue,
      PhoneNumber: this.state.phoneNumber.split(" ").join("").split("-").join(""),
      MessageBody: this.props.lpQ7
        ? "Audi Q7"
        : this.props.lpTesla3
        ? "promo"
        : this.props.lpTeslaY
        ? "Tesla Y"
        : this.props.lpAfi
        ? "Afi"
        : this.props.hyundai
        ? "hyundai"
        : this.props.lpBMW
        ? "BMW"
        : this.props.lpTargi
        ? "mauto/targi"
        : this.props.lpKonkurs
        ? "Konkurs"
        : this.state.question,
      Name: this.state.name,
      Surname: this.state.surName,
      Utm_source: localStorage.getItem("utm_source") ? localStorage.getItem("utm_source") : "",
      Utm_medium: localStorage.getItem("utm_medium") ? localStorage.getItem("utm_medium") : "",
      Utm_campaign: localStorage.getItem("utm_campaign") ? localStorage.getItem("utm_campaign") : "",
      Utm_content: localStorage.getItem("utm_content") ? localStorage.getItem("utm_content") : "",
      Dclid: localStorage.getItem("dclid") ? localStorage.getItem("dclid") : "",
      CapaginId: localStorage.getItem("mAutoCampaign")
      ? localStorage.getItem("mAutoCampaign")
      : "",
      CapaginCmId: this.props.lpQ7
        ? "Audi Q7"
        : this.props.lpTesla3
        ? "promo"
        : this.props.lpTeslaY
        ? "Tesla Y"
        : this.props.lpAfi
        ? "Afi"
        : this.props.hyundai
        ? "hyundai"
        : this.props.lpBMW
        ? "BMW"
        : this.props.lpTargi
        ? "mauto/targi"
        : this.props.lpKonkurs
        ? "Konkurs"
        : localStorage.getItem("mAutoclick_id")
        ? localStorage.getItem("mAutoclick_id")
        : "",
      CapaginSaId: localStorage.getItem("mAutoGCLID")
        ? localStorage.getItem("mAutoGCLID")
        : "",
      FormType: sessionStorage.getItem("ekoFleet")
        ? 4
        : this.state.specialTile === "1"
        ? 5
        : this.state.specialTile === "2"
        ? 6
        : this.state.specialTile === "3"
        ? 7
        : 2,
    }).then((res)=>{
      dataLayerObj(res.data.LeadId, "self_financing_form");
      if(this.props.lpAfi){
        sessionStorage.setItem("lpAfiFormId", res.data.LeadId);
      }
      window.location.href = "/wyslano-pomyslnie";
      this.clearClientContactPreference();
    });
  }

  async sendForm(event) {
    let nameErr = this.validateField("name", {
      required: true,
      minLength: 2,
      maxLength: 20,
      regex:"name"
    });
    let surErr = this.validateField("surName", {
      required: true,
      minLength: 2,
      maxLength: 50,
      regex:"surName"
    });
    let emailErr = this.validateField("email", {
      required: true,
      minLength: 6,
      maxLength: 50,
      regex: "email",
    });
    let phoneErr = this.validateField("phoneNumber", {
      required: true,
      minLength: 9,
      maxLength: 15,
      regex: "phoneNumber"
    });
    if (
      new Validator(event.target).isFormValid &&
      !nameErr &&
      !surErr &&
      !emailErr &&
      !phoneErr
    ) {

      let stateRecaptchaValue=this.state.recaptchaValue;
      if(!this.state.recaptchaValue && !this.state.hideCaptcha){
        stateRecaptchaValue = await recaptchaRef.current.executeAsync();
        this.setState({ recaptchaValue: stateRecaptchaValue });
      }
      if (stateRecaptchaValue || this.state.hideCaptcha) {
        if (this.props.offerData?.IsCertified && !this.state.isOnlyOffer) {
          this.props.handleCertificateSubmitted();
        }
        this.setState({ isSending: true });
        // if properties, offerData and auctionID are undefined then we want to send simple contact-form not related with any car
        if(!this.props.properties && !this.props.offerData && !this.props.auctionID) {
          this.sendSelfFinancingForm(stateRecaptchaValue);
        } else if (this.props.isSelfFinancing) {
          this.sendSelfFinancingForm(stateRecaptchaValue);
        } else {
          this.props.isAfterLease
          ? this.afterLeaseContactForm(stateRecaptchaValue)
          : this.newContactForm(stateRecaptchaValue);
        }
      } 
    } else {
      this.setState({
        acceptRules: !!this.state.acceptRules,
        acceptMarketingAgreement: !!this.state.acceptMarketingAgreement,
      });
    }
  }

  handleShowMoreButton() {
    this.setState({ showMore: !this.state.showMore });
  }

  render() {
    const state = this.state;
    return (
      <Paper
        className={`contact-form-section-container 
        }`}
      >
        {this.props.isSpecialLandingPage ? (
          <>
            <div className={`contact-form-section__logo-container ${this.props.lpBMW?'contact-form-section__logo-container--hide':''}`}>
              <Link to="/">
                {!this.props.lpTargi ? <Mauto40StripeMass /> : <MbankLeasingMass />}
              </Link>
              <div className="logo-container__line"></div>
              <div className="logo-container__text">
                <Text
                  as="p"
                  fontSize={14}
                  color={"black30"}
                  fontWeight={FontWeight.WEIGHT_600}
                  lineHeight={LineHeight.HEIGHT_16}
                >
                  Najem.
                </Text>
                <Text
                  as="p"
                  fontSize={14}
                  color={"black30"}
                  fontWeight={FontWeight.WEIGHT_600}
                  lineHeight={LineHeight.HEIGHT_16}
                >
                  Leasing.
                </Text>
                <Text
                  as="p"
                  fontSize={14}
                  color={"black30"}
                  fontWeight={FontWeight.WEIGHT_600}
                  lineHeight={LineHeight.HEIGHT_16}
                >
                  Dla firm.
                </Text>
              </div>
            </div>
            <div className="contact-form-section__text-container">
              <Text as="h2" className="title" marginBottom={8} fontSize={38} lineHeight={40}>
                wypełnij formularz
              </Text>
              {!this.props.lpTargi &&
                <Text as="h4" className="title" marginBottom={24} fontSize={16} fontWeight={500}>
                  Nasz doradca <br /> skontaktuje się z Tobą
                </Text>
              }
            </div>
          </>
        ) : (
          <>
            {!this.props.isDefaultContact && !this.state.isSending ? (
              <Text as="h2" className="title" marginBottom={24}>
                {this.state.literals && this.state.literals.cfp_form_title}
              </Text>
            ) : null}
          </>
        )}
        {!this.state.isSending ? (
          <>
            {/*{/* sapecial form for lpTargi */}
            {false ? <div class="ml-embedded" data-form="hEAG3f"></div>
            :
            <form className={ this.props.isSpecialLandingPage ? 'form--isSpecialLandingPage ':'' }>
              <Stack direction={[Direction.COLUMN, Direction.ROW]}>
                <TextField
                  placeholder="imię"
                  value={state.name.trimStart()}
                  inputProps={{
                    required: "required",
                    pattern: regexName,
                    autoComplete: "given-name",
                    minLength: 2,
                  }}
                  errorMessage={this.state.nameError}
                  onChange={(v) => this.handleNameChange(v)}
                  id="name"
                />
                <TextField
                  placeholder="nazwisko"
                  value={state.surName}
                  inputProps={{
                    required: "required",
                    pattern: regexSurname,
                    autoComplete: "family-name",
                    minLength: 2,
                  }}
                  errorMessage={this.state.surNameError}
                  onChange={(v) => this.handleSurNameChange(v)}
                  id="surname"
                />
              </Stack>
              <Stack direction={[Direction.COLUMN, Direction.ROW]}>
                <TextField
                  placeholder="adres e-mail"
                  value={state.email}
                  errorMessage={this.state.emailError}
                  inputProps={{
                    required: "required",
                    pattern: regexEmail,
                    minLength: 4,
                  }}
                  onChange={(v) => this.handleEmailChange(v)}
                  id="email"
                />
                <TextField
                  placeholder="numer telefonu"
                  value={state.phoneNumber.trim()}
                  errorMessage={this.state.phoneNumberError}
                  inputProps={{
                    required: "required",
                    autoComplete: "tel",
                    pattern: null,
                  }}
                  onChange={(v) => this.handlePhoneChange(v)}
                  id="phone"
                />
              </Stack>
              {this.props.specialTile === "2" &&
              <TextField
              onChange={(v) => this.handleMessageChange(v)}
              value={state.question}
              inputProps={{}}
              className="textarea"
              errorMessage={this.state.questionError}
              placeholder="napisz czego szukasz? (opcjonalnie)"
              multiline={true}
              />
              }
              {!this.props.offerData?.IsCertified || !this.state.isOnlyOffer &&
              <TextField
              onChange={(v) => this.handleMessageChange(v)}
              value={state.question}
              inputProps={{}}
              className="textarea"
              errorMessage={this.state.questionError}
              placeholder="pytanie lub specjalnie życzenia"
              multiline={true}
              />
              }
              {!this.props.lpTargi &&
                <div className="checkbox-container">
                  <Checkbox
                    id="ar"
                    checkboxAlignment={Alignment.START}
                    name="acceptRules"
                    required={this.props.lpTargi ? false : true}
                    labelSize={CheckboxLabelSize.SMALL}
                    fontWeight={FontWeight.WEIGHT_600}
                    checked={state.acceptRules}
                    onChange={(e) => this.handleAgreement(e)}
                    label={
                      <Text as="span" fontSize={FontSize.SIZE_12} fontWeight={FontWeight.WEIGHT_600}>
                        Zapoznałem się z&nbsp;
                        <a id="siteRules" className={this.props.isSpecialLandingPage ? "contact-form__link-button" : ""}>
                          regulaminem&nbsp;
                        </a>
                        i akceptuję jego postanowienia.*
                      </Text>
                    }
                    errorMessage={
                      state.acceptRules === false ? "Zgoda jest wymagana" : ""
                    }
                  />
                </div>
              }
              <div className="checkbox-container checkbox-container--last">
                <Checkbox
                  id="am"
                  checkboxAlignment={Alignment.START}
                  name="acceptMarketingAgreement"
                  required={true}
                  labelSize={CheckboxLabelSize.SMALL}
                  fontWeight={FontWeight.WEIGHT_600}
                  checked={state.acceptMarketingAgreement}
                  onChange={(e) => this.handleAgreement(e)}
                  label={this.props.isSpecialLandingPage&&!this.state.showFullAgreement?"Wyrażam zgodę na wykorzystanie przez mLeasing Sp. z o.o. wskazanych w formularzu numeru telefonu":"Wyrażam zgodę na wykorzystanie przez mLeasing Sp. z o.o. wskazanych w formularzu numeru telefonu lub adresu e-mail, w celu przedstawienia informacji handlowej (oferty leasingu) zgodnie z ustawą Prawo telekomunikacyjne. Wyrażenie zgody jest dobrowolne lecz niezbędne dla realizacji powyższego celu. Wyrażona zgoda może być cofnięta w każdym czasie, co nie będzie jednak miało wpływu na podejmowane uprzednio na jej podstawie działania.* "}
                  errorMessage={
                    state.acceptMarketingAgreement === false
                      ? "Zgoda jest wymagana"
                      : ""
                  }
                />
              
                {this.props.isSpecialLandingPage?
                  <span className="checkbox-container__extend-text-button" onClick={()=>this.setState({showFullAgreement:!this.state.showFullAgreement})}>{this.state.showFullAgreement?'zwiń':'rozwiń'}</span>:<></>
                }
              </div>
              {!this.props.isSpecialLandingPage?<>
                {!this.state.showMore && this.state.literals && (
                <Text
                  fontSize={FontSize.SIZE_12}
                  as="p"
                  fontWeight={FontWeight.WEIGHT_600}
                  lineHeight={LineHeight.HEIGHT_16}
                  color={"doveGrayUI"}
                  marginBottom={16}
                >
                  {this.props.shortDisclaimer
                    ? `${this.state.literals.form_disclaimer_short.slice(
                        0,
                        306
                      )}...`
                    : `${this.state.literals.form_disclaimer_long.slice(
                        0,
                        751
                      )}...`}{" "}
                  <span
                    className="show-more-element"
                    onClick={() => this.setState({ showMore: true })}
                  >
                    rozwiń
                  </span>
                </Text>
              )}
              {this.state.showMore && (
                <>
                  <Text
                    fontSize={FontSize.SIZE_12}
                    as="p"
                    fontWeight={FontWeight.WEIGHT_600}
                    lineHeight={LineHeight.HEIGHT_16}
                    color={"doveGrayUI"}
                    marginBottom={16}
                  >
                    {this.state.literals && this.props.shortDisclaimer
                      ? this.state.literals.form_disclaimer_short
                      : this.state.literals.form_disclaimer_long}
                  </Text>
                </>
              )}
              </>: <></> }
              {!this.state.hideCaptcha ? (
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6Le-vHMpAAAAAMMtKNbTxwBIKhZaYIm-ci5dNCEg"
                    size="invisible"
                  />
              ) : (
                <></>
              )}
              <div className="buttons-container">
              <Button type="button" size={"normal"} onClick={(e) => this.sendForm(e)}>
                <>
                  {this.props.isSpecialLandingPage ? (
                    <>{this.props.lpTargi ? "pobierz raport" : "zapytaj o ofertę"}</>
                  ) : (
                  <>
                    {!this.props.offerData?.IsCertified || this.state.isOnlyOffer
                      ? "wyślij zapytanie"
                      : "chcę otrzymać dostęp do pełnego certyfikatu"}
                  </>
                  )}
                </>
                </Button>
              </div>
            </form>
            }
          </>
        ) : (
          <LoadingSpinner />
          )}
      </Paper>
    );
  }
}

export default withRouter(ContactFormForm)