export const ratingStar = `<svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.5L15.09 8.753L22 9.762L17 14.627L18.18 21.5L12 18.253L5.82 21.5L7 14.627L2 9.762L8.91 8.753L12 2.5Z" stroke="#D8D8D8" stroke-width="1.5"/>
</svg>
`;
export const binIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 9.5V20.686M15 9.5V20.686M9 9.5V20.686M20 7H4L5 23H19L20 7ZM21 4H3V7H21V4ZM15 4H9V1H15V4Z" stroke="#6e6e6e" stroke-width="1.5"/>
</svg>`;
export const fileIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M13 19.001H7M17 16H7M17 12.999H7M15 6V2H5V22H19V6H15Z" stroke="#6e6e6e" stroke-width="1.5"/>
</svg>`;
