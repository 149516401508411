// clearest class validator
export default class Validator {
  constructor(target) {
    this.target = target;
    this.isValid = true;
    this.isFormValid = true;
    this.runValidator();
  }
  runValidator() {
    const currentForm = this.target.closest("form");
    const allFormNodes = currentForm.querySelectorAll(
      "input, select, textarea"
    );
    this.removeAllInValidClasses(allFormNodes);
    this.removeAllInValidMessages(currentForm);
    this.checkAllFormElementsValid(allFormNodes);
    if (currentForm.querySelectorAll(".is-invalid").length) {
      document.querySelector(".text-error") &&
        document.querySelector(".text-error").remove();
      currentForm.insertAdjacentHTML(
        "afterend",
        `<p class="mleasing-paragraf-small mleasing-form-error-text text-error">Formularz został źle wypełniony, prosimy o poprawienie pól zaznaczonych kolorem czerwonym.</p>`
      );
      this.isFormValid = false;
    } else {
      document.querySelector(".text-error") &&
        document.querySelector(".text-error").remove();
      this.isFormValid = true;
    }
  }
  checkIfFormElementIsRequired(el) {
    const validationRequired =
      el.getAttribute("validation-required") ||
      (el.getAttribute("required") === "" && "required");
    if (
      validationRequired === "required" &&
      el.value.length < el.getAttribute("minlength")
    ) {
      this.isValid = false;
    } else if (
      el.type === "checkbox" &&
      validationRequired === "required" &&
      !el.checked
    ) {
      this.isValid = false;
    } else if (
      el.type === "radio" &&
      document.querySelectorAll(`[name=${el.name}]:checked`).length === 0
    ) {
      this.isValid = false;
    } else {
      this.isValid = true;
    }
  }
  removeAllInValidClasses(allFormInputs) {
    allFormInputs.forEach(el => {
      el.classList.remove("is-invalid");
    });
  }
  removeAllInValidMessages(currentForm) {
    currentForm.querySelectorAll(".validation-message").forEach(el => {
      el.remove();
    });
  }
  checkAllFormElementsValid(allFormInputs) {
    allFormInputs.forEach(el => {
      this.checkIfFormElementIsRequired(el);
      this.checkValidationMethod(el);
      this.addValidationClassAndMessageToInputAndResetFlag(el);
    });
  }
  addValidationClassAndMessageToInputAndResetFlag(el) {
    const validationMessage = el.getAttribute("validation-message");
    if (!this.isValid && el.type !== "radio") {
      el.classList.add("is-invalid");
      if (validationMessage) {
        el.insertAdjacentHTML(
          "afterend",
          `<span class="validation-message text-danger">${validationMessage}</span>`
        );
      }
    } else if (!this.isValid && el.type === "radio") {
      el.classList.add("is-invalid");
    } else {
      el.classList.remove("is-invalid");
    }
    this.isValid = true;
  }
  checkValidationMethod(el) {
    let specialValidationMethod = el.getAttribute("validation-method");
    const pattern = el.getAttribute("pattern");
    if (pattern) specialValidationMethod = "checkPattern";
    const regex = new RegExp(pattern);
    switch (specialValidationMethod) {
      case "checkPattern":
        const isPatternCorrect = regex.test(el.value);
        if (this.isValid && isPatternCorrect) {
          this.isValid = true;
        } else {
          this.isValid = false;
        }
      case null:
        return;
    }
  }
}
