import React from "react";

import { Icon, Text } from "@mbank-design/design-system/components";
import { HomeArrowColor, HourGlassColor, PaperColor, ShieldTickColor } from "@mbank-design/design-system/icons";

import "./index.scss";

const DetailWhyUs = () => {
  return (
    <div className="why-us">
      <div className="why-us__container content-wrapper">
        <div className="why-us__typo">
          <Text as={"h2"} styledAs={["h2", "h1"]}>
            dlaczego my?
          </Text>
          <Text as={"body"}>zobacz jakie to proste</Text>
        </div>
        <div className="why-us__items">
          <div className="why-us__item">
            <Icon iconComponent={ShieldTickColor} size={32} />
            <div className="why-us__content">
              <Text as={"h4"}>bezpieczny zakup</Text>
              <Text as={"p"} styledAs={"body2"}>
                auta zgodne z polskimi standardami i wymogami
              </Text>
            </div>
          </div>
          <div className="why-us__item">
            <Icon iconComponent={HourGlassColor} size={32} />
            <div className="why-us__content">
              <Text as={"h4"}>szybki proces</Text>
              <Text as={"p"} styledAs={"body2"}>
                auto odbierzesz w ciągu kilku dni od podpisania umowy
              </Text>
            </div>
          </div>
          <div className="why-us__item">
            <Icon iconComponent={HomeArrowColor} size={32} />
            <div className="why-us__content">
              <Text as={"h4"}>Twój komfort</Text>
              <Text as={"p"} styledAs={"body2"}>
                auto dostarczymy za darmo pod wskazany adres
              </Text>
            </div>
          </div>
          <div className="why-us__item">
            <Icon iconComponent={PaperColor} size={32} />
            <div className="why-us__content">
              <Text as={"h4"}>Twoja wygoda</Text>
              <Text as={"p"} styledAs={"body2"}>
                masz 7 dni na darmowy zwrot jeśli wybierzesz dostawę
              </Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailWhyUs;
