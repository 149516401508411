import React from "react";
import "./index.scss";
import { Button, Text } from "@mbank-design/design-system/components";

export const DetailsCertificateWidget = (props) => {
  const { widgetUrl, onePageUrl } = props;

  return (
    <div>
      <Text as={"h1"} styledAs={"h2"} paddingBottom={32} paddingTop={40}>
        ocena stanu technicznego
      </Text>
      <div className="certificate-widget-container">
        <iframe src={widgetUrl} className="certificate-widget-iframe"></iframe>
        <div className="certificate-widget-button">
          <a href={onePageUrl} target="_blank">
            <Button>przejdź do certyfikatu</Button>
          </a>
          <a href={"/certyfikat-mAuto"}>
            <Button buttonType="secondary">dowiedz się więcej</Button>
          </a>
        </div>
      </div>
    </div>
  );
};
