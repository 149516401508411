import React from "react";
import "./index.scss";
import { Icon, Text } from "@mbank-design/design-system/components";
import { FontColor, FontWeight } from "@mbank-design/design-system/design-tokens/general/typography";
import { Pin } from "@mbank-design/design-system/icons";
import { IconColor } from "@mbank-design/design-system/enums";

const PromotedOfferSticker = (props) => {
  const text = props.text || "promowana oferta";
  let iconType = props.iconType ? props.iconType : Pin;
  let color = props.color ? props.color : FontColor.zeusBlackUI;
  return (
    <div className="promoted-offer-sticker">
      <div className="star-container">
        <Icon iconComponent={iconType} size={20} primaryColor={IconColor.LIGHT} />
      </div>
      <Text as="p" paddingLeft={8} fontWeight={FontWeight.WEIGHT_600} color={color}>
        {text}
      </Text>
    </div>
  );
};

export default PromotedOfferSticker;
