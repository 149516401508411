import React from "react";

import { Text } from "@mbank-design/design-system/components";
import {
  FontWeight,
  FontSize,
  LineHeight,
  FontColor,
} from "@mbank-design/design-system/design-tokens/general/typography";

import "../../../assets/styles/components/question-text-section-container.scss";

const QuestionTextSectionLeaseCar = () => {
  return (
    <div className='question-text-section-container mleasing-main-container'>
      <Text
        as='h5'
        fontSize={FontSize.SIZE_24}
        lineHeight={LineHeight.HEIGHT_32}
      >
        Auta poleasingowe – poznaj nową jakość poszukiwania wymarzonego pojazdu{" "}
      </Text>
      <Text
        as='p'
        fontSize={FontSize.SIZE_12}
        lineHeight={LineHeight.HEIGHT_16}
        fontWeight={FontWeight.WEIGHT_600}
        color={"doveGrayUI"}
      >
        Samochody poleasingowe to pierwszy wybór wielu przedsiębiorców. Jednak
        znalezienie odpowiedniego modelu nie zawsze jest tak proste, jak mogłoby
        się wydawać. Wie o tym każdy kierowca, któremu zdarzyło się przejechać
        wiele kilometrów tylko po to, by obejrzeć używane auto, które finalnie w
        niczym nie przypominało tego ze zdjęcia w internecie. Teraz możesz raz
        na zawsze zapomnieć o takich sytuacjach. W mAuto stawiamy przede
        wszystkim na wysoką jakość samochodów i pełną przejrzystość ofert. Każde
        auto poleasingowe prezentowane w naszym serwisie jest opisane od A do Z
        oraz szczegółowo sfotografowane. Dzięki temu nie musisz nigdzie jechać,
        by je oglądać na żywo (choć jeśli wolisz, zawsze chętnie Ci je
        pokażemy). Całą procedurę zamówienia możesz przejść online, korzystając
        ze wsparcia doradcy. Wybrany samochód dostarczymy Ci w formule
        door-to-door, a jeśli nie spełni on Twoich oczekiwań albo po prostu
        zmienisz zdanie – masz 7 dni na jego zwrot.
      </Text>
      <Text
        as='h5'
        fontSize={FontSize.SIZE_24}
        lineHeight={LineHeight.HEIGHT_32}
      >
        Najwyższa jakość samochodów gwarantowana przez mLeasing i DEKRĘ
      </Text>
      <Text
        as='p'
        fontSize={FontSize.SIZE_12}
        lineHeight={LineHeight.HEIGHT_16}
        fontWeight={FontWeight.WEIGHT_600}
        color={"doveGrayUI"}
      >
        Sprzedajemy kilka tysięcy aut każdego roku. Wszystkie samochody
        poleasingowe widoczne w naszych ofertach zostały zakupione w
        autoryzowanej sieci sprzedaży w Polsce i miały tylko jednego właściciela
        – mLeasing. Na każdym etapie dbaliśmy i dbamy o kompleksowy, prawidłowy
        serwis w sieci autoryzowanych punktów, które korzystają wyłącznie z
        oryginalnych części. Stan techniczny pojazdów każdorazowo weryfikują
        eksperci firmy DEKRA, która jest obecna na rynku motoryzacyjnym od
        niemal 100 lat i świadczy usługi w zakresie rzeczoznawstwa,
        certyfikacji, badań i testów. Rzeczoznawcy DEKRY pracują zgodnie z
        najwyższymi standardami rekomendowanymi przez Polski Związek Leasingu i
        Wynajmu Pojazdów. Dla naszych klientów oznacza to rzetelną informację o
        stanie auta poleasingowego, gwarantowany przebieg i zero niespodzianek
        typowych dla rynku wtórnego.
      </Text>

      <Text
        as='h5'
        fontSize={FontSize.SIZE_24}
        lineHeight={LineHeight.HEIGHT_32}
      >
        Samochody poleasingowe – wybierz optymalne finansowanie{" "}
      </Text>
      <Text
        as='p'
        fontSize={FontSize.SIZE_12}
        lineHeight={LineHeight.HEIGHT_16}
        fontWeight={FontWeight.WEIGHT_600}
        color={"doveGrayUI"}
      >
        W mAuto dajemy Ci do wyboru trzy formy finansowania samochodów
        poleasingowych: 
        <ul>
          <li>leasing</li>
          <li>najem</li>
          <li>zakup za gotówkę</li> 
        </ul>
        Leasing samochodu używanego to rozwiązanie idealne dla osób, które po
        zakończeniu okresu umowy planują wykupić pojazd. Miesięczne raty są
        nieco wyższe niż w wypadku najmu, ale za to spłacasz całą wartość auta, a
        kiedy umowa się kończy, możesz wykupić je nawet za 1% wartości. Dodatkowo
        zyskujesz korzyści podatkowe. 
        <br></br><br></br>
        Cechy leasingu w mAuto: 
        <ul>
          <li>brak limitu przebiegu</li>
          <li>możesz leasingować samochód osobowy lub ciężarowy do 3,5 tony</li>
          <li>wybierasz dogodny dla siebie wariant spłat: opłata wstępna 0% i
          nieco wyższe raty miesięczne albo wyższa opłata na start i niższe
          miesięczne koszty</li>
          <li>przez okres umowy spłacasz całą wartość auta</li>
          <li>możesz wykupić samochód za minimalną wartość</li>
        </ul>
        Najem z kolei jest
        wygodnym sposobem finansowania dla przedsiębiorców, którzy cenią sobie
        możliwość częstej wymiany modeli aut. Jak to działa? Korzystasz z
        pojazdu, płacąc stałą, niską ratę pokrywającą wyłącznie utratę jego
        wartości. Pod koniec umowy po prostu zwracasz auto i – jeśli chcesz –
        wynajmujesz kolejne. Taka konstrukcja finansowania pozwala oszczędzać
        pieniądze oraz zmieniać samochody poleasingowe bez zobowiązań i
        konieczności wykupu. 
        <br></br><br></br>
        Cechy najmu w mAuto: 
        <ul>
          <li>możesz użytkować samochód osobowy lub ciężarowy do 3,5 tony</li>
          <li>masz niską ratę leasingową – spłacasz wyłącznie utratę wartości samochodu</li>
          <li>limit przebiegu samochodu jest określony w umowie</li>
          <li>po zakończeniu umowy możesz wybrać kolejny samochód, np. nowszy model</li>
        </ul>
        Najem i leasing samochodów używanych
        to atrakcyjne modele finansowania, które pozwalają na sprawne
        zarządzanie pieniędzmi w firmie – nie zamrażasz kapitału, wykładając
        jednorazowo sporą kwotę na zakup samochodu, wygodne comiesięczne raty
        nie stanowią dużego obciążenia dla budżetu, a zaoszczędzone pieniądze
        możesz przeznaczyć na inwestycje i rozwój biznesu. Przedsiębiorcy
        korzystający z tych form finansowania zyskują możliwość optymalizacji
        podatkowej, a samo rozliczanie kosztów jest zdecydowanie wygodniejsze
        niż przy kupnie auta. Dodatkowo każdy klient mAuto może wybierać z
        szerokiego portfolio dodatkowych usług, które ułatwią komfortową i
        bezpieczną jazdę. Jeśli jednak wolisz od razu stać się właścicielem
        samochodu poleasingowego, w mAuto dajemy Ci również możliwość zakupu za
        gotówkę. Skorzystaj z wygodnego kalkulatora przy wybranej ofercie, by
        sprawdzić, która z trzech opcji będzie dla Ciebie najlepsza. W podjęciu
        decyzji pomogą Ci też nasi doradcy.
      </Text>

      <Text
        as='h5'
        fontSize={FontSize.SIZE_24}
        lineHeight={LineHeight.HEIGHT_32}
      >
        Auta używane plus usługi zarządzania – dostępne również dla małych firm
      </Text>
      <Text
        as='p'
        fontSize={FontSize.SIZE_12}
        lineHeight={LineHeight.HEIGHT_16}
        fontWeight={FontWeight.WEIGHT_600}
        color={"doveGrayUI"}
      >
        Finansujesz pojazd najmem lub leasingiem? W mAuto możesz skorzystać z
        wachlarza usług dodatkowych, dzięki którym użytkowanie auta będzie
        jeszcze wygodniejsze. Sam decydujesz o ich zakresie – możesz wybrać
        pełny pakiet, żeby zyskać kompleksową obsługę w jednym miejscu, albo
        wykupić pojedyncze usługi. Poszczególne pozycje możesz dodać do oferty w
        momencie wybierania auta. Zaznaczasz je w formularzu, który pokazuje ich
        miesięczny koszt i automatycznie podlicza ratę najmu lub leasingu wraz z
        wybranymi usługami.
        <br></br><br></br>
        Oferta mAuto obejmuje: 
        <ol>
          <li>Ubezpieczenie – wieloletnie ubezpieczenie OC, AC, NNW oraz Autoszyba to kompleksowa ochrona z gwarancją niezmiennej składki</li>
          <li>
            Assistance – szybka pomoc w 24 h na dobę. Pakiet zawiera m.in.: 
            holowanie, naprawę w miejscu zdarzenia, pomoc w podróży, samochód zastępczy i pomoc medyczną
          </li>
          <li>
            Obsługa serwisowa – naprawy i przeglądy auta z rabatami na usługi dla klientów mLeasingu. 
            Opłat za serwisowanie dokonasz na podstawie refaktury
          </li>
          <li>
            Karta paliwowa – wygoda płatności bezgotówkowych i przejrzyste rozliczenia. 
            Zbiorczą refakturę za tankowanie otrzymasz w kolejnym miesiącu
          </li>
        </ol>
      </Text>

      <Text
        as='h5'
        fontSize={FontSize.SIZE_24}
        lineHeight={LineHeight.HEIGHT_32}
      >
        Samochody poleasingowe w mAuto – dlaczego warto?
      </Text>
      <Text
        as='p'
        fontSize={FontSize.SIZE_12}
        lineHeight={LineHeight.HEIGHT_16}
        fontWeight={FontWeight.WEIGHT_600}
        color={"doveGrayUI"}
      >
        Jeśli szukasz używanego samochodu, który będzie wygodny, bezpieczny i
        zadbany, wybierz jedną z opcji finansowania aut poleasingowych w mAuto.
        Możesz zdecydować się na leasing lub najem, aby jednorazowo nie wykładać
        dużej sumy na zakup. To prosty sposób, by stać się użytkownikiem
        wymarzonego samochodu ulubionej marki. W ofercie mAuto znajdziesz
        wysokiej jakości samochody poleasingowe sprawdzone pod kątem technicznym
        przez renomowanych ekspertów rynku motoryzacyjnego. Tym samym zyskujesz
        pewność, że auto, które wybierzesz, będzie służyć Ci bez zarzutu przez
        długi czas. Po zakończonej umowie możesz wykupić pojazd lub podpisać
        umowę na kolejny. Leasing czy najem używanego samochodu? Decyzja należy
        do Ciebie. Sprawdź ofertę mAuto i wybierz model, który najlepiej spełnia
        Twoje oczekiwania. Pomoże Ci w tym zaawansowana wyszukiwarka, dzięki
        której wygodnie wyfiltrujesz np. samochody o niskim przebiegu, pojazdy
        konkretnej marki, z określonym typem nadwozia czy preferowaną skrzynią
        biegów. A gdy już upatrzysz swoje auto, wygodny kalkulator online
        pozwoli Ci w kilka chwil obliczyć ratę miesięczną. Pamiętaj też, że
        doradcy mAuto na każdym kroku służą Ci pomocą, aby proces ustalania
        warunków i podpisywania umowy przebiegł płynnie.
      </Text>
    </div>
  );
};

export default QuestionTextSectionLeaseCar;
