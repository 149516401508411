export function getInsurancePackagePriceByRentPeriods(vehicleDetails) {
  const selectedPeriod = vehicleDetails.Calculator.RentPeriods.find(
    (rentPeriod) => rentPeriod.Period.Status === true
  );

  return vehicleDetails.Calculator.Insurance.find(
    (insurance) => insurance.Period === selectedPeriod.Period.Value
  )?.Value;
}

export function getInsurancePackagePriceByLeasingPeriods(vehicleDetails) {
  let selectedPeriod = vehicleDetails.Calculator.LeasingPeriods.find(
    (rentPeriod) => rentPeriod.Period.Status === true
  );

  return vehicleDetails.Calculator.Insurance.find(
    (insurance) => insurance.Period === selectedPeriod.Period.Value
  )?.Value;
}

export function getInsurancePackagePriceBySelectedPeriod(vehicleDetails, selectedPeriod) {
  return vehicleDetails.Calculator.Insurance.find(
    (insurance) => insurance.Period === selectedPeriod.Value
  )?.Value;
}

export function getInstallmentRentPriceBySelectedOptions(vehicleDetails, selectedFilters) {
  const selectedPeriod = vehicleDetails.Calculator.RentPeriods.find(el => el.Period.Value === selectedFilters.Period);
  const selectedOw = selectedPeriod.Ows.find(el => el.Ow.Value === selectedFilters.Ow);
  const selectedMileage = selectedOw.Mileages.find(el => el.Value === selectedFilters.Mileage);

  return selectedMileage?.Price;
}

export function getInstallmentLeasingPriceBySelectedOptions(vehicleDetails, selectedFilters) {
  const selectedPeriod = vehicleDetails.Calculator.LeasingPeriods.find(el => el.Period.Value === selectedFilters.Period);
  const selectedOw = selectedPeriod.Ows.find(el => el.Ow.Value === selectedFilters.Ow);
  const selectedRv = selectedOw.Rvs.find(el => el.Value === selectedFilters.Rv);

  return selectedRv?.Price;
}

export function getOwBySelectedOptions(periods, selectedFilters) {
  const selectedPeriod = periods.find(el => el.Period.Value === selectedFilters.Period);
  const selectedOw = selectedPeriod.Ows.find(el => el.Ow.Value === selectedFilters.Ow);

  return selectedOw.Ow;
}

export function getOwPriceBySelectedOptions(periods, selectedFilters) {
  const ow = getOwBySelectedOptions(periods, selectedFilters);

  return ow?.Price;
}

export function getRvPriceLeasingBySelectedOptions(vehicleDetails, selectedFilters) {
  const selectedPeriod = vehicleDetails.Calculator.LeasingPeriods.find(el => el.Period.Value === selectedFilters.Period);
  const selectedOw = selectedPeriod.Ows.find(el => el.Ow.Value === selectedFilters.Ow);
  const selectedRv = selectedOw.Rvs.find(el => el.Value === selectedFilters.Rv);

  return selectedRv?.ValuePLN;
}

export function generateCalculatorData(data, filters, vehicleDetails, isNewVehicle) {
  let installmentPrice = data && data.InstallmentPrice;
  let ow = data && data.Ow;
  let rv = data && data.Rv;
  let insurancePrice = data && data.InsurancePriceInfo;
  let isPriceFromCalculator = false;

  if (isNewVehicle) {
    if (vehicleDetails.IsLeasingAvailable && filters.FinancingMethod === 2) {
      if (vehicleDetails.Calculator.LeasingPeriods && vehicleDetails.Calculator.LeasingPeriods.length) {
        installmentPrice = getInstallmentLeasingPriceBySelectedOptions(vehicleDetails, filters.Filters);
        rv = getRvPriceLeasingBySelectedOptions(vehicleDetails, filters.Filters);
        ow = getOwPriceBySelectedOptions(vehicleDetails.Calculator.LeasingPeriods, filters.Filters);
        insurancePrice = getInsurancePackagePriceByLeasingPeriods(vehicleDetails);
        isPriceFromCalculator = true;
      }
    }

    if (vehicleDetails.IsRentAvailable && filters.FinancingMethod === 1) {
      if (vehicleDetails.Calculator.RentPeriods && vehicleDetails.Calculator.RentPeriods.length) {
        installmentPrice = getInstallmentRentPriceBySelectedOptions(vehicleDetails, filters.Filters);
        ow = getOwPriceBySelectedOptions(vehicleDetails.Calculator.RentPeriods, filters.Filters);
        insurancePrice = getInsurancePackagePriceByRentPeriods(vehicleDetails);
        isPriceFromCalculator = true;
      }
    }
  }

  return {
    IsPriceFromCalculator: isPriceFromCalculator,
    InstallmentPriceFromCalculator: installmentPrice,
    InstallmentPricePromo: data &&data.InstallmentPricePromo || -1,
    InstallmentFinalPrice: installmentPrice,
    InsuranceAdditionalText: data &&data.InsuranceAdditionalText || vehicleDetails.Calculator.InsuranceAdditionalText,
    InsurancePriceInfo: insurancePrice || data && data.InsurancePriceInfo,
    Ow: ow,
    Rv: rv,
    TotalFees: data &&data.TotalFees || -1,
    params: filters
  };
}