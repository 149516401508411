import React, { Component } from "react";
import "./index.scss";
import PropTypes from "prop-types";
import { Text } from "@mbank-design/design-system/components";
import {
  FontColor,
  FontSize,
  FontWeight,
  LineHeight,
} from "@mbank-design/design-system/design-tokens/general/typography";

const HorizontalRadioBox = (props) => {
  HorizontalRadioBox.propTypes = {
    RadioName: PropTypes.string.isRequired,
    Options: PropTypes.array.isRequired,
    Label: PropTypes.string,
    Parent: PropTypes.string,
    Size: PropTypes.string,
    AdditionalInfo: PropTypes.any,
    Theme: PropTypes.string
  };
  return (
    <div className={`horizontal-radiobox-container ${props.Theme || ''}`}>
      {(props.AdditionalInfo || props.Label) && (
        <div className="header">
          {props.Label && (
            <Text
              as="p"
              styledAs={"small"}
              color={"doveGrayUI"}
            >
              {props.Label}
            </Text>
          )}
          {props.AdditionalInfo !== null &&
            props.AdditionalInfo !== undefined && (
              <Text
                as="p"
                lineHeight={LineHeight.HEIGHT_16}
                fontWeight={FontWeight.WEIGHT_600}
                fontSize={FontSize.SIZE_12}
              >
                {props.AdditionalInfo}
              </Text>
            )}
        </div>
      )}

      <div className="radiobox-container">
        {props.Options.map((item) => {
          return (
            <div
              key={item.Name + props.RadioName + (props.Parent || "")}
              className="radio-option"
              style={{ width: 100 / props.Options.length + "%" }}
            >
              <input
                type="radio"
                id={
                  item.Name +
                  (props.RadioName || "") +
                  (props.Parent || "")
                }
                name={props.RadioName + (props.Parent || "")}
                value={item.Value}
                checked={item.Status}
                onChange={(val) =>
                  props.handler({
                    Parent: props.Parent,
                    RadioName: props.RadioName,
                    Value: item.Value,
                    Status: val.target.checked,
                  })
                }
              />
              <Text
                as="label"
                align="center"
                lineHeight={LineHeight.HEIGHT_16}
                color={"doveGrayUI"}
                fontSize={FontSize.SIZE_12}
                className={props.Size || ""}
                htmlFor={
                  item.Name +
                  props.RadioName +
                  (props.Parent || "")
                }
              >
                {props.DisplayProperty ? item[props.DisplayProperty] : item.Name}{props.DisplayProperty === 'Price' ? ' zł' : ''}
              </Text>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HorizontalRadioBox;
